import { all } from 'redux-saga/effects';
import { fetchLogin } from './authentication.saga';
import { fetchDashboardDetails } from './dashboard.saga';
import { fetchMyProducts, } from './myproducts.saga';
import { fetchProductDetails } from './productDetails.saga'
import { editProducts } from './editProduct.saga'
import { postSingleProduct } from './addsingleproduct.saga';
import { postBulkProduct } from './addbulkproduct.saga';
import { fetchLocationQa } from './getLocationQa.saga';
import { fetchLocations } from './locations.saga'
import { fetchCategories } from './categories.saga';
import { fetchBrands } from './brands.saga';
import { fetchCompetitors } from './competitors.saga';
import { postMatchRate } from './matchrate.saga'
import { addCompetitor } from './addcompetitors.saga'
import { deleteCompetitor } from './deletecompetitor.saga'
import { deleteProduct } from './deleteproduct.saga';
import { fetchMarketPlace } from './marketplace.saga'
import { fetchRefreshProducts } from './refreshProduct.saga';
import { fetchMarketLocation } from './marketlocation.saga'
import { addMarketplace } from './addmarketplace.saga';
import { postChangepassword } from './changepassword.saga'
import { fetchProductPriceVariation } from './productPriceVariation.saga';
import { fetchDashboardPriceVariation } from './dashboardPriceVariation.saga';

import { fetchProductLocations, fetchProductCompetitors } from './productCompetitors.saga';
import { addMarketrule } from './addmarketrules.saga';
import { fetchDepartment } from './department.saga';
import { fetchCountryState } from './countrystate.saga';
import { addLocation } from './addlocations.saga'
import { addMarketLoc } from './addmarketloc.saga'
import { updateCurrency } from './currency.saga'
import { updateLocation } from './updatelocation.saga'
import { marketPlaceDelete } from './deletemarketplace.saga'
import { deleteAllProduct } from './deleteallproduct.saga'
import { getPrdctLocations } from './productLocations.saga'
import { updateProductNotification } from './productnotify.saga'
import { updateSettingsNotification } from './settingsnotify.saga'
import { fetchSellers } from './sellers.saga';
import { addCategory } from './addcategory.saga';
import { addDepartment } from './adddepartment.saga';
import { addSeller } from './addseller.saga';
import { handleGetQAUser } from './qauser.saga'
import { handleApproveAll } from './qaapprove.saga'
import { handleProductPrevNext } from './productprevnext.saga'
import { getXlsData } from './downloadxls.saga'
import { watchGetClients } from './clientsSaga';
import { watchUpdateClient } from './clientUpdate.saga';
import { watchCreateClients } from './clientsCreate.saga';
import { watchGetClientsMarketCount } from './clientMakrketCount.saga';
import { watchCreateClientMigrate } from './clientMigrate.saga';
import { watchSendComment } from './comments.saga';
import { watchPrdKeyvalue } from './productKeyvalue.saga';
import { watchMarketPlaceDeleteFull } from './deleteMarketplacefull.saga';
import { watchgetappnotification } from './getappnotification.saga';
import { watchPostPriceChangeNotification } from './pricechangeNotification.saga';
import { watchPrdEnablematch } from './updateEnablematch.saga';
import { getRefreshBtnStatus } from './getRefreshBtnStatus.saga';
import { refreshAll } from './refreshAll.saga';

export default function* rootSaga() {
    yield all([
        fetchLogin(),
        fetchDashboardDetails(),
        fetchMyProducts(),
        fetchProductLocations(),
        fetchProductCompetitors(),
        postSingleProduct(),
        postBulkProduct(),
        fetchLocationQa(),
        fetchLocations(),
        fetchCategories(),
        fetchBrands(),
        fetchDepartment(),
        fetchCompetitors(),
        postMatchRate(),
        addCompetitor(),
        deleteCompetitor(),
        editProducts(),
        deleteProduct(),
        fetchMarketPlace(),
        fetchRefreshProducts(),
        fetchProductDetails(),
        fetchMarketLocation(),
        addMarketplace(),
        addMarketrule(),
        postChangepassword(),
        fetchCountryState(),
        addLocation(),
        addMarketLoc(),
        updateCurrency(),
        updateLocation(),
        marketPlaceDelete(),
        deleteAllProduct(),
        getPrdctLocations(),
        updateProductNotification(),
        updateSettingsNotification(),
        fetchSellers(),
        addSeller(),
        addDepartment(),
        addCategory(),
        handleGetQAUser(),
        handleProductPrevNext(),
        handleApproveAll(),
        getXlsData(),
        fetchProductPriceVariation(),
        fetchDashboardPriceVariation(),
        watchGetClients(),
        watchUpdateClient(),
        watchCreateClients(),
        watchGetClientsMarketCount(),
        watchCreateClientMigrate(),
        watchSendComment(),
        watchPrdKeyvalue(),
        watchMarketPlaceDeleteFull(),
        watchgetappnotification(),
        watchPostPriceChangeNotification(),
        watchPrdEnablematch(),
        getRefreshBtnStatus(),
        refreshAll()
    ])
}  