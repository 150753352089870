import {
    PRODUCT_KEYVALUE,
    PRODUCT_KEYVALUE_SUCCESSFUL,
    PRODUCT_KEYVALUE_FAILED,
  } from '../constants/productkeyvalue.constants';
  
  export const getProductKeyValue = (productId) => ({
    type: PRODUCT_KEYVALUE,
    payload: productId,
  });
  
  export const getProductKeyValueSuccessful = (data) => ({
    type: PRODUCT_KEYVALUE_SUCCESSFUL,
    payload: data,
  });
  
  export const getProductKeyValueFailed = (error) => ({
    type: PRODUCT_KEYVALUE_FAILED,
    payload: error,
  });
  