import React from 'react';
import { Chart as ChartJS, ArcElement, CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    Legend, } from "chart.js";
    import datalabels from 'chartjs-plugin-datalabels';
import { Pie} from 'react-chartjs-2';
ChartJS.register(ArcElement, CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    datalabels,
    Legend);


const PieChartDashboard = props => {
    const currentTheme = props?.currentTheme
    const equalNumber = Number(props?.assortment_analysis.equal);
    const higherNumber = Number(props?.assortment_analysis.higher);
    const lowerNumber = Number(props?.assortment_analysis.lower);
    const totalNumber = Number(props?.assortment_analysis.total);
    const labels = ["Lower", "Higher", "Equal"];
   
    // console.log("currentTheme",currentTheme)

    const pieData = {
        labels,
        datasets: [
            {
              data: [lowerNumber, higherNumber, equalNumber],
              datalabels: {
                anchor: 'end',
                align:'end',
                offset:-40
              },
              backgroundColor: ['#3FCD7F', '#FF6767', '#DAC934'],
              hoverBackgroundColor: ['#3FCD7F', '#FF6767', '#DAC934'],
              border:0
            }
          ]
    };

    const option = {
        responsive: true,
        maintainAspectRatio: true,
        border:0,
        borderWidth:0,
        line:0,
        stroke:0,
        scales:{
          x:{
            ticks:{
              maxRotation: 0,
              display:false
            }
          }
        },
        
        plugins: {
          legend: {
            display: false,
          },
          aspectRatio: 4 / 3,
          datalabels: {
            formatter: Math.round,
            display: function(context) {
              var dataset = context.dataset;
              // var count = dataset.data.length;
              var value = dataset.data[context.dataIndex];
              // console.log('context',value, count, (count * 1.5))
              return value; //> count * 1.5;
            },
            color: '#FFF',
          }
        }
    }


return (
    // <Pie options={option} width={'50%'} height={'50%'} data={pieData} />
    <div style={{ position: 'relative' }}>
            <Pie width={'50%'} height={'50%'} data={pieData} options={option} />
            <div
                style={{
                    position: 'absolute',
                    top: '2%',
                    right: '-40px',
                    // transform: 'translateY(-50%)',
                    color: currentTheme === "dark" ? '#FFF' : "black"
                }}
            >
                Total: {totalNumber}
            </div>
        </div>
)

}
export default PieChartDashboard;