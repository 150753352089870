import * as constants from '../constants/getRefreshBtnStatus.constants';

export const getRefreshBtnStatus = (payload) => ({
    type: constants.GET_REFRESH_BTN,
    payload
})

export const getRefreshBtnStatusSuccessful = (response) => ({
    type: constants.GET_REFRESH_BTN_SUCCESSFULL,
    response
});

export const getRefreshBtnStatusFailed = (payload) => ({
    type: constants.GET_REFRESH_BTN_FAILED,
    payload
});