import * as constants from '../constants/clients.constants';
// import { GET_CLIENTS_SUCCESS,GET_CLIENTS,GET_CLIENTS_FAIL } from '../constants/clients.constants';
export const initialState = {
  loading: false,
  clients: [],
  isError: null,
};

export const clientsReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_CLIENTS:
      return {
        ...state,
        loading: true,
      };
    case constants.GET_CLIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        clients: action.payload,
        isError: null,
      };
    case constants.GET_CLIENTS_FAIL:
      return {
        ...state,
        loading: false,
        isError: action.payload,
      };
    default:
      return state;
  }
};
