// Import your action constants
import { GETAPP_NOTIFICATION, GETAPP_NOTIFICATION_FAILED, GETAPP_NOTIFICATION_SUCCESSFUL } from "../constants/getappnotification.constants";

// Define the initial state of your reducer
const initialState = {
  loading: false,
  data: null,
  error: null,
};

// Create the reducer function
export const getAppNotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETAPP_NOTIFICATION:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GETAPP_NOTIFICATION_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        data: action.payload, //  action.payload contains data
        error: null,
      };
    case GETAPP_NOTIFICATION_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload, //  action.payload contains error message
      };
    default:
      return state;
  }
};
