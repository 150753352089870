import {useEffect, useCallback} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getItems } from '../../../utils/storage';
import useActions from '../../../redux/use-actions';
import getBulkProductDetails from '../../../redux/selectors/addbulkproduct.selector';
import {handleBulkProduct, resetBulkProduct } from '../../../redux/actions/addbulkproduct.actions';
import * as dashboardactions from '../../../redux/actions/dashboard.actions';

const UseAddProduct = () => {
    
    const [productsCreated] = getItems(['productsCreated']);
    const navigate = useNavigate();
    const actions = useActions({ handleBulkProduct, resetBulkProduct });
    const { bulkProductDetails } = useSelector(getBulkProductDetails)
    const {isSuccessful, isFailure, errorMessage} = bulkProductDetails;
    const dispatch = useDispatch();

    useEffect(() =>{
        if(isSuccessful){
            setTimeout(()=>{
                dispatch(actions.resetBulkProduct());
                if(!productsCreated){
                    navigate('/settings')
                } else {
                    setTimeout(() => {
                        dispatch(dashboardactions.dashboardReset()) 
                        window.location.reload();
                    }, 2000);
                    // navigate('/dashboard')
                }
            }, 2*1000)
        }
    }, [isSuccessful]) // eslint-disable-line

    useEffect(() =>{
        if(isFailure){
            setTimeout(()=>{
                // console.log('error')
                dispatch(actions.resetBulkProduct());
            }, 2*1000)
        }
    }, [isFailure]) // eslint-disable-line

    const handleBulkProductSubmit = useCallback((pValues) =>{
        actions.handleBulkProduct(
            pValues
          );
    }, [actions])
    
    return {
        handleBulkProductSubmit, isSuccessful, isFailure, errorMessage
    }
}

export default UseAddProduct;