import * as constants from '../constants/comments.constants';
  
  // send comment new-comment
  export const sendComment = (commentData) => ({
    type: constants.COMMENT_SEND_REQUEST,
    payload: commentData,
  }); 
  
  export const sentCommentSuccess = (comment) => ({
    type: constants.COMMENT_SEND_SUCCESS,
    payload: comment,
  });
  
  export const sentCommentFail = (error) => ({
    type: constants.COMMENT_SEND_FAILURE,
    payload: error,
  });

