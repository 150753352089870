import { PRICE_CHANGE_NOTIFICATION,PRICE_CHANGE_NOTIFICATION_FAILED,PRICE_CHANGE_NOTIFICATION_SUCCESSFUL } from "../constants/pricechangeNotification.constats";

// Action creator for requesting app notifications
export const postpricecahgneNotification = (action) => {
    return {
      type: PRICE_CHANGE_NOTIFICATION,
      payload:action
    };
  };
  
  // Action creator for successful app notifications retrieval
  export const postpricecahgneNotificationSuccess = (data) => {
    return {
      type: PRICE_CHANGE_NOTIFICATION_SUCCESSFUL,
      payload: data, // You can pass the retrieved data as payload
    };
  };
  
  // Action creator for failed app notifications retrieval
  export const postpricecahgneNotificationFailure = (error) => {
    return {
      type: PRICE_CHANGE_NOTIFICATION_FAILED,
      payload: error, // You can pass the error message as payload
    };
  };