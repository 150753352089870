import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';
import { Grid, Box, Stack, Paper, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import moment from 'moment';

import {styled} from '@mui/material/styles'

import { handleDeleteCompetitor, deleteCompetitorFailed  } from '../../../../redux/actions/deletecompetitor.actions';
import deleteCompetitorSelector from '../../../../redux/selectors/deletecompetitor.selector'
import useActions from '../../../../redux/use-actions';
import AlertDialogSlide from '../../../../components/Dialog/';


// import UseCompetitor from './useCompetitor';
import PriceVariationChart from '../../../../components/charts/PriceVariationChart';
import ComeptitorItem from './competitorItem';


const Competitor = props => {

    const { competitorInformation, handleReload, totalProductValue, productData, activeLoc } = props;
    const actions = useActions({ handleDeleteCompetitor, deleteCompetitorFailed });
    const [value, setValue] = useState('1');
    const [marketLabel, setMarketLabel] = useState([])
    const [deletePop, setDeletePop] = useState({isVisible:false, deleteId:null})
    const { deleteCompetitorDetails } = useSelector(deleteCompetitorSelector)
    const { isSuccessful } = deleteCompetitorDetails;
    // const [marketList, setMarketList] = useState([]);
    const [formattedDateTimeMap, setFormattedDateTimeMap] = useState({});
    // console.log("competitorInformation",competitorInformation)
    // console.log("marketLabel",marketLabel)
    // console.log("totalProductValue",totalProductValue)
  // code for lastUpdated time with 
useEffect(() => {
  if (!isEmpty(competitorInformation)) {
    
    const marketLabel = [...new Set(competitorInformation.map(competitor => competitor.market_place.market_place_name).flat())]
    setMarketLabel(marketLabel.sort());

    // Calculate formattedDateTime for each market
    const formattedDateTimeObj = {};
    marketLabel.forEach((market) => {
      const marketCompetitors = competitorInformation.filter((competitor) => competitor.market_place.market_place_name === market);
      // console.log("marketCompetitors",marketCompetitors)
      const modTimeArray = marketCompetitors?.sort((a, b) => {
        if (a.comp_prd_custom_field === "BUY BOX" && b.comp_prd_custom_field !== "BUY BOX") {
          return -1;
        } else if (a.comp_prd_custom_field !== "BUY BOX" && b.comp_prd_custom_field === "BUY BOX") {
          return 1;
        } else {
          return b.comp_prd_score - a.comp_prd_score;
        }
      });
      const lastUpdatedTime = modTimeArray[0]?.modified_date;
      const formattedDateTime = moment(lastUpdatedTime).format('MM DD YYYY h:mm A');
      // const formattedDateTime = moment(lastUpdatedTime, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DD hh:mm A');
      formattedDateTimeObj[market] = formattedDateTime;

      // Log formattedDateTime and product ID
      // console.log("Market:", market);
      // console.log("Formatted Date and Time:", formattedDateTime);
      // if (modTimeArray.length > 0) {
      //   const compPrdId = modTimeArray[0]?.comp_prd_id;
      //   console.log("Product ID:", compPrdId);
      // }
    });
    setFormattedDateTimeMap(formattedDateTimeObj);
  }
}, [competitorInformation]);


// console.log(formattedDateTime);

 useEffect(() => {
    if(isSuccessful){
    setTimeout(()=>{
        actions.deleteCompetitorFailed();
        handleReload();
    }, 1000)
 }
 }, [isSuccessful, actions]) // eslint-disable-line

    const updateCompetitor = (marketName) =>{
        const filteredProducts = competitorInformation.filter(
            (product) => product.market_place.market_place_name === marketName
        );
        filteredProducts.sort(
        (a, b) =>  {
            if (a.comp_prd_score === b.comp_prd_score) {
              return b.comp_prd_sales_price - a.comp_prd_sales_price;
            } else {
              return b.comp_prd_score - a.comp_prd_score;
            }
          }
        );
        return filteredProducts;
    }
    

    useEffect(() => {
        if(!isEmpty(competitorInformation)){
            const marketLabel = [...new Set(competitorInformation.map(competitor => competitor.market_place_name).flat())]
            setMarketLabel(marketLabel.sort());
        }
    }, [competitorInformation]) //

    const Item = styled(Paper)(({ theme }) => ({
        // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#232D3B',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: '#FFFFFF',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        boxShadow:'none'
      }));

    const Tabe = styled(Tab)(({theme}) => {
        return {
            '.MuiTab-root':{
                '& .Mui-selected': {
                    backgroundColor: '#559EFF',
                    color:'#0E2B5A'
                }
            }
        }
    })
    

    
    const itemStyle ={marginTop:1, display:"flex", flexFlow:'column', width:"100%", padding:'25px 0px 25px 20px', justifyContent:'space-between', alignItems:'start'}


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDeleteFailed = () =>{
    setDeletePop({isVisible:false, deleteId:null});
  }
  const handleDeleteSuccess = () =>{
    // console.log('handleDeleteSuccess ');
    actions.handleDeleteCompetitor(deletePop.deleteId)
    setDeletePop({isVisible:false, deleteId:null});
  }
  const handleDelete = (pId) =>{
    // actions.handleDeleteCompetitor(pId)
    setDeletePop({isVisible:true, deleteId:pId});
  }

  
    return (
        <Grid>
            {deletePop.isVisible && <AlertDialogSlide isAlert={true} handleSuccess={handleDeleteSuccess} handleFailed={handleDeleteFailed}/>}
            { isSuccessful && <Typography variant='body2'>Competitor deleted successfully.</Typography>}
            {isEmpty(competitorInformation) ?
                <Grid item xs={12} sx={{height:200, display:'flex', alignItems:'center', justifyContent:'center'}} >
                    <Typography>No Competior Available</Typography>
                </Grid>
            :
            <TabContext value={value}>
           <Box sx={{ borderBottom: 2, borderColor: '#515151', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              {marketLabel.map((item, index) => (
                <Tabe
                  key={`tab_${index}`}
                  sx={{ color: 'text.secondary', border: 'none' }}
                  label={item}
                  value={`${Number(index + 1)}`}
                />
              ))}
            </TabList>
            {value && formattedDateTimeMap[marketLabel[value - 1]] && (
      <Typography sx={{ fontSize: '0.7rem', marginRight: "1em" }}>last updated on - {formattedDateTimeMap[marketLabel[value - 1]]}</Typography>
    )}
          </Box>

            {marketLabel.map((compet, index) => {
                const updatedArr = updateCompetitor(compet)
                return <TabPanel key={`competitor_${index}`} value={`${Number(index+1)}`} sx={{padding:2}} >
                    <Stack spacing={0} xs={10} sx={{marginTop:0}}>
                    {/* sorting competitor item with BUYBOX and descending highest_score */}
                    {updatedArr
  .sort((a, b) => {
    if (a.comp_prd_custom_field === "BUY BOX" && b.comp_prd_custom_field !== "BUY BOX") {
      return -1; // a should come before b
    } else if (a.comp_prd_custom_field !== "BUY BOX" && b.comp_prd_custom_field === "BUY BOX") {
      return 1; // a should come after b
    } else {
      // If Buy Box is not applicable or both items have the same Buy Box status, sort based on comp_prd_score in descending order
      const scoreComparison = b.comp_prd_score - a.comp_prd_score;
      if (scoreComparison !== 0) {
        return scoreComparison;
      } 
      else {
        // If the comp_prd_score is the same for both items, sort based on the absolute difference from "totalProductValue"
        const aAbsoluteDiff = Math.abs((Number(a.comp_prd_size) * Number(a.comp_prd_no_of_pieces)) - totalProductValue);
        const bAbsoluteDiff = Math.abs((Number(b.comp_prd_size) * Number(b.comp_prd_no_of_pieces)) - totalProductValue);
        return aAbsoluteDiff - bAbsoluteDiff;
      }
    }
  })
  .map((item) => {
    // console.log("item", item);
    return (
      <Item key={`competitor${Math.random()}`} sx={{ ...itemStyle }}>
        <ComeptitorItem productData={productData} competitordata={item} handleDelete={handleDelete} setDeletePop={setDeletePop} {...props} />
      </Item>
    );
  })}

                    <PriceVariationChart activeLoc={activeLoc} marketValue= {value} marketName={compet} {...props} />
                </Stack>
                </TabPanel>
            })}
        </TabContext>

            }
            
        </Grid>
    )
}

export default Competitor;