import * as constants from '../constants/authentication.constants';

export const validateLogin = (payload) => ({
  type: constants.VALIDATE_LOGIN,
  payload
});

export const userValidationSuccessful = (response) => ({
  type: constants.LOGIN_SUCCESSFUL,
  response
});

export const userValidationFailed = (payload) => ({
  type: constants.LOGIN_FAILED,
  payload
});

export const resetAuthState = () => ({
  type: constants.RESET_AUTH_STATE
});

export const toggleSessionExpire = (payload) => ({
  type: constants.TOGGLE_SESSION_EXPIRED_STATE,
  payload
});