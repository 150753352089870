import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Box, List, Button, Accordion, AccordionSummary, FormControl, InputAdornment, OutlinedInput, FormControlLabel, Typography, AccordionDetails, Checkbox, RadioGroup, Radio, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import { SearchOutlined } from '@mui/icons-material';
import { styled } from "@mui/material/styles";
import { getItem } from '../../utils/storage';

//import SearchBox from '../../components/searchBox'
import UseLeft from './UseLeft';
import { isEmpty } from 'lodash';

const LeftPanel = props => {

  const { selectedQaUser, selectedPrice, selectedLoc, filteredData, originalData, isSearched, handlePriceFilter, handleDeptFilter, handleCategoryFilter, handleBrandFilter, handleLocationFilter, removeAllFilters, refreshAllData } = props;

  const [locationValue, setLocationValue] = useState(selectedLoc);
  // const [priceValue, setPriceValue] = useState();
  const priceFilterData = [
    { name: 'Higher', value: 'higher' },
    { name: 'Equal', value: 'equal' },
    { name: 'Lower', value: 'lower' }
  ];
  const { departmentFilterData, categoryFilterData, brandFilterData, locationFilterData,
    handlePriceCheck, handleDepartmentChecks, handleCategoryChecks, handleBrandChecks, handleLocationCheck, handleRemoveFilter
  } = UseLeft({ selectedQaUser, filteredData, originalData, isSearched, handlePriceFilter, handleDeptFilter, handleCategoryFilter, handleBrandFilter, handleLocationFilter, removeAllFilters, selectedLoc })

  const [brandCollapse, setBrandCollapse] = useState(false);
  const [categoryCollapse, setCategoryCollapse] = useState(false);
  const [deptCollapse, setDeptCollapse] = useState(false);
  const [locCollapse, setLocCollapse] = useState(false);
  const [priceCollapse, setPriceCollapse] = useState(false);
  const [searchBrandData, setSearchBrandData] = useState(brandFilterData);
  const [serchBrandValue, setSearchBrandValue] = useState('')
  const [brandMessage, setBrandMessage] = useState('');
  const [searchCategoryData, setSearchCategoryData] = useState(categoryFilterData);
  const [serchCateValue, setSearchCateValue] = useState('')
  const [cateMessage, setCateMessage] = useState('');
  const [searchDepartmentData, setSearchDepartmentData] = useState(departmentFilterData);
  const [serchDepartmentValue, setSerchDepartmentValue] = useState('')
  const [deptMessage, setDeptMessage] = useState('');
  const pageList = getItem('pageList');


  // key value
  // const [checkedKeyvalue, setChecked] = useState(false);

  // const handleChange = (event) => {
  //   setCheckedKeyvalue(event.target.checked);
  //   // console.log(checkedKeyvalue)
  // };

  const OutlinedBox = styled(OutlinedInput)(({ theme }) => {
    return {
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: '#7B7B7B'
      }
    }
  })
  const StyledAccord = styled(Accordion)(({ theme }) => {
    return {

      '.MuiAccordionSummary-root.Mui-expanded': {
        minHeight: '48px !important'
      },
      '.MuiAccordionSummary-content.Mui-expanded': {
        margin: '12px 0'
      },
      '.MuiAccordionDetails-root': {
        padding: '8px 0px'
      }
    }
  })
  const accordWidth = '85%';


  const checkCategoryCheck = (pStr, pArr) => {
    return pArr.find(item => String(item) === String(pStr));
  }
  useEffect(() => {
    const tempBrandData = [...brandFilterData];
    if (pageList !== null && brandFilterData !== null) {
      const tempPageList = JSON.parse(pageList).split('&')
      tempPageList.map(item => {
        if (item.indexOf('prd_brand__in=') > -1) {
          const brand_id__in = item.split("=")[1];
          const tempBrandId = brand_id__in === '' ? [] : brand_id__in.split(',');
          if (!isEmpty(tempBrandId)) {
            tempBrandData.map(items => {
              if (checkCategoryCheck(items.value, tempBrandId)) {
                items.checked = true;
              }
              return items;
            })
          }
        }
        return item;
      })
      setSearchBrandData(tempBrandData)
    } else {
      setSearchBrandData(brandFilterData)
    }
  }, [brandFilterData]) // eslint-disable-line

  useEffect(() => {
    // console.log('selectedLoc');
    setLocationValue(selectedLoc)
  }, [selectedLoc])

  useEffect(() => {
    // console.log(pageList, ' categoryFilterData ', categoryFilterData);
    const tempCategoryData = [...categoryFilterData];
    if (pageList !== null && categoryFilterData !== null) {
      const tempPageList = JSON.parse(pageList).split('&')
      tempPageList.map(item => {
        if (item.indexOf('category_id__in=') > -1) {
          const category_id__in = item.split("=")[1];
          const tempCateId = category_id__in === '' ? [] : category_id__in.split(',');
          if (!isEmpty(tempCateId)) {
            tempCategoryData.map(items => {
              if (checkCategoryCheck(items.value, tempCateId)) {
                // console.log('came here ', items.checked, items.value, tempCateId);
                items.checked = true;
              }
              return items;
            })
          }
        }
        return item;
      })
      // console.log(tempCategoryData, ' categoryFilterData ', categoryFilterData)
      setSearchCategoryData(tempCategoryData)
    } else {
      setSearchCategoryData(categoryFilterData)
    }
  }, [categoryFilterData]) // eslint-disable-line

  useEffect(() => {
    const tempDepartmentData = [...departmentFilterData];
    if (pageList !== null && departmentFilterData !== null) {
      const tempPageList = JSON.parse(pageList).split('&')
      tempPageList.map(item => {
        if (item.indexOf('dept_id__in=') > -1) {
          const dept_id__in = item.split("=")[1];
          const tempDeptId = dept_id__in === '' ? [] : dept_id__in.split(',');
          if (!isEmpty(tempDeptId)) {
            tempDepartmentData.map(items => {
              if (checkCategoryCheck(items.value, tempDeptId)) {
                items.checked = true;
              }
              return items;
            })
          }
        }
        return item;
      })
      setSearchDepartmentData(tempDepartmentData)
    } else {
      setSearchDepartmentData(departmentFilterData)
    }
  }, [departmentFilterData]) // eslint-disable-line

  const handlePriceValue = (event) => {
    event.preventDefault();
    // console.log('handlePriceValue ', event.target.value)
    // setPriceValue(event.target.value)
    handlePriceCheck(event.target.value)
  }
  const handleLocationValue = (e) => {
    // console.log('handleLocationValue ', e.target.value)
    setLocationValue(e.target.value)
    handleLocationCheck(e)
  }
  const clearBrandSearch = (event) => {
    // console.log('cleared')
    setBrandMessage('')
    setSearchBrandValue('');
    const tempData = [...brandFilterData];
    setSearchBrandData(tempData)
  }
  const filterBrandSearch = (serchValue) => {
    const tempData = [...brandFilterData];
    const checkedCheckbox = tempData.filter(checkbox => {
      return String(checkbox.name).toLowerCase().includes(serchValue);
      // if (String(checkbox.name).toLowerCase().includes(serchValue)) return checkbox;
    })
    if (serchValue !== '') {

      if (isEmpty(checkedCheckbox)) {
        setSearchBrandData([])
        setBrandMessage('No brand found.')
        return;
      }
      setSearchBrandData(checkedCheckbox)
    } else {
      setSearchBrandData(tempData)
    };
  }
  const handleBrandButtonSearch = () => {
    const searchElement = document.getElementById('searchBrandTextBox')
    const serchValue = searchElement.value.toLowerCase();
    filterBrandSearch(serchValue)
  }
  const handleBrandSearch = useCallback((event) => {
    // console.log(event.target.value, categoryFilterData)
    event.preventDefault();
    setBrandMessage('')
    if (event.keyCode !== 13 && event.keyCode !== 8) return;

    setSearchBrandValue(event.target.value);
    if (event.keyCode === 8) {
      clearBrandSearch(event)
      return;
    }
    const serchValue = String(event.target.value).toLowerCase();
    filterBrandSearch(serchValue)
  }, [brandFilterData]) // eslint-disable-line


  const clearCategorySearch = (event) => {
    // console.log('cleared')
    setCateMessage('')
    setSearchCateValue('');
    setSearchCategoryData(categoryFilterData)
  }
  const filterCateSearch = (serchValue) => {
    const tempData = [...categoryFilterData];
    const checkedCheckbox = tempData.filter(checkbox => {
      return String(checkbox.name).toLowerCase().includes(serchValue)
      // if (String(checkbox.name).toLowerCase().includes(serchValue)) return checkbox;
    })
    if (serchValue !== '') {

      if (isEmpty(checkedCheckbox)) {
        setSearchCategoryData([])
        setCateMessage('No category found.')
        return;
      }
      setSearchCategoryData(checkedCheckbox)
    } else {
      setSearchCategoryData(tempData)
    };
  }
  const handleCateButtonSearch = () => {
    const searchElement = document.getElementById('searchcategoryTextBox')
    const serchValue = searchElement.value.toLowerCase();
    filterCateSearch(serchValue)
  }
  const handleCategorySearch = useCallback((event) => {
    // console.log(event.target, event)
    event.preventDefault();
    setCateMessage('')
    if (event.keyCode !== 13 && event.keyCode !== 8) return;

    setSearchCateValue(event.target.value);
    if (event.keyCode === 8) {
      clearCategorySearch(event)
      return;
    }

    const serchValue = String(event.target.value).toLowerCase();
    filterCateSearch(serchValue)
  }, [categoryFilterData]) // eslint-disable-line

  const clearDepartmentSearch = (event) => {
    // console.log('cleared')
    setDeptMessage('')
    setSerchDepartmentValue('');
    const tempData = [...departmentFilterData];
    setSearchDepartmentData(tempData)
  }
  const filterDeptSearch = (serchValue) => {
    const tempData = [...departmentFilterData];
    const checkedCheckbox = tempData.filter(checkbox => {
      return String(checkbox.name).toLowerCase().includes(serchValue)
      // if (String(checkbox.name).toLowerCase().includes(serchValue)) return checkbox;
    })
    if (serchValue !== '') {

      if (isEmpty(checkedCheckbox)) {
        setSearchDepartmentData([])
        setDeptMessage('No department found.')
        return;
      }
      setSearchDepartmentData(checkedCheckbox)
    } else {
      setSearchDepartmentData(tempData)
    };
  }
  const handleDeptButtonSearch = () => {
    const searchElement = document.getElementById('searchdeptTextBox')
    const serchValue = searchElement.value.toLowerCase();
    filterDeptSearch(serchValue)
  }
  const handleDeptSearch = useCallback((event) => {
    event.preventDefault();
    setDeptMessage('')
    if (event.keyCode !== 13 && event.keyCode !== 8) return;

    setSerchDepartmentValue(event.target.value);
    if (event.keyCode === 8) {
      clearDepartmentSearch(event)
      return;
    }
    const serchValue = String(event.target.value).toLowerCase();
    filterDeptSearch(serchValue)
  }, [departmentFilterData]) // eslint-disable-line



  const handleBrandCollapse = () => {
    setBrandCollapse(prevState => !prevState)
  }

  const handleDeptCollapse = () => {
    setDeptCollapse(prevState => !prevState)
  }
  const handleLocCollapse = () => {
    setLocCollapse(prevState => !prevState)
  }
  const handlePriceCollapse = () => {
    setPriceCollapse(prevState => !prevState);
  }


  const handleCategoryCollapse = () => {
    setCategoryCollapse(prevState => !prevState)
  }


  const checkedIcons = () => {
    return <CheckIcon color='#FFFFFF' sx={{ color: 'text.primary', fontSize: 14, borderRadius: 1, border: '1px solid #FFFFFF', borderColor: 'text.secondary', mr: 0.5, backgroundColor: 'background.default' }} />
  }
  const uncheckedIcons = () => {
    return <CheckIcon sx={{ color: 'transparent', fontSize: 14, borderRadius: 1, border: '1px solid #FFFFFF', borderColor: 'text.secondary', mr: 0.5, backgroundColor: 'none' }} />
  }
  // function for clearing error messages
  const handleClearFilters = () => {
    handleRemoveFilter();
    setBrandMessage("");
    setCateMessage("");
    setDeptMessage("");
    setSearchBrandValue("");
    setSearchCateValue("");
    setSerchDepartmentValue("");
  };
  useEffect(() => {
    if (refreshAllData) {
      handleClearFilters();
    }
  }, [refreshAllData]) // eslint-disable-line
  return (
    <Grid xs={2} sm={2} md={2} item sx={{ minHeight: 'calc(100vh - 70px)', pl: 3, maxWidth: '15% !important', pt: 1, backgroundColor: 'background.paper' }}>
      <Box sx={{ overflow: 'auto', paddinLeft: 2, paddingTop: 2 }}>
        <Button
          variant='text'
          onClick={handleClearFilters}
          sx={{ fontSize: 12, textDecoration: 'underline', color: 'text.primary', textTransform: 'inherit' }}
        >
          Clear filters
        </Button>
        <StyledAccord
          expanded={brandCollapse}
          onChange={handleBrandCollapse}
          disableGutters sx={{ background: 'transparent', boxShadow: 'none', ml: 1, mr: 2, width: `${accordWidth}` }}>
          <AccordionSummary sx={{ margin: 0, paddingLeft: 0, paddingRight: 0 }}
            expandIcon={<ExpandMoreIcon sx={{ color: 'text.primary' }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={{ fontSize: 12 }}>Brand</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontSize: 12 }}>{brandMessage}</Typography>
            <FormControl sx={{ m: 0, mr: 1, mb: 2, width: '20ch' }} variant="outlined">
              <OutlinedBox size='small'
                id="searchBrandTextBox"
                type='text' sx={{ fontSize: 11, backgroundColor: 'background.paper', color: 'text.primary' }}
                //value={serchCateValue}
                defaultValue={serchBrandValue}
                onKeyUp={handleBrandSearch}
                // onKeyUp={e => setSearchedText(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="search icon"
                      edge="end"
                      onClick={handleBrandButtonSearch}
                    >
                      <SearchOutlined sx={{ color: 'text.secondary' }} />
                    </IconButton>
                  </InputAdornment>
                }
                label=""
              />
            </FormControl>
            <List className='custom-scrollbar' sx={{ display: 'flex', flexFlow: 'column', height: 'auto', maxHeight: 200, overflowX: 'hidden' }}>
              {searchBrandData.map((item, index) => {
                if (item.checked) {
                  return (<FormControlLabel key={`brand${index}`}
                    onChange={e => handleBrandChecks(e)}
                    sx={{ fontSize: 11, ml: 0, color: 'text.secondary', textTransform: 'capitalize' }}
                    control={<Checkbox id={item.name} checked={true} checkedIcon={checkedIcons()} sx={{ padding: '5px' }} />}
                    label={<Typography variant='body2' sx={{}}><span dangerouslySetInnerHTML={{ __html: item.name }} /></Typography>}
                  />)
                }else{
                  return null;
                }
              })}
              {searchBrandData.map((item, index) => {
                if (!item.checked) {
                  return (<FormControlLabel key={`brand${index}`}
                    onChange={e => handleBrandChecks(e)}
                    sx={{ fontSize: 11, ml: 0, color: 'text.secondary', textTransform: 'capitalize' }}
                    control={<Checkbox id={item.name} checked={false} icon={uncheckedIcons()} sx={{ padding: '5px' }} />}
                    label={<Typography variant='body2' sx={{}}><span dangerouslySetInnerHTML={{ __html: item.name }} /></Typography>}
                  />)
                }else{
                  return null;
                }
              })}
            </List>
          </AccordionDetails>
        </StyledAccord>
        <StyledAccord expanded={categoryCollapse}
          onChange={handleCategoryCollapse}
          disableGutters sx={{ background: 'transparent', boxShadow: 'none', ml: 1, mr: 2, width: `${accordWidth}` }}>
          <AccordionSummary sx={{ margin: 0, paddingLeft: 0, paddingRight: 0 }}
            expandIcon={<ExpandMoreIcon sx={{ color: 'text.primary' }} />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography sx={{ fontSize: 12 }}>Category</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontSize: 12 }}>{cateMessage}</Typography>
            <FormControl sx={{ m: 0, mr: 1, mb: 2, width: '20ch' }} variant="outlined">
              <OutlinedBox size='small'
                id="searchcategoryTextBox"
                type='text' sx={{ fontSize: 11, backgroundColor: 'background.paper', color: 'text.primary' }}
                //value={serchCateValue}
                defaultValue={serchCateValue}
                onKeyUp={handleCategorySearch}
                // onKeyUp={e => setSearchedText(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="search icon"
                      edge="end"
                      onClick={(e) => handleCateButtonSearch(e)}
                    >
                      <SearchOutlined sx={{ color: 'text.secondary' }} />
                    </IconButton>
                  </InputAdornment>
                }
                label=""
              />
            </FormControl>
            <List className='custom-scrollbar' sx={{ display: 'flex', flexFlow: 'column', height: 'auto', maxHeight: 200, overflowX: 'hidden' }}>
              {searchCategoryData.map((item, index) => {
                if (item.checked) {
                  return (<FormControlLabel key={`check${index}`}
                    sx={{ fontSize: 11, ml: 0, color: 'text.secondary', textTransform: 'capitalize' }}
                    onChange={e => handleCategoryChecks(e)}
                    control={<Checkbox id={item.name} checked={true} checkedIcon={checkedIcons()} sx={{ padding: '5px' }} />}
                    label={<Typography variant='body2' sx={{}}>{item.name}</Typography>}
                  />)
                }else{
                  return null;
                }
              })}
              {searchCategoryData.map((item, index) => {
                if (!item.checked) {
                  return (<FormControlLabel key={`check${index}`}
                    sx={{ fontSize: 11, ml: 0, color: 'text.secondary', textTransform: 'capitalize' }}
                    onChange={e => handleCategoryChecks(e)}
                    control={<Checkbox id={item.name} checked={false} icon={uncheckedIcons()} sx={{ padding: '5px' }} />}
                    label={<Typography variant='body2' sx={{}}>{item.name}</Typography>}
                  />)
                }else{
                  return null;
                }
              })}
            </List>
          </AccordionDetails>
        </StyledAccord>
        <StyledAccord expanded={deptCollapse}
          onChange={handleDeptCollapse} disableGutters sx={{ background: 'transparent', boxShadow: 'none', ml: 1, mr: 2, width: `${accordWidth}` }}>
          <AccordionSummary sx={{ margin: 0, paddingLeft: 0, paddingRight: 0 }}
            expandIcon={<ExpandMoreIcon sx={{ color: 'text.primary' }} />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Typography sx={{ fontSize: 12 }}>Department</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontSize: 12 }}>{deptMessage}</Typography>
            <FormControl sx={{ m: 0, mr: 1, mb: 2, width: '20ch' }} variant="outlined">
              <OutlinedBox size='small'
                id="searchdeptTextBox"
                type='text' sx={{ fontSize: 11, backgroundColor: 'background.paper', color: 'text.primary' }}
                //value={serchCateValue}
                defaultValue={serchDepartmentValue}
                onKeyUp={handleDeptSearch}
                // onKeyUp={e => setSearchedText(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="search icon"
                      edge="end"
                      onClick={handleDeptButtonSearch}
                    >
                      <SearchOutlined sx={{ color: 'text.secondary' }} />
                    </IconButton>
                  </InputAdornment>
                }
                label=""
              />
            </FormControl>
            <List className='custom-scrollbar' sx={{ display: 'flex', flexFlow: 'column', height: 'auto', maxHeight: 200, overflowX: 'hidden' }}>
              {searchDepartmentData.map((item, index) => {
                if (item.checked) {
                  return (<FormControlLabel key={`department${index}`}
                    sx={{ fontSize: 11, ml: 0, color: 'text.secondary', textTransform: 'capitalize' }}
                    onChange={e => handleDepartmentChecks(e)}
                    control={<Checkbox id={item.name}
                      checked={true}
                      checkedIcon={checkedIcons()}
                      sx={{ padding: '5px', }} />}
                    label={<Typography variant='body2' sx={{ fontSize: 11, color: 'text.secondary' }}>{item.name}</Typography>}
                  />)
                }else{
                  return null;
                }
              })}
              {searchDepartmentData.map((item, index) => {
                if (!item.checked) {
                  return (<FormControlLabel key={`department${index}`}
                    sx={{ fontSize: 11, ml: 0, color: 'text.secondary', textTransform: 'capitalize' }}
                    onChange={e => handleDepartmentChecks(e)}
                    control={<Checkbox id={item.name}
                      checked={false}
                      icon={uncheckedIcons()} sx={{ padding: '5px', }} />}
                    label={<Typography variant='body2' sx={{ fontSize: 11, color: 'text.secondary' }}>{item.name}</Typography>}
                  />)
                }else{
                  return null;
                }
              })}
            </List>
          </AccordionDetails>
        </StyledAccord>
        <StyledAccord expanded={locCollapse}
          onChange={handleLocCollapse} disableGutters sx={{ background: 'transparent', boxShadow: 'none', ml: 1, mr: 2, width: `${accordWidth}` }}>
          <AccordionSummary sx={{ margin: 0, paddingLeft: 0, paddingRight: 0 }}
            expandIcon={<ExpandMoreIcon sx={{ color: 'text.primary' }} />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography sx={{ fontSize: 12 }}>Location</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List className='custom-scrollbar' sx={{ display: 'flex', flexFlow: 'column', height: 'auto', maxHeight: 200, overflowX: 'hidden' }}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={locationValue}
                onChange={handleLocationValue}
                name="radio-buttons-group">
                {locationFilterData.map((item, index) => {
                  // console.log('location ', item, Number(selectedLoc))
                  return (<FormControlLabel key={`check${index}`} value={item.value} sx={{ fontSize: 11, ml: 0, color: 'text.secondary' }}
                    control={<Radio id={String(item.value)} checked={Number(item.value) === Number(selectedLoc) ? true : false} sx={{ padding: '5px' }} size="small" />}
                    label={<Typography variant='body2' sx={{}}>{item.name}</Typography>} />)

                })}
              </RadioGroup>
            </List>
          </AccordionDetails>
        </StyledAccord>
        <StyledAccord expanded={priceCollapse}
          onChange={handlePriceCollapse} disableGutters sx={{ background: 'transparent', boxShadow: 'none', ml: 1, mr: 2, width: `${accordWidth}` }}>
          <AccordionSummary sx={{ margin: 0, paddingLeft: 0, paddingRight: 0 }}
            expandIcon={<ExpandMoreIcon sx={{ color: 'text.primary' }} />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography sx={{ fontSize: 12 }}>Price</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List className='custom-scrollbar' sx={{ display: 'flex', flexFlow: 'column', height: 'auto', maxHeight: 200, overflowX: 'hidden' }}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={locationValue}
                onChange={handlePriceValue}
                name="radio-buttons-group">

                {priceFilterData.map((item, index) => {
                  // console.log('location ', item, Number(selectedLoc))
                  return (<FormControlLabel key={`check${index}`} value={item.value} sx={{ fontSize: 11, ml: 0, color: 'text.secondary' }}
                    control={<Radio id={String(item.value)} checked={item.value === selectedPrice ? true : false} sx={{ padding: '5px' }} size="small" />}
                    label={<Typography variant='body2' sx={{}}>{item.name}</Typography>} />)

                })}
              </RadioGroup>
            </List>
          </AccordionDetails>
        </StyledAccord>
        {/* <div>
                <FormControlLabel
              sx={{ fontSize: 11, ml: 0, color: 'text.secondary', textTransform: 'capitalize' }}
              onChange={handleChange}
              control={<Checkbox id="key" checked={checkedKeyvalue} checkedIcon={checkedIcons()} icon={uncheckedIcons()} sx={{ padding: '5px' }} />}
              label={<Typography variant='body2' >Key Value</Typography>}
            />
              </div> */}



      </Box>
    </Grid>
  )
}

export default LeftPanel;