import { call, put, takeLatest  } from 'redux-saga/effects';  //takeLatest
// import { isEmpty, } from 'lodash';
import * as actions from '../actions/editProducts.actions';
import * as constants from '../constants/editProduct.constants';
import NetworkApi from '../../api/NetworkApi';
import { getItems } from '../../utils/storage';
import paths from '../../api/path';
//import { toast } from 'react-toastify';

const getApiConfig = () => {
  const [
    token
  ] = getItems([
    'token',
  ]);

  const headers = {
    Authorization: `token ${token}`,
  };

  return {
    Authorization: headers.Authorization
  }
}


export function* editProduct(request = {}) {
  const apiConfig = getApiConfig()
  const { payload = {} } = request;
  const {prd_id, loc_id,user_id} = payload;
  const userRole = getItems(['role'])
  try {
    const response = yield call(NetworkApi.put, `${Number(userRole[0] === 2) ? paths.qaUpdateProductURL : paths.updateProductURL}?prd_id=${prd_id}&loc_id=${loc_id}&user_id=${user_id}`, 
    payload,
      apiConfig, );
    // console.log(response);

    if (response && !response?.details) {
        yield put(actions.editProductSuccessful(response)); 
    } else {
      // console.log('error ', response)
      const message = response?.non_field_errors ? (
        response?.non_field_errors
      ) : (
        ''
        // <FormattedMessage id="notProcess" />
      );
      yield put(actions.editProductFailed(message));
    }

  } catch (error) {
  //  console.log('dashboard ', error)
    const message = error?.message ? (
        error?.message
    ) : (
      ''
      // <FormattedMessage id="notProcess" />
    );
    
    yield put(actions.editProductFailed(message));
  }
}

export function* editProducts() {
  yield takeLatest(constants.PRODUCT_EDIT, editProduct);
}
