import { call, put, takeLatest  } from 'redux-saga/effects';  //takeLatest
// import { isEmpty, } from 'lodash';
import * as actions from '../actions/countrystate.actions';
import * as constants from '../constants/countrystate.constants';
import NetworkApi from '../../api/NetworkApi';
import { getItems } from '../../utils/storage';

const getApiConfig = () => {
  const [
    token
  ] = getItems([
    'token',
  ]);

  const headers = {
    Authorization: `token ${token}`,
  };

  return {
    Authorization: headers.Authorization
  }
}


export function* getCountryState(request = {}) {
  const apiConfig = getApiConfig()
  try {
    const response = yield call(NetworkApi.get, 'https://countriesnow.space/api/v0.1/countries/states/', {}, apiConfig);
    // console.log('countries', response);
    if (response) {
      if(!response.error){
        yield put(actions.getCountryStateSuccessful(response.data));
      } else {
        yield put(actions.getCountryStateFailed(response.msg));
      }
    } else {
      // console.log('error ', response)
      const message = response? (
        response
      ) : (
        ''
        // <FormattedMessage id="notProcess" />
      );
      yield put(actions.getCountryStateFailed(message));
    }

  } catch (error) {
    const message = error?.response?.non_field_errors ? (
      error.response.non_field_errors
    ) : (
      ''
      // <FormattedMessage id="notProcess" />
    );
    yield put(actions.getCountryStateFailed(message));
  }
}


export function* fetchCountryState() {
  yield takeLatest(constants.COUNTRY_STATE_GET, getCountryState);
}
