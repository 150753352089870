import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from '../actions/getRefreshBtnStatus.actions';
import * as constants from '../constants/getRefreshBtnStatus.constants';
import NetworkApi from '../../api/NetworkApi';
import { getItems } from '../../utils/storage';
import paths from '../../api/path';

const getApiConfig = () => {
    const [
        token
    ] = getItems([
        'token',
    ]);

    const headers = {
        Authorization: `token ${token}`,
    };

    return {
        Authorization: headers.Authorization
    }
}

export function* getRefreshBtn(request = {}) {
    const apiConfig = getApiConfig()
    try {
        const response = yield call(NetworkApi.get, paths.refreshBtnStatus, {}, apiConfig);
        if (response) {
            yield put(actions.getRefreshBtnStatusSuccessful(response));
        } else {
            // console.log('error ', response)
            const message = response ? (
                response
            ) : (
                ''
                // <FormattedMessage id="notProcess" />
            );
            yield put(actions.getRefreshBtnStatusFailed(message));
        }
    } catch (error) {
        const message = error?.response?.data?.message ? (
            error?.response?.data?.message
        ) : (
            ''
            // <FormattedMessage id="notProcess" />
        );
        yield put(actions.getRefreshBtnStatusFailed(message));
    }
}

export function* getRefreshBtnStatus() {
    yield takeLatest(constants.GET_REFRESH_BTN, getRefreshBtn);
}