import React from 'react';
// import { useLocation } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { HashRouter as Router, } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import CustomThemeProvider from './theme/CustomThemeProvider';
import {configureStore} from './store/index';

// import ErrorBoundary from './components/ErrorBoundry/ErrorBoundry';

const {store} = configureStore();

// console.log(window.location.hash.indexOf('/login'));


if(window.location.hash.indexOf('/login') !== -1){
  document.body.classList.add('loginBackground');
} else {
  document.body.classList.remove('loginBackground');
}



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router forceRefresh={true}>
  <Provider store={store}>
    <CustomThemeProvider>
  {/* <ErrorBoundary> */}
  <CssBaseline />
    <App />
    {/* </ErrorBoundary> */}
    </CustomThemeProvider>
  </Provider>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
