import {
    UPDATE_CLIENT_REQUEST,
    UPDATE_CLIENT_SUCCESS,
    UPDATE_CLIENT_FAIL,
  } from '../constants/clientUpdate.constants';
  
  export const updateClientRequest = (clientId, updatedData) => ({
    type: UPDATE_CLIENT_REQUEST,
    payload: { clientId, updatedData },
  });
  
  export const updateClientSuccess = (updatedClient) => ({
    type: UPDATE_CLIENT_SUCCESS,
    payload: updatedClient,
  });
  
  export const updateClientFail = (error) => ({
    type: UPDATE_CLIENT_FAIL,
    payload: error,
  });
  