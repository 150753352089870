import {
    GETAPP_NOTIFICATION,
    GETAPP_NOTIFICATION_FAILED,
    GETAPP_NOTIFICATION_SUCCESSFUL,
  } from "../constants/getappnotification.constants";
  
  // Action creator for requesting app notifications
  export const getAppNotification = () => {
    return {
      type: GETAPP_NOTIFICATION,
    };
  };
  
  // Action creator for successful app notifications retrieval
  export const getAppNotificationSuccess = (data) => {
    return {
      type: GETAPP_NOTIFICATION_SUCCESSFUL,
      payload: data, // You can pass the retrieved data as payload
    };
  };
  
  // Action creator for failed app notifications retrieval
  export const getAppNotificationFailure = (error) => {
    return {
      type: GETAPP_NOTIFICATION_FAILED,
      payload: error, // You can pass the error message as payload
    };
  };
  