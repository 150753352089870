import { useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getItem } from '../../utils/storage';
import selectProducts from '../../redux/selectors/myproduct.selector';
import * as actions from '../../redux/actions/productDetails.actions';

import selectMarketPlaceDetails from '../../redux/selectors/marketplace.selector'
import * as marketActions from '../../redux/actions/marketplace.actions'


const UseReport = () =>{

    const [productDetails, setProductDetails] = useState([])
    const dispatch = useDispatch();
    const userToken = getItem('token');
    
    const { products } = useSelector(selectProducts);
    const { marketPlaceDetails } = useSelector(selectMarketPlaceDetails);
    const {data: marketData} = marketPlaceDetails;
    const {data} = products;

    useEffect(() =>{
        // console.log(marketData);
        if(!marketData){
            dispatch(marketActions.getMarketPlace())
        }
    }, [marketData, dispatch])

    useEffect(() =>{
        if(!data && userToken){
            // console.log('use report')
            dispatch(actions.getTotalProducts({pPath:"page=1&page_size=10&category_id__in=&prd_brand__in=&dept_id__in="}))
        }
    }, [data, userToken, dispatch])

    // console.log('marketData ', marketData);


    useEffect(() =>{
        if(data !== null){
            const storeData = Array.from(data);
            storeData.map(item => {
                item.deptname = item.dept.dept_name ? String(item.dept.dept_name).toLocaleUpperCase() : String(item.dept).toLocaleUpperCase();
                item.deptId = item.dept.dept_id ? item.dept.dept_id : item.deptId;
                item.categoryname = item.category.category_name ? String(item.category.category_name).toLocaleUpperCase() : String(item.category).toLocaleUpperCase();
                item.cateId = item.category.category_id ? item.category.category_id : item.cateId;
                return item;
            })
            setProductDetails(storeData)
        }
    },[data])

    return {
        filterdProducts: productDetails,
        data,
        marketData,
    }

}

export default UseReport;