import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Typography, Paper, MenuItem, Select } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import { styled } from '@mui/material/styles';
import { isNull, isUndefined } from 'lodash';
import selectLocationDetails from '../../redux/selectors/locations.selector'
import { isEmpty } from 'lodash';


const LocationDetails = props => {

    const {locationFilter} = props;
    const { locationDetails} = useSelector(selectLocationDetails);
    const { data } = locationDetails;
//  console.log('location_filter ', data)

    const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        boxShadow: 'none'
      }));
    const locationItem = (title, value) =>{
        return (<Grid item xs={3} sx={{}}>
                <Typography className='typo normalText' sx={{opacity:1, fontSize: 'calc(0.9rem + (0.1 * ((100vw - 32rem) / 96)))'}}>{title}</Typography>
                <Typography className='typo normalText' sx={{opacity:1}}>
                    {!isNull(value) && !isUndefined(value) ? value : 'N/A' }
                </Typography>
            </Grid>)     
    }

    return (
        <Item sx={{padding:'16px 0px', height:'-webkit-fill-available', width:'-webkit-fill-available'}}>
            <Grid item xs={12} md={12} sx={{display:'flex', width:'100%', mr:2, pb:1}}>
                <Grid item xs={6} md={7} sx={{display:'flex', justifyContent:'flex-end'}}>
                    <Typography className='typo' variant='h5' component={'h5'} sx={{color:'text.secondary'}} >Locations</Typography>
                </Grid>
                <Grid item xs={6} md={5} sx={{display:'flex', justifyContent:'flex-end'}}>
                <Select size='small' variant='outlined' 
                    sx={{display:'none', width:100 ,fontSize:11, fontFamily:'Inter', color:'#E4E3E3', fontWeight:400}}
                    // onChange={onMarketPlaceHandler}
                    input={<OutlinedInput label="" sx={{color:'text.secondary'}} />}
                >
                    <MenuItem sx={{fontSize:11}} selected={true} value={'change'}>Change Location</MenuItem>
                 {
                    !isEmpty(data) && data.map(lItem => {
                       return <MenuItem key={lItem.loc_id} sx={{fontSize:11}} value={lItem.loc_id}>{lItem.loc_zip}</MenuItem>    
                    })
                 }   
                </Select>
                </Grid>
            </Grid>
            <Grid item
                className='location-grid' 
                sx={{m:0, px:1, maxWidth:'100%', width:'-webkit-fill-available', display:'flex', justifyContent:'space-between', alignItems:'flex-end'}}>
                {locationItem('Total Number of Products', locationFilter.productCount)}   
                {locationItem('Total Number of Categories', locationFilter.categoryCount)}
                {locationItem('Total Number of Departments', locationFilter.departmentCount )}
                {locationItem('Total Brands', locationFilter.brandCount)}
                
            </Grid>
        </Item>
    )

}

export default LocationDetails; 
