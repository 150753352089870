import React, { useState, useEffect } from "react";
import { Navigate, useParams } from 'react-router';
// import { useDispatch } from 'react-redux';
import {useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import { Switch, Breadcrumbs, Container, Grid, Rating, IconButton, Paper, FormControlLabel, Typography, Button, Table, TableBody, TableRow, TableCell, Checkbox, Box, Badge } from '@mui/material';
import Chip from '@mui/material/Chip';
import WestIcon from '@mui/icons-material/West';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { styled } from '@mui/material/styles';
import AddIcon from "@mui/icons-material/Add";
import EditIcon from '@mui/icons-material/Edit'

import CheckIcon from '@mui/icons-material/Check';
import EditProduct from "../../../components/editProduct";
import EditKeyword from "../../../components/editProduct/editkeyword";

// import {editProduct, editProductReset} from '../../../redux/actions/editProducts.actions'
// import selectEditedProducts from '../../../redux/selectors/editProduct.selector'
// import useActions from '../../../redux/use-actions';
import { useDispatch } from "react-redux";

import Competitor from "./Competior";
import LeftPanel from './LeftPanel';

import UseProductDetails from './useProductDetails';
import AddCompetitor from "./AddCompetitor";
import RefreshProduct from "./RefreshProduct.js";
import { getItem } from '../../../utils/storage';
import { isEmpty, isUndefined, isNull } from 'lodash';
import Notification from "../../../components/notification/index.js";
import Loader from '../../../components/loader';
import EnableCrawlMatch from "./enableCrawlMatch.js";

import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
import ProductCommentBox from "../../../components/productCommentBox";

import MatchEnabledDialog from "../matchEnabledDialog";
import * as actions from '../../../redux/actions/productLocations.actions'
import selectProductLocationsDetails from '../../../redux/selectors/productLocations.selector';


// import { sendComment } from "../../../redux/actions/comments.actions";

const Index = () => {
    // const [locationValue, ] = useState('selectlocation');
    const dispatch = useDispatch()
    // const role = getItem("role")
    const [openMarket, setOpenMarket] = useState( false );
    const [productZip, setProductZip] = useState(null);
    const [selectedLoc, setSelectedLoc] = useState(null);
    const [currency, setCurrency] = useState(
        {'currency_symbol':'$'}
    );
    const [editedProduct, setEditedProduct] = useState({isVisible:false, productInformation:null})
    const [editKeyword, setEditKeyword] = useState({isVisible:false, productInformation:null})
    const params = useParams()
    const { id } = params;
    const navigate = useNavigate();
    const customDesign = getItem("customDesign")
    
    // const toggleactions = useActions({ editProduct, editProductReset });
    // const { editedProduct: toggleEditProduct } = useSelector(selectEditedProducts);
    // const { isSuccessful:approveSuccess , isFailure:approveFail, message:approveMessage} = toggleEditProduct;
    
    // const [allCommentsArr, setAllCommentsArr] = useState([]);
    // const [allResponsesArr, setAllResponsesArr] = useState([]);
    
    // comment-box dialog open
    const [commentBoxOpen, setCommentBoxOpen] = useState(false);

    const { productlocationDetails } = useSelector( selectProductLocationsDetails );
    const { prdlocdata } = productlocationDetails;
    const [ locations, setLocations] = useState([]);
    const [ activeLoc, setActiveLoc] = useState(null);
    const [ competitorsList, setCompetitorsList ] = useState([]);
    const locationProp = useLocation();

    useEffect(()=>{
        if(prdlocdata){
            const tempLoc = [];
            prdlocdata.forEach((loc)=>{
                tempLoc.push(loc)
            })
            setLocations(tempLoc);
        }
    },[prdlocdata]) //eslint-disable-line

    useEffect(() =>{
        if(!id ){
            Navigate('/myproducts');
        }
    }, [id]) //eslint-disable-line

    const {handleRefresh, productData, prevNextData, userRole, qaUser, isSuccessful, isFailure, handleReloadCompetitor } = UseProductDetails({id, productZip, setSelectedLoc})
    const {handleCrawlEnable, handleMatchEnable, editSuccess, editFailure, isCrawl, isMatch} = EnableCrawlMatch({productData})

    const handleCommentOpen = () => {
        setCommentBoxOpen(true);
      };
    // console.log('handleCrawlEnable ', productData, locationData)
    useEffect(() =>{
        dispatch(actions.getProductLocations());
        // const userCurrency = !isNull(getItem('currency')) && !isUndefined(getItem('currency')) ? JSON.parse(getItem('currency')) : [{'currency_symbol':'$'}];
        const userCurrency = !isNull(getItem('currency')) && !isUndefined(getItem('currency')) ? getItem('currency').length !== undefined ? typeof(getItem('currency')) === 'string' ? JSON.parse(getItem('currency')) : getItem('currency') : JSON.parse(getItem('currency')) : [{'currency_symbol':'$'}]
        // console.log('const userCurrency', userCurrency)
        setCurrency(userCurrency[0]);
        const loc_id_length = locationProp.search.indexOf("=");
        const locFromURL = locationProp.search.slice(1, loc_id_length);
        setActiveLoc(parseInt(locFromURL));
    }, []) //eslint-disable-line

    const Item = styled(Paper)(({ theme }) => ({
        // backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#232D3B',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        // color: '#FFFFFF',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        boxShadow:'none'
      }));

    const StyledBreadcrumb = styled(Chip)(({ theme }) => {
        // const backgroundColor =
        //   theme.palette.mode === 'light'
        //     ? theme.palette.grey[100]
        //     : theme.palette.grey[800];
        return {
          //backgroundColor,
          height: theme.spacing(3),
          backgroundColor:'transparent',
          fontSize:11,
          fontFamily:'Inter',
          color: 'text.primary', //theme.palette.text.primary,
          fontWeight: theme.typography.fontWeightRegular,
          '&:hover, &:focus': {
           // backgroundColor: emphasize(backgroundColor, 0.06),
          },
          '.MuiChip-label':{
            px:0
          },
          label:{paddingLeft:0, paddingRight:0},
          '&:active': {
            //boxShadow: theme.shadows[1],
            backgroundColor: 'transparent', //emphasize(backgroundColor, 0.12),
          },
        };
      });
    const checkedIcons = () => {
        return <CheckIcon sx={{color:'text.primary', fontSize:14, borderRadius:1, border:'1px solid', borderColor:'text.primary', marginRight:0.5, backgroundColor:'background.default'}} />
    }
    const uncheckedIcons = () => {
        return <CheckIcon sx={{color:'transparent', fontSize:14, borderRadius:1, border:'1px solid #FFFFFF', borderColor:'text.primary', marginRight:0.5, backgroundColor:'none'}} />
    }
    const handCloseMarketPlace = () => {
        // console.log('handCloseMarketPlace');
        setOpenMarket(false);
    }
    const openAddMarketPlaceHandler = () => {
        setOpenMarket(true);
    }
    const handleLoadProduct = (pStr) =>{
        // console.log('pStr', pStr, prevNextData)
        if(pStr === 'next'){
            navigate(`/myproducts/${prevNextData.next}`)
        } else if(pStr === 'prev'){
            navigate(`/myproducts/${prevNextData.previous}`)
        }
    }
    const handleEditKeyword = (item) =>{
        setEditKeyword({
            isVisible: true,
            productInformation: item
        })
    }
    const handleEditProduct = (item) => {
        setEditedProduct({
          isVisible: true,
          productInformation: item
        })
        // navigate(`/myproducts/${itemName}`)
      }
    const handleModifySuccess = () =>{
        setEditedProduct({
            isVisible: false,
            productInformation: null
        })
        setEditKeyword({
            isVisible: false,
            productInformation: null
        })
    }
    const openProductLink = (purl) =>{
        window.open(purl, '_blank')
        // navigate(purl, {replace:false})
    }
    const handleApproved = (item) =>{
        item.prd_qa_approved = !item.prd_qa_approved;
        item.category_id = item.category !== null ? item.category.category_id : null;
        item.dept_id = item.dept !== null ? item.dept.dept_id : null;
        item.loc_id= item.locations[0]?.loc?.loc_id;
        item.prd_mrp = item.activeLocator?.filtered_mrp;
        item.prd_sales_price= item.activeLocator?.filtered_sales_price;
        item.prd_norm_price= item.activeLocator?.filtered_norm_price;
        item.seller_id= item.locations[0].seller.seller_id;
        item.prd_offer= item.prd_offer || ''
        // console.log(item);
        // console.log('productData ', productData)
        // dispatch(toggleactions.editProduct(item))
    }
    const updateProductHandler= (pId) =>{
        // console.log('update ', pId)
        setProductZip(pId);
    }

      const tableCellStyle = {p:'2px 0px', border:0, color:'text.primary' , fontSize:'11px !important'}
      const tableCellLeftStyle = {padding:'2px 0px', width:'20%', border:0, fontSize:'10px !important', color:'text.primary'}

    // description readmore & readless button
      const MINIMUM_CHARACTER_LIMIT = 381;
      const [showFullDescription, setShowFullDescription] = useState(false);
      const productDescription = productData?.prd_desc && productData?.prd_desc !== 'nan' ? productData?.prd_desc : 'N/A';
      const shouldTruncate = productDescription.length > MINIMUM_CHARACTER_LIMIT;
    
      const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
      };

      let totalProductValue = Number(productData.prd_size) * Number(productData.prd_no_of_pieces)
    
    const [matchOpen, setMatchOpen] = React.useState(false);
      
    //   const handleMatchDialog = (productData)=>{
    //     setMatchOpen(true)
    //   }

      useEffect(()=>{
            const tempCompList =  productData?.competitors?.filter((comp)=>(comp.loc.loc_id === activeLoc));
            setCompetitorsList(tempCompList);
      },[activeLoc, productData])

      return (
        <Container maxWidth={'xl'} sx={{ height:'auto', px:'0px !important', marginTop:0}}>
            { matchOpen && <MatchEnabledDialog productData={productData} matchOpen={matchOpen} setMatchOpen={setMatchOpen} /> }
        {editKeyword.isVisible &&
            <EditKeyword 
                productInformation={editKeyword.productInformation} 
                handleClose={handleModifySuccess} modalOpen={editKeyword.isVisible}
            />
        }
        {editedProduct.isVisible  && 
          <EditProduct 
            productInformation={editedProduct.productInformation} 
            handleClose={handleModifySuccess} modalOpen={editedProduct.isVisible}
          />}
          
          {
            !isEmpty(productData) && isSuccessful ?
            <Grid container spacing={0} sx={{display:'flex'}}>
                <LeftPanel updateProduct= {updateProductHandler} 
                    productData= {productData} 
                    selectedLoc = {selectedLoc}
                    activeLoc = {activeLoc}
                    locations = {locations}
                    setActiveLoc = {setActiveLoc}
                    />
                <Grid item xs={10} sx={{padding:2}}>
                    <Grid sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                        <Grid item sx={{display:'flex', alignItems:'center', justifyContent:'flex-start'}}>
                            <IconButton sx={{padding:0, paddingRight:1}} onClick={() => navigate('/myproducts')}>
                                <WestIcon sx={{color:'text.primary', fontSize:20}} />
                            </IconButton>
                            <Breadcrumbs 
                                separator={<ArrowForwardIosIcon sx={{fontSize:15, color:"text.primary"}} />} 
                                sx={{ml:0}}>
                                <StyledBreadcrumb component="a" sx={{cursor:'pointer'}} href= "/#/myproducts" label="My Products" />
                                <StyledBreadcrumb component='span' label= {productData?.prd_name} />
                            </Breadcrumbs>
                        </Grid>
                        {userRole === 2 && <Grid item sx={{display:'flex', alignItems:'center', justifyContent:'flex-start'}}>
                            {prevNextData && prevNextData.previous && <Button variant='outlined' 
                                sx={{width:'80px', mb:1, fontSize:11, marginLeft:1, borderColor:'text.primary', color:'text.primary', textTransform:'none'}} 
                                onClick={e => handleLoadProduct('prev')}
                                // startIcon={<EditIcon sx={{color:'text.primary', width:'0.8em'}} />} 
                                
                            > Previous</Button>}
                            {prevNextData && prevNextData.next && 
                            <Button variant='outlined' 
                                sx={{width:'80px', mb:1, fontSize:11, marginLeft:1, borderColor:'text.primary', color:'text.primary', textTransform:'none'}} 
                                onClick={e => handleLoadProduct('next')}
                                // startIcon={<EditIcon sx={{color:'text.primary', width:'0.8em'}} />} 
                                
                            > Next</Button>
                            }
                        </Grid>}
                    </Grid>
                    <Grid item xs={12} sx={{marginTop:2, marginLeft:2, marginRight:2}}>
                        <Item sx={{ width:"100%",display:"flex", flexFlow:'column',p:'25px 20px 25px 20px',justifyContent:'space-between', alignItems:'start'}}>
                        {editSuccess && isCrawl && <Typography variant="body2" sx={{textAlign:'right', width:'100%'}}>{'Crawl updated successfully.'} </Typography>}
                        {editSuccess && isMatch && <Typography variant="body2" sx={{textAlign:'right', width:'100%'}}>{'Match updated successfully.'} </Typography>}
                        {editFailure && (isCrawl || isMatch) && <Typography variant="body2" sx={{textAlign:'right', width:'100%'}}>{'There is some issue on the server.'} </Typography>}
                            <Grid item style={{display:"flex", width:"100%", justifyContent:'space-between', alignItems:'center'}}>
                                <Grid item xs={12} sm={2} sx={{display:'flex', justifyContent:'flex-start'}}>
                                        <img 
                                            src={productData?.prd_medium_image ? productData?.prd_medium_image : '/assets/noimage.png'} 
                                            alt={productData?.prd_name} style={{width:'100%', maxWidth:150, maxHeight:161, height:'auto', left:0, top:0, transform:"none"}} />        
                                </Grid>
                                <Grid item xs={12} sm={10} sx={{display:'block', marginRight:1}}>
                                    <Grid item sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                                        <Grid item xs={6} sm={6} md={6} mg={6} sx={{display:'flex'}}>
                                            <Typography variant="body2" 
                                                onClick={e => productData?.prd_url !== 'nan' && openProductLink(productData?.prd_url)}
                                                className='tableText' sx={{cursor:productData?.prd_url !== 'nan' ? 'pointer': 'default', textAlign:'left', color:'text.primary'}}>
                                                {productData?.prd_name}
                                            </Typography>
                                        </Grid>
                                        <Grid item 
                                            xs={6} sm={6} md={6} mg={6} 
                                            sx={{display:'flex', justifyContent:'flex-end', flexFlow:'column', alignItems:'flex-end'}}>
                                                <Grid item sx={{display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
                                                    <Grid item sx={{display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
                                                    <Typography variant="body2" component={'p'} sx={{mr:1}} > Notification :</Typography>
                                                    <FormControlLabel sx={{fontSize:11, color:'text.secondary', fontFamily:'Inter'}} 
                                                        control={<Notification type='checkbox' item={productData}/>} 
                                                    />
                                                    </Grid>
                                                    <Grid item sx={{display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
                                                        <Typography variant="body2" component={'p'} sx={{mr:1}} > Monitor :</Typography>
                                                        <FormControlLabel 
                                                            sx={{fontSize:11, color:'text.secondary', fontFamily:'Inter'}} 
                                                            onChange = {handleCrawlEnable}
                                                            control={<Checkbox checked={productData.prd_is_crawl_enabled} checkedIcon={checkedIcons()} icon={uncheckedIcons()} 
                                                            sx={{padding:'8px', color:'text.secondary', backgroundColor:'transparent'}} />} 
                                                        />
                                                    </Grid>
                                                    {/* old match in demo */}
                                                    <Grid item sx={{display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
                                                        <Typography variant="body2" component={'p'} sx={{mr:1}} > Match :</Typography>
                                                        <FormControlLabel sx={{fontSize:11, color:'text.secondary', fontFamily:'Inter'}} 
                                                            onChange = {handleMatchEnable}
                                                            control={<Checkbox checked = {productData.prd_is_match_enabled} checkedIcon={checkedIcons()} icon={uncheckedIcons()} sx={{padding:'8px', color:'text.secondary', backgroundColor:'transparent'}} />} 
                                                        />
                                                    </Grid>
                                                    {/* prd match json format */}
                                                    {/* <Grid item sx={{display:'flex', justifyContent:'flex-start', alignItems:'center',marginRight:"6px" }}>
                                                    <Button onClick={()=>handleMatchDialog(productData)} color="primary">
                                                    <Typography variant="body2" component="p" sx={{ mr: 1,textTransform: 'capitalize'}}>
                                                      Match
                                                    </Typography>
                                                  </Button>
                                                    </Grid> */}

                                                    {/* comment box icon */}
                                                    <Grid item sx={{display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
                                                    <Box sx={{
                                                            cursor:"pointer"
                                                        }} onClick={handleCommentOpen} >
                                                        <Badge badgeContent={0} color="error" anchorOrigin={{
                                                            vertical:'top',
                                                            horizontal:'right'
                                                        }} >
                                                            <ForumRoundedIcon fontSize="medium" />
                                                        </Badge>
                                                        </Box>
                                                    </Grid>
                                                    <ProductCommentBox productData={productData} prd_id={productData?.prd_id} commentBoxOpen={commentBoxOpen} setCommentBoxOpen={setCommentBoxOpen} />

                                                </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid style={{display:"flex",justifyContent:"space-between"}} item xs={12} container >
                                    <Grid sx={{display:'flex'}}>
                                        <Typography variant="body2" component={'p'} className='productDetails' sx={{textAlign:'left', textTransform:'capitalize', color:'#559EFF'}}>
                                            <span style={{fontSize:18}}>{ !isEmpty(currency) ? currency?.currency_symbol ? currency?.currency_symbol : currency?.currency : '$' } 
                                            {/* {productData?.activeLocator?.filtered_sales_price ? productData?.activeLocator?.filtered_sales_price.toFixed(2) : productData?.activeLocator?.filtered_mrp.toFixed(2)} */}
                                            <span  > 
                                        {productData?.activeLocator?.filtered_sales_price ? productData?.activeLocator?.filtered_sales_price?.toFixed(2) : productData?.activeLocator?.filtered_mrp?.toFixed(2)}
                                            </span>
                                            </span>
                                         </Typography>
                                    </Grid>
                                    {/* code for BB in main product */}
                                    {
                                  (customDesign === "UI_2" || qaUser === 6) &&
                                  (
                                    productData?.locations?.[0]?.prd_sales_price === 0 ? null :
                                    competitorsList?.some((obj) => obj.comp_prd_custom_field === "BUY BOX") ? null :
                                    productData?.prd_remarks === "NO BUY BOX" ? null :
                                    <Grid item style={{ display: "flex", justifyContent: "flex-end" }}>
                                      <Typography sx={{ background: 'success', color: '#1d9e42', mr: 1, fontSize: 25 }}>BB</Typography>
                                    </Grid>
                                  )
                                }


                                    </Grid>
                                    
                                    <Grid item sx={{display:'flex', alignItems:'center'}}>
                                            <Rating readOnly defaultValue={productData?.prd_rating} precision={0.5} emptyIcon={<StarOutlineIcon sx={{color:'text.primary'}} />}/>
                                                <Typography className='typo fontweight-500' sx={{textAlign:'left !important', color:'text.primary', lineHeight:0.5, fontSize:'15px !important'}}> 
                                                    {`${Number(productData?.prd_rating).toFixed(1)} `}
                                                    {`- ${productData?.prd_tot_reviews !== 'null' &&  productData?.prd_tot_reviews !== null
                                                        ? productData?.prd_tot_reviews : 0 } Reviews`}    
                                                </Typography>
                                            </Grid>
                                    <Grid item xs={12} sx={{display:'flex'}}>
                                        <Grid item xs={12} sm={10} md={9} sx={{mt:1, display:'flex', alignItems:'flex-start',}}>
                                            <Grid item xs={12} sm={4} md={5}>
                                                <Grid item>
                                                    <Table>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell sx={{...tableCellLeftStyle}} className='tableText' >Brand</TableCell>
                                                                <TableCell sx={{textTransform:'capitalize',...tableCellStyle}} className='tableText' >: {productData?.prd_brand !== '' ? productData?.prd_brand : 'N/A'}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell sx={{...tableCellLeftStyle}} className='tableText' >Category</TableCell>
                                                                <TableCell sx={{textTransform:'capitalize', textOverflow:'ellipsis', ...tableCellStyle}} className='tableText' >:  {productData?.category?.category_name !== undefined ? productData?.category?.category_name : 'N/A'}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell sx={{...tableCellLeftStyle}} className='tableText' >Department</TableCell>
                                                                <TableCell sx={{textTransform:'capitalize', textOverflow:'ellipsis' , ...tableCellStyle}} className='tableText' >:  {productData?.dept?.dept_name !== undefined ? productData?.dept?.dept_name : 'N/A'}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell sx={{...tableCellLeftStyle}} className='tableText' >Variant</TableCell>
                                                                <TableCell sx={{textTransform:'capitalize',...tableCellStyle}} className='tableText' >:  {productData?.prd_variants && productData?.prd_variants !== 'nan' ? productData?.prd_variants : 'N/A'}</TableCell>
                                                            </TableRow>

                                                        </TableBody>
                                                    </Table>

                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={3} sx={{mx:1, display:'flex', justifyContent:'center', flexFlow:'column'}}>
                                                <Table>
                                                <TableBody>
                                                <TableRow>
                                                <TableCell sx={{ ...tableCellLeftStyle, width: '30%' }} className='tableText'>PPU</TableCell>
                                                <TableCell sx={{ ...tableCellStyle, width: '50%',whiteSpace: 'nowrap' }} className='tableText'>
                                                    : {Number(productData?.activeLocator?.filtered_norm_price).toFixed(2)
                                                        ? (
                                                            <span>
                                                                {`${!isEmpty(currency) ? currency.currency_symbol ? currency.currency_symbol : currency.currency : '$'} ${Number(productData?.activeLocator?.filtered_norm_price).toFixed(2)} / `}
                                                                <span style={{ textTransform: 'lowercase' }}>{productData?.prd_uom}</span>
                                                            </span>
                                                        )
                                                        : 'N/A'}
                                                </TableCell>
                                            </TableRow>
                                                    <TableRow>
                                                        <TableCell sx={{...tableCellLeftStyle, width:'30%'}} className='tableText' >UOM</TableCell>
                                                        <TableCell sx={{...tableCellStyle, width:'50%', }} className='tableText' >: {productData?.prd_uom && productData?.prd_uom !== 'nan' ? productData?.prd_uom : 'N/A'}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell sx={{...tableCellLeftStyle, width:'30%'}} className='tableText' >Size</TableCell>
                                                        <TableCell sx={{...tableCellStyle, width:'50%', }} className='tableText' >: {productData?.prd_size}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell sx={{...tableCellLeftStyle, width:'30%'}} className='tableText' >Quantity</TableCell>
                                                        <TableCell sx={{...tableCellStyle, width:'50%'}} className='tableText' >: {productData?.prd_no_of_pieces}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                                </Table>
                                    
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={4} sx={{mx:1, display:'flex', justifyContent:'center', flexFlow:'column'}}>
                                            <Table sx={{marginLeft:"2em"}} >
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell sx={{...tableCellLeftStyle, width:'20%', verticalAlign:'top'}} className='tableText' >UPC</TableCell>
                                                        <TableCell sx={{...tableCellStyle, width:'50%', }} className='tableText' >:  {productData?.prd_upc && productData?.prd_upc !== 'nan' ? productData?.prd_upc : 'N/A'}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell sx={{...tableCellLeftStyle, width:'20%'}} className='tableText' >SKU</TableCell>
                                                        <TableCell sx={{...tableCellStyle, width:'50%', }} className='tableText' >:  {productData?.prd_asin && productData?.prd_asin !== 'nan' ? productData?.prd_asin : 'N/A'}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell sx={{...tableCellLeftStyle, width:'20%'}} className='tableText' >Location</TableCell>
                                                        <TableCell sx={{textTransform:'capitalize', ...tableCellStyle, width:'50%', }} className='tableText' >:  {productData?.activeLocator?.loc_name ? productData?.activeLocator?.loc_name : 'N/A'}</TableCell>
                                                    </TableRow>
                                                    
                                                </TableBody>
                                                </Table>
                                    
                                            </Grid>
                                        </Grid>
                                        
                                        <Grid item xs={12} sm={2} md={3} sx={{display:'flex', alignItems:'center'}}>
                                            <Table>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell sx={{...tableCellLeftStyle, width:'35%'}} className='tableText' ></TableCell>
                                                        <TableCell sx={{width:'50%', padding:'2px 0px', border:0, fontSize:'11px !important', color:'#E4E3E3 !important', display:'flex', flexFlow:'column', alignItems:'flex-start'}} className='tableText' >
                                                        <Button variant='outlined' 
                                                            sx={{width:'150px', mb:1, fontSize:11, marginLeft:1, borderColor:'text.primary', color:'text.primary', textTransform:'none'}} 
                                                            onClick={e => handleEditProduct(productData)}
                                                            startIcon={<EditIcon sx={{color:'text.primary', width:'0.8em'}} />} 
                                                            
                                                        > Edit</Button>
                                                        <Button variant='outlined' sx={{width:'150px', mb:1, fontSize:11, marginLeft:1, borderColor:'text.primary', color:'text.primary', textTransform:'none'}} 
                                                            startIcon={<AddIcon sx={{color:'text.primary'}} />} 
                                                            onClick={openAddMarketPlaceHandler}
                                                        > Add Competior</Button>
                                                            { 
                                                            // !isEmpty(productData?.competitors) &&
                                                                <RefreshProduct competitorInformation={productData?.competitors} handleRefresh={handleRefresh} id={id} userRole={userRole} qaUser= {qaUser} />}
                                                        </TableCell>
                                                    </TableRow>
                                                    
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    </Grid>
                                    <Grid sx={{display: 'flex', width:'80%'}}>
                                        <Typography className='productDetails productDetails_smallText' sx={{height:'auto', textAlign:'left', color:'text.primary'}}>Spec</Typography>
                                        <Typography className='productDetails productDetails_smallText' sx={{height:'auto', textAlign:'left', color:'text.primary',marginLeft:"46px"}}>
                                        : {`${productData?.prd_spec && productData?.prd_spec !== 'nan' ? productData?.prd_spec  : 'N/A'}  `}
                                         </Typography>
                                    </Grid>
                                    <Grid sx={{display: 'flex', width:'95%', mb:1}}>

                                    <Typography className='productDetails productDetails_smallText' sx={{height:'auto', textAlign:'left', color:'text.primary'}}>Description</Typography>
                                    <Typography
          className='productDetails productDetails_smallText'
          sx={{ overflow: 'hidden', textTransform: 'capitalize', textAlign: 'left',marginLeft:"10px", color: 'text.primary' }}
        >
          : {`${
            shouldTruncate && !showFullDescription ? productDescription.slice(0, MINIMUM_CHARACTER_LIMIT) + '...' : productDescription
          } `}
          {shouldTruncate && (
            <Button onClick={toggleDescription}>
              {showFullDescription ? 'Read less' : 'Read more'}
            </Button>
          )}
        </Typography>
                                    </Grid>
                                    {userRole === 2 && 

                                    <Grid item sx={{display:'flex', justifyContent:'flex-end', width:'100%'}}>
                                        <Grid item sx={{display:'flex', width:'100%', justifyContent:'flex-start', alignItems:'center'}}>
                                            <Grid item xs={10} sx={{display:'flex'}}>
                                            {
                                                productData.keyword !== 'nan' ? JSON.parse(productData.keyword).map((item, indexs) =>{
                                                    return Object.entries(item).map((items,index) => 
                                                        <Typography key={`${indexs}-${index}`} variant="body2" component={'p'} 
                                                            sx={{mr:1, textAlign:'left', textTransform:'capitalize'}} > {indexs === 0 ? `Keywords : ${items[0]} : ${items[1]}` : ` ,${items[0]} : ${items[1]}`} 
                                                        </Typography>
                                                    )
                                                })
                                                :
                                                <Typography variant="body2" component={'p'} sx={{mr:1, textAlign:'left'}} > {`Keywords : N/A`} </Typography>

                                            }
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Button variant='outlined' 
                                                    sx={{width:'80px', fontSize:11, marginLeft:1, borderColor:'text.primary', color:'text.primary', textTransform:'none'}} 
                                                    onClick={e => handleEditKeyword(productData)}
                                                    startIcon={<EditIcon sx={{color:'text.primary', width:'0.8em', ml:1}} />} 
                                                    
                                                > Modify</Button>
                                                <Switch size='small' sx={{marginLeft:1}} checked={productData.prd_qa_approved} onChange={e=> handleApproved(productData)} />
                                            </Grid>
                                        </Grid>
                                        
                                    </Grid>
                                    }
                                </Grid>
                                
                                </Grid>
                        </Item>
                    </Grid>
                    {<Competitor activeLoc={activeLoc} productData={productData} totalProductValue={totalProductValue} id={id} currency={currency} handleReload={handleReloadCompetitor} productId={id} 
                        openProductLink={openProductLink} 
                        competitorInformation={competitorsList} />}
                    
                </Grid>
            </Grid>
            : isFailure && !isSuccessful ? 
            <Grid item sx={{display:'flex',flexFlow:'column', mt:'20%', justifyContent:'center', alignItems:'center'}}>
                <Typography component={'p'}>Product not found.</Typography>
            </Grid>
            :
            <Loader />
            }
            {openMarket && <AddCompetitor handleReload={handleReloadCompetitor} handleClose={handCloseMarketPlace} modalOpen={openMarket} />                                        
            }
        </Container>
    )
}

export default Index;