import React, { useContext, useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Typography, InputBase, Select, MenuItem, Rating, Table, TableBody, TableRow, TableCell, Checkbox, Button, Tooltip, } from '@mui/material';
import { styled } from '@mui/material/styles';
import { isNull, isEmpty } from 'lodash'
import {handleMatchRate, matchrateFailed} from '../../../../redux/actions/matchrate.actions'
import selectMatchRate from '../../../../redux/selectors/matchrate.selector'
import useActions from '../../../../redux/use-actions';

import DeleteIcon  from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
// import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { CustomThemeContext } from '../../../../theme/CustomThemeProvider';
import { getItem } from '../../../../utils/storage';
import EditCompetitor from '../../../../components/editCompetitor';
import EditIcon from '@mui/icons-material/Edit'



const ComeptitorItem = props =>{
    const qaUser = getItem('qaUser')
    const currentTheme = useContext(CustomThemeContext);
    const {currency, competitordata, handleDelete,productData} = props;
    const actions = useActions({ handleMatchRate, matchrateFailed });
    const {matchrateDetails } = useSelector(selectMatchRate);
    const userRole = getItem('role');
    const [editedProduct, setEditedProduct] = useState({isVisible:false, productInformation:null})
    // console.log("productData",productData)
    const {isSuccessful, data} = matchrateDetails;
    
    const [selectedMatchRate, setSelectedMatchRate] = useState(Math.round(Number(competitordata?.comp_prd_score)))
    
    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        'label + &': {
          marginTop: theme.spacing(2),
        },
        '& .MuiInputBase-input': {
          borderRadius: 4,
          position: 'relative',
          backgroundColor: 'transparent', //theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
          border: '1px solid #ced4da',
          fontSize: 11,
          width: '100%',
          padding: '10px 12px',
          color: theme.palette.text.primary,
          transition: theme.transitions.create([
            'border-color',
            'background-color',
          ]),
          // Use the system font instead of the default Roboto font.
          fontFamily:'Inter',
          '&:focus': {
            borderColor: theme.palette.primary.main,
          },
        },
    }));
    // console.log('item ',isSuccessful)
    useEffect(() => {
        if(isSuccessful){
            setTimeout(() =>{
                actions.matchrateFailed();
            }, 1000)
            
        }
    }, [isSuccessful, actions])

    const tableCellStyle = {p:'2px 0px', border:0, color:'text.primary' , fontSize:'11px !important'}
    const tableCellLeftStyle = {padding:'2px 0px', width:'20%', border:0, fontSize:'10px !important', color:'text.primary'}
    
    const checkedIcons = () => {
        return <CheckIcon sx={{color:'text.primary', fontSize:14, borderRadius:1, border:'1px solid', borderColor:'text.primary', marginRight:0.5, backgroundColor:'background.default'}} />
      }
      const uncheckedIcons = () => {
        return <CheckIcon sx={{color:'transparent', fontSize:14, borderRadius:1, border:'1px solid #FFFFFF', borderColor:'text.primary', marginRight:0.5, backgroundColor:'none'}} />
      }
      const getMatchValue = useCallback(() => {
        const matchList = []
        for(let i=1;i<=41;i++){
            const mvalue= 59+ (i)
            matchList.push(<MenuItem key={`item_${i}`} value={mvalue}>{mvalue} %</MenuItem>)
        }
        return matchList;
      }, [])
      
    const handleUpdateMatchRate = (item) =>{
        // console.log("item",item)
        // console.log('selectedMatchRate ', selectedMatchRate, comp_prd_id)
        actions.handleMatchRate(
        {
            comp_prd_score: selectedMatchRate,
            comp_prd_id:item.comp_prd_id,
            comp_prd_name:item.comp_prd_name,
            comp_prd_desc:item.comp_prd_desc,
            comp_prd_no_of_pieces:item.comp_prd_no_of_pieces,
            comp_prd_size:item.comp_prd_size,
            comp_prd_upc:item.comp_prd_upc,
            comp_prd_asin:item.comp_prd_asin,
            comp_prd_url: item.comp_prd_url ,
            comp_prd_uom: item.comp_prd_uom ,
            comp_category_name: item.comp_category_name ,
            comp_dept_name: item.comp_dept_name ,
            comp_prd_spec: item.comp_prd_spec ,
            comp_prd_mrp: item.comp_prd_mrp ,
            comp_prd_sales_price: item.comp_prd_sales_price ,
            comp_prd_brand:item.comp_prd_brand,
            comp_prd_medium_image:item.comp_prd_medium_image,
            user_id:qaUser,
        })
    }


  const handleDeleteItem = (pid) => {
    handleDelete(pid)
    // setDeletePop({isVisible:true, deleteId:pid.prd_id})

    // console.log('handleDelete', pid.prd_id)
  }

  const DeleteButton = ({prd_id}) => {
    return <Button variant='outlined' 
        sx={{color:'text.primary', borderColor:'text.primary', fontSize:11,width:'6rem'}} 
        endIcon={<DeleteIcon sx={{color:'#text.secondary'}} />}
        onClick={e => handleDeleteItem(prd_id)}>Delete</Button>
  }
  const handleSelectMatchRate = (event) =>{
    // console.log(event.target.value);
    setSelectedMatchRate(event.target.value);
    
  }
  const handleEditProduct = (item) => {
    setEditedProduct({
      isVisible: true,
      productInformation: item
    })
    // navigate(`/myproducts/${itemName}`)
  }
  const handleModifySuccess = () =>{
    setEditedProduct({
        isVisible: false,
        productInformation: null
    })
}

// description readmore & readless button
const MINIMUM_CHARACTER_LIMIT = 428;
  const [showFullDescription, setShowFullDescription] = useState(false);
  const description = competitordata.comp_prd_desc ? competitordata.comp_prd_desc : 'N/A';
  const shouldTruncate = description.length > MINIMUM_CHARACTER_LIMIT;

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

// let cpiValCalculate = (competitordata?.comp_prd_sales_price || competitordata?.comp_prd_mrp) /
// (productData?.activeLocator?.filtered_sales_price || productData?.activeLocator?.filtered_mrp)
// const result = cpiValCalculate === Infinity ? "-" : String(cpiValCalculate).slice(0, 4);

// logic for cpi calculation
let compPpu = typeof competitordata?.comp_prd_norm_price === 'string' ? competitordata?.comp_prd_norm_price?.match(/[\d.]+/) : competitordata?.comp_prd_norm_price;
let mainPpu = typeof productData?.activeLocator?.filtered_norm_price === 'string' ? productData?.activeLocator?.filtered_norm_price?.match(/[\d.]+/) : productData?.activeLocator?.filtered_norm_price;


let cpiAmnt = compPpu && compPpu[0] && mainPpu !== 0 ? parseFloat(compPpu[0]) / mainPpu : NaN;
let calculCpi = !isNaN(cpiAmnt) ? cpiAmnt?.toFixed(4) : "-";
let displayCpiValue = calculCpi?.slice(0,4)

// const displayCpiValue = (competitordata?.comp_prd_sales_price > 0 ) &&
//                      (productData?.activeLocator?.filtered_sales_price > 0 || productData?.activeLocator?.filtered_mrp > 0) ?
//                      result :
//                      "-";
    return (
        <>
<Grid container direction="row" alignItems="center" sx={{ display: 'flex', justifyContent: 'flex-start' }}>
  <Typography variant="body2" component="p" style={{ marginRight: '7em',color:currentTheme.currentTheme === 'light'? '#272727':'#FFFFFF' }}>Product Id: {competitordata?.match_id}</Typography>

</Grid>
        <Grid item style={{display:"flex", filter: Number(competitordata.comp_prd_sales_price) <= 0 && 'blur(1px)', width:"100%", justifyContent:'space-between', alignItems:'center'}}>
        {editedProduct.isVisible  && 
          <EditCompetitor 
            productInformation={editedProduct.productInformation} 
            handleClose={handleModifySuccess}
             modalOpen={editedProduct.isVisible}
             selectedMatchRate={selectedMatchRate}
          />}
            <Grid item xs={12} sm={2} sx={{display:'flex', justifyContent:'flex-start'}}>
                    <a target='_blank' rel="noreferrer" href={competitordata.comp_prd_url} >
                        <img src={competitordata.comp_prd_medium_image ? competitordata.comp_prd_medium_image :
                        '/assets/noimage.png'
                        } 
                            alt={competitordata.comp_prd_name} 
                            style={{width:'100%', maxWidth:150, maxHeight:161, height:'auto', left:0, top:0, transform:"none"}} />
                    </a>
            </Grid>
            <Grid item xs={12} sm={10} sx={{display:'block', marginRight:1}}>
            {isSuccessful && data?.comp_prd_id === competitordata?.comp_prd_id   && <Typography variant='body2' sx={{margin:0, color:'#559EFF'}}> Match Rate updated successfully. </Typography> }
                <Grid item sx={{display: 'flex', justifyContent:'space-between', alignItems:'center', marginRight:'1em'}}>
                    <Grid item sx={{width:'auto', maxWidth:'50%'}}>
                        <Typography variant='body2' onClick={e => props.openProductLink(competitordata?.comp_prd_url)} 
                            className='productDetails' 
                            sx={{cursor:'pointer',textAlign:'left', color:'text.primary', textTransform:'capitalize'}}>
                            {competitordata.comp_prd_name}
                        </Typography>
                    </Grid>
                   {
                    (competitordata?.comp_prd_sales_price !==null) && (
                      <Grid item sx={{display: 'flex', alignItems:'center'}}>
                    <span style={{marginLeft:8, color:currentTheme.currentTheme === 'light'? '#272727':'#FFFFFF', fontSize:12, fontWeight:400}}> Match rate :</span>
                            {/* {console.log('match ',Math.round(Number(competitordata?.comp_prd_score)))} */}
                            <Select size='small' 
                                input={<BootstrapInput sx={{marginTop:'0px !important'}}/>}
                                defaultValue={Math.round(Number(competitordata?.comp_prd_score))} value={selectedMatchRate}
                                className='custom-scrollbar'
                                MenuProps={{className:'custom-scrollbar', style: {maxHeight:200}}}
                                style={{marginLeft:8}}
                                onChange={handleSelectMatchRate}>
                                {getMatchValue()}
                            </Select>
                        
                        <Button id={'matchrateitem'}
                            sx={{ml:1, backgroundColor:'#559EFF',py:1, fontSize:11, textTransform:'none' , borderColor:'#1C3B6E', color:'#000000'}}
                            onClick={e=> handleUpdateMatchRate(competitordata)} 
                        >Update</Button>
                    </Grid>
                    )
                   }

                    
                    
                    <Grid item sx={{display:'flex', alignItems:'center'}}>
                     {!isNull(competitordata.comp_prd_auth_seller) && !competitordata.comp_prd_auth_seller ? (
                        <ErrorIcon sx={{ mr: 1, fontSize: 35, color: '#f1df19' }} />
                        ) : (
                          competitordata?.comp_prd_custom_field === 'BUY BOX' ? (
                            <Typography sx={{ background: 'success', color: '#1d9e42', mr: 1, fontSize: 25 }}>BB</Typography>
                          ): <CheckCircleIcon sx={{mr:1, fontSize:35, color:'#1d9e42'}} />
                        )}

                     <div style={{ display:"flex",flexDirection:"column" }} >
                    <DeleteButton prd_id={competitordata.comp_prd_id} />
                    {/* Edit Button */}
                    {
                    userRole === 2 && 
                    <Grid item sx={{ display:"flex",justifyContent:"flex-end",marginRight:"11px",marginTop:"2px" }} >
                    <Button variant='outlined'
                    style={{ boxSizing:"border-box" }} 
                        sx={{width:'6rem', mb:1, fontSize:11, borderColor:'text.primary', color:'text.primary', textTransform:'none'}} 
                        onClick={e => handleEditProduct(competitordata)}
                        endIcon={<EditIcon sx={{color:'text.primary', width:'0.8em'}} />}> 
                        Edit</Button>
                    </Grid>
                   }
                        </div>
                    </Grid>
                    
                </Grid>
                <Typography gutterBottom sx={{ margin: 0, textAlign: 'left', fontWeight: 500, textTransform: 'capitalize', fontSize: 18, color: '#559EFF' }}>
                  {
                    competitordata?.comp_prd_sales_price !==null && (
                      <>
                      {!isEmpty(currency) ? currency.currency_symbol ? currency.currency_symbol : currency.currency : '$'}{competitordata.comp_prd_sales_price?.toFixed(2)}
                      </>
                    )
                  }
                  {
                    competitordata?.comp_prd_sales_price !== null &&
                    competitordata?.comp_prd_mrp !== null &&
                    competitordata?.comp_prd_sales_price !== competitordata?.comp_prd_mrp && (
                      <span style={{marginLeft:"15px",textDecoration:"line-through",fontSize:"16px"}} >{
                        competitordata?.comp_prd_mrp !==null && (
                          <>
                          {!isEmpty(currency) ? currency.currency_symbol ? currency.currency_symbol : currency.currency : '$'} {competitordata?.comp_prd_mrp}
                          </>
                        )
                      }</span>
                    )  
                  }
                  
                  
                  
                  
                  {competitordata?.comp_prd_shipping_price > 0 && (
                  <Typography variant="caption" sx={{ fontSize: 12, color: currentTheme.currentTheme === 'light' ? '#272727' : '#FFFFFF',marginLeft:"5px",marginRight:"5px"}}>
                    + shipping cost {currency?.currency_symbol}: <span style={{color:"#559EFF"}} >{competitordata?.comp_prd_shipping_price?.toLocaleString()}</span>  
                  </Typography>
                )}
                </Typography>
                <Grid item sx={{display:'flex', alignItems:'center'}}>
                    {/* {console.log('competitordata?.comp_prd_rating', competitordata?.comp_prd_rating, Math.ceil(competitordata?.comp_prd_rating))} */}
                    {/* {console.log(Math.floor(competitordata?.comp_prd_rating))} */}
                <Rating readOnly defaultValue={competitordata?.comp_prd_rating} precision={0.5} emptyIcon={<StarOutlineIcon sx={{color:'text.primary'}} />}/>
                    <Typography className='typo fontweight-500' sx={{textAlign:'left !important', color:'text.primary', lineHeight:0.5, fontSize:'15px !important'}}> 
                        {`${Number(competitordata?.comp_prd_rating).toFixed(1)} `}
                        {`- ${competitordata?.comp_prd_tot_reviews !== 'null' &&  competitordata?.comp_prd_tot_reviews !== null
                            ? competitordata?.comp_prd_tot_reviews : 0 } Reviews`}    
                    </Typography>
                    {
                  (competitordata?.comp_prd_sales_price !==null || competitordata?.comp_prd_mrp !==null ) &&(
                    <Tooltip placement="top" title={<p style={{ fontSize: 11,padding:0 }}>Competitor Price Index</p>}>
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: 12,
                    color: currentTheme.currentTheme === 'light' ? '#272727' : '#FFFFFF',
                    border: currentTheme.currentTheme === 'light' ? '1px solid #606060' : '1px solid #e4e3e3',
                    borderRadius:"3px",
                    padding: '4px', 
                    display: 'inline-block', 
                    marginLeft:"12px"
                  }}>
                  CPI: <span>  </span> 
                  <>
                  <span style={{color:"#559EFF"}} > 
                    {displayCpiValue}
                </span>
                </>
                </Typography>
              </Tooltip>
                  )
                }
                </Grid>
                <Grid item xs={12} sx={{marginTop: 1, display:'flex', alignItems:'flex-start'}}>
                    <Grid item xs={12} sm={4} md={4}>
                        <Grid item sx={{}}>
                            <Table>
                            <TableBody>
                                <TableRow>
                                        <TableCell sx={{...tableCellLeftStyle}} className='tableText' >Brand</TableCell>
                                        <TableCell sx={{textTransform:'capitalize', ...tableCellStyle}} className='tableText' >: {competitordata.comp_prd_brand ? competitordata.comp_prd_brand : 'N/A'}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{...tableCellLeftStyle}} className='tableText' >Category</TableCell>
                                        <TableCell sx={{textTransform:'capitalize', textOverflow:'ellipsis' ,...tableCellStyle}} className='tableText' >:  {competitordata?.comp_category_name}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{...tableCellLeftStyle}} className='tableText' >Department</TableCell>
                                        <TableCell sx={{textTransform:'capitalize', textOverflow:'ellipsis', ...tableCellStyle}} className='tableText' >:  {competitordata?.comp_dept_name}</TableCell>
                                    </TableRow>
                                    {/* <TableRow>
                                        <TableCell sx={{...tableCellLeftStyle}} className='tableText' >Variant</TableCell>
                                        <TableCell sx={{...tableCellStyle}} className='tableText' >:  {competitordata.comp_prd_variants ? competitordata.comp_prd_variants : 'N/A' }</TableCell>
                                    </TableRow> */}
                                </TableBody>
                            </Table>

                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2} sx={{marginLeft:1, marginRight:1, display:'flex', justifyContent:'center', flexFlow:'column'}}>
                        <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{...tableCellLeftStyle, width: '20%'}} className='tableText' >PPU</TableCell>
                                <TableCell sx={{...tableCellLeftStyle, width:'40%', whiteSpace: 'nowrap'}} className='tableText' >:  {!isEmpty(currency) ? currency.currency_symbol ? currency.currency_symbol : currency.currency : '$'} {
                                  competitordata?.comp_prd_norm_price?.includes('/') ? (
                                    competitordata?.comp_prd_norm_price
                                  ) : (
                                <>{competitordata?.comp_prd_norm_price} / <span>{competitordata?.comp_prd_uom}</span></>)}

                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{...tableCellLeftStyle, width: '20%'}} className='tableText' >UOM</TableCell>
                                <TableCell sx={{...tableCellLeftStyle, width:'40%'}} className='tableText' >: {competitordata.comp_prd_uom}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{...tableCellLeftStyle, width: '20%'}} className='tableText' >Size</TableCell>
                                <TableCell sx={{...tableCellLeftStyle, width:'40%'}} className='tableText' >: {competitordata.comp_prd_size}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{...tableCellLeftStyle, width: '20%'}} className='tableText' >Quantity</TableCell>
                                <TableCell sx={{...tableCellLeftStyle, width:'40%'}} className='tableText' >:  {competitordata.comp_prd_no_of_pieces ? competitordata.comp_prd_no_of_pieces : 0}</TableCell>
                            </TableRow>
                        </TableBody>
                        </Table>
            
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} sx={{display:'flex', alignItems:'center', justifyContent:'flex-start'}}>
                    <Table sx={{marginLeft:"2em"}} >
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{...tableCellLeftStyle, width:'20%'}} className='tableText' >UPC</TableCell>
                                <TableCell sx={{...tableCellStyle, width:'50%'}} className='tableText' >: {competitordata.comp_prd_upc ? competitordata.comp_prd_upc : 'N/A'}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{...tableCellLeftStyle, width:'20%'}} className='tableText' >SKU</TableCell>
                                <TableCell sx={{...tableCellStyle, width:'50%'}} className='tableText' >: {competitordata.comp_prd_asin ? competitordata.comp_prd_asin : 'N/A'}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{...tableCellLeftStyle, width:'10%'}} className='tableText' >Availability</TableCell>
                                <TableCell sx={{...tableCellLeftStyle, width:'70%'}} className='tableText' >:  
                                    <Checkbox checkedIcon={checkedIcons()} icon={uncheckedIcons()} checked={competitordata.comp_prd_is_active} sx={{padding:'8px', color:'#FFFFFF', backgroundColor:'transparent'}} />
                                </TableCell>
                            </TableRow>
                            
                        </TableBody>
                        </Table>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} sx={{display:'flex', alignItems:'center', justifyContent:'flex-start'}}>
                    <Table>
                        <TableBody>
                             <TableRow>
                            <TableCell sx={{...tableCellLeftStyle, width:'38%'}} className='tableText' >Seller Name</TableCell>
                            <TableCell sx={{...tableCellStyle, width:'50%', wordWrap: 'break-word'}} className='tableText' >
                              :  {competitordata.seller?.seller_name ? competitordata.seller?.seller_name : 'N/A'}
                            </TableCell>
                          </TableRow>
                            <TableRow>
                                <TableCell sx={{...tableCellLeftStyle, width:'38%'}} className='tableText' >Seller Rating</TableCell>
                                <TableCell sx={{...tableCellStyle, width:'50%'}} className='tableText' >:  {competitordata.seller?.seller_rating ? competitordata.seller?.seller_rating : 'N/A'}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{...tableCellLeftStyle, width:'38%'}} className='tableText' >Seller Review</TableCell>
                                <TableCell sx={{...tableCellStyle, width:'50%'}} className='tableText' >:  {competitordata.seller?.seller_tot_reviews ? competitordata.seller?.seller_tot_reviews : 'N/A'}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    </Grid>
                </Grid>
                {/* variant */}
                <Grid sx={{display: 'flex', width:'95%', mb:1}}>
                  <Typography className='productDetails  productDetails_smallText' sx={{textAlign:'left', color:'text.primary'}}>Variant</Typography>                    
                    <Typography className='productDetails  productDetails_smallText' sx={{overflow:'hidden',marginLeft:"35px", textAlign:'left', color:'text.primary'}}>
                    : {competitordata.comp_prd_variants ? competitordata.comp_prd_variants : 'N/A' }
                        </Typography>
                </Grid>
                <Grid sx={{display: 'flex', width:'95%', mb:1}}>
                  <Typography className='productDetails  productDetails_smallText' sx={{textAlign:'left', color:'text.primary'}}>Spec</Typography>                    
                    <Typography className='productDetails  productDetails_smallText' sx={{overflow:'hidden',marginLeft:"46px", textAlign:'left', color:'text.primary'}}>
                    : {`${competitordata?.comp_prd_spec && competitordata?.comp_prd_spec !== 'nan' ? competitordata?.comp_prd_spec  : 'N/A'}  `}
                        </Typography>
                </Grid>
                <Grid sx={{ display: 'flex', width: '95%', mb: 1 }}>
                  <Typography className='productDetails  productDetails_smallText' sx={{textAlign:'left', color:'text.primary'}}>Description</Typography>
      <Typography
        className='productDetails productDetails_smallText'
        sx={{ overflow: 'hidden', textTransform: 'capitalize',marginLeft:"10px", textAlign: 'left', color: 'text.primary' }}
      >
        : {`${
          shouldTruncate && !showFullDescription ? description.slice(0, MINIMUM_CHARACTER_LIMIT) + '...' : description
        } `}
        {shouldTruncate && (
          <Button onClick={toggleDescription}>
            {showFullDescription ? 'Read less' : 'Read more'}
          </Button>
        )}
      </Typography>
    </Grid>

            </Grid>
            
        </Grid>
        </>
    )

}

export default ComeptitorItem;