// import * as constants from '../constants/clientsCreate.constants';
import * as constants from '../constants/clientMigrate.constants'

export const initialState = {
  isLoading: false,
  clientCreateMigrateData: null,
  isError: null,
};

export const clientMigrateReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.CREATE_CLIENTS_MIGRATE:
      return {
        ...state,
        isLoading: true,
      };
    case constants.CREATE_CLIENTS_MiGRATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        clientCreateMigrateData: action.payload,
        isError: null,
      };
    case constants.CREATE_CLIENTS_MIGRATE_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };
    default:
      return state;
  }
};
