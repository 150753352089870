import { call, put, takeLatest  } from 'redux-saga/effects';  //takeLatest
// import { isEmpty, } from 'lodash';
import * as actions from '../actions/downloadxls.actions';
import * as constants from '../constants/downloadxls.constants';
import NetworkApi from '../../api/NetworkApi';
import { getItems } from '../../utils/storage';
import paths from '../../api/path';

const getApiConfig = () => {
  const [
    token
  ] = getItems([
    'token',
  ]);

  const headers = {
    Authorization: `token ${token}`,
  };

  return {
    Authorization: headers.Authorization
  }
}

export function* handleDownloadXls(request = {}) {
  const apiConfig = getApiConfig()
  try {
    const response = yield call(NetworkApi.get, paths.downloadXlsURL, {}, apiConfig);
    // const response = yield call(NetworkApi.get, paths.downloadXlsURL, {}, apiConfig);
    if (response) {
        yield put(actions.downloadXlsSuccessful(response));
    } else {
      // console.log('error ', response)
      const message = response?.detail ? (
        response?.detail
      ) : (
        ''
        // <FormattedMessage id="notProcess" />
      );
      yield put(actions.downloadXlsFailed(message));
    }

  } catch (error) {
    // console.log('error ', error?.response?.data?.detail)
    const message = error?.response?.data?.detail ? (
      error?.response?.data?.detail
    ) : (
      ''
      // <FormattedMessage id="notProcess" />
    );
    yield put(actions.downloadXlsFailed(message));
  }
}

export function* getXlsData() {
  yield takeLatest(constants.DOWNLOAD_XLS, handleDownloadXls);
}
