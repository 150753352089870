import { put, takeLatest, call } from 'redux-saga/effects';
import { CREATE_CLIENTS_SUCCESS, CREATE_CLIENTS, CREATE_CLIENTS_FAIL } from '../constants/clientsCreate.constants';
import { createClientsSuccess,createClientsFail } from '../actions/clientCreate.action';
import NetworkApi from '../../api/NetworkApi';
import { getItem, getItems } from '../../utils/storage';
import paths from '../../api/path';

const getApiConfig = () => {
  const [ token ] = getItems(['token']);

  const headers = {
    Authorization: `token ${token}`,
  };

  return {
   // headers,
    Authorization: headers.Authorization
  }
}
function* createClientsSaga(action) {
    // console.log("action",action)
  const apiConfig = getApiConfig();
  const userRole = getItem('role');
  try {
    const response = yield call(NetworkApi.post,Number(userRole) === 1 && paths.clientsUrl, action.payload, apiConfig);
    // console.log(response)
    yield put(createClientsSuccess(response));
  } catch (error) {
    // console.log(error.message)
    yield put(createClientsFail(error));
  }
}

export function* watchCreateClients() {
  yield takeLatest(CREATE_CLIENTS, createClientsSaga);
}
