import React, { useEffect } from 'react';
import selectDashboardDetails from '../../redux/selectors/dashboard.selector';
import { useSelector, useDispatch } from 'react-redux';
import { getDashboardDetails } from '../../redux/actions/dashboard.actions';

function HeaderLogo({ userRole }) {

  const dispatch = useDispatch()

  const { dashboardDetails } = useSelector(selectDashboardDetails)

  useEffect(() => {
    dispatch(getDashboardDetails())
  }, []) // eslint-disable-line

  return (
    <>

      {/* <img style={{height:"59px"}} src={dashboardDetails?.data?.summary?.logo ? dashboardDetails?.data?.summary?.logo : "./assets/logo.png" } alt='OttixHow' className='logo' /> */}
      {/* {
    dashboardDetails?.data?.summary?.logo ? (<img style={{height:"50px"}} src={dashboardDetails?.data?.summary?.logo} alt='OttixHow' className='logo' />) : ( <img src="./assets/logo.png" alt='OttixHow' className='logo' /> )
    } */}
      {userRole === 3 ? (<img style={{ height: "50px" }} src={dashboardDetails?.data?.summary?.logo} alt='OttixHow' className='logo' />) :
        (<img src="./assets/logo.png" alt='OttixHow' className='logo' />)}
    </>
  )
}

export default HeaderLogo