import {
  DELETE_MARKET_PLACE_FULL,
  DELETE_MARKET_PLACE_FULL_FAILED,
  DELETE_MARKET_PLACE_FULL_SUCCESSFUL,
} from "../constants/deleteMarketplacefull.constants";

export const initialState = {
  isLoading: false,
  isSuccessful: false,
  isFailure: false,
  data: null,
  error: null,
};

export const deletemarketPlaceFullReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case DELETE_MARKET_PLACE_FULL:
      return {
        ...state,

        isLoading: true,
        isSuccessful: false,
        isFailure: false,
        data: null,
        errorMessage: null,
      };
    case DELETE_MARKET_PLACE_FULL_SUCCESSFUL:
      return {
        ...state,

        isLoading: false,
        isSuccessful: true,
        isFailure: false,
        errorMessage: null,
        data: action.response,
      };
    case DELETE_MARKET_PLACE_FULL_FAILED:
      return {
        ...state,

        isLoading: false,
        isSuccessful: false,
        isFailure: true,
        errorMessage: payload,
        data: null,
      };

    default:
      return state;
  }
};
