import { call, put, takeLatest  } from 'redux-saga/effects';  //takeLatest
// import { useDispatch } from 'react-redux';
// import { isEmpty, } from 'lodash';
import * as actions from '../actions/marketlocation.actions';
import * as constants from '../constants/marketLocation.constants';
import NetworkApi from '../../api/NetworkApi';
import { getItems } from '../../utils/storage';
import paths from '../../api/path';

const getApiConfig = () => {
  const [
    token
  ] = getItems([
    'token',
  ]);

  const headers = {
    Authorization: `token ${token}`,
  };

  return {
   // headers,
    Authorization: headers.Authorization
  }
}
export function* getMarketLocation(request = {}) {
  const apiConfig = getApiConfig()
  const { payload = {} } = request;

  try {
    const response = yield call(NetworkApi.post, paths.marketLocationURL, {"market_place_id":payload}, apiConfig);
    // console.log(response);
    if (response && !response?.detail) {
      yield put(actions.marketLocationSuccessful(response));

    } else {
      // console.log('error ', response)
      const message = response?.detail ? (
        response?.detail
      ) : (
        ''
        // <FormattedMessage id="notProcess" />
      );
      yield put(actions.marketLocationFailed(message));
    }

  } catch (error) {
    const message = error?.response?.data?.detail ? (
      error?.response?.data?.detail
    ) : (
      ''
      // <FormattedMessage id="notProcess" />
    );
    yield put(actions.marketLocationFailed(message));
  }
}

export function* fetchMarketLocation() {
  yield takeLatest(constants.GET_MARKET_LOCATION, getMarketLocation);
}
