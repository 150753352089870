import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import PieChart from '../../components/charts/PieChart';

const Positions = props => {
    const { productStats, currentTheme } = props;
    const [calculatedData, setCalculatedData] = useState([0, 0, 0])
    const [totalPositionValue, setTotalPositionValue] = useState(true)

    useEffect(() => {
        // console.log(productStats )
        const {less, more, equal} = productStats;
        const totalMarketValue = less+more+equal; 
        const lessPercent = less > 0 ? (Number(less)/totalMarketValue)*100 : 0;
        const morePercent = more > 0 ? (Number(more)/totalMarketValue)*100 : 0;
        const equalPercent = equal > 0 ?(Number(equal)/totalMarketValue)*100 : 0;
        // console.log(totalMarketValue, lessPercent, morePercent, equalPercent)

        setCalculatedData([lessPercent,morePercent, equalPercent])
        // setCalculatedData(productStats)
    }, [productStats])
    useEffect(() => {
        // console.log(calculatedData, calculatedData.reduce(item =>  item>0));
        setTotalPositionValue(calculatedData.reduce(item =>  item>0))
    }, [calculatedData])


    return (
        <>
            <Typography className='typo' variant='h5' component={'h5'} sx={{color:'text.secondary'}}  >Price Position (All Market Place)</Typography>
            {totalPositionValue ?
            <div style={{margin:0, marginTop:'2rem', textAlign:'', width:'50%'}}>
                <PieChart data={calculatedData} productStats={productStats}  />
            </div>
            : <div style={{margin:'12px 0px 8px 0', width:'61%'}}>
                <img src={currentTheme === 'light'? '/assets/positionlight.jpg' : '/assets/positiondark.jpg'} alt='position' style={{width:'100%'}} />
            </div>
            }
        </>
    )
}

export default Positions;