import { call, put, takeLatest  } from 'redux-saga/effects';  //takeLatest
// import { isEmpty, } from 'lodash';
import * as actions from '../actions/deleteproduct.actions';
import * as constants from '../constants/deleteproduct.constants';
import NetworkApi from '../../api/NetworkApi';
import { getItems } from '../../utils/storage';
import paths from '../../api/path';

const getApiConfig = () => {
  const [
    token
  ] = getItems([
    'token',
  ]);

  const headers = {
    Authorization: `token ${token}`,
  };

  return {
    Authorization: headers.Authorization
  }
}

export function* handleDeleteProduct(request = {}) {
  const apiConfig = getApiConfig()
  const { payload = {} } = request;
  const obj = {prd_id:payload};
  // console.log(paths.productURL, payload, apiConfig);
  
  // console.log('handleDeleteproduct ', payload);
  try {
    const response = yield call(NetworkApi.post, `${paths.productURL}delete/`, obj, apiConfig);
    // console.log(response)
    if (response && !response?.detail) {
        // console.log('kki ',response)
        yield put(actions.deleteProductSuccessful(response));
      
    } else {
      // console.log('error ', response)
      const message = response?.detail ? (
        response?.detail
      ) : (
        ''
        // <FormattedMessage id="notProcess" />
      );
      yield put(actions.deleteProductFailed(message));
    }

  } catch (error) {
    // console.log('error ', error?.response?.data?.detail)
    const message = error?.response?.data?.detail ? (
      error?.response?.data?.detail
    ) : (
      ''
      // <FormattedMessage id="notProcess" />
    );
    yield put(actions.deleteProductFailed(message));
  }
}

export function* deleteProduct() {
  yield takeLatest(constants.DELETE_PRODUCT, handleDeleteProduct);
}
