import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import {toast} from 'react-toastify';
import useActions from '../../redux/use-actions';
import selectUserDetails from '../../redux/selectors/authenticate.selector';
import {validateLogin, resetAuthState  } from '../../redux/actions/authentication.actions';
import { setItem } from '../../utils/storage';

const UserProfile = () => {

    const navigate = useNavigate()
    // const dispatch = useDispatch();
    const actions = useActions({ validateLogin, resetAuthState });
    const {userValidation } = useSelector(selectUserDetails);
    const {data, isSuccessful, isFailure } = userValidation;

    useEffect(() => {
        if (isSuccessful) {
            if(Number(data.role) === 2){
                navigate('/myproducts')
            } else {
                if(!data?.productsCreated){
                    navigate('/addproduct')
                } else {
                    navigate('/dashboard');
                }
            }
            
        }
    }, [isSuccessful, navigate]); // eslint-disable-line

    useEffect(()=>{
        if(isFailure){
            setTimeout(() =>{
                actions.resetAuthState()
            }, 2000)
        }
    }, [isFailure]) // eslint-disable-line


    const handleSubmit = useCallback((pValues) =>{
        // console.log('handlesubmit');
        const { username, password} = pValues
        setItem('username',username)
        actions.validateLogin({
            username,
            password
          });
    }, [actions])

    return {
        handleSubmit, isFailure
    }
}

export default UserProfile;


