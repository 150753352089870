import React,{ useEffect } from 'react';
import { Container, Grid, Avatar, List, ListItem, ListItemAvatar, ListItemText, Typography, Divider } from '@mui/material';
// import MailLockIcon from '@mui/icons-material/MailLock';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

import { getAppNotification } from '../../redux/actions/getappnotification.action';
import selectGetappNotification from '../../redux/selectors/getappnotification.selector';
import { useDispatch, useSelector } from 'react-redux';


const Notification = () => {
    
    const dispatch = useDispatch()    
    const {loading, data} = useSelector(selectGetappNotification);

    useEffect(() => {
        dispatch(getAppNotification());
    }, []) //eslint-disable-line

return (
    <Container maxWidth={'xl'} className='maincontainer' sx={{padding:0, marginTop:2}}>
        <Grid container spacing={0} sx={{display:'flex', pb:2}}>
            <Grid item xs={12} sx={{ backgroundColor:'background.paper', display:'flex', flexFlow:'column', justifyContent:'center', padding:5}}>
                <Typography variant='h4' component={'h4'} sx={{fontSize:16, textAlign:'center' }}>Notifications</Typography>
                <List>
            {loading ? ( 
              <ListItem sx={{ my: 1.5 }}>
                 <Typography variant="subtitle2">Loading</Typography>
              </ListItem>
            ) : (
              data?.map((obj) => (
                <ListItem key={obj?.notification_id} sx={{ my: 1.5 }}>
                  <ListItemAvatar>
                    <Avatar>
                      <NotificationsNoneIcon sx={{ fontSize: 30 }} />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2">{obj?.created_date}</Typography>
                    }
                    secondary={
                      <Typography variant="body1" style={{ fontSize: '16px' }}>
                        {obj?.notification}
                      </Typography>
                    }
                  />
                  <Divider />
                </ListItem>
              ))
            )}
          </List>



            </Grid>
        </Grid>
    </Container>
)

}

export default Notification;