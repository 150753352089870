import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; 
import { isEmpty, isNull, isUndefined } from 'lodash';
import { Button, Typography } from '@mui/material';
import { RefreshRounded } from "@mui/icons-material";

import selectRefreshProductDetails from '../../../redux/selectors/refreshProduct.selector';
import * as actions from '../../../redux/actions/refreshProduct.actions';
import SelectMarket from './SelectMarket';
import { getItem } from '../../../utils/storage';
import selectMarketPlaceDetails from '../../../redux/selectors/marketplace.selector';
import * as refreshBtnAction from '../../../redux/actions/getRefreshBtnStatus.actions';
import selectRefreshBtnStatus from '../../../redux/selectors/getRefreshBtnStatus.selector';

const RefreshProduct = props => {
const {id, handleRefresh, competitorInformation, userRole, qaUser} = props;
const dispatch = useDispatch()
const {refreshProductDetails} = useSelector(selectRefreshProductDetails)
const {isSuccessful, errorMessage} = refreshProductDetails; //isFailure
const [marketIds, setMarketIds] = useState([])
const [locIds, setLocIds] = useState([])
const [maketLabels, setMaketLabel] = useState([])
const [clicked, setClicked] = useState(false)
const [isSelected, setIsSelected] = useState(false)
const [isError, setIsError] = useState(false)
const [selectedMarket,setSelectedMarket] = useState('selectmarket')
const [fetchBtnClicked, setFetchBtnClicked] = useState(true);
const currency = !isNull(getItem('currency')) && !isUndefined(getItem('currency')) ? getItem('currency').length !== undefined ? typeof(getItem('currency')) === 'string' ? JSON.parse(getItem('currency')) : getItem('currency') : JSON.parse(getItem('currency')) : [{'currency_symbol':'$'}]

// 
const {marketPlaceDetails} = useSelector(selectMarketPlaceDetails)
const {data} = marketPlaceDetails
const { refreshStatus } = useSelector(selectRefreshBtnStatus);
const customDesign = getItem('customDesign');
// console.log("data in refresh page ",data)

const newLoc_id = data && data.length > 0 ? data[0].locations[0].loc?.loc_id : undefined;
const newMarket_place_id = data && data[0]?.market_place_id
// console.log("newMarket_place_id", newMarket_place_id);
const [errorAvailable,setErrorAvailable] = useState("")
//  console.log('refresh ', isSuccessful)
// console.log(currency)
useEffect(() =>{
 if(isSuccessful){
    setTimeout(() => {
        setClicked(false)
        setIsSelected(false)
        actions.getRefreshReload();
        handleRefresh();
    }, [1000])
 }
})
useEffect(() => {
  if(errorMessage){
    setErrorAvailable(errorMessage)
  }
}, [errorMessage])
useEffect(() => {
  setErrorAvailable("")
}, [id])

useEffect(()=>{
    if (customDesign === "UI_2" || qaUser === 6) {
        dispatch(refreshBtnAction.getRefreshBtnStatus());
    }else{
        setFetchBtnClicked(false);
    }
},[]) //eslint-disable-line

// useEffect(() => {
//     if (refreshStatus.data) {
//       const tempStatus = refreshStatus.data.show_refresh
//       if (tempStatus)
//         setFetchBtnClicked(false);
//       else
//         setFetchBtnClicked(true);
//     }
//   }, [refreshStatus])
useEffect(() => {
    if (customDesign === "UI_2" || qaUser === 6) {
      if (refreshStatus.data) {
        const tempStatus = refreshStatus.data.show_refresh;
        if(tempStatus){
            setFetchBtnClicked(false);
        }else{
            setFetchBtnClicked(true);
        }
      }
    }
  }, [ refreshStatus]); //eslint-disable-line

// console.log("errorAvailable",errorAvailable)
useEffect(() => {
    if(!isEmpty(competitorInformation)){
        const marketIdsa = [...new Set(competitorInformation.map(competitor => competitor.market_place_id).flat())]
        const marketLabel = [...new Set(competitorInformation.map(competitor => competitor.market_place_name).flat())]
        // console.log('marketLabel ', marketLabel);
        const locationIds = [...new Set(competitorInformation.map(competitor => competitor.loc.loc_id).flat())]
        // console.log(competitorInformation,' locationIds ', locationIds)
        setMarketIds(marketIdsa);
        setMaketLabel(marketLabel);
        // console.log('locationIds ', locationIds)
        setLocIds(locationIds);
    }
}, [competitorInformation]) //

    const handleMarketPlace = (value) => {
        setIsError(false);
        setSelectedMarket(value)
        if(!value) {};
        const mIndex = maketLabels.findIndex(item => item === value)
        console.log(mIndex);
        setIsSelected(true);
        //  console.log({loc_id:locIds[mIndex],prd_id:id, cuttoff_score:60, user_id:currency[0]?.user, market_place_ids:marketIds[mIndex], })
    //    console.log('locIds[mIndex] ', locIds[mIndex], mIndex, locIds, value);
        dispatch(actions.getRefreshProduct({
            prd_id:id,
            cuttoff_score:100, // changed from 60 to 100
            // loc_id:locIds[mIndex] !== undefined ? locIds[mIndex] : locIds[0]            
            loc_id: locIds[mIndex] !== undefined ? locIds[mIndex] : (locIds[0] !== undefined ? locIds[0] : newLoc_id),
            user_id: userRole !== 2 ? currency[0]?.user : qaUser, 
            market_place_ids:marketIds[mIndex] ? marketIds[mIndex] : newMarket_place_id 
        }))
        handleClose()
        
        
    }

    const handleClose = () =>{
        setClicked(false);
    }
    const handleRefreshHandler = () =>{
        setClicked(true);
        setSelectedMarket('selectmarket');
    }
    return (
        <>
        { clicked &&
            <SelectMarket 
                // handleSelectedMarket = {handleSelectedMarket}
                userRole = {userRole}
                handleClose = {handleClose}
                selectedMarket={selectedMarket} 
                marketData={maketLabels} 
                handleSelectMarket={handleMarketPlace}
            />
        }
        {errorAvailable && <Typography variant='body2' sx={{width:150, ml:1}}>{errorAvailable}</ Typography> }
        {/* {isFailure && errorMessage && <Typography variant='body2' sx={{width:150, ml:1}}>{errorMessage}</ Typography>} */}
        {isError && <Typography variant='body2' sx={{width:150, ml:1}}>Select MarketPlace for refresh product.</ Typography>}
        { !isSelected ?
            <Button variant='outlined'
            disabled={fetchBtnClicked}
            sx={{width:'150px', fontSize:11, ml:1, borderColor:'text.primary', color:'text.primary', textTransform:'none'}} 
            onClick={handleRefreshHandler}
            startIcon={<RefreshRounded sx={{color:'text.primary'}} />}> Refresh</Button>
            :
            <Typography variant='body2' sx={{width:150, ml:1}}>Refreshing market place....</Typography>
        }
        </>
    )


} 

export default RefreshProduct