import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getItem, setItem } from '../../utils/storage';

import selectProducts from '../../redux/selectors/myproduct.selector';
import * as actions from '../../redux/actions/productDetails.actions';

import * as prdactions from '../../redux/actions/productDetails.actions';
import selectProductsDetails from '../../redux/selectors/productdetails.selector';

import selectQaUserDetails from '../../redux/selectors/qauser.selector';
import * as qaActions from '../../redux/actions/qaUser.actions';

import selectAapproveDetails from '../../redux/selectors/qaapprove.selector';
import * as qaApproveAction from '../../redux/actions/qaapprove.actions'

import { groupBy, isEmpty, isNull, isUndefined } from 'lodash';
import moment from 'moment';
// import { original } from '@reduxjs/toolkit';

const UseMyProduct = ({ page, rowsPerPage, priceFilterData, filteringData, categoringData, brandFilterData, locationFilterData, checkedKeyvalue, setCheckedKeyvalue, setIsFiltering }) => {

    const [productDetails, setProductDetails] = useState([])
    const [originalData, setOriginalData] = useState([])
    const [selectedUser, setSelectedUser] = useState('');
    const [marketPlaceLabel, setMarketPlaceLabel] = useState([])
    const [isSearched, setIsSearched] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [selectLoc, setSelectLoc] = useState(null);
    const [getLoaded, setLoaded] = useState(false);
    const dispatch = useDispatch();
    const pageList = getItem('pageList');
    const userToken = getItem('token');
    const userRole = getItem('role');
    const customDesign = getItem('customDesign');
    const qaUser = getItem('qaUser');
    const searchkeyword = getItem('searchKeyword')
    const marketPlace = getItem('activeMarketPlace');

    // const [userList, ] = useState([{id:1, email:'orbioadmin@gmail.com'}]); 
    const { products } = useSelector(selectProducts);
    const { qaUserDetails } = useSelector(selectQaUserDetails);
    const { productDetails: prdDetails } = useSelector(selectProductsDetails)
    const { data: prdData } = prdDetails;
    const { data: userList } = qaUserDetails;
    const { data, isLoading, isFailure, message: errorMessage } = products;
    const qaapproveDetails = useSelector(selectAapproveDetails)
    const { isSuccessful: qaSuccess, isFailure: qaFailure } = qaapproveDetails.qaapproveDetails
    const [buyboxMarket, setBuyboxMarket] = useState('');

    useEffect(() => {
        if (prdData) {
            dispatch(prdactions.productDetailsReset())
        }
    }, [prdData, dispatch])

    useEffect(() => {
        if (qaSuccess) {
            setTimeout(() => {
                dispatch(qaApproveAction.approveAllReset())
            }, 1000)

        }
    }, [qaSuccess, dispatch])

    // console.log('selectAapproveDetails ', qaapproveDetails.qaapproveDetails)

    const getProductList = useCallback((pValue = '') => {
        // console.log('pValue ', pValue, priceFilterData, pValue.indexOf('price'));
        if (userToken) {
            setProductDetails([])
            // console.log(pValue,'locationFilterData.toString() ', priceFilterData, filteringData, brandFilterData, categoringData)
            if (pValue.indexOf('category_id__in') === -1) {
                pValue = `${pValue}&category_id__in=${categoringData.toString()}`
            }
            if (pValue.indexOf('price') === -1) {
                pValue = `${pValue}&price=${priceFilterData.toString().toLowerCase()}`
            }
            if (pValue.indexOf('prd_brand__in') === -1) {
                // console.log('iuoo', encodeURI(brandFilterData))
                pValue = `${pValue}&prd_brand__in=${brandFilterData.toString().toLowerCase()}`
            }
            if (pValue.indexOf('dept_id__in') === -1) {
                pValue = `${pValue}&dept_id__in=${filteringData.toString()}`
            }
            if (pValue.indexOf('page_size') === -1) {
                pValue = `${pValue}&page_size=${rowsPerPage}`
            }
            if (pValue.indexOf('page') === -1) {
                pValue = `${pValue}&page=${page}`
            }
            if (pValue.indexOf('prd_mp') === -1) {
                pValue = `${pValue}&prd_mp=${marketPlace ? marketPlace : ""}`
            }
            pValue ? setIsSearched(true) : setIsSearched(false);
            //  console.log('getProductList', userRole, selectedUser, getItem('qaUser'))
            setItem('pageList', JSON.stringify(pValue));
            // console.log('pValue', pValue);
            setLoaded(true);
            // Added a new URL parameter
            // pValue += `&prd_is_key_value=${checkedKeyvalue}`;
            if (userRole === 2) {
                if (getItem('qaUser') !== '' && pValue.indexOf('user_id') === -1) {
                    // setItem('qaUser', selectedUser)
                    pValue = `${pValue}&user_id=${getItem('qaUser')}`
                }
                dispatch(actions.getQATotalProducts({ pPath: pValue }))
            } else if (userRole !== 2) {
                dispatch(actions.getTotalProducts({ pPath: pValue }))
            }
        }
    }, [dispatch, userToken]) // eslint-disable-line

    useEffect(() => {
        // console.log('loadingData  ', data, isLoading, isFailure, userRole, getLoaded);
        // console.log('rowsPerPage ', rowsPerPage, data)
        if (!data && !loadingData && userRole !== 2) {
            // console.log('firstloading');

            let pages = page === 0 ? 1 : page + 2;
            let rowsPage = rowsPerPage;
            let searchStr = '';
            let price = '';
            let category_id__in = '';
            let dept_id__in = '';
            let prd_brand__in = '';
            let prd_mp = marketPlace ? marketPlace : "";
            setLoadingData(prevState => !prevState)
            if (pageList !== undefined && pageList !== null && pageList !== '' && typeof (pageList) === 'string') {
                // console.log('pageList', pageList)
                const tempPageList = JSON.parse(pageList).split('&')
                tempPageList.map(item => {
                    // console.log(item.indexOf('page='), item.indexOf('page_size='))
                    if (item.indexOf('page=') > -1) {
                        pages = item.split("=")[1];
                    } else if (item.indexOf('page_size=') > -1) {
                        rowsPage = item.split("=")[1];
                    } else if (item.indexOf('price=') > -1) {
                        price = item.split("=")[1];
                    } else if (item.indexOf('search=') > -1) {
                        searchStr = item.split("=")[1];
                    } else if (item.indexOf('category_id__in=') > -1) {
                        category_id__in = item.split("=")[1];
                    } else if (item.indexOf('dept_id__in=') > -1) {
                        dept_id__in = item.split("=")[1];
                    } else if (item.indexOf('prd_brand__in=') > -1) {
                        prd_brand__in = item.split("=")[1];
                    } else if (item.indexOf('prd_mp=') > -1) {
                        prd_mp = item.split("=")[1];
                    }
                    return item;
                });
                // console.log('pageList ', tempPageList)
            }
            // console.log('price ', price, getLoaded);
            // console.log('loading ',getLoaded, pages, rowsPage, searchStr, prd_brand__in, dept_id__in, category_id__in);
            if (!getLoaded) {
                if (searchStr === '' && prd_brand__in === '' && dept_id__in === '' && category_id__in === '') {
                    getProductList(`page=${pages !== '' ? pages : 1}&page_size=${rowsPage !== '' ? rowsPage : 10}&prd_mp=${marketPlace ? marketPlace : ""}`)

                } else {
                    getProductList(`search=${searchStr}&page=${pages !== '' ? pages : 1}&page_size=${rowsPage !== '' ? rowsPage : 10}&price=${price}&price=${price}&prd_brand__in=${prd_brand__in.toString().toLowerCase()}&category_id__in=${category_id__in.toString()}&dept_id__in=${dept_id__in.toString()}&prd_mp=${marketPlace ? marketPlace : ""}`)
                }
            }

        }
    }, [getProductList, data, loadingData]) // eslint-disable-line

    useEffect(() => {
        if (isEmpty(userList) && userRole === 2) {
            dispatch(qaActions.getQAUser())
        }
        // console.log(userList, selectedUser)
        if (userList !== undefined && !isEmpty(userList) && selectedUser === '') {
            const qaUser = getItem('qaUser');
            if (qaUser === '' || qaUser === undefined || qaUser === null) {
                setItem('qaUser', userList[0].id)
                setItem('currency', JSON.stringify(userList[0]?.currency))
                setSelectedUser(prevState => userList[0].id);
            }
            let pages = page === 0 ? 1 : page + 2;
            let rowsPage = rowsPerPage;
            let searchStr = '';
            let price = '';
            let category_id__in = '';
            let dept_id__in = '';
            let prd_brand__in = '';
            let prd_mp = marketPlace ? marketPlace : "";
            setLoadingData(prevState => !prevState)
            if (pageList !== undefined && pageList !== null && pageList !== '') {
                // console.log('pageList', pageList)
                const tempPageList = JSON.parse(pageList).split('&')
                tempPageList.map(item => {
                    // console.log(item.indexOf('page='), item.indexOf('page_size='))
                    if (item.indexOf('page=') > -1) {
                        pages = item.split("=")[1];
                    } else if (item.indexOf('page_size=') > -1) {
                        rowsPage = item.split("=")[1];
                    } else if (item.indexOf('price=') > -1) {
                        price = item.split("=")[1];
                    } else if (item.indexOf('search=') > -1) {
                        searchStr = item.split("=")[1];
                    } else if (item.indexOf('category_id__in=') > -1) {
                        category_id__in = item.split("=")[1];
                    } else if (item.indexOf('dept_id__in=') > -1) {
                        dept_id__in = item.split("=")[1];
                    } else if (item.indexOf('prd_brand__in=') > -1) {
                        prd_brand__in = item.split("=")[1];
                    } else if (item.indexOf('prd_mp=') > -1) {
                        prd_mp = item.split("=")[1];
                    }
                    return item;
                });
                // console.log('pageList ', tempPageList)
            }
            // console.log('qaloading', price, page, rowsPerPage, getItem('qaUser'), userList[0].id);
            if (!getLoaded && searchStr === '' && prd_brand__in === '' && dept_id__in === '' && category_id__in === '') {
                //    console.log('sdd');
                getProductList(`page=${pages}&page_size=${rowsPage}&price=${price}&user_id=${getItem('qaUser')}&prd_mp=${marketPlace ? marketPlace : ""}`)
            }
        }
    }, [userRole, userList, selectedUser]) // eslint-disable-line

    const setpageList = (pValue) => {
        if (pValue.indexOf('category_id__in') === -1) {
            pValue = `${pValue}&category_id__in=${categoringData.toString()}`
        }
        if (pValue.indexOf('price') === -1) {
            pValue = `${pValue}&price=${priceFilterData.toString().toLowerCase()}`
        }
        if (pValue.indexOf('prd_brand__in') === -1) {
            // console.log('iuoo', encodeURI(brandFilterData))
            pValue = `${pValue}&prd_brand__in=${brandFilterData.toString().toLowerCase()}`
        }
        if (pValue.indexOf('dept_id__in') === -1) {
            pValue = `${pValue}&dept_id__in=${filteringData.toString()}`
        }
        if (pValue.indexOf('prd_mp') === -1) {
            console.log(pValue);
            pValue = `${pValue}&prd_mp=${marketPlace ? marketPlace : ""}`
        }
        getProductList(pValue);
        // console.log('setpageList ', priceFilterData, brandFilterData, categoringData, filteringData)
    }
    const setProductList = (priceData, filtData, cateData, brndData) => {
        // console.log('using loc ', rowsPerPage, page, priceFilterData)
        const pages = page === 0 ? 1 : page;
        const str = `page=${pages}&page_size=${rowsPerPage}&price=${priceData}&prd_brand__in=${brndData.toString().toLowerCase()}&category_id__in=${cateData.toString()}&dept_id__in=${filtData.toString()}&prd_mp=${marketPlace ? marketPlace : ""}`
        getProductList(str)
    }

    useEffect(() => {
        if (data !== null) {
            //  console.log('data ')
            if (!isEmpty(locationFilterData)) {
                setSelectLoc(Number(locationFilterData[0]));
            }
            setTimeout(() => {
                setLoaded(true);
            }, 2000)
            const storeData = Array.from(data.results);
            let returnData = storeData.map(item => {
                item.deptname = !isNull(item.dept) && item.dept?.dept_name ? String(item.dept?.dept_name) : 'N/A';
                item.deptId = !isNull(item.dept) && item.dept?.dept_id ? item.dept?.dept_id : null;
                item.categoryname = !isNull(item.category) && item.category?.category_name ? String(item.category?.category_name) : 'N/A';
                item.cateId = !isNull(item.category) && item.category?.category_id ? item.category?.category_id : null;
                item.prd_variants = item.prd_variants !== 'nan' && item.prd_variants !== '' ? item.prd_variants : 'N/A'
                item.prd_url = item.prd_url !== 'nan' && item.prd_url !== '' ? item.prd_url : ''
                const activeLocation = [item.locations[0]] // getDefaultLocation(item.locations); //
                // console.log('activeLocation', item.locations);
                if (!isEmpty(activeLocation) && activeLocation[0] !== undefined) {
                    item.filtered_sales_price = activeLocation[0].prd_sales_price !== null ? activeLocation[0].prd_sales_price : activeLocation[0].prd_mrp;
                    item.filtered_norm_price = activeLocation[0].prd_norm_price;
                    item.filtered_mrp = activeLocation[0].prd_mrp;
                    item.activeLocation = activeLocation;
                } else if (!item.locations) {
                    item.filtered_sales_price = item.locations[0].prd_sales_price !== null ? item.locations[0].prd_sales_price : item.locations[0].prd_mrp;
                    item.filtered_norm_price = item.locations[0].prd_norm_price;
                    item.filtered_mrp = item.locations[0].prd_mrp;
                    item.activeLocation = item.locations[0];
                }



                const comp_market_Data = !isEmpty(item.competitors) && item.competitors.map(competitor => {
                    if (competitor.market_place !== null) {
                        competitor.market_place_id = competitor.market_place?.market_place_id;
                        competitor.market_place_name = competitor.market_place?.market_place_name;
                    }
                    return competitor;
                }
                )
                item.competitors = comp_market_Data !== false ? comp_market_Data : [];

                const filteredCompetitors = !isEmpty(item.competitors) && [groupBy(item.competitors, 'market_place_name')];
                item.filteredMarket = !isEmpty(item.competitors) && [...new Set(item.competitors.map(competitor => competitor.market_place_name).flat())]
                if (item.filteredMarket && (customDesign === "UI_2" || qaUser === 6)) {
                    setBuyboxMarket(item.filteredMarket[0]);
                }
                item.filteredCompetitors = filteredCompetitors;

                // console.log(item.competitors, filteredCompetitors, item.filteredMarket)

                // code for displaying competitors price with maxscore and maxsalesprice
                item.filteredMarket && item.filteredMarket.map(marketLabel => {
                    let maxPrice = 0;
                    for (const [key, value] of Object.entries(filteredCompetitors[0])) {
                        if (key === marketLabel && (key !== undefined || key !== null)) {
                            // console.log(key , marketLabel)
                            // const maxMatchRate = Math.max(...value.map( competitorItem=> !isNull(competitorItem.comp_prd_score) && competitorItem.comp_prd_score))
                            const maxMatchRate = isEmpty(locationFilterData) ?
                                Math.max(...value.map(competitorItem => !isNull(competitorItem.comp_prd_score) && competitorItem.comp_prd_score))
                                : Math.max(...value.map(competitorItem => locationFilterData[0] === competitorItem.loc?.loc_id && !isNull(competitorItem.comp_prd_score) && competitorItem.comp_prd_score))
                            const filters = value.filter(competitorItem => !isNull(competitorItem.comp_prd_score) && !isUndefined(competitorItem.comp_prd_score) && competitorItem.comp_prd_score === maxMatchRate && competitorItem.comp_prd_sales_price !== 0)
                            // console.log(maxMatchRate, filters);
                            if (!isEmpty(filters)) {
                                const maxCompetitorPrice = Math.max(...filters.map(item => item.comp_prd_sales_price))
                                const maxCompetitorMrp = Math.max(...filters.map(item => item.comp_prd_mrp))
                                // console.log('maxCompetitorPrice', maxCompetitorPrice)
                                maxPrice = !isNull(maxCompetitorMrp) ? maxCompetitorPrice : maxCompetitorMrp
                            }
                        }
                    }
                    if (marketLabel !== undefined && marketLabel !== null && maxPrice !== null && maxPrice !== undefined) {
                        item[`competitorPriceDiff${marketLabel}`] = Number(maxPrice - item.prd_sales_price).toFixed(2);
                        item[`competitorMaxPrice${marketLabel}`] = maxPrice !== false ? Number(maxPrice.toFixed(2)) : 'N/A';
                    }
                    return maxPrice;
                })
                item.filteredMarket && item.filteredMarket.map(marketLabel => {
                    let maxPrice = 0;
                    // let maxScore = 0;
                    let maxNormPrice = 0;

                    for (const [key, value] of Object.entries(filteredCompetitors[0])) {
                        if (key === marketLabel && (key !== undefined || key !== null)) {
                            const filteredItems = value.filter(competitorItem => competitorItem?.comp_prd_sales_price !== 0);

                            if (!isEmpty(filteredItems)) {
                                const maxMatchScore = Math.max(...filteredItems?.map(competitorItem => competitorItem?.comp_prd_score));
                                const maxMatchItems = filteredItems.filter(competitorItem => competitorItem?.comp_prd_score === maxMatchScore);

                                if (maxMatchItems.length > 0) {
                                    // const highestSalesPriceItem = maxMatchItems.reduce((prevItem, currItem) =>
                                    //   (currItem.comp_prd_sales_price > prevItem.comp_prd_sales_price) ? currItem : prevItem
                                    // );
                                    const sortedItems = maxMatchItems.sort((a, b) => {
                                        const scoreComparison = (b?.comp_prd_score || 0) - (a?.comp_prd_score || 0);
                                        if (scoreComparison !== 0) {
                                            return scoreComparison;
                                        }
                                        return b?.comp_prd_sales_price - a?.comp_prd_sales_price;
                                    })
                                    const highestSalesPriceItem = sortedItems[0];

                                    maxNormPrice = highestSalesPriceItem?.comp_prd_norm_price || highestSalesPriceItem?.comp_prd_mrp;
                                    maxPrice = highestSalesPriceItem?.comp_prd_sales_price || highestSalesPriceItem?.comp_prd_mrp;


                                    // maxScore = highestSalesPriceItem?.comp_prd_score || 0;
                                    if (maxNormPrice !== undefined && maxNormPrice !== null) {
                                        maxNormPrice = String(maxNormPrice); // Convert to string
                                    }
                                    if (!maxNormPrice?.includes("/")) {
                                        maxNormPrice = `${maxNormPrice} / ${highestSalesPriceItem?.comp_prd_uom}`;
                                    }
                                    if (!maxNormPrice?.includes("/")) {
                                        maxNormPrice = `${maxNormPrice} / ${highestSalesPriceItem?.comp_prd_uom}`;
                                    }
                                }
                            } else {
                                const maxMatchMrp = Math.max(...value.map(competitorItem => competitorItem?.comp_prd_mrp));
                                maxNormPrice = maxMatchMrp;
                                maxPrice = maxMatchMrp;
                                // maxScore = 0; // Set the score to 0 when using "comp_prd_mrp"
                            }
                        }

                        //  only 
                        if (customDesign === "UI_2" || qaUser === 6) {
                            // && item.filtered_sales_price !==0
                            if (item.competitors.length > 0 && item.competitors.some(obj => obj.comp_prd_custom_field === "BUY BOX")) {
                                const buyBoxItems = item.competitors.filter(obj => obj.comp_prd_custom_field === "BUY BOX");
                                const maxScore = Math.max(...buyBoxItems.map(obj => obj.comp_prd_score));

                                const maxScoreItem = buyBoxItems.find(obj => obj.comp_prd_score === maxScore);
                                // console.log("maxScoreItem",maxScoreItem)
                                maxPrice = maxScoreItem.comp_prd_sales_price;
                                let maxNormValue = maxScoreItem?.comp_prd_norm_price
                                if (typeof maxNormValue === 'string' && maxNormValue.includes("/")) {
                                    maxNormPrice = maxScoreItem.comp_prd_norm_price
                                } else {
                                    maxNormPrice = `${maxScoreItem.comp_prd_norm_price} / ${maxScoreItem.comp_prd_uom} `
                                }
                            } else {
                                maxPrice = item.filtered_sales_price;
                                let maxNormVal = item?.filtered_norm_price
                                if (typeof maxNormVal === 'string' && maxNormVal?.includes("/")) {
                                    maxNormPrice = item.filtered_sales_price
                                } else {
                                    maxNormPrice = `${item.filtered_sales_price} / ${item.prd_uom}`
                                }

                            }
                        }
                        // if (customDesign === "UI_2"){
                        //     item[`competitorMaxPrice${marketLabel}`] = maxPrice;

                        // }




                    }

                    if (marketLabel !== undefined && marketLabel !== null && maxPrice !== null && maxPrice !== undefined) {
                        //   item[`competitorPriceDiff${marketLabel}`] = Number(maxPrice - item.prd_sales_price).toFixed(2);
                        // item[`competitorMaxPrice${marketLabel}`] = maxPrice !== false ? Number(maxPrice.toFixed(2)) : 'N/A';
                        item[`competitorMaxNormPrice${marketLabel}`] = String(maxNormPrice);
                        // item[`competitorMaxScore${marketLabel}`] = maxScore;
                    }
                    return maxPrice;
                });

                // buyboxx
                //  !isEmpty(filteredCompetitors) && filteredCompetitors.map(items => 
                //     {
                //         const filteredComp = Object.values(items)[0].filter(compItem => {
                //             return !isNull(compItem.comp_prd_custom_field) && compItem.comp_prd_custom_field !== '' && compItem.comp_prd_custom_field.toLowerCase() === "buy box"
                //         })
                //         if(!isEmpty(filteredComp)){
                //             let maxScore = Math.max(...filteredComp.map((obj) => obj.comp_prd_score));
                //             item.comp_modified_date = moment(filteredComp[0].modified_date).format('MM DD YYYY h:mm:ss A');
                //             const sellerWithMaxScore = filteredComp.find(sobj => sobj.comp_prd_score === maxScore)
                //             // console.log(maxScore, sellerWithMaxScore);
                //             if (sellerWithMaxScore) {
                //                 item.buybox = sellerWithMaxScore?.seller?.seller_name?.replace(/[^\w\s]/g, '')
                //             }
                //             const sobj = Object.entries(item).filter(compLabel => compLabel[0].indexOf('competitorMaxPrice') !== -1)
                //             item[sobj[0][0]] = sellerWithMaxScore.comp_prd_sales_price ? sellerWithMaxScore.comp_prd_sales_price : sellerWithMaxScore.comp_prd_mrp;
                //         }       
                //         return items;
                //     }
                // )
                // buy box logic
                if (!isEmpty(item)) {
                    const buyboxItem = item.competitors?.find(obj => obj?.comp_prd_custom_field === "BUY BOX");
                    if (buyboxItem) {
                        item.buybox = buyboxItem.seller?.seller_name.replace(/[\p{Emoji_Presentation}\p{Emoji}]/gu, '').trim();
                        // item.comp_modified_date = moment(buyboxItem?.modified_date).format('MM DD YYYY h:mm:ss A')
                        item.comp_modified_date = moment(item.modified_date).format('MM DD YYYY h:mm:ss A')
                    }
                    //if (item.prd_remarks === 'NO BUY BOX')
                    else {
                        item.buybox = "N/A";
                        item.comp_modified_date = moment(item.modified_date).format('MM DD YYYY h:mm:ss A')
                    }
                    // else {
                    //     item.buybox = (item?.filtered_sales_price !== 0) ? item.activeLocation[0]?.seller?.seller_name?.replace(/[\p{Emoji_Presentation}\p{Emoji}]/gu, '').trim() : "N/A"
                    //     item.comp_modified_date = (item?.filtered_sales_price !== 0) ? moment(item?.modified_date).format('MM DD YYYY h:mm:ss A') : "N/A"
                    // }
                }



                return item;
            })
            returnData = returnData.filter(item => item !== undefined)
            // console.log(returnData);
            const marketplaces = [...new Set(returnData.map(item => item.competitors.map(competitor => competitor.market_place_name)).flat())];
            marketplaces.map((mrkt, index) => {
                if (mrkt === undefined || mrkt === null) {
                    marketplaces.splice(index, 1);
                }
                return mrkt;
            })
            // marketplaces.sort();
            setMarketPlaceLabel(marketplaces.sort());
            // setProductDetails(returnData);
            setOriginalData(returnData);
        }
    }, [data, locationFilterData]) // eslint-disable-line
    // console.log('originalData', originalData);

    useEffect(() => {
        if (!isEmpty(locationFilterData)) {
            const tempData = originalData.map((prd) => {
                return {
                    ...prd,
                    competitors: prd.competitors.filter(
                        (comp) => comp.loc?.loc_id === locationFilterData[0]
                    )
                };
            });
            tempData.forEach((prd) => {
                if (prd.filteredMarket) {
                    const tempMarket = [];
                    prd.competitors.forEach((comp) => {
                        if (!tempMarket.includes(comp.market_place_name)) {
                            tempMarket.push(comp.market_place_name);
                        }
                    })
                    prd.filteredMarket = tempMarket;
                    prd.filteredMarket.forEach((market) => {
                        let price = 0;
                        let normPrice = "";
                        let highestScore = 0; // Track the highest comp_prd_score
                        let highestPrice = 0; // Track the highest comp_prd_sales_price

                        prd.competitors.forEach((comp) => {
                            if (
                                comp.market_place_name === market &&
                                comp.comp_prd_score >= highestScore
                            ) {
                                if (comp.comp_prd_score > highestScore || comp.comp_prd_sales_price > highestPrice) {
                                    highestScore = comp.comp_prd_score;
                                    highestPrice = comp.comp_prd_sales_price;
                                    price = comp.comp_prd_sales_price;
                                    if (comp.comp_prd_norm_price?.includes("/"))
                                        normPrice = comp.comp_prd_norm_price;
                                    else
                                        normPrice = `${comp.comp_prd_norm_price} / ${comp.comp_prd_uom}`;
                                }
                            }
                        });

                        prd[`competitorMaxPrice${market}`] = price;
                        prd[`competitorMaxNormPrice${market}`] = normPrice;
                    });
                }
                if (prd.buybox && prd.buybox === "N/A") {
                    prd[`competitorMaxPrice${buyboxMarket}`] = 'N/A';
                    prd[`competitorMaxNormPrice${buyboxMarket}`] = 'N/A';
                }
                if (prd.buybox && prd.buybox !== "N/A") {
                    if (prd.filteredMarket) {
                        const market = prd.filteredMarket[0];
                        if (prd.competitors.length) {
                            let maxPrice = 0;
                            let maxNormPrice = 0;
                            prd.competitors.forEach((comp) => {
                                if (comp.comp_prd_custom_field === "BUY BOX" && comp.comp_prd_sales_price > maxPrice) {
                                    maxPrice = comp.comp_prd_sales_price;
                                    if (comp.comp_prd_norm_price.includes("/"))
                                        maxNormPrice = comp.comp_prd_norm_price;
                                    else
                                        maxNormPrice = `${comp.comp_prd_norm_price} / ${comp.comp_prd_uom}`;
                                }
                            })
                            if (maxPrice === 0) {
                                prd[`competitorMaxPrice${market}`] = prd.filtered_sales_price;
                                if (prd && typeof prd.filtered_norm_price === 'string' && prd.filtered_norm_price.includes("/"))
                                    prd[`competitorMaxNormPrice${market}`] = prd.filtered_norm_price;
                                else
                                    prd[`competitorMaxNormPrice${market}`] = `${prd.filtered_norm_price} / ${prd.prd_uom}`;
                            } else {
                                prd[`competitorMaxPrice${market}`] = maxPrice;
                                if (maxNormPrice.includes("/"))
                                    prd[`competitorMaxNormPrice${market}`] = maxNormPrice;
                                else
                                    prd[`competitorMaxNormPrice${market}`] = `${maxNormPrice} / ${prd.prd_uom}`;
                            }
                        } else {
                            prd[`competitorMaxPrice${market}`] = prd.filtered_sales_price;
                            prd[`competitorMaxNormPrice${market}`] = prd.filtered_norm_price;
                        }
                    } else {
                        prd[`competitorMaxPrice${buyboxMarket}`] = prd.filtered_sales_price;
                        prd[`competitorMaxNormPrice${buyboxMarket}`] = prd.filtered_norm_price;
                    }
                }
            });
            setProductDetails(tempData);
            setTimeout(() => {
                setIsFiltering(false);
            }, 2000)
        }
    }, [locationFilterData, originalData]); // eslint-disable-line


    const handleApproveAll = () => {
        dispatch(qaApproveAction.approveAll({ user_id: getItem('qaUser') }))
    }

    const handleGetProductList = (event) => {
        // console.log(event.target.value)
        // setItem('qaUser', event.target.value)
        setItem('qaUser', event.target.value)
        setSelectedUser(event.target.value)
        const pages = page === 0 ? 1 : page;
        const str = `page=${pages}&user_id=${event.target.value}&page_size=${rowsPerPage}&prd_brand__in=${brandFilterData.toString().toLowerCase()}&category_id__in=${categoringData.toString()}&dept_id__in=${filteringData.toString()}&prd_mp=${marketPlace ? marketPlace : ""}`
        getProductList(str)
    }

    return {
        userRole,
        userList,
        selectedUser,
        filterdProducts: productDetails,
        data,
        isLoading,
        isFailure,
        qaSuccess,
        qaFailure,
        errorMessage,
        isSearched,
        handleGetProductList,
        getProductList,
        setpageList,
        setProductList,
        marketPlaceLabel,
        selectLoc,
        handleApproveAll,
        searchkeyword
        // handleFilterProduct
    }
}

export default UseMyProduct;