import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, Typography, Collapse, Divider, IconButton, Button, InputBase, FormControl, InputLabel, Alert } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { styled } from '@mui/material/styles';

import selectAddMarketplaceDetails from '../../redux/selectors/addmarketplace.selector'
import { handleAddMarketplace, addMarketplaceReset } from '../../redux/actions/addmarketplace.actions'
import useActions from '../../redux/use-actions';
import CloseIcon from '@mui/icons-material/Close'

import { CustomThemeContext } from '../../theme/CustomThemeProvider';
import Locations from '../locations';
import CompModal from '../Modal'
import { isEmpty } from 'lodash';

const marketSchema = Yup.object().shape({
    name: Yup.string().min(6, 'Too short - should be 6 chars minimum!').required('Required'),
    url: Yup.string().min(8, 'Too short - should be 8 chars minimum!').required('Required')
  })



const AddMarketPlace = props =>{
  const {currentTheme } = useContext(CustomThemeContext)
    const dispatch = useDispatch()
    const {marketData} = props;
    const { addMarketplaceDetails } = useSelector(selectAddMarketplaceDetails);
// const [selectValue, setSelectValue] = useState('marketPlace');
    const { isSuccessful } =  addMarketplaceDetails;
    const actions = useActions({ handleAddMarketplace, addMarketplaceReset });
    const [errorBool, setErrorBool] = useState(false)

// console.log('addcompetitor', data, isSuccessful)
    useEffect(()=>{
        if(isSuccessful){
          setTimeout(() =>{
              setErrorBool(false)
              dispatch(actions.addMarketplaceReset())
              props.handleClose('load')
          }, 2000)
        }
    }, [isSuccessful, dispatch, props.handleClose, actions]) // eslint-disable-line

    const formStyle = {width:'100%'};

    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        'label + &': {
          marginTop: theme.spacing(2),
          width: '100%',
        },
        '& .MuiInputBase-input': {
          borderRadius: 4,
          position: 'relative',
          backgroundColor: 'transparent', //theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
          border: '1px solid #ced4da',
          fontSize: 11,
          width: '100%',
          padding: '10px 12px',
          color: theme.palette.text.primary,
          transition: theme.transitions.create([
            'border-color',
            'background-color',
          ]),
          // Use the system font instead of the default Roboto font.
          fontFamily:'Inter',
          '&:focus': {
            borderColor: theme.palette.primary.main,
          },
        },
      }));
    
    const checkName = (pName) => {
      if(isEmpty(marketData)){
        return [];
      }
        return marketData.filter(item => item.market_place_name === pName)
    }
    const handleSubmit = (values) =>{
      //  console.log(values);
       const name = checkName(values.market_place_name);
      //  console.log('name', name)
       if(isEmpty(name)){
        setErrorBool(false)
         actions.handleAddMarketplace(values);
       } else {
        setErrorBool(true)
       }
    }
    return (
        <CompModal {...props}>
                <Typography variant="h5" component={'h5'}>Add Market</Typography>
                <Divider />
                <Collapse in={errorBool}>
                
        <Alert severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setErrorBool(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 1 }}
        >
          This market is already taken.
        </Alert>
      </Collapse>
                <Grid item sx={{mt:2}}>
                  <Typography variant='h5' component={'h5'} >Please contact <a style={{color:currentTheme === 'light'? '#5A5A5A': '#FFF'}} rel="noreferrer" href={'https://www.orbiosolutions.com/contacts/'} target='_blank'> Orbio Solutions</a> for adding market.</Typography>
                </Grid>
                <Grid item sx={{display:'none'}}>
                <Formik
                   // enableReinitialize
                    initialValues={{ market_place_name: '', market_place_logo: '',  market_place_url: '', locations: [], 
                    }}
                    validationSchema={marketSchema}
                    >
                {({ errors, 
                    setFieldValue,
                    values 
                }) => (
                    <Form className='loginform' style={{width:'100%'}}>
                        {isSuccessful && <Typography variant="body2" component={'p'}>Market Place added successfully.</Typography> }
                        <Grid item xs={12} sx={{display:'flex',}}>
                            <Grid item xs={12} md={12} xl={12} sx={{mr:1}}>
                                <FormControl variant="standard" sx={{mt:2}} style={formStyle}>
                                <InputLabel shrink htmlFor='marketname' sx={{color:'text.primary'}}>
                                    Market Name
                                    </InputLabel>
                                <BootstrapInput 
                                    value={values.market_place_name} 
                                    placeholder='Enter market name'
                                    id='marketname'
                                    onChange={e => setFieldValue('market_place_name', e.target.value)}
                                />
                                <Typography color="red" variant="subtitle" sx={{width:'70%'}}>{errors.market_place_name}</Typography>
                            </FormControl>

                            <FormControl id={'urls'} variant="standard" sx={{mt:2}} style={formStyle}>
                                <InputLabel shrink htmlFor='url' sx={{color:'text.primary'}}>
                                    Market URL*
                                    </InputLabel>
                                <BootstrapInput 
                                    value={values.market_place_url} 
                                    placeholder='Enter Market URL '
                                    id='url'
                                    onChange={e => setFieldValue('market_place_url', e.target.value)}
                                />
                                <Typography color="red" variant="subtitle" sx={{width:'70%'}}>{errors.market_place_url}</Typography>
                            </FormControl>
                            <FormControl id={'brands'} variant="standard" sx={{mt:2}} style={formStyle}>
                                <InputLabel shrink htmlFor='markgetlogo' sx={{color:'text.primary'}}>
                                    Market Logo 
                                    </InputLabel>
                                <BootstrapInput 
                                    value={values.market_place_logo} 
                                    placeholder='Put Market Logo '
                                    id='markgetlogo'
                                    onChange={e => setFieldValue('market_place_logo', e.target.value)}
                                />
                            </FormControl>
                            <FormControl id={'locations'} variant="standard" sx={{mt:2}} style={formStyle}>
                                <InputLabel shrink htmlFor='size' sx={{position:'relative' ,color:'text.primary'}}>
                                    Location
                                    </InputLabel>
                                <Locations multiple={true} handleChange={e => setFieldValue('locations', e.target.value)}/>
                                <Typography color="red" variant="subtitle" sx={{width:'70%'}}>{errors.brand}</Typography>
                            </FormControl>
                            </Grid>
                        </Grid>
                    
                        <FormControl sx={{ m: 0, mt:3, mb:2, width:'100%', alignItems:'center' }}>
                            <Button sx={{width:'70%', backgroundColor:'#559EFF', borderColor:'#1C3B6E', color:'#000000'}} 
                            onClick={() => handleSubmit(values)}>Submit</Button>
                        </FormControl>
                    </Form>
                )}
                </Formik>

                </Grid>
        </CompModal>
    )
}

export default AddMarketPlace;