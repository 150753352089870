import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Grid, Typography, Button, Accordion, AccordionSummary, AccordionDetails} from '@mui/material';
import {styled} from '@mui/material/styles';
import AlertDialogSlide from '../../components/Dialog/'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChangePasswordPop from './changePasswordPop';
// import Currency from './Currency';
import DefaultLocation from './DefaultLocation';

import * as actions from '../../redux/actions/deleteallproduct.actions'
import deleteAllProductSelector from '../../redux/selectors/deleteallproduct.selector'

import DeleteCompetitorPop from './DeleteCompetitorPop';

const GeneralSettings = props => {

    const {headingStyle} = props;
    const dispatch = useDispatch()
    const [deletePop, setDeletePop] = useState({isVisible:false, deleteId:null})
    // const [deletePassword, setDeletePassword] = useState(false);
    const [generalView, setGeneralView] = useState(false);
    const [passwordView, setPasswordView] = useState(false)
    const {deleteAllProductDetails} = useSelector(deleteAllProductSelector)
    const {isSuccessful, isFailure, message} = deleteAllProductDetails;
    const [competitorPopOpen, setCompetitorPopOpen] = useState(false); 

    const StyledAccord = styled(Accordion)(({ theme }) =>{
        return {
          '.MuiAccordionSummary-root.Mui-expanded':{
            minHeight:'48px !important',
          },
          '.MuiAccordionSummary-content':{
            display:'flex',
           justifyContent:'left' 
          },
          '.MuiAccordionSummary-content.Mui-expanded':{
           margin:'12px 0',
          },
          '.MuiAccordionDetails-root':{
            padding: '8px 0px'
          }
        }
      })
    useEffect(() =>{
      if(isFailure){
        setTimeout(()=>{
          dispatch(actions.deleteAllProductReset());
        }, [2000])
      }
    }, [isFailure]) // eslint-disable-line
    
    useEffect(() =>{
      if(isSuccessful){
        setTimeout(()=>{
          dispatch(actions.deleteAllProductReset());
          window.location.reload()
        }, [1000])
        
      }
    }, [isSuccessful]) // eslint-disable-line

    const handleDeleteFailed = () =>{
        setDeletePop({isVisible:false, deleteId:null});
      }
      const handleDeleteSuccess = (values) =>{
        setDeletePop({isVisible:false, deleteId:null});
      //  console.log('delete ', values);
        dispatch(actions.handleDeleteAllProduct({password: values.password}));
        // setDeletePassword(true)
      }
      const openDeletePop = () => {
        setDeletePop({isVisible:true, deleteId:null});
      }
      const handleGeneralView = () => {
        setGeneralView(prevState => !prevState);
      }

      const openPasswordPop = () => {
        setPasswordView(true);
      }
      const handlePasswordSuccess = () =>{
        setPasswordView(false);
      }
      const handlePasswordFailed = () =>{
        setPasswordView(false)
      }
      // competitor delete 
      const handleCompetitorPopOpen = () => {
        setCompetitorPopOpen(true);
      };
    
      const handleCompetitorPopClose = () => {
        setCompetitorPopOpen(false);
      };


    return (
        <>
             {/* <DeleteCompetitorPop open={competitorPopOpen} onClose={handleCompetitorPopClose} /> */}
            {deletePop.isVisible && <AlertDialogSlide isAlert={false} handleSuccess={handleDeleteSuccess} handleFailed={handleDeleteFailed}/>}
            {passwordView && <ChangePasswordPop {...props} handleSuccess={handlePasswordSuccess} handleFailed={handlePasswordFailed}/>}
            {isSuccessful && <Typography variant='body' component={'p'}>All Products has been deleted successfully.</Typography>}
            {isFailure && message && <Typography variant='body' component={'p'}>There is some error. Please try again later</Typography>}
            <Typography variant='h4' component={'h4'} className='typo headingText' sx={headingStyle}>General Settings</Typography>
                {/* <Currency /> */}
                <DefaultLocation />
                <StyledAccord 
                    expanded={generalView}
                    onChange={handleGeneralView}
                    disableGutters sx={{background:'transparent', boxShadow:'none', px:2, width:'100%'}}>
                    <AccordionSummary sx={{margin:0, paddingLeft:0, paddingRight:0}}
                        expandIcon={<ExpandMoreIcon sx={{color:'text.primary'}} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant='h4' component={'h4'} className='typo headingText' sx={{mt:2, textAlign:'left !important'}}>Advance Settings</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid item sx={{display:'flex', flexFlow:'column'}}>
                            <Grid item xs={12} sm={4}>
                                <Button onClick={openPasswordPop}>Change Password</Button>
                                <Button onClick={openDeletePop}>Delete all products</Button>
                                {/* <Button onClick={handleCompetitorPopOpen}>Delete MarketPlace</Button>  */}
                            </Grid>
                        </Grid>

                    </AccordionDetails>
                </StyledAccord>
                
        </>
    )

}

export default GeneralSettings;