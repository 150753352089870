import { PRD_UPDATE_MATCH,PRD_UPDATE_MATCH_FAILED,PRD_UPDATE_MATCH_SUCCESSFULL,PRD_UPDATE_MATCH_RESET } from "../constants/updateEnablematch.constants";


export const initialState = { 
    isLoading: false,
    isSuccessful: false,
    isFailure: false,
    data: null,
    message: null, 
};

export const PrdUpdateMatchReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    
    case PRD_UPDATE_MATCH:
      return {
        ...state,
          isLoading: true,
          isSuccessful: false,
          isFailure: false,
          data: null,
          message: null
      };
    case PRD_UPDATE_MATCH_SUCCESSFULL:
      return {
        ...state,
          isLoading: false,
          isSuccessful: true,
          isFailure: false,
          message: null,
          data: action.payload,
          
      };
    case PRD_UPDATE_MATCH_FAILED:
      return {
        ...state,
          isLoading: false,
          isSuccessful: false,
          isFailure: true,
          message: payload,
          data: null
      };
      case PRD_UPDATE_MATCH_RESET:
        return {
          ...state,
            isLoading: false,
            isSuccessful: false,
            isFailure: false,
            message: null,
            data: null
        };
    default:
      return state;
  }
};
