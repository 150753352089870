import React, { useState } from 'react';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Button, FormControlLabel, Switch, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { updateClientRequest } from '../../redux/actions/clientUpdate.actions';
import selectClientsUpdate from '../../redux/selectors/clientUpdate.selectors';

function ClientFeatureAccordion({ clientData, themeName }) {
//  console.log("clientData",clientData.db_connection_string)
  const dispatch = useDispatch();
  const { updatedClient, isError } = useSelector(selectClientsUpdate);
  const [isUpdating, setIsUpdating] = useState(false);
  // const [isUpdateError, setIsUpdateError] = useState(false);
  // const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);

  // useEffect(() => {
  //   if (updatedClient) {
  //     if (updatedClient.status === 'Success') {
  //       setIsUpdateSuccess(true);
  //       setTimeout(() => {
  //         setIsUpdateSuccess(false);
  //         window.location.reload();
  //       }, 2000);
  //     }
  //   }
  // }, [updatedClient]);

  // useEffect(() => {
  //   if (isError) {
  //     setIsUpdateError(true);
  //     setTimeout(() => {
  //       setIsUpdateError(false);
  //       window.location.reload();
  //     }, 2000);
  //   }
  // }, [isError]);

  const formik = useFormik({
    initialValues: {
      sales_analysis: Boolean(clientData.sales_analysis),
      competitor_analysis: Boolean(clientData.competitor_analysis),
      company_name: clientData?.company_name,
      email: clientData?.email,
      first_name: clientData?.first_name,
      last_name: clientData?.last_name,
      phone_number: clientData?.phone_number,
      Industry: clientData?.Industry,
      created_date: clientData?.created_date,
      no_of_mp_allowed: clientData?.no_of_mp_allowed,
      no_of_produts_allowed: clientData?.no_of_produts_allowed,
      db_name: clientData?.db_name,
      status: clientData?.status,
      address: clientData?.address,
      time_zone: clientData?.currency[0]?.time_zone,
      role: clientData?.role,
      last_login: clientData?.last_login,
      currency_symbol: clientData?.currency[0]?.currency_symbol,
      currency: clientData?.currency[0]?.currency,
      country: clientData?.country,
      remarks: clientData?.remarks,
      user_id: Number(clientData?.id),
      db_connection_string:clientData?.db_connection_string
    },
    onSubmit: (values) => {
      setIsUpdating(true);
      const payload = {
        competitor_analysis: Boolean(values.competitor_analysis),
        sales_analysis: Boolean(values.sales_analysis),
        company_name: values?.company_name || "" ,
        email: values?.email || "" ,
        first_name: values?.first_name || "",
        last_name: values?.last_name || "",
        phone_number: values?.phone_number || null ,
        Industry: values?.Industry || "" ,
        created_date: values?.created_date || "" ,
        no_of_mp_allowed: values?.no_of_mp_allowed || 0,
        no_of_produts_allowed: values?.no_of_produts_allowed || 0 ,
        db_name: values?.db_name || "" ,
        status: values?.status,
        address: values?.address || "" ,
        time_zone: values?.time_zone || "" ,
        role: values?.role,
        last_login: values?.last_login || "" ,
        currency_symbol: values?.currency_symbol || "" ,
        currency: values?.currency || "" ,
        country: values?.country || "" ,
        remarks: values?.remarks || "" ,
        user_id: Number(values?.id),
        db_connection_string:values.db_connection_string
      };
      dispatch(updateClientRequest(clientData.id, payload));
    },
  });

  const handleUpdate = () => {
    formik.submitForm();
  };

  return (
    <Accordion style={{ borderColor: themeName === 'dark' ? 'white' : 'black', borderWidth: '1px', borderStyle: 'solid', boxShadow: 'none' }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="features-content"
        id="features-header"
      >
        <Typography>Features</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
        
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography sx={{ fontSize: '0.75rem !important' }} >Competitors Analysis : </Typography>
            <FormControlLabel
              control={
                <Switch
                  size='small'
                  checked={formik.values.competitor_analysis}
                  onChange={formik.handleChange('competitor_analysis')}
                  name="competitor_analysis"
                  style={{ color: formik.values.competitor_analysis ? 'green' : 'red' }}
                />
              }
              labelPlacement="start"
              label={
                <Typography sx={{ fontSize: '0.75rem !important' }}>
                  {formik.values.competitor_analysis ? 'Allowed' : 'Not Allowed'}
                </Typography>
              }
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography sx={{ fontSize: "0.75rem !important" }}>Sales Analysis: </Typography>
            <FormControlLabel
              control={
                <Switch
                  size='small'
                  checked={formik.values.sales_analysis}
                  onChange={formik.handleChange('sales_analysis')}
                  name="sales_analysis"
                  style={{ color: formik.values.sales_analysis ? 'green' : 'red' }}
                  
                />
              }
              labelPlacement="start"
              label={
                <Typography sx={{ fontSize: '0.75rem !important' }}>
                  {formik.values.sales_analysis ? 'Allowed' : 'Not Allowed'}
                </Typography>
              }
            />
          </div>
        </div>
      </AccordionDetails>
      { !isError && isUpdating && !updatedClient && (
        <AccordionDetails>
          <Typography textAlign="center">Updating please wait...</Typography>
        </AccordionDetails>
      )}
      { isError && isUpdating && !updatedClient && (
        <AccordionDetails>
          <Typography textAlign="center">Error occurred while updating </Typography>
        </AccordionDetails>
      )}
      { !isError && isUpdating && updatedClient && (
        <AccordionDetails>
          <Typography textAlign="center">Client details updated successfully </Typography>
        </AccordionDetails>
      )}
      
        <AccordionDetails>
          <Button variant="contained" color="primary" onClick={handleUpdate}>
            Update
          </Button>
        </AccordionDetails>
   
    </Accordion>
  );
}

export default ClientFeatureAccordion;