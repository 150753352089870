import { GET_CLIENTS_MARKETCOUNT,GET_CLIENTS_MARKETCOUNT_FAIL,GET_CLIENTS_MARKETCOUNT_SUCCESS } from "../constants/clientMarketCount.constants";

export const getClientsMarketCount = () => ({
  type: GET_CLIENTS_MARKETCOUNT,
});

export const getClientsMarketCountSuccess = (count) => ({
  type: GET_CLIENTS_MARKETCOUNT_SUCCESS,
  payload: count,
});

export const getClientsMarketCountFail = (error) => ({
  type: GET_CLIENTS_MARKETCOUNT_FAIL,
  payload: error,
});
