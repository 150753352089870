import { useCallback, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import selectMarketPlaceDetails from '../../redux/selectors/marketplace.selector'
// import selectMarketLocationDetails from '../../redux/selectors/marketlocation.selector';
// import { getMarketLocation } from '../../redux/actions/marketlocation.actions';

import selectAddMarketruleDetails from '../../redux/selectors/addmarketrules.selector'
import {getMarketPlace} from '../../redux/actions/marketplace.actions'
import { handleAddMarketrule, addMarketruleReset } from '../../redux/actions/addmarketrules.actions';

import { getItem } from '../../utils/storage';
import useActions from '../../redux/use-actions';
import { isNull, isUndefined, isEmpty, groupBy, sortedUniq } from 'lodash';

const UseAddRules = () =>{
    const [selectedMarket, setSelectedMarket] = useState([])
     const [ countryList, setCountryList ] = useState([])
     const [ stateList, setStateList ] = useState([])
     const [ selectedLocation, setSelectedLocation ] = useState([])
     const [ marketValue, setMarketValue ] = useState('marketplace')
     const [timeZone, setTimeZone] = useState(null)
     const [ singleSelectedMarket, setSingleSelectedMarket ] = useState(null)
    //  const [ storedMarketlocalData, setMarketLocalData ] = useState(null)
     const [mktlocdata, setMktlocdata] = useState([])
     const [ deleteMarketView, setDeleteMarketView ] = useState({
        isBool: false,
        market_place_id:null,
        market_index:null
     });
     const [isError, setIsError] = useState({
        isBool: false,
        msg:''
     })
    const dispatch = useDispatch();
    const actions = useActions({ getMarketPlace, handleAddMarketrule, addMarketruleReset });

    // const {marketLocationDetails } = useSelector( selectMarketLocationDetails );
    // const {mktlocdata} = marketLocationDetails;

    const { addMarketruleDetails } = useSelector( selectAddMarketruleDetails)
    const {isSuccessful, isFailure} = addMarketruleDetails;

    const { marketPlaceDetails } = useSelector(selectMarketPlaceDetails);
    const {data} = marketPlaceDetails;

    useEffect(()=>{
        if(!data){
            actions.getMarketPlace();
        }
    }, [data]) // eslint-disable-line

    
    useEffect(() =>{
        if(isSuccessful || isFailure){
            setTimeout(()=>{
                dispatch(actions.addMarketruleReset())
            }, 2000)
        }
    }, [isSuccessful, isFailure, actions, dispatch])

    // console.log('market data', data);

    // useEffect(() =>{
    //     let marketLocalData = localStorage.getItem('marketData');
    //     if(marketLocalData !== null && data !== null){
    //         marketLocalData = JSON.parse(marketLocalData);
    //         setMarketLocalData(marketLocalData)
    //     }
    // }, [data])
    useEffect(() =>{
        const userCurrency = !isNull(getItem('currency')) && !isUndefined(getItem('currency')) ? JSON.parse(getItem('currency')) : [{'currency_symbol':'$'}];
        // console.log('userCurrency' ,userCurrency)
        setTimeZone(userCurrency[0].time_zone);
    }, [])
    const checkCountry = (countryArr, pCountry) =>{
        return countryArr.find(pItem => pItem.country_name === pCountry.country_name)
    }
    const checkState = (stateArr, pState) =>{
        return stateArr.find(pItem => pItem.state_name === pState.state_name)
    }
    const convertTimeToZone = (cTime) =>{
        const userCurrency = !isNull(getItem('currency')) && !isUndefined(getItem('currency')) ? JSON.parse(getItem('currency')) : [{'currency_symbol':'$'}];
        const timeZ = userCurrency[0].time_zone;
        let userTimezone = moment.tz(new Date(), timeZ).format('Z');
        userTimezone = moment().utcOffset(userTimezone)._offset
        // console.log('userTimezone ', userTimezone, moment().utcOffset(userTimezone))
        const startTime = moment(cTime, 'HH:mm:ss');
        const endTime = startTime.add(userTimezone, 'minutes');
        // console.log(timeZone, timeZ, cTime, userTimezone, startTime);
        return endTime;
        // console.log(endTime.format('HH:mm:ss A'));
        // console.log(endTime, userTimezone, 'convertedTime')
    }
    const convertStoredWeekday = (pDays) => {
        const dayArr = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
        let tmpDays = pDays.substring(1, pDays.length)
        tmpDays = tmpDays.split('');
        const resultDays = []
        tmpDays.map( (day, index) => {
            if(Number(day) === 1 ){
                resultDays.push(dayArr[index])
            }
            return resultDays
        })
        // console.log('resultDays', resultDays)
        return resultDays;
    }
    const handleMarketLocation = useCallback((pValue, selectedMarket) =>{
        const mktData = data.filter(item => item.market_place_id === pValue)
        const mkLocData = mktData[0].locations;
        setMktlocdata(mkLocData)
        if(!isEmpty(mkLocData)){
            const filterdmktloc = Object.values(groupBy(mkLocData, 'loc.loc_zip'))[0][0];
            const marketobj = [filterdmktloc]
            setCountryList([])
            setStateList([])
            setSelectedLocation([])
            const tempCountryList = []
            const tempStateList = []
            marketobj.map(item =>{
                const searchCountry = checkCountry(tempCountryList, item.loc?.state?.country)
                if(searchCountry === undefined){
                    tempCountryList.push(item.loc?.state?.country)
                }
                const searchState = checkState(tempStateList, item.loc?.state)
                if(searchState === undefined){
                    tempStateList.push(item.loc?.state)
                }
                return searchCountry;
            })
            setCountryList(tempCountryList)
            setStateList(tempStateList);
            const marketData = [...selectedMarket];
            // Checking whether the market is already inserted for use..
            const resultData = marketData.filter(mrkt => {
                return mrkt.market_place_id === marketValue;
            })
            //  console.log('resultData ', resultData)
            if(isEmpty(resultData)){
                let obj = null;
                const resultObj = [...mktData];
                if(!isEmpty(resultObj[0].settings)){
                    const tempLocation = resultObj[0].locations.filter(item => {
                      return  Number(item.loc.loc_id) === Number(resultObj[0].settings[0].loc)
                    })
                    
                    obj = Object.assign(resultObj[0], 
                        {country:tempCountryList[0].country_name, state:tempStateList[0].state_name, 
                            location_d:tempLocation[0].loc.loc_name, 
                            // crawl_time: convertTimeToZone(resultObj[0].settings[0].crawl_time),
                            crawl_time:resultObj[0].settings[0].crawl_time,
                            cuttoff_score: resultObj[0].settings[0].cuttoff_score,
                            weekday:convertStoredWeekday(resultObj[0].settings[0].weekday)})
                            
                } else {
                    obj = Object.assign(resultObj[0], 
                        {country:null, state:null, location_d:null, crawl_time:null, weekday:null, cuttoff_score:null})
                }
                marketData.push(obj)
                setSelectedMarket(marketData)
                setSingleSelectedMarket(obj)
                return true;
            }
        }
    }, [data]) // eslint-disable-line

    useEffect(() =>{
        if(data !== null){
            let dataMarket = [];
            setIsError({isBool:false, msg:''})
            data.map((item) => {
                const mkLocData = item.locations;
                if(!isEmpty(mkLocData)){
                    const filterdmktloc = Object.values(groupBy(mkLocData, 'loc.loc_zip'))[0][0];
                    const marketobj = [filterdmktloc]
                    const tempCountryList = []
                    const tempStateList = []
                    marketobj.map(item =>{
                        const searchCountry = checkCountry(tempCountryList, item.loc?.state?.country)
                        if(searchCountry === undefined){
                            tempCountryList.push(item.loc?.state?.country)
                        }
                        const searchState = checkState(tempStateList, item.loc?.state)
                        if(searchState === undefined){
                            tempStateList.push(item.loc?.state)
                        }
                        return searchCountry;
                    })
                    let obj = null;
                    const resultObj = [item];
                    // console.log(resultObj[0].settings)
                    if(!isEmpty(resultObj[0].settings)){
                        const tempLocation = resultObj[0].locations.filter(item => {
                        return  Number(item.loc.loc_id) === Number(resultObj[0].settings[0].loc)
                        })
                        // console.log('sett', resultObj[0].settings)
                        // convertTimeToZone(resultObj[0].settings[0].crawl_time)
                        obj = Object.assign(resultObj[0], 
                            {   settings_id: !isEmpty(resultObj[0].settings) ? resultObj[0].settings[0].settings_id : null, 
                                country:tempCountryList[0].country_name, state:tempStateList[0].state_name, 
                                location_d: !isEmpty(tempLocation) ? tempLocation[0].loc?.loc_name : null, 
                                crawl_time: convertTimeToZone(resultObj[0].settings[0].crawl_time),
                                // crawl_time:resultObj[0].settings[0].crawl_time, 
                                cuttoff_score: resultObj[0].settings[0].cuttoff_score,
                                weekday:convertStoredWeekday(resultObj[0].settings[0].weekday)})
                    } else {
                        obj = Object.assign(resultObj[0], 
                            {settings_id:null, country:null, state:null, location_d:null, crawl_time:null, weekday:null, cuttoff_score:null})
                    }
                    // console.log('obj ', obj)
                    dataMarket.push(obj);
                }
                setSelectedMarket(dataMarket);
                return dataMarket;
            })
        }
    }, [data]) // eslint-disable-line

    const handleDeleteMarketSuccess = () =>{
        // console.log('handleDeleteMarketSuccess')
        const tempDelMarketView = {...deleteMarketView}
        const marketData = [...selectedMarket];
        marketData.splice(tempDelMarketView.market_index, 1);
        // console.log(tempDelMarketView.marketIndex, 'ddsd', marketData);
        setSelectedMarket(marketData)
        tempDelMarketView.isBool = false;
        tempDelMarketView.market_place_id = null;
        tempDelMarketView.market_index = null;
        setDeleteMarketView(tempDelMarketView)
    }
    const handleDeleteMarketFailed = () =>{
        const tempDelMarketView = {...deleteMarketView}
        tempDelMarketView.isBool = false;
        tempDelMarketView.market_place_id = null;
        tempDelMarketView.market_index = null;
        setDeleteMarketView(tempDelMarketView)
    }
    const handleRemoveMarket = (pItem) => {

        const marketData = [...selectedMarket];
        const resultIndex = marketData.findIndex(mrkt => {
            return mrkt.market_place_id === pItem.market_place_id;
        })
        // console.log('resultIndex ',resultIndex);
        if(resultIndex !== -1){
            const tempDelMarketView = {...deleteMarketView}
            tempDelMarketView.market_index = resultIndex
            tempDelMarketView.market_place_id = pItem.market_place_id;
            tempDelMarketView.settings_id = pItem.settings_id;
            tempDelMarketView.isBool = true
            setDeleteMarketView(tempDelMarketView);
            // marketData.splice(resultIndex, 1);
            // console.log(marketData);
            // setSelectedMarket(marketData)
        }
    }
    const getCountryName = (pId) => {
        return countryList.filter(item => item.country_id === pId)[0].country_name;
    }
    const updateMarket = (pItem) =>{
        const obj = {...singleSelectedMarket};
        //  console.log('pItem ', pItem, obj)
        if(pItem.type === 'country'){
            obj[pItem.type] = getCountryName(pItem.value);
        } else if(pItem.type !== 'country'){
            obj[pItem.type] = pItem.value;
        }
        // console.log(obj);
        const tempMarket = [...selectedMarket]
        const tempIndex = tempMarket.findIndex(item => Number(item.market_place_id) === Number(obj.market_place_id))
        if(tempIndex !== -1){
            tempMarket[tempIndex] = obj;
        }
        // console.log('getting updated', tempMarket)
        setSelectedMarket(tempMarket);
        setSingleSelectedMarket(obj)
        // console.log(obj);
        localStorage.setItem('marketData', JSON.stringify(obj));
    }
    const changeDayRule = (pDays) =>{
        // let daynum = 0;
        const days = ['mon', 'tue','wed', 'thu', 'fri', 'sat', 'sun']
        const filteredDay = days.map(item => pDays.includes(item) === true ? 1:0).join('').toString()
        return `0${filteredDay}`;
    }
    const setEditMarket = (value, resultData) => {
        const resultMarket = data.filter(item => Number(item.market_place_id) === Number(value))
        setCountryList([])
        setStateList([])
        setSelectedLocation([])
        setMktlocdata(resultMarket[0].locations)
        const tempCountryList = []
        const tempStateList = []
        // console.log(resultMarket, 'checking ', resultMarket[0].locations)
        if(!isEmpty(resultMarket[0].locations)){
            const filterdmktloc = Object.values(groupBy(resultMarket[0].locations, 'loc.loc_zip'))[0][0];
            [filterdmktloc].map(item =>{
                const searchCountry = checkCountry(tempCountryList, item.loc?.state?.country)
                if(searchCountry === undefined){
                    tempCountryList.push(item.loc?.state?.country)
                }
                const searchState = checkState(tempStateList, item.loc?.state)
                if(searchState === undefined){
                    tempStateList.push(item.loc?.state)
                }
                return searchCountry;
            })
        }
        // console.log('yyyy ', tempCountryList, tempStateList)
        setCountryList(tempCountryList)
        setStateList(tempStateList);
        setSelectedLocation([resultData[0].location_d])
        setSingleSelectedMarket(resultData[0])
    }
    const handleEditMarket = (item) => {
        // console.log(item);
        setSingleSelectedMarket(null)
        setMarketValue(item.market_place_id);
        setSelectedLocation([])
        const resultData = [...selectedMarket].filter(mrkt => {
            return mrkt.market_place_id === item.market_place_id;
        })
        setEditMarket(item.market_place_id, resultData);
        
    }
    const handleRule = () =>{
        // console.log('handleRule ', marketValue, selectedMarket);
        const item = selectedMarket.filter(items => Number(items.market_place_id) === Number(marketValue))
        // console.log(item);
        setIsError({isBool:false, msg:''})
        const {settings_id, weekday, crawl_time, cuttoff_score, market_place_id, location_d} = item[0];
        // console.log('crawl_time', crawl_date, moment(crawl_date).utc().format('HH:mm:ss'));
        // console.log('crawl_time ', typeof(crawl_time), location_d, market_place_id, selectedLocation)
        if(weekday === null || crawl_time === null || String(crawl_time).toLowerCase() === 'invalid date' || market_place_id === null || location_d === null ){
        //    console.log('came here ',)
            let errorStr = "";
            if(weekday === null){
                errorStr = "Week day has not been selected.";
            } else if(market_place_id === null){
                errorStr = "Market place has not been selected.";
            } else if(location_d === null){
                errorStr = "Location has not been selected.";
            } else if(crawl_time === null || String(crawl_time).toLowerCase() === 'invalid date'){
                errorStr = "Time has not been selected.";
            }
            setIsError({isBool:true, msg:`${errorStr}. Please check.`})
            setTimeout(() => {
                setIsError({isBool:false, msg:''})
            }, 2000)
            return null;
        }
        const filterdDay = changeDayRule(weekday);
        let loc_id = mktlocdata.filter(item => location_d.includes(item.loc?.loc_name)).map(lc => lc.loc?.loc_id)
        // console.log(location_d, ' loc_id', loc_id);
        loc_id = sortedUniq(loc_id).toString()
        // console.log('loc_id --- ', loc_id);
        if(loc_id === null) {
            return null;
        }
        
        let crawl_date = moment().format('YYYY-MM-DD');
        crawl_date = crawl_date +" "+ crawl_time ;
        // console.log(crawl_date, timeZone, typeof(crawl_time));
        if(typeof(crawl_time) !== 'object'){
            // crawl_date = moment(crawl_date).utc().format('HH:mm:ss');
            crawl_date = moment.tz(crawl_date, timeZone).utc().format('HH:mm:ss');
        } else {
            crawl_date = crawl_time._i;
        }
        // console.log('crawl_date ', crawl_date);
        // if(typeof(crawl_time) !== 'object' && moment(crawl_date).utc().format('HH:mm:ss').toLowerCase() === 'invalid date'){
        //     setIsError({isBool:true, msg:'There is some issue with crawl time. Please check.'})
        //     setTimeout(() => {
        //         setIsError({isBool:false, msg:''})
        //     }, 2000)
        //     return null;
        // }

        // console.log('handleRule ', {settings_id, 'weekday':filterdDay, cuttoff_score, crawl_time: moment(crawl_date).utc().format('HH:mm:ss') , market_place_id, loc_id});
        actions.handleAddMarketrule({settings_id, 'weekday':filterdDay, cuttoff_score, crawl_time: crawl_date , market_place_id, loc_id:loc_id})
    }
    const onMarketPlaceHandler = (value) => {
        // console.log(event.target.value)
        // const {target: { value } } = event;
        setMarketValue(value);
        if(value === 'marketplace')return;
        // Getting item from marketplaces
        const marketData = [...selectedMarket];
        // Checking whether the market is already inserted for use..
        const resultData = marketData.filter(mrkt => {
            return mrkt.market_place_id === value;
        })
        // console.log('loading resultData ', resultData)
        if(isEmpty(resultData)){
            setCountryList([])
            setStateList([])
            setSelectedLocation([])
            // console.log('mktlocdata ', mktlocdata)
            setSingleSelectedMarket(null)
            handleMarketLocation(value, selectedMarket)
            //actions.getMarketLocation(value);
        } else {
            setEditMarket(value, resultData);
            // console.log('uuuusjdj')
            // const resultMarket = data.filter(item => Number(item.market_place_id) === Number(value))
            // setCountryList([])
            // setStateList([])
            // setSelectedLocation([])
            // setMktlocdata(resultMarket[0].locations)
            // const tempCountryList = []
            // const tempStateList = []
            // // console.log(resultMarket, 'checking ', resultMarket[0].locations)
            // if(!isEmpty(resultMarket[0].locations)){
            //     const filterdmktloc = Object.values(groupBy(resultMarket[0].locations, 'loc.loc_zip'))[0][0];
            //     [filterdmktloc].map(item =>{
            //         const searchCountry = checkCountry(tempCountryList, item.loc?.state?.country)
            //         if(searchCountry === undefined){
            //             tempCountryList.push(item.loc?.state?.country)
            //         }
            //         const searchState = checkState(tempStateList, item.loc?.state)
            //         if(searchState === undefined){
            //             tempStateList.push(item.loc?.state)
            //         }
            //         return searchCountry;
            //     })
            // }
            // // console.log('yyyy ', tempCountryList, tempStateList)
            // setCountryList(tempCountryList)
            // setStateList(tempStateList);
            // setSelectedLocation([resultData[0].location_d])
            // setSingleSelectedMarket(resultData[0])
        }
    }

return {
    marketStoredData: data,
    // storedMarketlocalData,
    marketValue,
    mktlocdata,
    countryList,
    stateList,
    selectedMarket,
    singleSelectedMarket,
    selectedLocation,
    setSelectedLocation,
    onMarketPlaceHandler,
    handleRemoveMarket,
    updateMarket,
    handleRule,
    handleDeleteMarketSuccess,
    handleDeleteMarketFailed,
    handleEditMarket,
    deleteMarketView,
    isSuccessful,
    isFailure,
    isError
}

}

export default UseAddRules;