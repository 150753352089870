import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
// import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
// import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import { useState } from 'react';
import { Box } from '@mui/material';

import { useDispatch,useSelector } from 'react-redux';
import { editPrdmatchRate } from '../../redux/actions/updateEnablematch.action';
import selectPrdUpdateMatchReducerDetails from '../../redux/selectors/updateEnablematch.selector';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
       width:"320px",
      //  padding:"7px",
       margin:"29px"
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function MatchEnabledDialog({matchOpen,setMatchOpen,productData}) {
//   const [matchOpen, setMatchOpen] = React.useState(true);

const dispatch = useDispatch()
// const prdUpdate = useSelector(selectPrdUpdateMatchReducerDetails)
const {isSuccessful, isFailure} = useSelector(selectPrdUpdateMatchReducerDetails)
// console.log(prdUpdate)

  // const handleClickOpen = () => {
  //   setMatchOpen(true);
  // };
  const handleClose = () => {
    setMatchOpen(false);
  };
  useEffect(() => {
    if(isSuccessful){
        setTimeout(() => {
            window.location.reload()
        }, 1000);
    }
  }, [isSuccessful])

  useEffect(() => {
    if(isFailure){
        setTimeout(() => {
            window.location.reload()
        }, 1000);
    }
  }, [isFailure])
  

const [prdMatchEnabled, setPrdMatchEnabled] = useState({});
const [prdArray, setPrdArray] = useState([]); 
// const [selectedValues, setSelectedValues] = useState([]);

useEffect(() => {
    // Update prdMatchEnabled when productData changes
    setPrdMatchEnabled(JSON.parse(productData?.prd_is_match_enabled));
  }, [productData]);

useEffect(() => {
    // This code will run when prdMatchEnabled is updated
    let newArray = [];

    for (let key in prdMatchEnabled) {
        newArray.push({ key: key, value: prdMatchEnabled[key] });
    }

    setPrdArray(newArray); // Update prdArray state
    // console.log(newArray);
}, [prdMatchEnabled]);


// console.log("productData",productData)
// {walgreens: true, cvs: true, dollar general: true}

const handleCheckboxChange = (key) => {
    // Update the prdMatchEnabled state when a checkbox is changed
    const updatedPrdMatchEnabled = { ...prdMatchEnabled };
    updatedPrdMatchEnabled[key] = !updatedPrdMatchEnabled[key];
    setPrdMatchEnabled(updatedPrdMatchEnabled);
  };

  const handleButtonClick = () => {
    if (productData) { // Ensure productData exists
      const selected = prdArray;
  
      if (selected) {
        const updatedArray = selected.reduce((acc, current) => {
          acc[current.key] = current.value;
          return acc;
        }, {});
  
        productData.prd_is_match_enabled = JSON.stringify(updatedArray);
        dispatch(editPrdmatchRate(productData))
      }
    }
  };
  

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={matchOpen}
      >
        <DialogTitle sx={{ m: 0, p: 2,textAlign:"center" }} id="customized-dialog-title">
        Enable Match Rate
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
        sx={{
            padding:"15px"
        }}
        >
        {prdArray?.map((item) => (
        <div key={item.key}>
          <label>
            {item.key}: {item.value}
            <input
            style={{ float: 'right' }}
              type="checkbox"
              checked={prdMatchEnabled[item.key]}
              onChange={() => handleCheckboxChange(item.key)}
            />
          </label>
        </div>
      ))}
      <Box textAlign="center" mt={2} >
      <Button style={{ backgroundColor: 'rgb(65,133,244)', color: 'white' }} variant="contained"  onClick={handleButtonClick}>Update</Button>
      </Box>
        </DialogContent>
        <div style={{dispaly:"flex",justifyContent:"center",alignItems:"center",textAlign:"center" }} >
        { isSuccessful ? <span>Match rate updated succesfully</span> : null }
        {isFailure ? <span>Update failed</span> : null}
        </div>
      </BootstrapDialog>
    </div>
  );
}
