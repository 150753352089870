import * as constants from '../constants/getRefreshBtnStatus.constants';

export const initialState = {

    refreshStatus: {
        isLoading: false,
        isSuccessful: false,
        isFailure: false,
        data: null,
        message: null,
    }

};

export const getRefreshBtnStatusReducer = (state = initialState, action) => {
    const { payload } = action;

    switch (action.type) {

        case constants.GET_REFRESH_BTN:
            return {
                ...state,
                refreshStatus: {
                    isLoading: true,
                    isSuccessful: false,
                    isFailure: false,
                    data: null,
                    message: null,
                }
            };
        case constants.GET_REFRESH_BTN_SUCCESSFULL:
            return {
                ...state,
                refreshStatus: {
                    isLoading: false,
                    isSuccessful: true,
                    isFailure: false,
                    data: action.response,
                    message: null,
                }
            };
        case constants.GET_REFRESH_BTN_FAILED:
            return {
                ...state,
                refreshStatus: {
                    isLoading: false,
                    isSuccessful: false,
                    isFailure: true,
                    message: payload,
                    data: null
                }
            };
        default:
            return state;
    }
};