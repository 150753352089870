import React, { useState, useCallback, useContext, useEffect } from 'react';
import { Grid, Typography, Paper, Button, IconButton, TextField, Select, MenuItem, InputBase } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {styled} from '@mui/material/styles';
import moment from 'moment';
import EastIcon from '@mui/icons-material/East';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import LineChart from './LineChart2';
import * as variationactions from '../../redux/actions/productPriceVariation.actions';
import selectProductPriceVariationDetails from '../../redux/selectors/productPriceVariation.selector'
import { isEmpty } from 'lodash';
import { CustomThemeContext } from '../../theme/CustomThemeProvider';
import Loader from '../loader';

const PriceVariationChart = props => {
    const {id, competitorInformation, marketName, activeLoc} = props;
    const currentTheme = useContext(CustomThemeContext);
    const [chartIndexValue, setChartIndexValue] = useState({
        dataIndex:0,
        dataLength:0,
        chartData:[]
    });
    const [selectedOption, setSelectedOption ] = useState(7);
    const [showError, setShowError] = useState({bool:false, msg:''});
    const [showChartOption, setShowChartOption] = useState(false);
    const DARK_TEXT_COLOR = '#E4E3E3';
    const LIGHT_TEXT_COLOR = '#272727'; 
    const [customDates, setCustomDates] = useState({
        from_date: '',
        to_date: '',
    })
    const { productPriceVariationDetails } = useSelector(selectProductPriceVariationDetails);
    const { data, isLoading, isFailure } = productPriceVariationDetails;
    const colorArr = ['#355FBE', '#4F8EEC', '#A8CBFF'];
    const [filteredData, setFilteredData] = useState([]);
    const [marketId, setMarketId] = useState(null);
    // const userCurrency = getItem('currency');
    useEffect(()=> {
        let tempData = data?.map(timeData => {
            const dynamicKey = Object.keys(timeData)[0];
            const secondArray = timeData[dynamicKey][1];
            const filteredSecondArray = secondArray.filter(item => item.location_id === activeLoc);
            return { [dynamicKey]: [timeData[dynamicKey][0], filteredSecondArray] };
        })
        setFilteredData(tempData);
    },[data, activeLoc])

    useEffect(()=>{
        if(!isEmpty(filteredData)){
            let dataLength = 0;
            let dataIndex = 0;
            let chartData = [];
            let resultData = filteredData.map(item => Object.values(item).map(items => items.flat()));
            resultData = !isEmpty(resultData) && resultData.map((item) => item[0]);
            
            resultData.map((item, index) => {
                if(item.length>dataLength) {dataLength = item.length; dataIndex = index; chartData=item}  
                return item
            })
            setChartIndexValue({dataIndex, dataLength, chartData})
        }
    }, [filteredData])


    useEffect(() =>{
        if(competitorInformation){
            const filteredId = competitorInformation.filter(item => item.market_place_name === marketName )
            // console.log('filteredId ', filteredId, filteredId[0].market_place_id)
            if(!isEmpty(filteredId)){
                // console.log('filteredId[0].market_place.market_place_id ', filteredId[0].market_place.market_place_id);
                setMarketId(filteredId[0].market_place_id)
            }
        }
    }, [competitorInformation, marketName])
    const dispatch = useDispatch();
    // console.log(marketValue, marketName, marketId);
    // const [chartView, setChartView] = useState(false);
    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        'label + &': {
          marginTop: theme.spacing(2),
        },
        '& .MuiInputBase-input': {
          borderRadius: 4,
          position: 'relative',
          backgroundColor: 'transparent', //theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
          border: '1px solid #ced4da',
          fontSize: 11,
          width: '100%',
          padding: '10px 12px',
          color: theme.palette.text.primary,
          transition: theme.transitions.create([
            'border-color',
            'background-color',
          ]),
          // Use the system font instead of the default Roboto font.
          fontFamily:'Inter',
          '&:focus': {
            borderColor: theme.palette.primary.main,
          },
        },
    }));
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: currentTheme.currentTheme === 'dark' ? '#1A2027' : '#FFFFFF',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: '#FFFFFF',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
      }));

    const getPriceVariation = useCallback(({frmDate, toDate}) =>{
        dispatch(variationactions.getProductsPriceVariation({prd_id:Number(id), market_place_id: marketId,  from_date: frmDate, to_date: toDate}))
    }, [dispatch, marketId]); // eslint-disable-line

    const handlePriceVariantOption = (pTarget) => {
        const { value } = pTarget.target;
        setSelectedOption(value);
        setShowError({bool: false, msg: ''});
        // console.log('value ', value)
        if(value !== -1 && value !== 0){
            const getPeriod = moment().subtract(value, 'days').format('YYYY-MM-DD');
            // console.log(getPeriod, moment().format('YYYY-MM-DD'));
            getPriceVariation({toDate: moment().format('YYYY-MM-DD'), frmDate: getPeriod})
        }
    }
    const handleCustomSubmit = () => {
        const currentDate = moment().format('YYYY-MM-DD');
        const { from_date, to_date } = customDates;
        if(isEmpty( from_date) || isEmpty( to_date)){
            setShowError({bool:true, msg: "Dates can not be left blank."})
            return;
        }
        
        // const diff = moment.duration(moment(currentDate).diff(moment(from_date)));
        // console.log(isEmpty( from_date), 'diff ' , diff._data.days, diff);
        if(from_date > currentDate){
            setShowError({bool:true, msg: "From date can not be greater than current date."});
            return;
        }
        getPriceVariation({frmDate: from_date, toDate: to_date})
    }
    const handleCustomPriceVariant = (pTarget) => {
        setShowError({bool: false, msg: ''});
        const {value, id} = pTarget.target;
        const dateData = {...customDates};
        dateData[id] = value;
        setCustomDates(dateData);
    }
    const showGraphPanel = () =>{
        setShowError({bool: false, msg: ''});
        setShowChartOption(true);
        getPriceVariation({toDate: moment().format('YYYY-MM-DD'), frmDate: moment().subtract(7, 'days').format('YYYY-MM-DD')})

    }

        return (
            <Grid item sx={{padding:0, mt:2, }}>
                <Item sx={{padding:0, pt:2}}>
                    <Grid item xs={12} sx={{display:'flex', width: '100%'}}>
                        <Grid item xs={12} md={6} lg={6}> 
                        <Typography variant='h4' component={'h4'} sx={{color:currentTheme.currentTheme === 'light'? LIGHT_TEXT_COLOR:DARK_TEXT_COLOR}}>View Price Variation Chart</Typography>
                        </Grid>
                        {showChartOption && <Grid item xs={12} md={6} lg={6} sx={{display:'flex', justifyContent:'flex-end', mr: 2}}>
                            { showError.bool && <Typography variant="body2">{showError.msg}</Typography>}
                            <Grid item sx={{display:'flex', alignItems:'center'}}>
                                
                                <Grid item>
                                    {/* <Typography variant='body2' sx={{mb:1}}>Select Option</Typography> */}
                                    <Select size='small'
                                        input={<BootstrapInput sx={{marginTop:'0px !important'}}/>}
                                        value= {selectedOption} onChange={handlePriceVariantOption}>
                                        <MenuItem value={7}>Last Week</MenuItem>
                                        <MenuItem value={30}>Last Month</MenuItem>
                                        <MenuItem value={0}>Select Period</MenuItem>
                                    </Select>
                                </Grid>
                                { selectedOption === 0 && <Grid item sx={{ ml:0, display:'flex', alignItems:'center' }}>
                                    <TextField id="from_date" placeholder='Start Date' value={customDates.from_date} onChange={handleCustomPriceVariant} sx={{ml:1}} size='small' type='date' />
                                    <EastIcon sx={{ml:1, color:currentTheme.currentTheme === 'light'? LIGHT_TEXT_COLOR:DARK_TEXT_COLOR}} />
                                    <TextField id="to_date" placeholder='End Date' value={customDates.to_date} onChange={handleCustomPriceVariant} sx={{ml:1}} size='small' type='date' />
                                    <IconButton 
                                        sx={{
                                            ml:1,
                                            padding:'2px',
                                            backgroundColor: currentTheme.currentTheme === 'dark' ? '#1A2027' : '#FFFFFF', 
                                            borderColor:'#1C3B6E', 
                                            color:currentTheme.currentTheme === 'light'? LIGHT_TEXT_COLOR:DARK_TEXT_COLOR
                                        }}
                                        onClick={handleCustomSubmit}>
                                            <ExpandCircleDownOutlinedIcon sx={{transform:'rotate(265deg)', fontSize:'22px'}}/>
                                        </IconButton>
                                    </Grid>
                                }
                            
                            </Grid>
                        </Grid>
                        }
                    </Grid>
                    <Grid item xs={12} md={12} xl={12} sx={{width:'90%',mt: '50px', position:'absolute', }}>
                        {isEmpty(filteredData) && !isLoading && isFailure && <Typography sx={{mb:2, color:currentTheme.currentTheme === 'light'? LIGHT_TEXT_COLOR:DARK_TEXT_COLOR}}>There is no data available.</Typography>}
                        {isEmpty(filteredData) && isLoading && (
                            <Loader />
                        )  }
                        {!showChartOption && 
                            <Button 
                                onClick={showGraphPanel}
                                sx={{zIndex:100, margin:'0px 0px 0px -5em', backgroundColor:'#559EFF', fontSize:11, textTransform:'none' , borderColor:'#1C3B6E', color:'#000000'}}>Refresh</Button>
                        }
                     </Grid>
                
                    <Grid item sx={{mx:2, display:'flex', filter:showChartOption && !isEmpty(filteredData) ? 'blur(0px)' : 'blur(2px)'}} >
                        <Grid item xs={10}>
                            <Typography className='chartLegendLabel' component={'p'} variant='body2' 
                                sx={{
                                        width:'90%', marginTop:'2em', textAlign:'left', marginLeft:'0em !important',
                                        color:currentTheme.currentTheme === 'light'? LIGHT_TEXT_COLOR:DARK_TEXT_COLOR
                                    }}>
                                    Price/unit</Typography>    
                                <div style={{maxWidth:'90%', width:'100%', marginTop:'1em'}}>
                                     <LineChart colorArr = {colorArr} currentTheme={currentTheme} pricevariantData = {filteredData} activeLoc={activeLoc} chartData = {chartIndexValue.chartData} {...props} />
                                </div>
                                <Typography className='chartLegendLabel' component={'p'} variant='body2' 
                                    sx={{ marginTop:'-1em', marginBottom:'1em', width:'100%', textAlign:'right !important', color:currentTheme.currentTheme === 'light'? LIGHT_TEXT_COLOR:DARK_TEXT_COLOR}}>Date</Typography>
                        </Grid>
                        <Grid item xs={2} sx={{mt:'5em', display:'flex', justifyContent:'flex-end'}}>
                            {
                                !isEmpty(filteredData) && !isEmpty(filteredData[0])
                                ? 
                                <div style={{display:'flex',alignItems:'flex-start',flexDirection:'column'}}>
                                    <Grid item sx={{display:'flex', alignItems:'flex-start'}}>
                                    <div style={{backgroundColor:'#355FBE', width:'16px', height:'10px', borderRadius:'50%'}} />
                                    <Typography className='chartLegendLabel' component={'p'} variant='body2' sx={{color:currentTheme.currentTheme === 'light'? LIGHT_TEXT_COLOR:DARK_TEXT_COLOR}}>{Object.values(filteredData[0])[0][0][0].prd_name}</Typography>
                                    </Grid>
                                    {Object.values(filteredData[0])[0][1].map((item, pIndex) => {
                                        return (<Grid key={`chartTitle_${pIndex}`} item sx={{display:'flex', alignItems:'flex-start'}}>
                                            <div style={{backgroundColor:colorArr[pIndex % 3], width:'10px', height:'10px', borderRadius:'50%'}} />
                                            <Typography className='chartLegendLabel' component={'p'} variant='body2' sx={{ml:1, color:currentTheme.currentTheme === 'light'? LIGHT_TEXT_COLOR:DARK_TEXT_COLOR}}>{item.match_id}</Typography>
                                        </Grid>)}
                                        )}
                                </div>
                                :
                                <>
                                    <div style={{display:'flex', alignItems:'center', marginLeft:8}}>
                                        <div style={{backgroundColor:'#4F8EEC',width:'16px', height:'16px', borderRadius:'50%'}} />
                                        <Typography className='chartLegendLabel' component={'p'} variant='body2'>Competitor variance 1</Typography>
                                    </div>
                                    <div style={{display:'flex', alignItems:'center', marginLeft:8}}>
                                        <div style={{backgroundColor:'#A8CBFF',width:'16px', height:'16px', borderRadius:'50%'}} />
                                        <Typography className='chartLegendLabel' component={'p'} variant='body2'>Competitor variance 2</Typography>
                                    </div>
                                </>
                            }  
                        </Grid>
                    </Grid>
                
                  
                </Item>
                
                
            </Grid>
    )
}

export default PriceVariationChart;