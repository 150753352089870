import { PRICE_CHANGE_NOTIFICATION,PRICE_CHANGE_NOTIFICATION_FAILED,PRICE_CHANGE_NOTIFICATION_SUCCESSFUL } from "../constants/pricechangeNotification.constats";


// Define the initial state of your reducer
const initialState = {
    loading: false,
    data: null,
    error: null,
  };

// Create the reducer function
export const priceChangeNotificationReducer = (state=initialState,action)=>{
    switch (action.type) {
        case PRICE_CHANGE_NOTIFICATION:
          return {
            ...state,
            loading: true,
            error: null,
          };
        case PRICE_CHANGE_NOTIFICATION_SUCCESSFUL:
          return {
            ...state,
            loading: false,
            data: action.payload, //  action.payload contains data
            error: null,
          };
        case PRICE_CHANGE_NOTIFICATION_FAILED:
          return {
            ...state,
            loading: false,
            data: null,
            error: action.payload, //  action.payload contains error message
          };
        default:
          return state;
      }
}