
import { CREATE_CLIENTS_MIGRATE,CREATE_CLIENTS_MiGRATE_SUCCESS,CREATE_CLIENTS_MIGRATE_FAIL } from "../constants/clientMigrate.constants";

export const createClientsMigrate = (payload) => ({
  type: CREATE_CLIENTS_MIGRATE,
  payload,
});

export const createClientsMigrateSuccess = (data) => ({
  type: CREATE_CLIENTS_MiGRATE_SUCCESS,
  payload: data,
});

export const createClientsMigrateFail = (error) => ({
  type: CREATE_CLIENTS_MIGRATE_FAIL,
  payload: error,
});
