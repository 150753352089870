import { call, put, takeLatest  } from 'redux-saga/effects';  //takeLatest
// import { isEmpty, } from 'lodash';
import * as actions from '../actions/refreshAll.actions';
import * as constants from '../constants/refreshAll.constants';
import NetworkApi from '../../api/NetworkApi';
import { getItems } from '../../utils/storage';
import paths from '../../api/path';

const getApiConfig = () => {
  const [
    token
  ] = getItems([
    'token',
  ]);

  const headers = {
    Authorization: `token ${token}`,
  };

  return {
    Authorization: headers.Authorization
  }
}

export function* getRefreshAllProducts({cuttoff_score, loc_id, market_place_id}) {
  const apiConfig = getApiConfig()
    try {
      const response = yield call(NetworkApi.post, paths.refreshAll,
        {cuttoff_score, loc_id, market_place_id},
        apiConfig );
        // console.log(response);
      if (response) {
        yield put(actions.refreshAllSuccessful(response)); 
      } else {
        const message = response?.message ? (
          response?.message
        ) : (
          'There is some issue with loading data'
        );
        yield put(actions.refreshAllFailed(message));
      }

    } catch (error) {
      console.log(error);
      const message = error?.response?.data?.detail ? (
          error?.response?.data?.detail
      ) : (
        'There is some issue with loading data'
      );
      yield put(actions.refreshAllFailed(message));
    }
}

export function* getRefreshAll(request = {}) {
  const { payload = {} } = request;
  //  console.log('refresh', payload);
    const {cuttoff_score, loc_id, market_place_id} = payload;
    yield call (getRefreshAllProducts, {cuttoff_score, loc_id, market_place_id})
}

export function* refreshAll() {
  yield takeLatest(constants.REFRESH_ALL, getRefreshAll);
}
