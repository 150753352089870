import * as constants from '../constants/comments.constants';

export const initialState = {
  loading: false,
  commentStatus: null,
  error: null,
};

export const commentReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.COMMENT_SEND_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case constants.COMMENT_SEND_SUCCESS:
      return {
        ...state,
        commentStatus: action.payload,
        error: null,
      };
    default:
      return state;
  }
};
