import { call, put, takeLatest  } from 'redux-saga/effects';  
import NetworkApi from '../../api/NetworkApi';
import { getItem, getItems } from '../../utils/storage';
import paths from '../../api/path';

import { PRICE_CHANGE_NOTIFICATION,PRICE_CHANGE_NOTIFICATION_FAILED,PRICE_CHANGE_NOTIFICATION_SUCCESSFUL } from '../constants/pricechangeNotification.constats';
import { postpricecahgneNotification,postpricecahgneNotificationFailure,postpricecahgneNotificationSuccess } from '../actions/priceChangeNotification.action';


const getApiConfig = () => {
    const [
      token
    ] = getItems([
      'token',
    ]);
  
    const headers = {
      Authorization: `token ${token}`,
    };
  
    return {
      // headers,
      Authorization: headers.Authorization
    }
  }


  function* postPriceChangeNotificationFcuntion(action) {
    const apiConfig = getApiConfig();
    // const userRole = getItem('role');
    const payload = action.payload
    // console.log("payload",payload)
    // console.log("apiConfig",apiConfig)
    try {
        const response = yield call(NetworkApi.post, paths.pricechangenotification, payload,apiConfig);
        // console.log(response)
        yield put(postpricecahgneNotificationSuccess(response));
    } catch (error) {
       yield put(postpricecahgneNotificationFailure(error))
    }
  }


  export function* watchPostPriceChangeNotification() {
    yield takeLatest(PRICE_CHANGE_NOTIFICATION, postPriceChangeNotificationFcuntion);
  }