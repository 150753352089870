import { call, put, takeLatest  } from 'redux-saga/effects';  //takeLatest
// import { isEmpty, } from 'lodash';
import * as actions from '../actions/refreshProduct.actions';
import * as constants from '../constants/refreshProduct.constants';
import NetworkApi from '../../api/NetworkApi';
import { getItems } from '../../utils/storage';
import paths from '../../api/path';
//import { toast } from 'react-toastify';

const getApiConfig = () => {
  const [
    token
  ] = getItems([
    'token',
  ]);

  const headers = {
    Authorization: `token ${token}`,
  };

  return {
    Authorization: headers.Authorization
  }
}

function* refreshSingleProduct({market_place_ids, prd_id, loc_id, user_id, cuttoff_score, index} ) {
  // console.log('refreshSingleProduct ', market_place_ids, prd_id, index)
  const apiConfig = getApiConfig()
    try {
      const response = yield call(NetworkApi.post, paths.refreshURL, 
        {market_place_id:market_place_ids, prd_id, loc_id, user_id, cuttoff_score},
        apiConfig, );
        // console.log(response);
      if (response && !response.message) {
        yield put(actions.getRefreshSuccessful(response)); 
            
      } else {
        const message = response?.message ? (
          response?.message
        ) : (
          'There is some issue with loading data'
        );
        yield put(actions.getRefreshFailed(message));
      }

    } catch (error) {
      const message = error?.response?.data?.detail ? (
          error?.response?.data?.detail
      ) : (
        'There is some issue with loading data'
      );
      yield put(actions.getRefreshFailed(message));
    }
}

export function* getRefreshProduct(request = {}) {
  const { payload = {} } = request;
  //  console.log('refresh', payload);
    const {market_place_ids , prd_id, loc_id, user_id, cuttoff_score} = payload;
    yield call (refreshSingleProduct, {market_place_ids, prd_id, loc_id, user_id, cuttoff_score, index:0})
}

export function* fetchRefreshProducts() {
  yield takeLatest(constants.REFRESH_PRODUCT, getRefreshProduct);
}
