import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, Divider, Button, InputBase, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { styled } from '@mui/material/styles';

import CountryState from '../CountryState';
import selectCountryStateDetails from '../../redux/selectors/countrystate.selector'
import * as countryactions from '../../redux/actions/countrystate.actions'

import * as actions from '../../redux/actions/addlocations.actions'
import selectAddLocationDetails from '../../redux/selectors/addlocations.selector'
 

import CompModal from '../Modal'
import { isEmpty } from 'lodash';

const locationSchema = Yup.object().shape({
    name: Yup.string().min(6, 'Too short - should be 6 chars minimum!').required('Required'),
    url: Yup.string().min(8, 'Too short - should be 8 chars minimum!').required('Required')
  })



const AddCountryLocations = props =>{
    const {marketPlaceId, countryName, stateName, heading, addType} = props;
    const dispatch = useDispatch()
    const { countryStateDetails } = useSelector(selectCountryStateDetails);
    const {addLocationDetails} = useSelector(selectAddLocationDetails)
    const { countrydata } = countryStateDetails;
    const { isSuccessful, isFailure, errorMessage } = addLocationDetails
    const [cntyState, setCntyState] = useState([])
    const [stateValue, setStateValue] = useState('state')

    const formStyle = {width:'100%'};

    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        'label + &': {
          marginTop: theme.spacing(2),
          width: '100%',
        },
        '& .MuiInputBase-input': {
          borderRadius: 4,
          position: 'relative',
          backgroundColor: 'transparent', //theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
          border: '1px solid #ced4da',
          fontSize: 11,
          width: '100%',
          padding: '10px 12px',
          color: theme.palette.text.primary,
          transition: theme.transitions.create([
            'border-color',
            'background-color',
          ]),
          // Use the system font instead of the default Roboto font.
          fontFamily:'Inter',
          '&:focus': {
            borderColor: theme.palette.primary.main,
          },
        },
      }));
    
    //   console.log('countryName', countryName, stateName)

    useEffect(()=>{
        if(!countrydata){
            dispatch(countryactions.getCountryState());
        }
    }, [countrydata, dispatch])
    

      useEffect(() =>{
        if(isSuccessful){
            setTimeout(()=>{
                dispatch(actions.addLocationReset())
                window.location.reload()
            }, 1000)
        }
      }, [isSuccessful, dispatch])

        useEffect(() =>{
            // console.log('countrydata ', countrydata?.length, countryName);
            if(countryName !== undefined && countryName !== '' && countrydata !== null){
                let cntName = countryName;
                if(countryName.toLowerCase() === 'united states of america'){
                    cntName = 'United States';
                }
                const result = countrydata.filter(item => item.name === cntName);
                // console.log('effect result ', result)
                if(!isEmpty(result)){
                    setCntyState(result[0].states)
                }    
            }
        }, [countryName, countrydata])

    //   console.log('countryStateDetails ', countryStateDetails)

    const handleSelectCountry = (e, setFieldValue) =>{
        setFieldValue('country_name', e.target.value);
        const result = countrydata.filter(item => item.name === e.target.value)
        //  console.log('result ', result);
         setFieldValue('country_short_name', result[0].iso3)
         setCntyState(result[0].states)
    }
     const handleSelectState = (e, setFieldValue) =>{
        setFieldValue('state_name', e.target.value)
        const result = cntyState.filter(item => item.name === e.target.value)
        setFieldValue('state_short_name', result[0].state_code)
        setStateValue(e.target.value);
    }
 
    const handleSubmit = (values) =>{
        if(values.country_name.toLowerCase() === 'united states of america'){
            values.country_name = 'United States';
        }
        if(values.country_name !== '' && values.country_name !== undefined && values.country_name !== null && values.country_short_name === ''){
            // console.log(values.country_name, countrydata.length)
            const result = countrydata.filter(item => item.name.toLowerCase() === values.country_name.toLowerCase())
            // console.log('result', result)
            if(!isEmpty(result)) values.country_short_name = result[0].iso3;
        }
        //   console.log('submit ', values);
        if(values.state_name !== '' && values.state_name !== null  && values.state_name !== undefined && values.state_short_name === ''){
            const result = cntyState.filter(item => item.name.toLowerCase() === values.state_name.toLowerCase())
            // console.log('state result ', result)
            if(!isEmpty(result)) values.state_short_name = result[0].state_code;
        }
        dispatch(actions.handleAddLocation(values))
    }
    return (
        <CompModal {...props}>
                <Typography variant="h5" component={'h5'}>Add {heading}</Typography>
                <Divider />
                {isSuccessful && <Typography variant="body2" component={'p'}>Location added successfully.</Typography>}
                {isFailure && errorMessage && <Typography variant="body2" component={'p'}>There is some issue with location submission. Try again.</Typography>}
                <Grid item sx={{width:'100%'}}>
                <Formik
                   // enableReinitialize
                    initialValues={{market_place_id:marketPlaceId, country_name: countryName !== undefined && countryName !== null && countryName !== '' ? countryName : '', 
                        country_short_name: '',  state_short_name: '', state_name: stateName !== undefined && stateName !== null && stateName !== '' ? stateName : '',
                        loc_zip:'', loc_name:'' 
                    }}
                    validationSchema={locationSchema}
                    >
                {({ errors, 
                    setFieldValue,
                    values 
                }) => (
                    <Form className='loginform' style={{width:'100%'}}>
                        {/* {isSuccessful && <Typography variant="body2" component={'p'}>Location added successfully.</Typography> } */}
                        <Grid item xs={12} sx={{display:'flex',}}>
                            <Grid item xs={12} md={12} xl={12} sx={{mr:1}}>
                                <FormControl variant="standard" sx={{mt:2}} style={formStyle}>
                                    <InputLabel shrink htmlFor='countryname' sx={{position:'relative',color:'text.primary'}}>
                                        Country
                                        </InputLabel>
                                    {countryName !== '' && countryName !== undefined && countryName !== null && addType !== 'country' ?    
                                    <Typography variant="body2" sx={{width:'70%'}}>{countryName}</Typography>
                                     :
                                     <CountryState handleChange={e => {
                                        // console.log('e ', e.target.value);
                                        handleSelectCountry(e, setFieldValue)
                                    }}/>
                                    }
                                    <Typography color="red" variant="subtitle" sx={{width:'70%'}}>{errors.market_place_name}</Typography>
                            </FormControl>

                            <FormControl id={'urls'} variant="standard" sx={{mt:2}} style={formStyle}>
                                <InputLabel shrink htmlFor='url' sx={{position:'relative', color:'text.primary'}}>
                                    State
                                    </InputLabel>
                                {
                                    stateName !== '' && stateName !== undefined && stateName !== null && addType !== 'country' && addType !== 'state' ? 
                                    <Typography variant="body2" sx={{width:'70%'}}>{stateName}</Typography>
                                :
                                <Select variant='outlined' value={stateValue} size='small' onChange={e => handleSelectState(e, setFieldValue)}>
                                    <MenuItem value='state'>Select State</MenuItem>
                                    {
                                        cntyState.map(item => {
                                            return <MenuItem value={item.name}>{item.name}</MenuItem>
                                        })
                                    }
                                </Select>
                                }
                                <Typography color="red" variant="subtitle" sx={{width:'70%'}}>{errors.market_place_url}</Typography>
                            </FormControl>
                            <FormControl id={'brands'} variant="standard" sx={{mt:2}} style={formStyle}>
                                <InputLabel shrink htmlFor='markgetlogo' sx={{color:'text.primary'}}>
                                    Location name 
                                    </InputLabel>
                                <BootstrapInput 
                                    value={values.loc_name} 
                                    placeholder='Write location name '
                                    id='markgetlogo'
                                    onChange={e => setFieldValue('loc_name', e.target.value)}
                                />
                            </FormControl>
                            <FormControl id={'categories'} variant="standard" sx={{mt:2}} style={formStyle}>
                                <InputLabel shrink htmlFor='size' sx={{color:'text.primary'}}>
                                    Zip code
                                    </InputLabel>
                                <BootstrapInput 
                                    value={values.loc_zip} 
                                    placeholder='Enter location zip '
                                    id='size'
                                    onChange={e => setFieldValue('loc_zip', e.target.value)}
                                />
                                <Typography color="red" variant="subtitle" sx={{width:'70%'}}>{errors.brand}</Typography>
                            </FormControl>
                            </Grid>
                        </Grid>
                    
                        <FormControl sx={{ m: 0, mt:3, mb:2, width:'100%', alignItems:'center' }}>
                            <Button sx={{width:'70%', backgroundColor:'#559EFF', borderColor:'#1C3B6E', color:'#000000'}} 
                            onClick={() => handleSubmit(values)}>Add</Button>
                        </FormControl>
                    </Form>
                )}
                </Formik>

                </Grid>
        </CompModal>
    )
}

export default AddCountryLocations;