import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, FormGroup, Button, FormControl, InputLabel, InputBase, Typography, Select, MenuItem } from '@mui/material';
// import Input from '../../../components/Input/index';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { styled } from '@mui/material/styles';
import { Formik } from 'formik';
import * as Yup from 'yup';

import UseAddProduct from './useAddProduct';
// import SalesItem from './salesItem';
import Department from '../../../components/Department';
import Categories from '../../../components/Categories'
import AddLocations from '../../../components/locations/addLocations';
import AddSeller from './AddSeller';


import selectLocationDetails from '../../../redux/selectors/locations.selector';
import * as actions from '../../../redux/actions/locations.actions';

import selectSellerDetails from '../../../redux/selectors/sellers.selector';
import * as sellerActions from '../../../redux/actions/sellers.actions';

import ClearIcon from '@mui/icons-material/Clear';

import { isEmpty } from 'lodash';
import SingleAddproductpopup from '../../../components/singleAddproductPopup/singleAddproductPopup';

const SingleProductSchema = Yup.object().shape({
    prd_name: Yup.string().required('Product Name is required'),
        prd_uom: Yup.string().required('Unit of Measurement is required'),
        prd_size: Yup.number().required('Size is required'),
        prd_no_of_pieces: Yup.number().required('Number of pieces is required').positive('Number of pieces must be positive'),
        locations: Yup.array().of(
          Yup.object().shape({
            loc_id: Yup.string().required('Location is required'),
            seller_id: Yup.string()
        .test('required', 'Seller is required', (value) => value !== null)
        .nullable(),
            prd_sales_price: Yup.number().typeError('Sales Price must be a number').required('Sales Price is required'),
            prd_mrp: Yup.number().typeError('MRP must be a number').required('MRP is required'),
            // prd_offer: Yup.string().required('Sales Price field is required'),
          })
        ),
})

const steps = [
    'Step 1',
    'Step 2'
  ];

const SingleProduct = props => {

    const dispatch = useDispatch();
    const {locationDetails} = useSelector(selectLocationDetails);
    const { sellerDetails } = useSelector(selectSellerDetails)
    const { data, isSuccessful } = locationDetails;
    const {data:sellerData} = sellerDetails
    const [activeStep, setActiveStep] = useState(0);
    const [addLocationBool, setAddLocationBool] = useState(false);
    const [completed, ] = useState({});
    const [sellerError , setSellerError] = useState(false);
    const [sellerBool, setSellerBool] = useState({saleId:-1, visible:false});
    const [error, setError] = useState({
        name:false,
        locations:false
    });
    // const [saleId, setSaleId] = useState(0);
    const {handleSingleProductSubmit, addProductSuccess, addProductFailure,message} = UseAddProduct();

    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        'label + &': {
          marginTop: theme.spacing(2),
        },
        '& .MuiInputBase-input': {
          borderRadius: 4,
          position: 'relative',
          backgroundColor: 'transparent', //theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
          border: '1px solid #ced4da',
          fontSize: 11,
          width: '100%',
          padding: '10px 12px',
          color: theme.palette.text.primary,
          transition: theme.transitions.create([
            'border-color',
            'background-color',
          ]),
          // Use the system font instead of the default Roboto font.
          fontFamily:'Inter',
          '&:focus': {
            borderColor: theme.palette.primary.main,
          },
        },
      }));


    useEffect(() =>{
        if(!data && !isSuccessful){
            dispatch(actions.getLocations())
        }
    }, [data, isSuccessful, dispatch])

    useEffect(() =>{
        if(!sellerData ){
            dispatch(sellerActions.getSellers())
        }
    }, [sellerData, dispatch]) // eslint-disable-line

    const totalSteps = () => {
        return steps.length;
      };
    const completedSteps = () => {
        return Object.keys(completed).length;
      };
    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
      };
    const allStepsCompleted = () => {
       return completedSteps() === totalSteps();
    };
    const handleSellerClose = () =>{
        setSellerBool({saleId:-1, visible:false});
    }
    const handleNext = () => {
        const newActiveStep =
          isLastStep() && !allStepsCompleted()
            ? // It's the last step, but not all steps have been completed,
              // find the first step that has been completed
              steps.findIndex((step, i) => !(i in completed))
            : activeStep + 1;
        setActiveStep(newActiveStep);
      };
    
      const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      };

    const onAdd = (locations, fieldValue) => {
        locations.push({'loc_id':'', 'seller_id':1, 'prd_sales_price':0, 'prd_mrp':'', 'prd_norm_price':'', 'prd_offer':''})
        fieldValue('locations', locations)
    }
  // Function to remove a location section
const onRemove = (locations, index, setFieldValue) => {
    if (index > 0) { // Check if it's not the first location
      locations.splice(index, 1);
      setFieldValue('locations', locations);
    }
  };
  
    const SalesItem = (setFieldValue, values, saleId,errors) =>{
        return <Grid id={`sales_${saleId}`} item sx={{width:'100%', display:'flex'}}>
            <FormControl variant="standard" sx={{mt:2, width:'100%', mr:1}} >
                <InputLabel shrink htmlFor='location' sx={{position:'relative', color:'text.primary'}}>
                    Location<span>*</span>
                </InputLabel>
                <Select id='location' size='small' variant='outlined' 
                    value={values.locations[saleId].loc_id}
                    input={<BootstrapInput sx={{marginTop:'0px !important', '& .MuiInputBase-input': {paddingTop:'8px', paddingBottom:'8px'}}}/>}
                    onChange={e => {
                        setSellerError(false);
                        if(e.target.value !== 'addlocation'){
                            setFieldValue(`locations[${saleId}].loc_id`, e.target.value)
                        } else {
                            setAddLocationBool(true)
                        }
                        
                    }}>
                        <MenuItem value=''>Select Location</MenuItem>
                        {data && data.map(locItem =>{
                            return <MenuItem key={`loc_${locItem.loc_id}`} value={locItem.loc_id}>{locItem.loc_name}</MenuItem>
                        })}
                        <MenuItem value='addlocation'>Add Location</MenuItem>

                </Select>
                <Typography color="error" variant="subtitle">
      {errors?.locations && errors?.locations[0] ? errors?.locations[0]?.loc_id : ''}
    </Typography>
            </FormControl>
            <FormControl variant="standard" sx={{mt:2, width:'100%', mr:1}} >
                <InputLabel shrink htmlFor='sellers' sx={{position:'relative', color:'text.primary'}}>
                    Seller<span>*</span>
                </InputLabel>
                <Select id='sellers' size='small' variant='outlined' 
                    value={values.locations[saleId].seller_id}
                    input={<BootstrapInput sx={{marginTop:'0px !important', '& .MuiInputBase-input': {paddingTop:'8px', paddingBottom:'8px'}}}/>}
                    onChange={e => {
                        if(e.target.value === 'addSeller') {
                            setSellerError(false);
                            if(values.locations[saleId].loc_id){
                                setSellerBool({saleId, visible:true});
                            } else {
                                setSellerError(true);
                            }
                            
                            return;
                        } else {
                            setSellerError(false)
                            setFieldValue(`locations[${saleId}].seller_id`, e.target.value)
                        }
                        // 
                    }}>
                        {/* <MenuItem value=''>Select Seller</MenuItem> */}
                        {sellerData && sellerData.map(sellerItem =>{
                            return <MenuItem key={`loc_${sellerItem.seller_id}`} value={sellerItem.seller_id}>{sellerItem.seller_name}</MenuItem>
                        })}
                        <MenuItem value='addSeller'>Add Seller</MenuItem>
                </Select>
                <Typography color="error" variant="subtitle">
      {errors?.locations && errors?.locations[0] ? errors?.locations[0]?.seller_id : ''}
    </Typography>
            </FormControl>
            
            <FormControl style={{marginTop:"24px"}} variant="standard" sx={{mt:2, width:'100%', mr:1}} >
                <InputLabel shrink htmlFor={`${values.locations[saleId].prd_mrp}`} sx={{color:'text.primary'}}>
                    MRP<span>*</span>
                </InputLabel>
                <BootstrapInput 
                    value={values.locations[saleId].prd_mrp} 
                    placeholder='Enter MRP'
                    // type='number'
                    inputProps={{ pattern: "([^0-9]*)" }}
                    id={`${values.locations[saleId].prd_mrp}`}
                    name= {`${values.locations[saleId].prd_mrp}`}
                    onChange={e => {
                        // const terror = {...error};
                        // terror.locations = false;
                        // setError(terror);
                        setFieldValue(`locations[${saleId}].prd_mrp`, Number(e.target.value))
                    }}
                />
                <Typography color="error" variant="subtitle">
      {errors?.locations && errors?.locations[0] ? errors?.locations[0]?.prd_mrp : ''}
    </Typography>
            </FormControl>
            <FormControl style={{marginTop:"24px"}} variant="standard" sx={{mt:2, width:'100%', mr:1}} >
                <InputLabel shrink htmlFor='productSalePrice' sx={{color:'text.primary'}}>
                    Sale price
                </InputLabel>
                <BootstrapInput 
                    value={values.locations[saleId].prd_sales_price} 
                    placeholder='Enter Sale Price'
                    id='productSalePrice'
                    onChange={e => {
                        // const terror = {...error};
                        // terror.locations = false;
                        // setError(terror);
                        setFieldValue(`locations[${saleId}].prd_sales_price`, Number(e.target.value))
                        setFieldValue(`locations[${saleId}].prd_norm_price`, e.target.value !== 0 ? Number(e.target.value)/values.prd_no_of_pieces : 0)
                    }}
                />
                <Typography color="error" variant="subtitle">
      {errors?.locations && errors?.locations[0] ? errors?.locations[0]?.prd_sales_price : ''}
    </Typography>
            </FormControl>
            {saleId > 0 && ( // Only render the button if saleId is greater than 0
        <ClearIcon
        sx={{ 
            marginTop:"2em",
            cursor:"pointer"
          }}
        size="small"
          variant="contained"
          color="secondary"
          onClick={() => onRemove(values.locations, saleId, setFieldValue)}
        />
      )}
        </Grid>
    }

    const handleLocation = () =>{
        setAddLocationBool(false);
    }

    const handleSubmit = (values) =>{
        const { locations } = values; //prd_name
        // console.log(values);
        const filteredLoc = locations.filter(item => (item.prd_sales_price === '' && item.prd_mrp === '') || item.seller_id === '')
        // console.log('filteredLoc ', filteredLoc)
        if(!isEmpty(filteredLoc)){
            const tError = {...error};
            tError['locations'] = true;
             setError(tError);
             return;
        }
        locations.map(item => {
            if(item.prd_sales_price === '' ){
                item.prd_sales_price = item.prd_mrp;
            } else if(item.prd_mrp === ''){
                item.prd_mrp = item.prd_sales_price;
                // item.prd_norm_price = item.prd_sales_price !== 0 ? 0
            }
            return item;
        })
        handleSingleProductSubmit(values);
    }
    const [openSinglePopup, setOpenSinglePopup] = useState(false);
useEffect(() => {
  if(message && addProductFailure){
    setOpenSinglePopup(true)
  }
}, [message]) //eslint-disable-line

useEffect(() => {
  if(addProductSuccess === true){
    setOpenSinglePopup(true)
  }
}, [addProductSuccess])


    return (
        <Grid item sx={{mt:1}}>
            {openSinglePopup && message !== undefined && (
    <SingleAddproductpopup
      message={message}
      openSinglePopup={openSinglePopup}
      setOpenSinglePopup={setOpenSinglePopup}
    />
    )}
            { openSinglePopup && addProductSuccess && <SingleAddproductpopup message={"Your product has been added successfully."} openSinglePopup={openSinglePopup} setOpenSinglePopup={setOpenSinglePopup} /> }
            {/* {addProductSuccess && 
                <Typography variant='body2' component={'p'}> 
                    Product added successfully.
                </Typography>
            
            } */}
            {/* {addProductFailure && message && 
                <Typography variant='body2' component={'p'}> 
                    {message}
                </Typography>
            
            } */}
            {addLocationBool && <AddLocations handleClose={handleLocation} /> }

            <Stepper activeStep={activeStep+1} alternativeLabel>
                {steps.map((label) => (
                <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                </Step>
                ))}
            </Stepper>
            <Formik enableReinitialize
            initialValues={{
                prd_name: '',
                prd_desc: '',
                prd_medium_image: '',
                prd_small_image: '', 
                prd_brand: '',
                category_id: '',
                dept_id: '',
                prd_url: '',
                prd_upc: '',
                prd_asin: '',
                prd_uom: '',
                prd_size: 1,
                prd_no_of_pieces: 1,
                keyword:'',
                prd_variants:'',
                prd_is_active: true,
                prd_is_crawl_enabled: true,
                prd_is_match_enabled: true,
                prd_rating: 4.0,
                prd_tot_reviews: 0,
                prd_matched: '',
                prd_spec: '',
                crawl_id:'', 
                prd_notification_enabled: false,
                locations: [
                    {'loc_id':'', 'seller_id':null, 'prd_sales_price':0, 'prd_mrp':0, 'prd_norm_price':0, 'prd_offer':''}
                ]
                
            }}
            validationSchema={SingleProductSchema}
            onSubmit={(values, { setSubmitting }) => {
                handleSubmit(values);
                setSubmitting(false);
              }}
            >
                 {({ errors, isValid, setFieldValue, values, handleSubmit }) => (
          <form onSubmit={handleSubmit}> {/* Wrap content inside form */}
            <FormGroup sx={{ flexFlow: 'column' }}>
            { sellerBool.visible && sellerBool.saleId !== -1 && <AddSeller handleClose={handleSellerClose} loc_id = {values.locations[sellerBool.saleId].loc_id} sellerData={sellerData} /> }
                    <Grid item xs={12} sx={{display:activeStep === 0 ? 'flex':'none'}}>
                    <Grid item xs={12} sm={6} sx={{mr:2, pr:2}}>
                    <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                            <InputLabel shrink htmlFor='productname' sx={{color:'text.primary'}}>
                                Product Name<span>*</span>
                                </InputLabel>
                            <BootstrapInput 
                                value={values.prd_name} 
                                placeholder='Enter Product Name'
                                id='productname'
                                onChange={e => {
                                    // const terror = {...error};
                                    // terror.name = false;
                                    // setError(terror);
                                    setFieldValue('prd_name', e.target.value)
                                }}
                            />
                            <Typography color="red" variant="subtitle">{errors.prd_name}</Typography>
                        </FormControl>
                        <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                            <InputLabel shrink htmlFor='productDescription' sx={{color:'text.primary'}}>
                                Product Description
                                </InputLabel>
                            <BootstrapInput 
                                value={values.productDescription} 
                                placeholder='Enter Product Description'
                                id='productDescription'
                                onChange={e => setFieldValue('productDescription', e.target.value)}
                            />
                        </FormControl>
                        <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                            <InputLabel shrink htmlFor='productImage' sx={{color:'text.primary'}}>
                                Product Image
                                </InputLabel>
                            <BootstrapInput 
                                value={values.prd_medium_image} 
                                placeholder='Enter Product Image Path'
                                id='productImage'
                                onChange={e => setFieldValue('prd_medium_image', e.target.value)}
                            />
                        </FormControl>
                        <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                            <InputLabel shrink htmlFor='productBrand' sx={{color:'text.primary'}}>
                                Brand
                                </InputLabel>
                            <BootstrapInput 
                                value={values.prd_brand} 
                                placeholder='Enter Brand Name'
                                id='productBrand'
                                onChange={e => setFieldValue('prd_brand', e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}sx={{mr:0, pr:1}}>
                            <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                                <InputLabel shrink htmlFor='productCategory' sx={{position:'relative', color:'text.primary'}}>
                                    Category<span>*</span>
                                    </InputLabel>
                                <Categories value={values.category_id}  
                                    handleChange={e =>  setFieldValue('category_id', e.target.value)}
                                />
                                
                            </FormControl>
                            <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                                <InputLabel shrink htmlFor='productDepartment' sx={{position:'relative', color:'text.primary'}}>
                                    Department
                                    </InputLabel>
                                <Department value={values.dept_id} handleChange={e =>  setFieldValue('dept_id', e.target.value)} />
                            </FormControl>
                            <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                                <InputLabel shrink htmlFor='productZip' sx={{color:'text.primary'}}>
                                    Variant
                                    </InputLabel>
                                <BootstrapInput 
                                    value={values.prd_variants} 
                                    placeholder='Enter Variant'
                                    id='productZip'
                                    onChange={e => setFieldValue('prd_variants', e.target.value)}
                                />
                            </FormControl>
                            <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                                <InputLabel shrink htmlFor='productURL' sx={{color:'text.primary'}}>
                                    URL
                                    </InputLabel>
                                <BootstrapInput 
                                    value={values.prd_url} 
                                    placeholder='Enter URL'
                                    id='productURL'
                                    onChange={e => setFieldValue('prd_url', e.target.value)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{display:activeStep === 1 ? 'flex':'none', flexDirection: activeStep === 1 ? 'column':''}} >
                        <Grid item sx={{display:'flex'}} >
                            <Grid item xs={12} sm={6} sx={{mr:2, pr:2}}>
                                <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                                    <InputLabel shrink htmlFor='productUPC' sx={{color:'text.primary'}}>
                                        UPC
                                        </InputLabel>
                                    <BootstrapInput 
                                        value={values.prd_upc} 
                                        placeholder='Enter UPC'
                                        id='productUPC'
                                        onChange={e => setFieldValue('prd_upc', e.target.value)}
                                    />
                                </FormControl>
                                <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                                    <InputLabel shrink htmlFor='productASIN' sx={{color:'text.primary'}}>
                                        ASIN
                                        </InputLabel>
                                    <BootstrapInput 
                                        value={values.prd_asin} 
                                        placeholder='Enter ASIN'
                                        id='productASIN'
                                        onChange={e => setFieldValue('prd_asin', e.target.value)}
                                    />
                                </FormControl>
                                <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                                    <InputLabel shrink htmlFor='productMeasure' sx={{color:'text.primary'}}>
                                        Unit of measurement<span>*</span>
                                        </InputLabel>
                                    <BootstrapInput 
                                        value={values.prd_uom} 
                                        placeholder='Enter Unit of Measurement'
                                        id='productMeasure'
                                        onChange={e => setFieldValue('prd_uom', e.target.value)}
                                    />
                            <Typography color="red" variant="subtitle">{errors.prd_uom}</Typography>
                                </FormControl>
                                
                            </Grid>
                            <Grid item xs={12} sm={6}sx={{mr:0, pr:1}}>
                                <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                                    <InputLabel shrink htmlFor='productQuantity' sx={{color:'text.primary'}}>
                                        Number of pieces<span>*</span>
                                    </InputLabel>
                                    <BootstrapInput 
                                        value={values.prd_no_of_pieces} 
                                        placeholder='Enter Number of pieces'
                                        id='productQuantity'
                                        onChange={e => setFieldValue('prd_no_of_pieces', e.target.value)}
                                    />
                            <Typography color="red" variant="subtitle">{errors.prd_no_of_pieces}</Typography>
                                </FormControl>
                                <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                                    <InputLabel shrink htmlFor='productSize' sx={{color:'text.primary'}}>
                                        Size<span>*</span>
                                        </InputLabel>
                                    <BootstrapInput 
                                        value={values.prd_size} 
                                        placeholder='Enter Size'
                                        id='productSize'
                                        onChange={e => setFieldValue('prd_size', e.target.value)}
                                    />
                            <Typography color="red" variant="subtitle">{errors.prd_size}</Typography>
                                </FormControl>
                                {/* <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                                    <InputLabel shrink htmlFor='productKey' sx={{color:'text.primary'}}>
                                        Keyword<span>*</span>
                                    </InputLabel>
                                    <BootstrapInput 
                                        value={values.keyword} 
                                        placeholder='Enter Keywords'
                                        id='productKey'
                                        onChange={e => setFieldValue('keyword', e.target.value)}
                                    />
                                </FormControl> */}
                            </Grid>
                        </Grid>
                        <Grid item sx={{display:'flex', flexDirection:'column'}} >
                            {values.locations.map((item, index) => {
                                return SalesItem(setFieldValue, values, index,errors)
                            })}
                            {sellerError && <Typography color="red" variant="subtitle">Location should be selected before adding seller.</Typography> }
                            {error.locations && <Typography color="red" variant="subtitle">Check for mrp or sales price in the locations.</Typography>}
                            <Button sx={{mt:1.5, alignSelf:'center', width:'30%',backgroundColor:'#559EFF', borderColor:'#1C3B6E', color:'text.secondary'}}  onClick={e => onAdd(values.locations, setFieldValue)}>Add Location</Button>
                        </Grid>
                    </Grid>
              {/* ... (existing code) */}
              <Grid
                item
                xs={12}
                sx={{ mt: 4, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              >
                <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }} > Back </Button>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Button
                            sx={{
                                display: activeStep === 1 ? 'flex' : 'none',
                                alignSelf: 'center',
                                backgroundColor: '#559EFF',
                                borderColor: '#1C3B6E',
                                color: 'text.secondary'
                            }}
                            onClick={handleSubmit} 
                            disabled={!isValid}
                        >
                            Add product
                        </Button>
                        {Object.keys(errors).length > 0 && (
                            <Typography variant='body2' color='error' style={{ marginTop: '16px' }}>
                                Please fix the form errors before adding the product.
                            </Typography>
                        )}
                    </div>
                <Button disabled={activeStep === totalSteps()-1} onClick={handleNext} sx={{ mr: 1, color: 'text.secondary' }}> Next </Button>
              </Grid>
            </FormGroup>
          </form>
        )}
      </Formik>
    </Grid>
  );
};

export default SingleProduct;