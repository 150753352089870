import React, { useCallback, useEffect, useState } from 'react';

import {ToggleButton, Button, Grid, TextField, Typography, FormControl, InputBase, List, ListItem, ListItemAvatar, ListItemText, IconButton, Select, MenuItem } from '@mui/material'  //ListSubheader, 
import { isEmpty, isUndefined, isNull, groupBy, sortedUniq } from 'lodash';
// import moment from 'moment';
import moment from 'moment-timezone';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';

import MarketPlace from '../../components/MarketPlace';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import UseAddRules from './useAddRules';
import AddCountryLocations from '../../components/locations/addCountryLocations';
// import Locations from '../../components/locations';
import PasswordPop from './PasswordPop';


const AddRuels = props => {
    
    const {marketValue, selectedMarket, onMarketPlaceHandler, mktlocdata, 
        countryList, stateList, selectedLocation, setSelectedLocation, singleSelectedMarket, handleDeleteMarketSuccess, deleteMarketView, handleDeleteMarketFailed,
        handleRule, handleRemoveMarket, handleEditMarket, updateMarket, isSuccessful, isFailure, isError
        } = UseAddRules()

    
    const [selectedCountry, setCountrySelected] = useState(null)
    const [selectedState, setSelectedState] = useState(null)
    const [locationList, setLocationList] = useState(null);
    const [selectedAddType, setSelectedAddType] = useState('')
    const [selectedStateList, setSelectedStateList] = useState(null)
    const [selectedMatchRate, setSelectedMatchRate] = useState(60)
    const [ dateTime, setDateTime ] = useState(dayjs(new Date()))
    // const [timeZone, setTimeZone] = useState(null)
    // const [addLocationBool, setAddLocationBool] = useState(false)
    
    // const [selectedMarketLoc, setSelectedMarketLoc] = useState([])
    const [countryStateBool, setCountryStateBool] = useState(false);

    
    const MAX_SELECTED = 7;

    const [ toggalSelected ,setSelected] = useState({
        mon: false,
        tue: false,
        web: false,
        thu: false,
        fri: false,
        sat: false,
        sun: false
    });

    // useEffect(() =>{
    //     const userCurrency = !isNull(getItem('currency')) && !isUndefined(getItem('currency')) ? JSON.parse(getItem('currency')) : [{'currency_symbol':'$'}];
    //     // console.log('userCurrency' ,userCurrency)
    //     setTimeZone(userCurrency[0].time_zone);
    // }, [])

    const toggleButtonSize = 40;
    const toggleButtonStyle = {'&.Mui-selected':{backgroundColor:'#4F8EEC'},backgroundColor:'#314055', borderRadius:'50%', width:toggleButtonSize, height:toggleButtonSize, color:'#FFFFFF', fontSize:12}

    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        'label + &': {
          marginTop: theme.spacing(1),
        },
        '& .MuiInputBase-input': {
          borderRadius: 4,
          position: 'relative',
          backgroundColor: 'transparent', //theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
          border: '1px solid #ced4da',
          fontSize: 11,
          width: '100%',
          padding: '8px 12px',
          color: theme.palette.text.primary,
          transition: theme.transitions.create([
            'border-color',
            'background-color',
          ]),
          // Use the system font instead of the default Roboto font.
          fontFamily:'Inter',
          '&:focus': {
            borderColor: theme.palette.primary.main,
          },
        },
      }));

    const getMatchValue = useCallback(() => {
        const matchList = []
        for(let i=1;i<=100;i++){
            const mvalue= 0+ (i)
            matchList.push(<MenuItem key={`item_${i}`} value={mvalue}>{mvalue} %</MenuItem>)
        }
        return matchList;
    }, [])

    useEffect(() =>{
        // console.log('singleSelectedMarket ', singleSelectedMarket)
        // console.log('country ', countryList)
        if(singleSelectedMarket !== null){
            const tObj = {...toggalSelected}
            const findDay = Object.entries(tObj).map(item => item[1] = false)
            setSelected(findDay);
            const selectedCountry = countryList.filter(item => singleSelectedMarket.country === item.country_name)
            // console.log(countryList,' selectedCountry ', selectedCountry)
            // console.log(selectedCountry[0]?.country_id, typeof(selectedCountry[0]?.country_id))
            
            if(!isEmpty(selectedCountry)){
                setCountrySelected(selectedCountry[0])
                // console.log('statelist ', stateList);
                const stateId = stateList.filter(item => item.country.country_id === selectedCountry[0].country_id)
                const locList = mktlocdata.filter(item =>{
                   return Number(item.loc?.state?.state_id) === stateId[0].state_id;
                })
                //  console.log(stateId, 'locList', locList);
                if(!isEmpty(locList)){
                    // const filteredLoc = Object.values(groupBy(locList, 'loc.loc_zip'))[0][0];
                    if(singleSelectedMarket.state !== null && locList[0].loc.state.state_name === singleSelectedMarket.state){
                        setSelectedState(locList[0].loc.state);
                        
                        if(singleSelectedMarket.location_d !== ''){
                            setLocationList(Object.values(groupBy(locList, 'loc.loc_name')));
                            // setSelectedLocation([Number(locList[0].loc.loc_id)])
                        }
                        
                    }
                }
            }
                        
            if(!isEmpty(singleSelectedMarket) && singleSelectedMarket.weekday !== null  && !isEmpty(singleSelectedMarket.weekday)){     
                singleSelectedMarket.weekday.map(wek => findDay[wek] = true)
                setSelected(findDay);
            }
            if(!isEmpty(singleSelectedMarket)){
                // console.log('moment().utcOffset(); ', moment().utcOffset())
                if(!isNull(singleSelectedMarket.crawl_time)){
                    setDateTime(moment(singleSelectedMarket.crawl_time,'HH:mm:ss', true).format());
                } else {
                    setDateTime('')
                }
                if(!isNull(singleSelectedMarket.cuttoff_score)){
                    setSelectedMatchRate(singleSelectedMarket.cuttoff_score);
                } else {
                    setSelectedMatchRate(0);
                }
            }
        } else if(singleSelectedMarket === null){
            setCountrySelected(null);
            setSelectedState(null);
            setLocationList([]);
        }
    }, [singleSelectedMarket]) // eslint-disable-line

    const handleWeekChange = (key, value) =>{
      const obj = {...toggalSelected}
      obj[key] = !obj[key];
      const findDay = Object.entries(obj).filter(item => item[1] === true)
      if(findDay.length > MAX_SELECTED){
        obj[key] = false;
      }
       setSelected(obj)
       const weekday = Object.entries(obj).filter(item => item[1] === true).map(item => item[0])
       updateMarket({type:'weekday', value: weekday})
    }
    const handleDateChange = (newValue) =>{
        if(newValue !== null) {
            const dd = moment(newValue.$d).format('HH:mm:ss')
            updateMarket({type:'crawl_time', value: dd})
        }
      setDateTime(newValue)
    }
    
    const handleCountryList = (event) => {
        //  console.log('handleCountryList ', event.target.value)
        if(event.target.value === 'addcountry'){
            setSelectedAddType('country')
            setCountryStateBool(true);
            return null;
        }
        const result = countryList.filter(item => item.country_id === event.target.value)
        // console.log(result)
        setCountrySelected(result[0]);
        updateMarket({type:'country', value: event.target.value})
        handleStateList(event.target.value)
        // handleLocationList(event.target.value)
    }
    const handleStateList = (pCountry) =>{
        const locList = stateList.filter(item =>{
            return item.country?.country_id === pCountry;
        })
        // console.log('handleStateList ',locList)
        setSelectedStateList(locList[0].loc.state);
    }
    const handleShowStateList = (event) =>{
        const locList = mktlocdata.filter(item =>{
            // console.log(item.loc?.state?.state_id, pState.target.value)
            return Number(item.loc?.state?.state_id) === Number(event.target.value);
        })
        if(event.target.value === 'addstate'){
            setSelectedAddType('state')
            setCountryStateBool(true);
            return null;
        }
        // console.log(mktlocdata)
        // console.log(locList)
        const filteredLoc = Object.values(groupBy(locList, 'loc.loc_zip'))[0][0];
        //  console.log('filteredLoc ', filteredLoc);
        //  console.log(locList, ' handleShowStateList ')
        setSelectedState(locList[0].loc.state);
        updateMarket({type:'state', value: locList[0].loc.state.state_name})
        setLocationList(Object.values(groupBy(locList, 'loc.loc_zip')));
    }
    const handleShowLocationList = (event) =>{
      const {target: {value}, } = event;
    //   if(isEmpty(value)) return;
//  console.log('loc ', typeof(value), value, value.includes('addlocation'))
        if(value.includes('addlocation')){
            setCountryStateBool(true);
            return null;
        }
    const locationStr = mktlocdata.filter(item => value.includes(item.loc?.loc_id)).map(loc => {return loc?.loc?.loc_name})
    // console.log('locationstr ', value, locationStr,sortedUniq(locationStr))  
    if(!isEmpty(value)){
        setSelectedLocation(
            typeof value === 'string' ? value.split(',') : value,
        )
        //   console.log(sortedUniq(locationStr).toString());
        updateMarket({type:'location_d', value: sortedUniq(locationStr).toString()})
        }
    }
    
    const handleCountryLocClose = () =>{
        setCountryStateBool(false);
    }
    const handleSelectMatchRate = (event) =>{
        // setSelectedMatchRate(event.target.value);
        updateMarket({type:'cuttoff_score', value: event.target.value})
    }

return (
    <>
    <Grid item sx={{mt:2}}>
        <Typography variant='h4' component={'h4'} sx={{fontSize:15, fontWeight:500}}>Scrapping</Typography>
        {deleteMarketView.isBool && <PasswordPop {...props}
            deleteMarketView={deleteMarketView} 
            handleSuccess={handleDeleteMarketSuccess} 
            handleFailed={handleDeleteMarketFailed}/>}
        {/* {console.log('isFailure', isFailure)} */}
        {countryStateBool && <AddCountryLocations 
            marketPlaceId = {marketValue}
            countryName={selectedCountry !== null && selectedCountry !== undefined ? selectedCountry.country_name : ''} 
            stateName={selectedState !== null && selectedState !== undefined ? selectedState.state_name : ''}
            addType={selectedAddType}
            heading={'Country'}
            modalOpen={countryStateBool} handleClose={handleCountryLocClose} 
        /> }
        
        <Grid item xs={12} sx={{pl:3, pt:3}}>
            <Grid item sx={{display:'flex', mb:0, alignItems:'center'}}>
                <Grid item xs={12} sm={4}>
                    <Typography variant='body2' sx={{}}>Market Place</Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <MarketPlace value= {marketValue} handleChange={onMarketPlaceHandler}/>
                </Grid>
            </Grid>
            
            {
            // !isEmpty(mktlocdata) && 
            (marketValue !== 'marketplace') && 
            <>
                <Grid item sx={{display:'flex', mb:1, alignItems:'center'}}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant='body2' sx={{mt:2, fontSize:11, fontWeight:400}}>Country</Typography>
                    </Grid>
                    <Grid item xs={12} sm={8} sx={{display:'flex', flexFlow:'column'}}>
                    <FormControl variant="standard" sx={{mt:1, width:'100%'}} >
                        <Select size='small' 
                            input={<BootstrapInput sx={{marginTop:'0px !important'}}/>}
                            sx={{textTransform:'capitalize'}} variant='outlined' value={selectedCountry !== null && selectedCountry.country_id} onChange={handleCountryList}>
                            {
                                !isEmpty(countryList) && countryList.map((cntryItem, index) =>{
                                    // console.log('country list', cntryItem)
                                    return <MenuItem key={`country_${index}`} sx={{textTransform:'capitalize'}} value={cntryItem.country_id}>{cntryItem.country_name}</MenuItem>
                                })
                            }
                            <MenuItem value='addcountry'>Add Country</MenuItem>
                        </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                {
                    // !isEmpty(selectedStateList) && 
                        <Grid item sx={{display:'flex', mb:1, alignItems:'center'}}>
                            <Grid item xs={12} sm={4}>
                                <Typography variant='body2' sx={{mt:2, fontSize:11, fontWeight:400}}>State</Typography>
                            </Grid>
                            <Grid item xs={12} sm={8} sx={{display:'flex', flexFlow:'column'}}>

                                <Select size='small' sx={{textTransform:'capitalize'}} 
                                    input={<BootstrapInput sx={{marginTop:'0px !important'}}/>}
                                    value={selectedState !== null && selectedState.state_id} variant='outlined' onChange={handleShowStateList}>
                                    
                                    {!isEmpty(stateList) && stateList.map((stateItem, index) => {
                                        return <MenuItem key={`state_${index}`} sx={{textTransform:'capitalize'}} value={stateItem?.state_id}>{stateItem?.state_name}</MenuItem>
                                    })}
                                    <MenuItem value='addstate' sx={{textTransform:'capitalize'}}>Add State</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                    }
                    {
                        // !isEmpty(locationList) && 
                        <Grid item sx={{display:'flex', mb:1, alignItems:'center'}}>
                            <Grid item xs={12} sm={4}>
                                <Typography variant='body2' sx={{mt:2, fontSize:11, fontWeight:400}}>Zip Location</Typography>
                            </Grid>
                            {/* {console.log('selectedLocation ', selectedLocation, locationList)} */}
                            <Grid item xs={12} sm={8} sx={{display:'flex', flexFlow:'column'}}>
                                {/* {console.log('selectedLocation', mktlocdata, typeof(selectedLocation[0]), selectedLocation, mktlocdata.filter(item => item.loc?.loc_name === selectedLocation[0]).map(loc => {return loc?.loc?.loc_id}))} */}
                                <Select size='small' multiple variant='outlined' 
                                    input={<BootstrapInput sx={{marginTop:'0px !important'}}/>}
                                    value={sortedUniq( mktlocdata.filter(item => typeof(selectedLocation[0]) === 'number' ? item.loc?.loc_id === selectedLocation[0] : item.loc?.loc_name === selectedLocation[0] ).map(loc => {return loc?.loc?.loc_id}))} 
                                    sx={{textTransform:'capitalize'}} 
                                    onChange={handleShowLocationList}>
                                    {!isEmpty(locationList) && locationList.map((locItem, index) => {
                                        // console.log(locItem[0]);
                                        return <MenuItem key={`loc_${index}`} value={locItem[0]?.loc.loc_id} sx={{textTransform:'capitalize'}}>{locItem[0]?.loc.loc_name}</MenuItem>
                                    })}
                                    <MenuItem value='addlocation'>Add Location</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                    }
                {
                    // !isEmpty(selectedStateList) && 
                        <Grid item sx={{display:'flex', mb:1, alignItems:'center'}}>
                            <Grid item xs={12} sm={4}>
                                <Typography variant='body2' sx={{mt:2, fontSize:11, fontWeight:400}}>Match Rate</Typography>
                            </Grid>
                            <Grid item xs={12} sm={8} sx={{display:'flex', flexFlow:'column'}}>

                                <Select size='small' 
                                    input={<BootstrapInput sx={{marginTop:'0px !important'}}/>}
                                    // defaultValue={Math.round(Number(competitordata?.comp_prd_score))} 
                                    value={selectedMatchRate}
                                    className='custom-scrollbar'
                                    MenuProps={{className:'custom-scrollbar', style: {maxHeight:200}}}
                                    style={{marginLeft:0}}
                                    onChange={handleSelectMatchRate}>
                                    {getMatchValue()}
                                </Select>
                            </Grid>
                        </Grid>
                    }
            <Grid item sx={{display:'flex', mb:2, alignItems:'start', visibility: selectedLocation.length === 0 ? 'hidden':'visible'}}>
                <Grid item xs={12} sm={4}>
                    <Typography variant='body2' sx={{mt:2, fontSize:11, fontWeight:400}}>Repeat on</Typography>
                </Grid>
                <Grid item xs={12} sm={8} sx={{display:'flex', flexFlow:'column'}}>
                    <Grid item>
                        <ToggleButton value={toggalSelected.sun} sx={{...toggleButtonStyle}} 
                            selected={toggalSelected.sun} onChange={e => handleWeekChange('sun', e.target.value)}> S </ToggleButton>
                        <ToggleButton value={toggalSelected.mon} sx={{...toggleButtonStyle, ml:0.5 }} selected={toggalSelected.mon} 
                            onChange={e => handleWeekChange('mon', e.target.value)}> M </ToggleButton>
                        <ToggleButton value={toggalSelected.tue} sx={{...toggleButtonStyle, ml:0.5 }} 
                            selected={toggalSelected.tue} onChange={e => handleWeekChange('tue', e.target.value)}> T </ToggleButton>
                        <ToggleButton value={toggalSelected.wed} sx={{...toggleButtonStyle, ml:0.5 }} 
                            selected={toggalSelected.wed} onChange={e => handleWeekChange('wed', e.target.value)}> W </ToggleButton>
                        <ToggleButton value={toggalSelected.thu} sx={{...toggleButtonStyle, ml:0.5 }} 
                            selected={toggalSelected.thu} onChange={e => handleWeekChange('thu', e.target.value)}> T </ToggleButton>
                        <ToggleButton value={toggalSelected.fri} sx={{...toggleButtonStyle, ml:0.5 }} 
                            selected={toggalSelected.fri} onChange={e => handleWeekChange('fri', e.target.value)}> F </ToggleButton>
                        <ToggleButton value={toggalSelected.sat} sx={{...toggleButtonStyle, ml:0.5 }} 
                            selected={toggalSelected.sat} onChange={e => handleWeekChange('sat', e.target.value)}> S </ToggleButton>
                        
                    </Grid>
                    <Grid item sx={{display:'flex', mt:1, alignItems:'center'}}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                label=""
                                value={dateTime}
                                size='small'
                                onChange={handleDateChange}
                                sx={{fontSize:11, fontFamily:'Inter', color:'text.secondary'}}
                                renderInput={(params) => <TextField  {...params} />}
                            />
                            
                        </LocalizationProvider>
                        
                        <Button sx={{ml:1, height:30, display:selectedLocation.length === 0 && 'none'}} onClick={handleRule}>Save</Button>
                    </Grid>
                    {isFailure && <Typography variant='body2' sx={{width:'100%', textAlign:'center'}}>There are some issues while creating market place.</Typography>}    
                    {isError.isBool && <Typography variant='body2' sx={{width:'100%', textAlign:'center'}}>{isError.msg}</Typography>}
                    {isSuccessful && <Typography variant='body2' sx={{width:'100%', textAlign:'center'}}>Market Rules created successfully.</Typography>}
                    <Grid item>
                        {/* {console.log(moment.tz.guess() , new Date())} */}
                    {/* <Typography variant='body2'>Time Difference: {moment().format('Z')}</Typography> */}
                    </Grid>
                </Grid>
            </Grid>
            </>
            }
            
        </Grid>
        
    </Grid>
    {
    // console.log('selected market', selectedMarket)
    }
    {selectedMarket &&
    <Grid item xs={12} sm={12} lg={12} sx={{display:'flex', flexFlow:'row', width:'100%'}}>
        <List sx={{display:'flex',flexFlow:'column', width:'100%'}}>
        { selectedMarket.map((mktItem, index) => {
            const {market_place_logo, market_place_name, country, state, location_d, crawl_time, weekday, cuttoff_score } = mktItem;
            return <ListItem key={`market${index}`} sx={{display:'flex', width:'100%', border:1, m:1, justifyContent:'space-between' }}>
                <ListItemAvatar>
                    <img src= {market_place_logo !== null ? market_place_logo : '/assets/noimage.png'} 
                        style={{width:85}} alt='logo' />
                </ListItemAvatar>
                <Grid item xs={12} md={12} lg={12} sx={{width:'100%', ml:2, display:'flex', flexFlow:'column', justifyContent:'space-between'}}>
                    <Grid item sx={{display:'flex', mb:1, flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
                        <Grid>
                            <Typography variant='body2' className='headingText' sx={{}}>{market_place_name}</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{display:'flex', justifyContent:'flex-end'}}>
                            <IconButton aria-label="deleteicon" size="small" onClick={() => handleRemoveMarket(mktItem)}>  
                            <CloseIcon id='deleteicon' sx={{fontSize:14, color:'text.secondary'}}/>
                            </IconButton>
                            <IconButton sx={{display:'block'}} 
                                aria-label="editicon" size="small" 
                                onClick={() => handleEditMarket(mktItem)}>
                            <EditIcon id='editicon' sx={{ fontSize:14, color:'text.secondary'}}/>
                            </IconButton>
                        </Grid>
                    </Grid>
                    {/* {console.log(crawl_time, ' timeZone ', !isNull(crawl_time) && moment.tz(new Date(), timeZone).format("Z"))} */}
                    {/* Time: ${!isNull(crawl_time) ? moment.tz(crawl_time, timeZone).format('hh:mm:ss A'): 'N/A' } |  */}
                    <ListItemText sx={{textTransform:'capitalize'}}
                        primary={`Repeat on: ${!isEmpty(weekday) ? weekday : 'N/A' } | 
                        Time: ${!isNull(crawl_time) ? moment(crawl_time, 'HH:mm:ss', true).format('hh:mm:ss A'): 'N/A' } | 
                        
                        Match Rate: ${!isNull(cuttoff_score) && !isUndefined(cuttoff_score) ? cuttoff_score : 'N/A'} ${!isNull(cuttoff_score) && !isUndefined(cuttoff_score) ? '%' : ''}`}
                        secondary= {`Location: ${!isNull(state) ? state : '-'} , ${!isNull(country) ? country : '-'} 
                         | ${!isEmpty(location_d) ? location_d : 'N/A'}`} />
                </Grid>
            </ListItem>
        })}
        </List>
    </Grid>
    }
    {/* <Button sx={{display:selectedMarket.length === 0 && 'none'}} onClick={handleRule}>Add</Button> */}
    </>
)

}


export default AddRuels;