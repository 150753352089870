import { call, put, takeLatest  } from 'redux-saga/effects';
import * as actions from '../actions/getLocationQa.actions';
import * as constants from '../constants/getLocationQa.constants';
import NetworkApi from '../../api/NetworkApi';
import { getItems } from '../../utils/storage';
import paths from '../../api/path';

const getApiConfig = () => {
    const [
      token
    ] = getItems([
      'token',
    ]);

    const headers = {
        Authorization: `token ${token}`,
    };

    return {
        Authorization: headers.Authorization
    }
}

export function* getLocationQa(request = {}) {
    const apiConfig = getApiConfig()
    const { payload = {} } = request;
    try {
      const response = yield call(NetworkApi.get, `${paths.getLocationQa}?user_id=${payload}`, {}, apiConfig);
      // console.log('locations', response);
      if (response) {
          yield put(actions.getLocationQaSuccessful(response));
      } else {
        // console.log('error ', response)
        const message = response? (
          response
        ) : (
          ''
          // <FormattedMessage id="notProcess" />
        );
        yield put(actions.getLocationQaFailed(message));
      }
  
    } catch (error) {
      const message = error?.response?.data?.message ? (
        error?.response?.data?.message
      ) : (
        ''
        // <FormattedMessage id="notProcess" />
      );
      yield put(actions.getLocationQaFailed(message));
    }
  }
  
  
  export function* fetchLocationQa() {
    yield takeLatest(constants.GET_LOCATION_QA, getLocationQa);
}