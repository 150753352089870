import { call, put, takeLatest  } from 'redux-saga/effects';  //takeLatest
// import { isEmpty, } from 'lodash';
import * as actions from '../actions/productDetails.actions';
import * as constants from '../constants/productDetails.constants';
import NetworkApi from '../../api/NetworkApi';
import { getItems, setItems } from '../../utils/storage';
import paths from '../../api/path';
//import { toast } from 'react-toastify';

const getApiConfig = () => {
  const [
    token
  ] = getItems([
    'token',
  ]);

  const headers = {
    Authorization: `token ${token}`,
  };

  return {
    // headers,
    Authorization: headers.Authorization
  }
}


export function* getProductCompetitors(request = {}) {

  const apiConfig = getApiConfig()
  
  try {
    const response = yield call(NetworkApi.get, paths.getProductCompetitorURL, 
      {},
      apiConfig, );
    // console.log(response);

    if (response && response.message.toLocaleLowerCase() === 'ok') {
      const {
        token,
      } = response;

      if (token.length> 1) {
        setItems([
          {
            key: 'token',
            value: token
          }
        ]);
        yield put(actions.competitorsSuccessful(response));
      } else {
        
      }
    } else {
      // console.log('error ', response)
      const message = response?.non_field_errors ? (
        response?.non_field_errors
      ) : (
        ''
        // <FormattedMessage id="notProcess" />
      );
      yield put(actions.competitorsFailed(message));
    }

  } catch (error) {
  //  console.log('dashboard ', error)
    const message = error?.message ? (
        error?.message
    ) : (
      ''
      // <FormattedMessage id="notProcess" />
    );
    
    yield put(actions.competitorsFailed(message));
  }
}

export function* getProductLocations(request = {}) {

  const apiConfig = getApiConfig()
  
  try {
    const response = yield call(NetworkApi.get, paths.getLocationsURL, 
      {},
      apiConfig, );
    // console.log(response);

    if (response && response.message.toLocaleLowerCase() === 'ok') {
      const {
        token,
      } = response;

      if (token.length> 1) {
        setItems([
          {
            key: 'token',
            value: token
          }
        ]);
        yield put(actions.locationsSuccessful(response));
      } else {
        
      }
    } else {
      // console.log('error ', response)
      const message = response?.non_field_errors ? (
        response?.non_field_errors
      ) : (
        ''
        // <FormattedMessage id="notProcess" />
      );
      yield put(actions.locationsFailed(message));
    }

  } catch (error) {
  //  console.log('dashboard ', error)
    const message = error?.message ? (
        error?.message
    ) : (
      ''
      // <FormattedMessage id="notProcess" />
    );
    
    yield put(actions.locationsFailed(message));
  }
}


export function* fetchProductCompetitors() {
  yield takeLatest(constants.COMPETITORS_GET, getProductCompetitors);
}
export function* fetchProductLocations() {
  yield takeLatest(constants.LOCATIONS_GET, getProductLocations);
}
