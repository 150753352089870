import React from 'react';
import { Chart as ChartJS, LineElement, CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    Legend, } from "chart.js";
import { Line } from 'react-chartjs-2';
import { isEmpty } from 'lodash';
import moment from 'moment';

ChartJS.register(LineElement, CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    Legend);



const LineChart = props => {

  const {currentTheme, colorArr, showChartOption, pricevariantData} = props;
    
  const dummyLabels = ['', '', '', '', '', '', ''];
  const labels = !isEmpty(pricevariantData) ? Object.keys(pricevariantData[0]).length <= 7 ?
    Object.keys(pricevariantData[0]).map(item => moment(item).format('DD-MM-YYYY HH:MM:SS').split(" ")) 
    : Object.keys(pricevariantData[0]).map(item => moment(item).format('DD-MM').split(" ")) 
    : dummyLabels;

  let resultData = !isEmpty(pricevariantData) && pricevariantData.map(item => Object.values(item).map(items => items));
  const xLabels = !isEmpty(resultData) && Object.keys(resultData[0][0]).flat();
  const valArr = [];
  !isEmpty(xLabels) && xLabels.map(item => {
    const val = resultData[0].map(items => Object.entries(items)).map(itemss => itemss);
    const obj = {};
    const tempArr = [];
    val.map(valItem => 
      valItem.map(valItems => {
        if(valItems[0] === item){
          tempArr.push(valItems[1])
        } 
        return valItems;
      }
    ))
    obj[item] = tempArr;
    valArr.push(tempArr);
      return val;
  })

  const priceData = !isEmpty(xLabels) && xLabels.map((item, index) => {
    return {
      label: item,
      data: valArr[index],
      borderColor: colorArr[index % 3],
      backgroundColor: colorArr[index % 3]
    }
  })



  const dummylineData = {
    dummyLabels,
    datasets: [
      {
        label: 'Site 1',
        data: dummyLabels.map(() => Math.random()*1000),
        borderColor: '#EAD18F',
        backgroundColor: '#EAD18F',
      },
      {
        label: 'Site 2',
        data: dummyLabels.map(() => Math.random()*1000),
        borderColor: '#FF7272',
        backgroundColor: '#FF7272',
      },
      {
        label: 'Site 3',
        data: dummyLabels.map(() => Math.random()*1000),
        borderColor: '#4F8EEC',
        backgroundColor: '#4F8EEC',
      },
    ],
  };
  let lineData = {
    labels,
    datasets: priceData,
  };


  const option = {
    responsive: false,
    maintainAspectRatio: false,
    interaction: {
      intersect: false
    },
    borderWidth:2,
    scales: {
        y:{
          border:{
            display:true,
            color:'#515151'
          },
          ticks:{
              display: true,
                color: currentTheme.currentTheme === 'light'? '#272727' : '#E4E3E3',
                maxRotation: 0,
                autoSkip: false,
          },
          grid:{
            drawOnChartArea:false
          },
          display: true
        },
        x: {
          stacked: true,
          border:{
            display:true,
            color:'#515151'
          },
          ticks:{
              display: true,
              color: currentTheme.currentTheme === 'light'? '#272727' : '#E4E3E3',
              maxRotation: 0,
              autoSkip: false,
          },
          grid:{
            drawOnChartArea:false
          }
        },
        yAxes: {
            display:false,
            border:{
              display:false,
            },
            labels:{
              display: false
            },
            // color:'#FFFFFF',
            title:{display:false},
            ticks: {
                display: false
            }
        },
        xAxes: {
            border:{
              display:false,
            },
            ticks: {
                display: false
            }
        }
    },
    plugins: {
      datalabels: {
        display: false
      },
      legend: {
        display: false,
        // onClick: null,
        //   position: 'top',
        //   lineWidth:0,
        //   labels:{
        //     color: '#FFFFFF',
        //     usePointStyle: true,
        //     pointStyle: 'circle',
        //   }
      }
    }
}


return (
    <Line options={option} data={!isEmpty(pricevariantData) ? lineData :dummylineData} width={570} height={showChartOption ? 220 : 240} />
)

}
export default LineChart;