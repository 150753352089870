import React from 'react';
import { Chart as ChartJS, ArcElement, CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    Legend, } from "chart.js";
import { Doughnut } from 'react-chartjs-2';
ChartJS.register(ArcElement, CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    Legend);

const DonutChart = props => {
    
    const {marketValues, totalMarketValue} = props;
    const {Less, more, Equal} = marketValues;
    const lessPercent = Less !== 0 ? (Number(Less)/totalMarketValue)*100 : 0;
    const morePercent = more !== 0 ? (Number(more)/totalMarketValue)*100 : 0;
    const equalPercent = Equal !== 0 ?(Number(Equal)/totalMarketValue)*100 : 0;
    const data = {
        datasets: [
            {
              data: [equalPercent, lessPercent, morePercent, ],
              backgroundColor: ['#DAC934','#3FCD7F', '#FF6767'],
              hoverBackgroundColor: ['#DAC934','#3FCD7F', '#FF6767'],
              borderWidth:0,
            }
          ],
    };

    const option = {
        responsive: false,
        maintainAspectRatio: true,
        cutout: '70%',
        plugins: {
            datalabels: {
                display: false
            }
            
        }
    }


return (
    <Doughnut options={option} width={'120%'} data={data} style={{marginRight:'1.5em'}} />
)

}
export default DonutChart;