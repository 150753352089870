import React, { useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, TextField, Grid, Select, Button, Typography, FormControl, InputLabel, MenuItem } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { updateClientRequest } from '../../redux/actions/clientUpdate.actions';
import selectClientsUpdate from '../../redux/selectors/clientUpdate.selectors';
import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    company_name: Yup.string().required('Company Name is required'),
    first_name: Yup.string().required('First Name field cannot be empty'),
    // // last_name: Yup.string().required('Last Name field cannot be empty'),
    phone_number: Yup.string().required('Phone Number is required').matches(/^\+?\d+$/, 'Phone Number must contain only digits'),
    Industry: Yup.string().required('Industry field cannot be empty'),
    created_date: Yup.string().required('Created Date field cannot be empty'),
    db_name: Yup.string().required('DB Name field cannot be empty'),
    address: Yup.string().required('Address field cannot be empty'),
    time_zone: Yup.string().nullable().required('Time Zone field cannot be empty'),
    no_of_produts_allowed: Yup.number().typeError('Only digits are allowed').required('Number of Products Allowed field cannot be empty'),
    // no_of_produts_exist: Yup.number().required('Number of Products Exist field cannot be empty'),
    no_of_mp_allowed: Yup.number().typeError('Only digits are allowed').required('Number of MP Allowed field cannot be empty'),
    // no_of_mp_exist: Yup.number().required('Number of MP Exist field cannot be empty'),
    currency_symbol: Yup.string().required('Currency Symbol field cannot be empty'),
    currency: Yup.string().required('Currency field cannot be empty'),
    status: Yup.boolean().required('Status field cannot be empty'),
    remarks: Yup.string().required('Remarks field cannot be empty'),
});

function AdminEditBox({ onClose, clientValue,clientsData }) {


  // console.log("clientValue in  ",clientValue)

  const [themeName, setThemeName] = useState('');

  // const [emailExists, setEmailExists] = useState(false);
  // const [dbNameExists, setDbNameExists] = useState(false);
  // const [phoneNumberExists, setPhoneNumberExists] = useState(false);
  // const [companyNameExists, setCompanyNameExists] = useState(false);
  // useEffect(() => {
  //   setEmailExists(false);
  //   setDbNameExists(false);
  //   setPhoneNumberExists(false);
  //   setCompanyNameExists(false);
  // }, [clientsData]);

  const validateEmail = (value) => {
    let error;
    if (!value) {
      error = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(value)) {
      error = "Invalid email address";
    } else if (
      clientsData.some((client) => client.email === value && client.id !== clientValue.id)
    ) {
      error = "Email already exists";
    }
    return error;
  };
  
  const validateDbName = (value) => {
    let error;
    if (!value) {
      error = "DB Name is required";
    } else if (
      // clientsData.some((client) => client.db_name === value)
      clientsData.some((client) => client.db_name === value && client.id !== clientValue.id)
    ) {
      error = "DB Name already exists";
    }
    return error;
  };
  
  const validatePhoneNumber = (value) => {
    let error;
    if (!value) {
      error = "Phone Number is required";
    } else if (
      // clientsData.some((client) => client.phone_number === value)
      clientsData.some((client) => client.phone_number === value && client.id !== clientValue.id)
    ) {
      error = "Phone Number already exists";
    }
    return error;
  };
  
  const validateCompanyName = (value) => {
    let error;
    if (!value) {
      error = "Company Name is required";
    } else if (
      // clientsData.some((client) => client.company_name === value)
      clientsData.some((client) => client.company_name === value && client.id !== clientValue.id)
    ) {
      error = "Company Name already exists";
    }
    return error;
  };


  // console.log("clientsData in edit",clientsData)
  const useStyles = makeStyles({
    inputField: {
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: themeName==="dark" && "#ced4da" ,
        },
        '&:hover fieldset': {
          borderColor: themeName==="dark" && "#ced4da",
        },
        '&.Mui-focused fieldset': {
          borderColor: themeName==="dark" && "#ced4da",
        },
      },
      '& .MuiSelect-root': {
        '& .MuiSelect-icon': {
          color: themeName==="dark" && "#ced4da",
        },
        '&:hover .MuiSelect-icon': {
          color: themeName==="dark" && "#ced4da",
        },
        '&.Mui-focused .MuiSelect-icon': {
          color: themeName==="dark" && "#ced4da",
        },
      },
    },scrollbar: {
      '&::-webkit-scrollbar': {
        width: '8px',
        background: '#f5f5f5',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
        borderRadius: '4px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },
    },
    errorMessage: {
      color: 'red', // Customize the error message color here
    },
  });
  const inputBoxStyle = {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: themeName === "dark" && "#ced4da" , 
      },
    },
  };
  const classes = useStyles();
  const dispatch = useDispatch();
  const { updatedClient, isLoading, isError } = useSelector(selectClientsUpdate);
// const {status} = updatedClient
  useEffect(() => {
    const storedTheme = localStorage.getItem('appTheme');
    setThemeName(storedTheme);
  }, []);

  // funciton for updating clients
  const handleUpdate = (values) => {
    dispatch(updateClientRequest(clientValue.id, values));
  };

  useEffect(() => {
    if (updatedClient) {
      if (updatedClient.status === 'Success') {
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    }
  }, [updatedClient]);

  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }, [isError]);  

  return (
    <Dialog open onClose={onClose} maxWidth="xl" fullWidth>
      <DialogTitle>
        <Typography textAlign="center">Update Client</Typography>
        {isLoading && <Typography textAlign="center">Updating please wait...</Typography>}
        {isError && <Typography textAlign="center">Error occurred while updating client</Typography>}
        {updatedClient && <Typography textAlign="center">Client details updated successfully</Typography>}
      </DialogTitle>
      <DialogContent className={classes.scrollbar} >
        <Formik
          initialValues={{
            company_name: clientValue?.company_name || '',
            email: clientValue?.email || '',
            first_name: clientValue?.first_name || '',
            last_name: clientValue?.last_name || '',
            phone_number: clientValue?.phone_number || '',
            Industry: clientValue?.Industry || '',
            created_date: clientValue?.created_date || '',
            no_of_mp_allowed: clientValue?.no_of_mp_allowed || 0,
            no_of_produts_allowed: clientValue?.no_of_produts_allowed || 0,
            no_of_mp_exist: clientValue?.no_of_mp_exist || 0,
            no_of_produts_exist: clientValue?.no_of_produts_exist || 0,
            db_name: clientValue?.db_name || '',
            status: clientValue?.status,
            address: clientValue?.address || '',
            time_zone: clientValue?.currency[0]?.time_zone || '',
            role: clientValue?.role || '',
            last_login: clientValue?.last_login || '',
            currency_symbol: clientValue?.currency[0]?.currency_symbol || '',
            currency: clientValue?.currency[0]?.currency || '',
            country: clientValue?.country || '',
            remarks: clientValue?.remarks || '',
            user_id: clientValue?.id,
            competitor_analysis:clientValue?.competitor_analysis,
            sales_analysis:clientValue?.sales_analysis,
            db_connection_string:clientValue?.db_connection_string
          }}
          validationSchema={validationSchema}
          onSubmit={handleUpdate}
        >
          <Form>
            <Grid mt={1} container spacing={2}>
              {/* Form fields */}
              <Grid item xs={6}>
                <Field validate={validateEmail} size="small" sx={inputBoxStyle} name="email" as={TextField} label="Email" fullWidth />
                <ErrorMessage name="email" component="div" className={classes.errorMessage} />
              </Grid>
              <Grid item xs={6}>
                <Field validate={validateCompanyName} size="small" sx={inputBoxStyle} name="company_name" as={TextField} label="Company Name" fullWidth />
                <ErrorMessage name="company_name" component="div" className={classes.errorMessage} />
              </Grid>
              <Grid item xs={6}>
                <Field size="small" sx={inputBoxStyle} name="first_name" as={TextField} label="First Name" fullWidth />
                <ErrorMessage name="first_name" component="div" className={classes.errorMessage} />
              </Grid>
              <Grid item xs={6}>
      <Field size="small" sx={inputBoxStyle} name="last_name" as={TextField} label="Last Name" fullWidth />
    </Grid>
    <Grid item xs={6}>
      <Field validate={validatePhoneNumber} size="small" sx={inputBoxStyle} name="phone_number" as={TextField} label="Phone" fullWidth />
      <ErrorMessage name="phone_number" component="div" className={classes.errorMessage} />
    </Grid>
    <Grid item xs={6}>
      <Field size="small" sx={inputBoxStyle} name="Industry" as={TextField} label="Industry" fullWidth />
      <ErrorMessage name="Industry" component="div" className={classes.errorMessage} />
    </Grid>
    <Grid item xs={6}>
      <Field size="small" sx={inputBoxStyle} name="created_date" as={TextField} label="Created Date " fullWidth InputProps={{readOnly: true,}} />
      {/* <ErrorMessage name="created_date" component="div" className={classes.errorMessage} /> */}
    </Grid>
    <Grid item xs={6}>
      <Field validate={validateDbName} size="small" sx={inputBoxStyle} name="db_name" as={TextField} label="DB Name" fullWidth />
      <ErrorMessage name="db_name" component="div" className={classes.errorMessage} />
    </Grid>
    <Grid item xs={6}>
      <Field size="small" sx={inputBoxStyle} name="address" as={TextField} label="Address" fullWidth />
      <ErrorMessage name="address" component="div" className={classes.errorMessage} />
    </Grid>
    <Grid item xs={6}>
      <Field size="small" sx={inputBoxStyle} name="time_zone" as={TextField} label="Time Zone" fullWidth />
      <ErrorMessage name="time_zone" component="div" className={classes.errorMessage} />
    </Grid>
    <Grid item xs={6}>
      <Field size="small" sx={inputBoxStyle} name="country" as={TextField} label="Country" fullWidth />
    </Grid>
    <Grid item xs={6}>
      <Field size="small" sx={inputBoxStyle} name="no_of_produts_allowed" as={TextField} label="No of Produts Allowed" fullWidth />
    <ErrorMessage name="no_of_produts_allowed" component="div" className={classes.errorMessage} />
    </Grid>
    {/* <Grid item xs={6}>
      <Field size="small" sx={inputBoxStyle} name="no_of_produts_exist" as={TextField} label="No of Produts Exist" fullWidth />
    </Grid> */}
    <Grid item xs={6}>
      <Field size="small" sx={inputBoxStyle} name="no_of_mp_allowed" as={TextField} label="No of MP Allowed" fullWidth />
    <ErrorMessage name="no_of_mp_allowed" component="div" className={classes.errorMessage} />
    </Grid>
    {/* <Grid item xs={6}>
      <Field size="small" sx={inputBoxStyle} name="no_of_mp_exist" as={TextField} label="No of MP Exist" fullWidth />
    </Grid> */}
    <Grid item xs={6}>
      <Field size="small" sx={inputBoxStyle} name="currency_symbol" as={TextField} label="Curency Symbol" fullWidth />
    <ErrorMessage name="currency_symbol" component="div" className={classes.errorMessage} />
    </Grid>
    <Grid item xs={6}>
      <Field size="small" sx={inputBoxStyle} name="currency" as={TextField} label="Currency" fullWidth />
    <ErrorMessage name="currency" component="div" className={classes.errorMessage} />
    </Grid>
    <Grid item xs={6}>
      <Field size="small" sx={inputBoxStyle} name="remarks" as={TextField} label="Remarks" fullWidth />
    <ErrorMessage name="remarks" component="div" className={classes.errorMessage} />
    </Grid>
    <Grid item xs={6}>
      <FormControl fullWidth className={classes.inputField}>
        <InputLabel id="status-label">Status</InputLabel>
        <Field size="small" name="status" as={Select} labelId="status-label" label="Status">
          <MenuItem value={true}>Active</MenuItem>
          <MenuItem value={false}>Inactive</MenuItem>
        </Field>
      </FormControl>
    </Grid>
    <Grid item xs={6}>
      <FormControl fullWidth className={classes.inputField}>
        <InputLabel id="competitor_analysis-label">Competitor Analysis</InputLabel>
        <Field size="small" name="competitor_analysis" as={Select} labelId="competitor_analysis-label" label="Competitor Analysis">
          <MenuItem value={true}>Allowed</MenuItem>
          <MenuItem value={false}>Not-Allowed</MenuItem>
        </Field>
      </FormControl>
    </Grid>
    <Grid item xs={6}>
      <FormControl fullWidth className={classes.inputField}>
        <InputLabel id="sales_analysis-label">Sales Analysis</InputLabel>
        <Field size="small" name="sales_analysis" as={Select} labelId="sales_analysis-label" label="Sales Analysis">
          <MenuItem value={true}>Allowed</MenuItem>
          <MenuItem value={false}>Not-Allowed</MenuItem>
        </Field>
      </FormControl>
    </Grid>
              
            </Grid>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '1em' }}>
              <Button variant="contained" type="submit">
                Update
              </Button>
            </div>
          </Form>
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

export default AdminEditBox;
