import React, { useState,useEffect } from 'react';
import { Switch } from '@mui/material';
import { useDispatch,useSelector } from 'react-redux';
import selectPrdKeyvalueDetails from '../../redux/selectors/productKeyvalue.selector';

import { getProductKeyValue } from '../../redux/actions/productKeyvalue.actions';


const KeyValue = (props) => {
  const { item } = props;
  const {prd_id,prd_is_key_value} = item
  const [isChecked, setIsChecked] = useState(prd_is_key_value === true);
  
  const dispatch = useDispatch()
  const {isSuccessful} = useSelector(selectPrdKeyvalueDetails)

  const handleSwitchChange = (prd_id) => {
    setIsChecked(!isChecked);
    dispatch(getProductKeyValue(prd_id))
  };
  useEffect(() => {
    if(isSuccessful === true){
      window.location.reload()
    }
  }, [isSuccessful])
  
  return (
    <Switch
      size="small"
      checked={isChecked}
      onChange={()=>handleSwitchChange(prd_id)}
      color="primary" 
    />
  );
};

export default KeyValue;
