import React, { useState, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, NavLink as RouterLink, useLocation } from 'react-router-dom';
import { Container, Button, IconButton, Popover, Badge, List, ListItem, ListItemButton, ListItemText, Typography, FormControlLabel, Switch, Divider } from '@mui/material';

import Grid from '@mui/material/Unstable_Grid2';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
import IdleTimer from '../../components/idleTimer';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
// import QueryStatsIcon from '@mui/icons-material/QueryStats';

import * as actions from '../../redux/actions/authentication.actions';
import * as dashboardActions from '../../redux/actions/dashboard.actions'
import * as matchRateActions from '../../redux/actions/matchrate.actions'
import * as productDetailsAction from '../../redux/actions/productDetails.actions'
import * as marketActions from '../../redux/actions/marketplace.actions'
import * as locationAction from '../../redux/actions/locations.actions'
import * as marketLocActions from '../../redux/actions/marketlocation.actions'
import * as qaActions from '../../redux/actions/qaUser.actions';

import { getAppNotification } from '../../redux/actions/getappnotification.action';
import selectGetappNotification from '../../redux/selectors/getappnotification.selector';

import { getItem, removeItems } from '../../utils/storage';
import { makeStyles, } from '@mui/styles';
import { CustomThemeContext } from '../../theme/CustomThemeProvider'
// import Logo from '../../components/Logo';

import { } from './header.class.css';
import HeaderLogo from './headerLogo';

const Header = props => {
    const { setThemeModeHandler, } = props;
    const { currentTheme, setTheme } = useContext(CustomThemeContext)
    const locations = useLocation();
    const { pathname } = locations;

    const dispatch = useDispatch();
    const classes = useStyles()
    const navigate = useNavigate();
    const userToken = getItem('token')
    const userRole = getItem('role');
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const appNotificationData = useSelector(selectGetappNotification);

    useEffect(() => {
        // Dispatch action to fetch appNotification data
        if (open === true) {
            dispatch(getAppNotification());
        }
    }, [open]); // eslint-disable-line

    useEffect(() => {
        if (pathname === '/') {
            if (!userToken) {
                navigate('/login');
            } else {
                navigate('/dashboard');
            }
        }
        if (!userToken) {
            navigate('/login');
        }
    }, [userToken, navigate, pathname])

    const handlePopClick = (e) => {
        setAnchorEl(e.currentTarget);
    }
    const handleClose = () => {
        setAnchorEl(null);
    };
    // console.log('header ', pathname);
    const openListHandler = () => {
        navigate('/notifications')
    }
    const handleLogout = () => {
        dispatch(locationAction.getLocationsReset())
        dispatch(productDetailsAction.productTotalReset())
        dispatch(marketLocActions.marketLocationReset())
        dispatch(marketActions.getMarketPlaceReset())
        dispatch(matchRateActions.matchrateReset())
        dispatch(dashboardActions.dashboardReset())
        dispatch(qaActions.qaUserReset())
        dispatch(actions.resetAuthState())
        removeItems(['token', 'currency', 'role', 'qaUser', 'pageList', 'username', 'activeMarketPlace', 'marketLocation', 'searchKeyword']);
        navigate('/login')
    }
    const changeThemeHandler = () => {
        if (currentTheme === 'light') {
            setThemeModeHandler("dark");
            setTheme('dark')
        } else {
            setThemeModeHandler("light");
            setTheme('light')
        }
    }

    return (
        <Container component="header" className={['headerClasses', classes.backgroundColor].join(' ')}>
            <nav className="nav">
                <Button component={Link} to='/dashboard' sx={{ width: '14%' }} >
                    {/* <Logo /> */}
                    <HeaderLogo userRole={userRole} />
                </Button>
                <Grid Container className='navContainer'>
                    {userToken && <>
                        <RouterLink id='dashboard' className={`navLink ${currentTheme}navLink ${currentTheme}-dashboard`} to='/dashboard'>
                            <span>Dashboard</span>
                        </RouterLink>
                        <RouterLink id='myproducts' className={`navLink ${currentTheme}navLink ${currentTheme}-myproduct`} to='/myproducts'>
                            <span style={{ marginTop: 2 }}>My Products</span>
                        </RouterLink>
                        <RouterLink id='product' className={`navLink ${currentTheme}navLink ${currentTheme}-addproduct`} to='/addproduct'>
                            {/* <img src='./assets/productadd-icon.png' alt='Add Product' /> */}
                            <span style={{ marginTop: 2 }}>Add Product</span>
                        </RouterLink>
                        <RouterLink id='reports' className={`navLink ${currentTheme}navLink ${currentTheme}-report`} to='/reports'>
                            {/* <img src='./assets/report-icon.png' alt='Report' /> */}
                            <span style={{ marginTop: 2 }}>Report</span>
                        </RouterLink>
                        {/* <RouterLink  id='mysales' className={`navLink ${currentTheme}navLink ${currentTheme}-mysales`} to='/mysales'>
                        <QueryStatsIcon />
                        <span style={{marginTop:2,marginLeft:"1px"}}>My Sales</span>
                    </RouterLink> */}
                        {userRole === 1 && (
                            <RouterLink id='clients' className={`navLink ${currentTheme}navLink ${currentTheme}-client`} to='/clients'>
                                <PeopleOutlinedIcon /> {/* This adds the icon */}
                                <span style={{ marginTop: 2 }}>Clients</span>
                            </RouterLink>
                        )}

                        {/* <RouterLink id='dashboard' className={`navLink ${currentTheme}navLink ${currentTheme}-contact`} to='/contact'>
                        
                        <span style={{marginTop:2}}>Contact Us</span>
                    </RouterLink> */}
                    </>
                    }
                </Grid>
                <div className='rightNav'>
                    {userToken ? <>
                        <IconButton component={Link} className='navLink' sx={{ borderRadius: '0 !important', padding: 0.2, mr: 1, minWidth: 'auto !important' }}
                            onClick={changeThemeHandler}>
                            {/* {currentTheme === "light" ? <Brightness5OutlinedIcon /> : <Brightness4OutlinedIcon />} */}
                            {currentTheme === "light" ? <img src='/assets/dark.svg' alt='dark' style={{ width: 20 }} /> : <img src='/assets/light.svg' alt='light' style={{ width: 20 }} />}
                            {/* <SettingsBrightnessRoundedIcon sx={{ fontSize:24, color: currentTheme === 'light' ? '#232D3B' : '#D9D9D9' }} /> */}
                        </IconButton>
                        {userRole !== 2 && <IconButton component={Link} className='navLink' sx={{ borderRadius: '0 !important', padding: 0.2, minWidth: 'auto !important' }} to='/settings'>
                            <SettingsIcon sx={{ fontSize: 18, color: 'text.secondary' }} />
                        </IconButton>}
                        <IconButton component={Button} className='navLink' sx={{ mr: '0px !important', borderRadius: '0 !important', minWidth: 'auto !important' }}
                            onClick={handlePopClick}
                            aria-describedby={id}
                        >
                            <Badge>
                                <NotificationsIcon sx={{ color: 'text.secondary' }} />
                            </Badge>
                        </IconButton>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            sx={{ width: '400px', height: '400px' }}
                        >
                            <Grid item sx={{ px: 2, py: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant='h5' component={'h5'}>Notification</Typography>
                                <FormControlLabel
                                    control={
                                        <Switch />
                                    }
                                    label="Do not disturb" labelPlacement="start"
                                />
                            </Grid>
                            <Divider />
                            <List className='custom-scrollbar' sx={{ overflowY: 'auto', overflowX: 'hidden', pb: 0, pt: 0, width: '500px', maxHeight: '200px', height: 'auto' }}>
                                {
                                    appNotificationData?.loading ? (
                                        <ListItem sx={{ marginLeft: "24%" }}>
                                            <ListItemText primary="Loading..." />
                                        </ListItem>
                                    ) : appNotificationData?.data?.length > 0 ? (
                                        appNotificationData?.data
                                            ?.slice(0, 15)
                                            .map((obj) => (
                                                <ListItem key={obj?.notification_id} sx={{ px: 2, py: 0 }}>
                                                    <ListItemButton sx={{ p: 1, px: 1 }}>
                                                        <ListItemText primary={obj?.notification} />
                                                    </ListItemButton>
                                                </ListItem>
                                            ))
                                    ) : (
                                        <ListItem sx={{ marginLeft: "24%" }}>
                                            <ListItemText primary="No notifications" />
                                        </ListItem>
                                    )
                                }


                            </List>
                            <Divider />
                            <List sx={{ pb: 0, pt: 0, width: '500px' }}>
                                <ListItem sx={{ p: 1 }}>
                                    <ListItemButton sx={{ p: 1, px: 2 }}>
                                        <ListItemText primary="View All" variant='text' onClick={openListHandler} />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </Popover>
                        <Button variant='text' className={`navLink ${currentTheme}navLink ${currentTheme}-login`} onClick={handleLogout}>Logout</Button>
                        <IdleTimer handleLogout={handleLogout} />
                        {/* <IconButton component={Link} to='/login' sx={{paddingRight:0, mr:'0px !important', borderRadius:'0 !important', minWidth:'auto !important'}}>
                        <Avatar className='headerAvatar' variant="rounded" src='./assets/avatarImg.png' />
                    </IconButton> */}
                    </>
                        :
                        ''
                        // <Link className={`navLink ${currentTheme}navLink ${currentTheme}-login`} to='/login'>
                        //     {/* <img src='./assets/contact-icon.png' alt='Contact Us' /> */}
                        //     <span style={{marginTop:17}}>Login</span>
                        // </Link>

                    }
                </div>

            </nav>


        </Container>
    )
}

const useStyles = makeStyles((theme) => ({

    backgroundColor: {
        backgroundColor: theme.palette.background.paper,
    },

}))

export default Header;