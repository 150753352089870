import { call, put, takeLatest  } from 'redux-saga/effects';  
import NetworkApi from '../../api/NetworkApi';
import { getItem, getItems } from '../../utils/storage';
import paths from '../../api/path';
import { getProductKeyValueSuccessful,getProductKeyValue,getProductKeyValueFailed } from '../actions/productKeyvalue.actions';
import { PRODUCT_KEYVALUE,PRODUCT_KEYVALUE_FAILED,PRODUCT_KEYVALUE_SUCCESSFUL } from '../constants/productkeyvalue.constants';


const getApiConfig = () => {
    const [
      token
    ] = getItems([
      'token',
    ]);
  
    const headers = {
      Authorization: `token ${token}`,
    };
  
    return {
      // headers,
      Authorization: headers.Authorization
    }
  }

function* setProductKeyValue(action){
    const apiConfig = getApiConfig();
    const userRole = getItem('role');
    // const prd_id = action.payload
    let payload = {
        prd_id:action.payload
    }
    
    try {
        const response = yield call(NetworkApi.put,paths.setPrdKeyvalue, payload, apiConfig);
        yield put(getProductKeyValueSuccessful(response));
    } catch (error) {
        yield put(getProductKeyValueFailed(error));
    }

}

export function* watchPrdKeyvalue(){
    yield takeLatest(PRODUCT_KEYVALUE,setProductKeyValue)
}