import * as constants from '../constants/clientMarketCount.constants'

export const initialState = {
  loading: false,
  marketCount: [],
  isError: null,
};

export const clientsMarketCountReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_CLIENTS_MARKETCOUNT:
      return {
        ...state,
        loading: true,
      };
    case constants.GET_CLIENTS_MARKETCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        marketCount: action.payload,
        isError: null,
      };
    case constants.GET_CLIENTS_MARKETCOUNT_FAIL:
      return {
        ...state,
        loading: false,
        isError: action.payload,
      };
    default:
      return state;
  }
};
