import { call, put, takeLatest  } from 'redux-saga/effects';  //takeLatest
// import { isEmpty, } from 'lodash';
import * as actions from '../actions/competitors.actions';
import * as constants from '../constants/competitors.constants';
import NetworkApi from '../../api/NetworkApi';
import { getItems } from '../../utils/storage';
import paths from '../../api/path';

const getApiConfig = () => {
  const [
    token
  ] = getItems([
    'token',
  ]);

  
  return {
    Authorization: `token ${token}`
  }
}


export function* getCompetitors(request = {}) {
  const apiConfig = getApiConfig()
  const { payload = {} } = request;
  const { productId:prd_id } = payload;
  // console.log('getCompetitors', payload) 
  try {
    const response = yield call(NetworkApi.post, paths.getProductCompetitorURL, {prd_id}, apiConfig);
    // console.log('competitors response', response);
    if (response) {
      if(!response.details){
        yield put(actions.getCompetitorsSuccessful(response));
      } else {
        yield put(actions.getCompetitorsFailed(response.details));
      }
    } else {
      // console.log('error ', response)
      const message = response? (
        response
      ) : (
        ''
        // <FormattedMessage id="notProcess" />
      );
      yield put(actions.getCompetitorsFailed(message));
    }

  } catch (error) {
    const message = error?.response?.detail ? (
      error.response.detail
    ) : (
      ''
      // <FormattedMessage id="notProcess" />
    );
    yield put(actions.getCompetitorsFailed(message));
  }
}


export function* fetchCompetitors() {
  // console.log('fetchCompett')
  yield takeLatest(constants.VALIDATE_COMPETITOR, getCompetitors);
}
