import React, { useState, useEffect } from 'react';
import { Container, Grid, Typography, Box, Stack, Paper, IconButton, } from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { styled } from '@mui/material/styles'
import { isEmpty } from 'lodash';

import CompModal from '../../components/Modal';
import { getItem } from '../../utils/storage';
import { isUndefined, isNull } from 'lodash';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';


const BrandProductPop = props => {

  const { productInformation } = props;
  const { isVisible, brand, productInfo } = productInformation;
  const [value, setValue] = useState('1');
  const [currency, setCurrency] = useState(
    { 'currency_symbol': '$' }
  );
  const [themeName, setThemeName] = useState("");
  useEffect(() => {
    const storedTheme = localStorage.getItem("appTheme");
    setThemeName(storedTheme);
  }, []);
  const Item = styled(Paper)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#232D3B',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    paddingLeft: 0,
    paddingRight: 0,
    textAlign: 'center',
    // color: '#FFFFFF',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: 'none'
  }));

  const Tabe = styled(Tab)(({ theme }) => {
    return {
      '.MuiTab-root': {
        '& .Mui-selected': {
          backgroundColor: '#559EFF',
          color: '#0E2B5A'
        }
      }
    }
  })

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleBrandClose = () => {
    props.handleClose();
  }

  useEffect(() => {
    const userCurrency = !isNull(getItem('currency')) && !isUndefined(getItem('currency')) ? getItem('currency').length !== undefined ? typeof (getItem('currency')) === 'string' ? JSON.parse(getItem('currency')) : getItem('currency') : JSON.parse(getItem('currency')) : [{ 'currency_symbol': '$' }]
    setCurrency(userCurrency[0]);
  }, [])

  useEffect(() => {
    if(productInfo.filteredMarket){
      const indx = productInfo.filteredMarket.findIndex(item => item === brand);
      setValue(Number(indx + 1).toString())
    }
  }, [brand]) // eslint-disable-line

  // console.log("productInfo",productInfo);
  return (
    <>
      <CompModal {...props} modalOpen={isVisible} width={800}>
        <Container style={{ width: 750, paddingLeft: '0px', paddingRight: '0px' }}>
          <Grid item sx={{ float: 'right', position: 'absolute', top: 3, right: 3 }}>
            <IconButton onClick={handleBrandClose}  ><CloseRoundedIcon /> </IconButton>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <img src={productInfo.prd_medium_image} alt={productInfo?.prd_name} style={{ width: '100%', maxWidth: 55, maxHeight: 70, height: 'auto', left: 0, top: 0, transform: "none" }} />
            <Typography variant='h5' component={'h5'} sx={{ ml: 1, fontSize: themeName === "dark" ? "0.6875rem" : "0.625rem", width: 350, textAlign: 'left' }}>{productInfo?.prd_name}</Typography>
            <Typography variant='h5' component={'h5'} sx={{ ml: 1, fontSize: themeName === "dark" ? "0.6875rem" : "0.625rem", width: 100 }}>{!isEmpty(currency) ? currency.currency_symbol ? currency.currency_symbol : currency.currency : '$'} {productInfo?.activeLocation[0]?.prd_sales_price?.toFixed(2)}</Typography>
            <Typography variant='body2' sx={{ fontSize: themeName === "dark" ? "0.6875rem" : "0.625rem", width: 180 }}>{!isEmpty(currency) ? currency.currency_symbol ? currency.currency_symbol : currency.currency : '$'} {Number(productInfo?.activeLocation[0]?.prd_norm_price)?.toFixed(2)}/{productInfo?.prd_uom}</Typography>
          </Grid>
          <Grid>
            <TabContext value={value}>
              {productInfo.filteredMarket && <>
                <Box sx={{ borderBottom: 2, borderColor: '#515151' }}>
                  <TabList onChange={handleChange} aria-label="lab API tabs example">
                    {productInfo.filteredMarket.map((item, index) => {
                      return <Tabe key={`tab_${index}`}
                        sx={{ color: 'text.secondary', border: 'none', fontSize: themeName === "dark" ? "0.6875rem" : "0.625rem" }}
                        label={item} value={`${Number(index + 1)}`}
                      />
                    })}
                  </TabList>
                </Box>
                {productInfo.filteredMarket.map((compet, index) => {
                  const arrBuyBox = [];
                  const arrOthers = [];
                  return (
                    <TabPanel key={`competitor_${index}`} value={`${Number(index + 1)}`} sx={{ px: 0, py: 1, maxHeight: 200 }}>
                      <Stack spacing={0} xs={10} className='custom-scrollbar' sx={{ marginTop: 0, maxHeight: 200, overflowY: 'auto', overflowX: 'hidden' }}>
                        {productInfo.competitors
                          .filter(item => String(item.market_place_name).toLowerCase() === String(compet).toLowerCase() && Number(item?.comp_prd_sales_price) > 0)
                          .sort((a, b) => {
                            const scoreComparison = (b?.comp_prd_score || 0) - (a?.comp_prd_score || 0);
                            if (scoreComparison !== 0) {
                              return scoreComparison;
                            }
                            return b?.comp_prd_sales_price - a?.comp_prd_sales_price;
                          })
                          // .sort((a, b) => {
                          //   // Compare based on comp_prd_score
                          //   const scoreComparison = (b?.comp_prd_score || 0) - (a?.comp_prd_score || 0);
                          //   if (scoreComparison !== 0) {
                          //     return scoreComparison;
                          //   } else {
                          //     // If comp_prd_score is the same, compare based on the product of comp_prd_size and comp_prd_no_of_pieces
                          //     const aValue = Number(a.comp_prd_size) * Number(a.comp_prd_no_of_pieces);
                          //     const bValue = Number(b.comp_prd_size) * Number(b.comp_prd_no_of_pieces);

                          //     if (bValue !== aValue) {
                          //       return bValue - aValue;
                          //     } else {
                          //       // If both comp_prd_score and combined value are the same,
                          //       // compare based on comp_prd_sales_price
                          //       return b?.comp_prd_sales_price - a?.comp_prd_sales_price;
                          //     }
                          //   }
                          // })
                          .forEach(item => {
                            if (item.comp_prd_custom_field === "BUY BOX") {
                              arrBuyBox.push(item);
                            } else {
                              arrOthers.push(item);
                            }
                          })}
                        {arrBuyBox.map(item => {
                          const matchRate = item?.comp_prd_score ? `${item?.comp_prd_score} %` : 'N/A';
                          return (
                            <Item key={`competitor${item.comp_prd_id}`} sx={{}}>
                              <img src={item.comp_prd_medium_image} alt={item?.comp_prd_name} style={{ width: '100%', maxWidth: 55, maxHeight: 70, height: 'auto', left: 0, top: 0, transform: "none" }} />
                              <Typography variant='h5' component={'h5'} sx={{ ml: 1, fontSize: themeName === "dark" ? "0.6875rem" : "0.625rem", width: 350, textAlign: 'left' }}>{item?.comp_prd_name}</Typography>
                              <Typography variant='h5' component={'h5'} sx={{ ml: 1, fontSize: themeName === "dark" ? "0.6875rem" : "0.625rem", width: 100, textAlign: 'left' }}>{!isEmpty(currency) ? currency.currency_symbol ? currency.currency_symbol : currency.currency : '$'} {item?.comp_prd_sales_price?.toFixed(2)}</Typography>
                              <Typography variant='body2' sx={{ fontSize: themeName === "dark" ? "0.6875rem" : "0.625rem", width: 100 }}>
                                {!isEmpty(currency) ? currency.currency_symbol ? currency.currency_symbol : currency.currency : '$'} {String(item?.comp_prd_norm_price)}
                                {String(item?.comp_prd_norm_price)?.includes("/") ? '' : `/ ${item?.comp_prd_uom}`}
                              </Typography>

                              <Typography variant='body2' sx={{ fontSize: themeName === "dark" ? "0.6875rem" : "0.625rem", width: 80 }}>{matchRate}</Typography>
                            </Item>
                          );
                        })
                        }
                        {arrOthers.map(item => {
                          const matchRate = item?.comp_prd_score ? `${item?.comp_prd_score} %` : 'N/A';
                          return (
                            <Item key={`competitor${item.comp_prd_id}`} sx={{}}>
                              <img src={item.comp_prd_medium_image} alt={item?.comp_prd_name} style={{ width: '100%', maxWidth: 55, maxHeight: 70, height: 'auto', left: 0, top: 0, transform: "none" }} />
                              <Typography variant='h5' component={'h5'} sx={{ ml: 1, fontSize: themeName === "dark" ? "0.6875rem" : "0.625rem", width: 350, textAlign: 'left' }}>{item?.comp_prd_name}</Typography>
                              <Typography variant='h5' component={'h5'} sx={{ ml: 1, fontSize: themeName === "dark" ? "0.6875rem" : "0.625rem", width: 100, textAlign: 'left' }}>{!isEmpty(currency) ? currency.currency_symbol ? currency.currency_symbol : currency.currency : '$'} {item?.comp_prd_sales_price?.toFixed(2)}</Typography>
                              <Typography variant='body2' sx={{ fontSize: themeName === "dark" ? "0.6875rem" : "0.625rem", width: 100 }}>
                                {!isEmpty(currency) ? currency.currency_symbol ? currency.currency_symbol : currency.currency : '$'} {String(item?.comp_prd_norm_price)}
                                {String(item?.comp_prd_norm_price)?.includes("/") ? '' : `/ ${item?.comp_prd_uom}`}
                              </Typography>

                              <Typography variant='body2' sx={{ fontSize: themeName === "dark" ? "0.6875rem" : "0.625rem", width: 80 }}>{matchRate}</Typography>
                            </Item>
                          );
                        })
                        }
                      </Stack>
                    </TabPanel>
                  )
                })}
              </>}
            </TabContext>
          </Grid>

        </Container>


      </CompModal>

    </>
  )
}

export default BrandProductPop;