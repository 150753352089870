import { call, put, takeLatest  } from 'redux-saga/effects';  
import NetworkApi from '../../api/NetworkApi';
import { getItem, getItems } from '../../utils/storage';
import paths from '../../api/path';
import { DELETE_MARKET_PLACE_FULL,DELETE_MARKET_PLACE_FULL_FAILED,DELETE_MARKET_PLACE_FULL_SUCCESSFUL } from '../constants/deleteMarketplacefull.constants';
import { deleteMarketPlaceFull,deleteMarketPlaceSuccessfulFull,deleteMarketPlaceFailedFull } from '../actions/deleteMarketplacefull.actions';

const getApiConfig = () => {
    const [
      token
    ] = getItems([
      'token',
    ]);
  
    const headers = {
      Authorization: `token ${token}`,
    };
  
    return {
      // headers,
      Authorization: headers.Authorization
    }
  }

export function* deleteMarketPlaceFullSettings(action){
    const apiConfig = getApiConfig();
    const userRole = getItem('role');
    const payload = action.payload
    try {
        const response = yield call(NetworkApi.delete, paths.deletemarketplaceFull_URL, payload,apiConfig, );
        yield put(deleteMarketPlaceSuccessfulFull(response));
    } catch (error) {
       yield put(deleteMarketPlaceFailedFull(error))
    }
}


export function* watchMarketPlaceDeleteFull() {
    yield takeLatest(DELETE_MARKET_PLACE_FULL, deleteMarketPlaceFullSettings);
  }