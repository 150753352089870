import React, {useState} from 'react';

import { Container, Grid, Typography} from '@mui/material';
import LeftPanel from './LeftPanel';
import SingleProduct from './SingleProduct';
import BulkUpload from './BulkProduct';



const AddProduct = () => {

    const [productLabel, setProductLabel] = useState('bulk')
    
 
    return (
        <Container maxWidth={'xl'} className='maincontainer' sx={{height:'auto', px:'0px !important', marginTop:0}}>
            <Grid container spacing={0} sx={{display:'flex'}}>
                <LeftPanel productLabel={productLabel} setProduct={setProductLabel}/>
                <Grid item xs={10}>
                    <Grid item xs={12} sx={{ml:2, mt:2, backgroundColor:'background.paper', display:'flex', flexFlow:'column', justifyContent:'center', p:5}}>
                        <Typography variant='h4' component={'h4'} sx={{fontSize:15, textAlign:'center', color:'text.secondary', fontWeight:700}}>Add Product</Typography>

                    { productLabel === 'single' ?
                        <SingleProduct />
                     :
                     <BulkUpload />
                    }
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )


}

export default AddProduct;