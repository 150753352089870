import React, { useEffect, useState } from 'react';
import { Grid, Typography, Switch, Select, Checkbox, InputBase, Button, FormControlLabel, FormGroup, MenuItem, TextField, InputAdornment } from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import PercentIcon from '@mui/icons-material/Percent';
import Box from '@mui/material/Box';
import { useDispatch,useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { postpricecahgneNotification } from '../../redux/actions/priceChangeNotification.action';
import selectPriceChangeNotificationReducerDetails from '../../redux/selectors/pricechangeNotification.selector';

const NotificationSettings = (props) => {
  const dispatch = useDispatch();
  const { currentTheme, headingStyle } = props;
  const [notificationShow, setNotificationShow] = useState(false);
  const [priceIsChecked, setPriceIsChecked] = useState(false);
  const [selectedFrequency, setSelectedFrequency] = useState('2');
  const [thresholdLimit, setThresholdLimit] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [email, setEmail] = useState('');
  const [successMessage,setSuccessMessage] = useState(false)
  const {loading,data,error} = useSelector(selectPriceChangeNotificationReducerDetails)
  
// console.log(loading,data,error)
useEffect(() => {
  if (data?.notification_type === "True") {
    setSuccessMessage(true);
    setTimeout(() => {
      setSuccessMessage(false);
    }, 2000);
  }
}, [loading]);
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(2),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: 'transparent',
      border: '1px solid #ced4da',
      fontSize: 11,
      width: '100%',
      padding: '10px 12px',
      color: theme.palette.text.primary,
      transition: theme.transitions.create(['border-color', 'background-color']),
      fontFamily: 'Inter',
      '&:focus': {
        borderColor: theme.palette.primary.main,
      },
    },
  }));

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 50,
    height: 22,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 20,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(27px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 20,
      height: 20,
      borderRadius: 10,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 30 / 2,
      opacity: 1,
      backgroundColor:
        currentTheme === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }));

  const checkedIcons = () => {
    return (
      <CheckIcon
        sx={{
          color: 'text.primary',
          fontSize: 14,
          borderRadius: 1,
          border: '1px solid',
          borderColor: 'text.primary',
          marginRight: 0.5,
          backgroundColor: 'background.default',
        }}
      />
    );
  };

  const uncheckedIcons = () => {
    return (
      <CheckIcon
        sx={{
          color: 'transparent',
          fontSize: 14,
          borderRadius: 1,
          border: '1px solid #FFFFFF',
          borderColor: 'text.primary',
          marginRight: 0.5,
          backgroundColor: 'none',
        }}
      />
    );
  };

  const validationSchema = Yup.object().shape({
    frequency: Yup.string().required('Frequency is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    notification_type: Yup.boolean().oneOf([true], 'You must select the notification type').required('Notification Type is required'),
    threshold_limit: Yup.number().typeError('Threshold Limit must be a number').required('Threshold Limit is required').min(0, 'Threshold Limit must be greater than or equal to 0'),
  });

  const formik = useFormik({
    initialValues: {
      frequency: selectedFrequency,
      email: email,
      notification_type: priceIsChecked,
      threshold_limit: thresholdLimit,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(postpricecahgneNotification(values));
      formik.resetForm(); // Reset the form values to their initial state
    },
  });
  const handleNotificationChange = () => {
    setNotificationShow((prevState) => !prevState);
  };

  const handleFrequencyChange = (event) => {
    setSelectedFrequency(event.target.value);
  };

  const handlePriceCheckboxChange = (event) => {
    setPriceIsChecked(event.target.checked);
  };

  const handleThresholdLimitChange = (event) => {
    setThresholdLimit(event.target.value);
  };

  // const handleButtonClick = () => {
  //   const notificationData = {
  //     frequency: selectedFrequency,
  //     email: email,
  //     notification_type: priceIsChecked && 'Price Change',
  //     threshold_limit: thresholdLimit,
  //   };
  //   dispatch(postpricecahgneNotification(notificationData));
  // };

  const handleInputBlur = () => {
    setIsEditing(false);
  };

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <>
      <Typography variant="h4" component={'h4'} className="typo headingText" sx={headingStyle}>
        Notification Settings
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid item xs={12} sx={{ pl: 3, pt: 5 }}>
          <Grid item sx={{ display: 'flex', mb: 2, alignItems: 'center' }}>
            <Grid item xs={12} sm={4}>
              <Typography variant="body2" sx={{}}>
                Notification
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" sx={{}}>
                Off
              </Typography>
              <AntSwitch defaultChecked sx={{ ml: 2, mr: 2 }} checked={notificationShow} onChange={handleNotificationChange} inputProps={{ 'aria-label': 'ant design' }} />
              <Typography variant="body2" sx={{}}>
                On
              </Typography>
            </Grid>
          </Grid>
          {notificationShow && (
            <>
              <Grid item sx={{ display: 'flex', mb: 2, alignItems: 'center' }}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2" sx={{}}>
                    Frequency
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Select
                    size="small"
                    variant="outlined"
                    name="frequency"
                    value={formik.values.frequency}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    input={<BootstrapInput sx={{ marginTop: '0px !important' }} />}
                    sx={{ width: 200, fontSize: 11, fontFamily: 'Inter', color: '#E4E3E3', fontWeight: 400 }}
                  >
                    <MenuItem sx={{ fontSize: 11 }} value={'1'}>
                      Send email instantly
                    </MenuItem>
                  <MenuItem sx={{ fontSize: 11 }} value={'2'}>
                    Send email once a day
                  </MenuItem>
                  <MenuItem sx={{ fontSize: 11 }} value={'3'}>
                    Send email once a week
                  </MenuItem>
                  <MenuItem sx={{ fontSize: 11 }} value={'4'}>
                    Send email once a month
                  </MenuItem>
                  <MenuItem sx={{ fontSize: 11 }} value={'5'}>
                    Do not send email at all
                  </MenuItem>
                </Select>
                {formik.touched.frequency && formik.errors.frequency ? (
                    <div style={{ color: 'red' }}>{formik.errors.frequency}</div>
                  ) : null}
                </Grid>
              </Grid>
              <Grid item sx={{ display: 'flex', mb: 2, alignItems: 'center' }}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2" sx={{}}>
                    Email
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    type="email"
                    name="email"
                    value={formik.values.email}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    sx={{
                      width: '48%',
                      '& .MuiOutlinedInput-input': {
                        padding: '5px 8px',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: currentTheme === 'light' ? '2px solid black' : '2px solid white',
                        },
                      },
                    }}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div style={{ color: 'red' }}>{formik.errors.email}</div>
                  ) : null}
                </Grid>
              </Grid>
              <Grid item sx={{ display: 'flex' }}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2" sx={{}}>
                    Notification Type
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <FormGroup>
                    <FormControlLabel
                      disableRipple
                      control={<Checkbox checked={formik.values.notification_type} onChange={formik.handleChange} name="notification_type" />}
                      label={<Typography variant="body2" sx={{}}>Price Change</Typography>}
                    />
                  </FormGroup>
                  {formik.touched.notification_type && formik.errors.notification_type ? (
                    <div style={{ color: 'red' }}>{formik.errors.notification_type}</div>
                  ) : null}
                </Grid>
              </Grid>
              <Grid mt={3} item sx={{ display: 'flex' }}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2" sx={{}}>
                    Threshold Limit
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                      <PercentIcon size="small" />
                    </div>
                    <TextField
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.threshold_limit}
                      type="number"
                      inputProps={{
                        step: 'any',
                        pattern: '[0-9]*',
                      }}
                      name="threshold_limit"
                      sx={{
                        width: '20%',
                        marginTop: '-12px',
                        '& .MuiOutlinedInput-input': {
                          padding: '5px 8px',
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: currentTheme === 'light' ? '2px solid black' : '2px solid white',
                          },
                        },
                      }}
                    />
                  </Box>
                  {formik.touched.threshold_limit && formik.errors.threshold_limit ? (
                    <div style={{ color: 'red' }}>{formik.errors.threshold_limit}</div>
                  ) : null}
                  <Box mt={1}>
                    <Button
                      sx={{
                        backgroundColor: '#1890ff',
                        color: 'white',
                      }}
                      variant="contained"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Box>
                  <div>
                  {/* {console.log("loading:", loading)} */}
  {/* {console.log("data?.notification_type:", data?.notification_type)} */}
      {/* {data?.notification_type === "True" && (
        <Typography>Price Notification Enabled</Typography>
      )} */}
      
      {successMessage && <Typography>Price Notification Enabled</Typography>}
    </div>
    <div>
      { error && <Typography>Some error occured</Typography> }
    </div>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </form>
    </>
  );
};

export default NotificationSettings;