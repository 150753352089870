import React, { useState, useRef, useEffect } from 'react';
// import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { setItem, getItem } from '../../utils/storage';
import * as brandAction from '../../redux/actions/brands.actions';
import * as categoryAction from '../../redux/actions/categories.actions';
import * as departmentAction from '../../redux/actions/department.actions';
import * as actions from '../../redux/actions/productLocations.actions';
import * as locActions from '../../redux/actions/getLocationQa.actions';
import selectLocationQa from '../../redux/selectors/getLocationQa.selector';
import selectProductLocationsDetails from '../../redux/selectors/productLocations.selector';

import LeftPanel from '../LeftPanel';
import RightPanel from '../RightPanel';

const MyProduct = () => {
    const [filteringData, setFilteringData] = useState([])
    const [categoringData, setCategoryData] = useState([])
    const [brandFilterData, setBrandFilterData] = useState([])
    const [locationFilterData, setLocationFilterData] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [originalData, setOriginalData] = useState([])
    const [selectedLoc, setSelectedLoc] = useState()
    const [selectedPrice, setSelectedPrice] = useState('');
    const [refreshAllData, setRefreshAllData] = useState(false);
    const [isFiltering, setIsFiltering] = useState(true);
    // keyvalue state
    const [checkedKeyvalue, setCheckedKeyvalue] = useState(false);

    const [page, setPage] = useState(0);
    const [selectedQaUser, setQaUser] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const productRef = useRef()
    const dispatch = useDispatch();
    const { productlocationDetails } = useSelector( selectProductLocationsDetails );
    const qaUser = getItem('qaUser');
    const { prdlocdata } = productlocationDetails;
    const { locationQa } = useSelector(selectLocationQa);
    // const params = useParams();
    const getFilterdData = (filteredProduct, data) =>{
        setFilteredData(filteredProduct);
        setOriginalData(data);
    }
    const handlePriceFilter = (pData) => {
        setSelectedPrice(pData);
        productRef.current.handleFilters(pData, filteringData, categoringData, brandFilterData, locationFilterData );
    }
    const handleDeptFilter = (pStr, pData) =>{
        setFilteringData(pData);
        productRef.current.handleFilters(selectedPrice, pData, categoringData, brandFilterData, locationFilterData );
    }
    const handleLocationFilter = (pStr, pData) => {
        // console.log('handleLocationFilter', pData)
        setSelectedLoc(pData[0]);
        setLocationFilterData(pData);
        // productRef.current.handleFilters(filteringData, categoringData, brandFilterData, pData );
    }
    const handleCategoryFilter = (pStr, pData) => {
        // console.log('handleCategoryFilter ', pData)
        setCategoryData(pData)
        productRef.current.handleFilters(selectedPrice, filteringData, pData, brandFilterData, locationFilterData );
    }
    const handleBrandFilter = (pStr, pData) => {
        const sData = pData.map(item => escape(item));
        setBrandFilterData(pData)
        productRef.current.handleFilters(selectedPrice, filteringData, categoringData, sData, locationFilterData );
    }
    const handleQaUser = (event, userList) =>{
        setItem('qaUser', event.target.value);
        // console.log(userList.filter(item => item.id === event.target.value)[0].currency);
        setItem('currency', userList.filter(item => item.id === event.target.value)[0].currency)
        dispatch(brandAction.getBrands(event.target.value));
        dispatch(categoryAction.getCategories(event.target.value));
        dispatch(departmentAction.getDepartment(event.target.value));
        setQaUser(event.target.value);
    }
    const handleRefreshAllData = () => {
        setRefreshAllData(true);
    }
    const removeAllFilters = () =>{
        setRefreshAllData(false);
        // setLocationFilterData([])
        setBrandFilterData([])
        setCategoryData([])
        setFilteringData([])
        setSelectedPrice('');
        // setSelectedLoc(null);
        // setItem('pageList', JSON.stringify("page=1&page_size=10&price=&price=&prd_brand__in=&category_id__in=&dept_id__in="));
        productRef.current.handleFilters('', [], [], [], []);
        // console.log('removeall');
    }

    useEffect(()=> {
        if(qaUser){
            const tempLocQa = getItem('LocQa');

            if(tempLocQa){
                setLocationFilterData([tempLocQa]);
                setSelectedLoc(tempLocQa);
            }else{
                setQaUser(qaUser);
                dispatch(locActions.getLocationQa(qaUser));
            }
        }else{
            dispatch(actions.getProductLocations());
        }
    },[]) // eslint-disable-line

    useEffect(()=>{
        if(qaUser){
        dispatch(locActions.getLocationQa(qaUser));
        }
    },[qaUser]) // eslint-disable-line

    useEffect(()=> {
        if(locationQa.data?.length){
            let locDef = false;
            locationQa.data.forEach((loc)=> {
                if(loc.loc_is_default){
                    setLocationFilterData([loc.loc_id]);
                    setSelectedLoc(loc?.loc_id);
                    setItem('LocQa', loc?.loc_id);
                    locDef = true;
                }
            })
            if(!locDef){
                setSelectedLoc(locationQa.data[0]?.loc_id);
                setLocationFilterData([locationQa.data[0]?.loc_id]);
                setItem('LocQa', locationQa.data[0]?.loc_id);
            }
        }
    },[locationQa])

    useEffect(()=>{
        if(prdlocdata && !qaUser){
            let locDef = false;
            prdlocdata.forEach((loc)=>{
                if(loc.loc_is_default){
                    setLocationFilterData([loc.loc_id]);
                    setSelectedLoc(loc?.loc_id);
                    locDef = true;
                }
            })
            if(!locDef){
                setSelectedLoc(prdlocdata[0]?.loc_id);
                setLocationFilterData([prdlocdata[0]?.loc_id]);
            }
        }
    },[prdlocdata]) // eslint-disable-line

    return (
        <Grid sx={{height:'auto', px:'0px !important', mt:0}}>
            <Grid container spacing={0} sx={{display:'flex'}}>
                <LeftPanel 
                checkedKeyvalue={checkedKeyvalue} 
                setCheckedKeyvalue={setCheckedKeyvalue}
                    handlePriceFilter= {handlePriceFilter}
                    handleDeptFilter={handleDeptFilter} 
                    handleBrandFilter={handleBrandFilter}
                    handleCategoryFilter={handleCategoryFilter}
                    handleLocationFilter = {handleLocationFilter}
                    removeAllFilters = {removeAllFilters}
                    setRefreshAllData = {setRefreshAllData}
                    refreshAllData= {refreshAllData}
                    selectedPrice = {selectedPrice}
                    selectedLoc = {selectedLoc}
                    filteredData = {filteredData}
                    originalData = {originalData}
                    selectedQaUser = {selectedQaUser}
                    
                />
                <RightPanel
                checkedKeyvalue={checkedKeyvalue} 
                setCheckedKeyvalue={setCheckedKeyvalue}
                    ref= {productRef}
                    handleRefreshAllData = {handleRefreshAllData}
                    priceFilterData = {selectedPrice}
                    filteringData = {filteringData}
                    brandFilterData = {brandFilterData}
                    categoringData = {categoringData}
                    locationFilterData = {locationFilterData}
                    setSelectedLoc = {setSelectedLoc}
                    getFilterdData = {getFilterdData}
                    removeAllFilters = {removeAllFilters}
                    handleQaUser = {handleQaUser}
                    prdlocdata = {prdlocdata}
                    page = {page}
                    setPage = {setPage}
                    setRowsPerPage = {setRowsPerPage}
                    rowsPerPage = {rowsPerPage}
                    isFiltering = {isFiltering}
                    setIsFiltering = {setIsFiltering}
                />
            </Grid>
        </Grid>
    )
}


export default MyProduct;