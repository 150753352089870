import { useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getItem } from '../../../utils/storage';
import { groupBy, isEmpty } from 'lodash';
import selectProductsDetails from '../../../redux/selectors/productdetails.selector';
import * as actions from '../../../redux/actions/productDetails.actions';


import selectProductPrevNextDetails from '../../../redux/selectors/productprevnext.selector';
import * as prdactions from '../../../redux/actions/productprevnext.actions';

const UseProductDetails = (props) => {

    const { id, productZip, setSelectedLoc } = props
    const dispatch = useDispatch()
    const userToken = getItem('token');
    const userRole = getItem('role');
    
    const qaUser = Number(userRole) === 2 ? getItem('qaUser') : null;
    const { productDetails } = useSelector(selectProductsDetails);
    const { productPrevNextDetails } = useSelector(selectProductPrevNextDetails)
    
    const [productData, setProductData] = useState([])
    const [locationData, setLocationData] = useState([])
    const {data: prevNextData} = productPrevNextDetails;
    const { data, isSuccessful, isFailure } = productDetails;
 
    // const getDefaultLocation = (pData) =>{
    //     const defaultItem = pData.filter(item =>{
    //          if(item.loc.loc_is_default) return item
    //     })
    //     // console.log(defaultItem);
    //     return defaultItem;
    //     // return 0;
    // }

    const handleRefresh = () =>{
        window.location.reload()
    }

    const handleReloadCompetitor = () =>{
        window.location.reload()
    }
    const handleLocationGroup = (item) =>{
        // const activeLocation = Number(userRole) === 2 ? [item.locations[0]] : getDefaultLocation(item.locations);
        const activeLocation = [item.locations[0]];
        // console.log(activeLocation[0].prd_norm_price, activeLocation[0].prd_sales_price, activeLocation[0].filtered_mrp)
        // console.log(activeLocation)
        const locator = {};
        locator.loc_id = !isEmpty(activeLocation) && activeLocation[0]?.loc?.loc_id;
        locator.loc_name = !isEmpty(activeLocation) && activeLocation[0]?.loc?.loc_name;
        locator.loc_zip = !isEmpty(activeLocation) && activeLocation[0]?.loc?.loc_zip;
        locator.category_name = !isEmpty(activeLocation) && item.category !== null && item.category.category_name ? item.category.category_name : 'N/A';
        locator.filtered_sales_price = !isEmpty(activeLocation) && activeLocation[0].prd_sales_price;
        locator.filtered_norm_price = !isEmpty(activeLocation) && activeLocation[0].prd_norm_price;
        locator.filtered_mrp = !isEmpty(activeLocation) && activeLocation[0].prd_mrp; 
        setLocationData(data.locations);
        return locator;
    }

    useEffect(() =>{
        if(Number(userRole) === 2){
            dispatch(prdactions.getProductPrevNext({user_id:qaUser, prd_id: Number(id)}))
        }
    }, [userRole, id]) // eslint-disable-line

    useEffect(() =>{
       if(productZip){
         const item = {...data};
        const resultData = locationData.filter(item => {
            return Number(item.loc?.loc_zip) === Number(productZip)
        })
        const locator = resultData[0];
        locator.loc_id = !isEmpty(resultData) && resultData[0]?.loc?.loc_id;
        locator.loc_name = !isEmpty(resultData) && resultData[0]?.loc?.loc_name;
        locator.loc_zip = !isEmpty(resultData) && resultData[0]?.loc?.loc_zip;
        locator.filtered_sales_price = !isEmpty(resultData) && resultData[0].prd_sales_price;
        locator.filtered_norm_price = !isEmpty(resultData) && resultData[0].prd_norm_price;
        locator.filtered_mrp = !isEmpty(resultData) && resultData[0].prd_mrp; 
        
        item.activeLocator = locator;
        setSelectedLoc(locator.loc?.loc_zip);
        setProductData(item);
       } 
    },[locationData, productZip]) // eslint-disable-line

    useEffect(() =>{
        // console.log('userRole ', userRole, qaUser, getItem('qaUser'))
        const productPath = userRole !== 2 && qaUser === null ? id : `${id}?user_id=${qaUser}`;
        dispatch(actions.productTotalReset())
        if(!data && userToken){
                dispatch(actions.getProductDetails(productPath))
        } else if(Number(data?.prd_id) !== Number(id) && userToken){
            dispatch(actions.getProductDetails(productPath))
        } else {
                const item = {...data}
                item.category_name = item.category !== null && item.category.category_name !== undefined ? item.category.category_name : item.category;
                
                const filteredCompetitors = !isEmpty(item.competitors) && [groupBy(item.competitors, 'market_place')];
                item.filteredMarket = !isEmpty(item.competitors) && [...new Set(item.competitors.map(competitor => competitor.market_place).flat())]
                item.filteredCompetitors = filteredCompetitors;
                
                const comp_market_Data = !isEmpty(item.competitors) && item.competitors.map(competitor => 
                    {
                        competitor.market_place_id =  competitor.market_place?.market_place_id;
                        competitor.market_place_name =  competitor.market_place?.market_place_name;
                        return competitor;
                    }
                )
                item.competitors = comp_market_Data !== false ? comp_market_Data : [];
                item.activeLocator = handleLocationGroup(item);
                setSelectedLoc(item.activeLocator?.loc_zip)
            setProductData(item);
        }
    }, [id, data, userToken, qaUser, dispatch]) // eslint-disable-line

    return {
        productData,
        locationData,
        prevNextData,
        isSuccessful, 
        isFailure,
        userRole,
        qaUser,
        handleRefresh,
        handleReloadCompetitor
    }

}

export default UseProductDetails;