import {PRODUCT_KEYVALUE,PRODUCT_KEYVALUE_FAILED,PRODUCT_KEYVALUE_SUCCESSFUL} from "../constants/productkeyvalue.constants"
  
  export const initialState = {
    isLoading: false,
    isSuccessful: false,
    isFailure: false,
    data: null,
    error: null,
  };
  
  export const productKeyValueReducer = (state = initialState, action) => {
    switch (action.type) {
      case PRODUCT_KEYVALUE:
        return {
          ...state,
          isLoading: true,
          isSuccessful: false,
          isFailure: false,
          error: null,
        };
  
      case PRODUCT_KEYVALUE_SUCCESSFUL:
        return {
          ...state,
          isLoading: false,
          isSuccessful: true,
          isFailure: false,
          data: action.payload,
          error: null,
        };
  
      case PRODUCT_KEYVALUE_FAILED:
        return {
          ...state,
          isLoading: false,
          isSuccessful: false,
          isFailure: true,
          error: action.payload,
          data: null,
        };
  
      default:
        return state;
    }
  };
  