import {
    UPDATE_CLIENT_REQUEST,
    UPDATE_CLIENT_SUCCESS,
    UPDATE_CLIENT_FAIL,
  } from '../constants/clientUpdate.constants';
  
  const initialState = {
    isLoading: false,
    updatedClient: null,
    isError: null,
  };
  
  export const clientUpdateReducer = (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_CLIENT_REQUEST:
        return {
          ...state,
          isLoading: true,
          isError: null,
        };
      case UPDATE_CLIENT_SUCCESS:
        return {
          ...state,
          isLoading: false,
          updatedClient: action.payload,
        };
      case UPDATE_CLIENT_FAIL:
        return {
          ...state,
          isLoading: false,
          isError: action.payload,
        };
      default:
        return state;
    }
  };
  
  