import React from 'react';
import { Chart as ChartJS, LineElement, CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    Legend, } from "chart.js";
import { Line } from 'react-chartjs-2';
import { isEmpty } from 'lodash';
import moment from 'moment';

ChartJS.register(LineElement, CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    Legend);



const LineChart = props => {
  
  const { pricevariantData, currentTheme, colorArr } = props;
  // Sort the pricevariantData in descending order based on the timestamp
  const sortedPricevariantData = pricevariantData?.sort((a, b) => {
    const timestampA = new Date(Object.keys(a)[0]).getTime();
    const timestampB = new Date(Object.keys(b)[0]).getTime();
    return timestampA - timestampB;
  });
  const dummyLabels = ['', '', '', '', '', '', ''];
  const labels = !isEmpty(sortedPricevariantData)
    ? sortedPricevariantData.length <= 15
      ? sortedPricevariantData.map(item => moment(Object.keys(item)[0]).format('DD-MM-YYYY HH:MM:SS').split(" ")) 
      : sortedPricevariantData.map(item => moment(Object.keys(item)[0]).format('DD-MM').split(" ")) 
    : dummyLabels;
  // console.log(labels);
  let resultData = !isEmpty(pricevariantData) && pricevariantData.map(item => Object.values(item).map(items => items.flat()));
  resultData = !isEmpty(resultData) && resultData.map((item, index) => item[0]);
  // console.log('resultData1 ', resultData);
  let dataLength = 0;
  let dataIndex = 0;
  !isEmpty(resultData) && resultData.map((item, index) => {
    if(item.length>dataLength) {dataLength = item.length; dataIndex = index}  
    return item
  })
        // code before string conversion in comp_prd_norm_price
  const priceData = !isEmpty(resultData) && resultData[dataIndex].map((item, index) => {
    const matchId = item.match_id;
    return {
      label: index === 0 ? item.prd_name : `Product Id: ${item.match_id}`,
      data: index === 0 ? pricevariantData.map(items => Object.values(items)[0][0][0].price)
      : resultData.map(ele => {
        const match = ele?.find(prd => prd.match_id === matchId);
          if(match){
            const spaceIndex = item?.comp_prd_norm_price.indexOf(" ");
            if(spaceIndex === -1){
              return match.comp_prd_norm_price;
            } else {
              return match.comp_prd_norm_price.slice(0,spaceIndex);
            }
          }else{
            return null
          }
      }),
      borderColor: colorArr[index % 3],
      backgroundColor: colorArr[index % 3]
    }
  })

  //console.log(priceData);

  // code for conversion of comp_prd_norm_price to numberic values
  // const priceData = !isEmpty(resultData) && resultData[dataIndex].map((item, index) => {
  //   const numericRegex = /[0-9]+(\.[0-9]+)?/;
    
  //   return {
  //     label: index === 0 ? item.prd_name : `Product Id: ${item.match_id}`,
  //     data: index === 0 ? pricevariantData.map(items => Object.values(items)[0][0][0].price)
  //       : resultData.map(item => item[index]).map(items => {
  //         const match = items?.comp_prd_norm_price?.match(numericRegex);
  //         return match ? parseFloat(match[0]) : null;
  //       }),
  //     borderColor: colorArr[index % 3],
  //     backgroundColor: colorArr[index % 3]
  //   }
  // })
  

  const dummylineData = {
    labels,
    datasets: [
      {
        label: 'Our Product',
        data: labels.map(() => Math.random()*1000),
        borderColor: '#355FBE',
        backgroundColor: '#355FBE',
      },
      {
        label: 'Competitor variance 1',
        data: labels.map(() => Math.random()*1000),
        borderColor: '#4F8EEC',
        backgroundColor: '#4F8EEC',
      },
      {
        label: 'Competitor variance 2',
        data: labels.map(() => Math.random()*1000),
        borderColor: '#A8CBFF',
        backgroundColor: '#A8CBFF',
      },
    ],
  };


  let lineData = {
    labels,
    datasets: priceData,
  };

    const option = {
        responsive: false,
        maintainAspectRatio: false,
        interaction: {
          intersect: false
        },
        borderWidth:2,
        scales: {
            y:{
              border:{
                display:true,
                color:'#515151'
              },
              ticks:{
                  display: true,
                    color: currentTheme.currentTheme === 'light'? '#272727' : '#E4E3E3',
                    maxRotation: 0,
                    autoSkip: false,
              },
              grid:{
                drawOnChartArea:false
              },
              display: true
            },
            x: {
              stacked: true,
              border:{
                display:true,
                color:'#515151'
              },
              ticks:{
                  display: true,
                  color: currentTheme.currentTheme === 'light'? '#272727' : '#E4E3E3',
                  maxRotation: 0,
                  autoSkip: false,
              },
              grid:{
                drawOnChartArea:false
              }
            },
            yAxes: {
                display:false,
                border:{
                  display:false,
                },
                labels:{
                  display: false
                },
                // color:'#FFFFFF',
                title:{display:false},
                ticks: {
                    display: false
                }
            },
            xAxes: {
                border:{
                  display:false,
                },
                ticks: {
                    display: false
                }
            }
        },
        plugins: {
          datalabels: {
            display: false
          },
          legend: {
            display: false,
            // onClick: null,
            //   position: 'top',
            //   lineWidth:0,
            //   labels:{
            //     color: '#FFFFFF',
            //     usePointStyle: true,
            //     pointStyle: 'circle',
            //   }
          }
        }
    }


return (
    <Line options={option} data={!isEmpty(pricevariantData) ? lineData :dummylineData} width={870} height={430} />
)

}
export default LineChart;