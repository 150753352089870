import { put, takeLatest, call } from 'redux-saga/effects';

import { COMMENT_SEND_REQUEST,COMMENT_SEND_SUCCESS,COMMENT_SEND_FAILURE } from '../constants/comments.constants';

import { createClientsSuccess,createClientsFail } from '../actions/clientCreate.action';
import { sendComment,sentCommentFail,sentCommentSuccess } from '../actions/comments.actions';

import NetworkApi from '../../api/NetworkApi';
import { getItem, getItems } from '../../utils/storage';
import paths from '../../api/path';

const getApiConfig = () => {
  const [ token ] = getItems(['token']);

  const headers = {
    Authorization: `token ${token}`,
  };

  return {
   // headers,
    Authorization: headers.Authorization
  }
}
function* sendProductComment(action) {
    // console.log("action",action.payload)
  const apiConfig = getApiConfig();
  const userRole = getItem('role');
  try {
    const response = yield call(NetworkApi.put,Number(userRole === 2 || userRole === 3) && paths.productSendComments, action.payload, apiConfig);
    // console.log("response",response)
    yield put(sentCommentSuccess(response));
  } catch (error) {
    // console.log("error.message",error.message)
    yield put(sentCommentFail(error));
  }
}

export function* watchSendComment() {
  yield takeLatest(COMMENT_SEND_REQUEST, sendProductComment);
}
