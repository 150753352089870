import { call, put, takeLatest  } from 'redux-saga/effects';  //takeLatest
// import { isEmpty, } from 'lodash';
import * as actions from '../actions/department.actions';
import * as constants from '../constants/department.constants';
import NetworkApi from '../../api/NetworkApi';
import { getItems, getItem } from '../../utils/storage';
import paths from '../../api/path';

const getApiConfig = () => {
  const [
    token
  ] = getItems([
    'token',
  ]);

  const headers = {
    Authorization: `token ${token}`,
  };

  return {
    Authorization: headers.Authorization
  }
}

export function* getDepartment(request = {}) {
  const apiConfig = getApiConfig()
  const { payload = {} } = request;
  const userRole = getItem('role');
  const marketPlace = getItem('activeMarketPlace');
  console.log()
  const customDesign = getItem('customDesign');
  try {
    const response = yield call(NetworkApi.get,
      userRole === 2 ? `${paths.qaDepartmentsURL}?user_id=${payload}` : customDesign === "UI_2" ? `${paths.departmentsURL}?prd_mp=${marketPlace}`: paths.departmentsURL,
      {}, apiConfig);
    // console.log('locations', response);
    if (response) {
      if(!response.details){
        yield put(actions.getDepartmentSuccessful(response));
      } else {
        yield put(actions.getDepartmentFailed(response.details));
      }
    } else {
      // console.log('error ', response)
      const message = response? (
        response
      ) : (
        ''
        // <FormattedMessage id="notProcess" />
      );
      yield put(actions.getDepartmentFailed(message));
    }

  } catch (error) {
    const message = error?.response?.data?.detail ? (
      error.response.data?.detail
    ) : (
      ''
      // <FormattedMessage id="notProcess" />
    );
    yield put(actions.getDepartmentFailed(message));
  }
}


export function* fetchDepartment() {
  yield takeLatest(constants.DEPARTMENT_GET, getDepartment);
}
