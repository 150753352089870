import { call, put, takeLatest  } from 'redux-saga/effects';  //takeLatest
// import { isEmpty, } from 'lodash';
import * as actions from '../actions/locations.actions';
import * as constants from '../constants/locations.constants';
import NetworkApi from '../../api/NetworkApi';
import { getItems } from '../../utils/storage';
import paths from '../../api/path';

const getApiConfig = () => {
  const [
    token
  ] = getItems([
    'token',
  ]);

  const headers = {
    Authorization: `token ${token}`,
  };

  return {
    Authorization: headers.Authorization
  }
}


export function* getLocations(request = {}) {
  const apiConfig = getApiConfig()
  try {
    const response = yield call(NetworkApi.get, paths.locationsURL, {}, apiConfig);
    // console.log('locations', response);
    if (response) {
      if(!response.details){
        yield put(actions.getLocationsSuccessful(response));
      } else {
        yield put(actions.getLocationsFailed(response.details));
      }
    } else {
      // console.log('error ', response)
      const message = response? (
        response
      ) : (
        ''
        // <FormattedMessage id="notProcess" />
      );
      yield put(actions.getLocationsFailed(message));
    }

  } catch (error) {
    const message = error?.response?.data?.message ? (
      error?.response?.data?.message
    ) : (
      ''
      // <FormattedMessage id="notProcess" />
    );
    yield put(actions.getLocationsFailed(message));
  }
}


export function* fetchLocations() {
  yield takeLatest(constants.LOCATION_GET, getLocations);
}
