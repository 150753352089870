import { call, put, takeLatest } from 'redux-saga/effects';
import { UPDATE_CLIENT_REQUEST } from '../constants/clientUpdate.constants';
import { updateClientSuccess, updateClientFail } from '../actions/clientUpdate.actions';
import paths from '../../api/path';
import { getItems, getItem } from '../../utils/storage';
import NetworkApi from '../../api/NetworkApi';

const getApiConfig = () => {
    const [token] = getItems(['token']);
  
    const headers = {
      Authorization: `token ${token}`,
      
    };
  
    return {
      Authorization: headers.Authorization,
    };
  };

function* updateClientSaga(action) {
    const apiConfig = getApiConfig();
    const userRole = getItem('role');
  try {
    const { clientId,updatedData} = action.payload;
    // console.log('Payload:', action.payload);
    // Call the API function to update the client
    const response = yield call(NetworkApi.put, Number(userRole) === 1 && `${paths.clientsUpdateUrl}${clientId}/`,updatedData, apiConfig);
    // Dispatch the success action with the updated client
    // console.log("response",response)
    yield put(updateClientSuccess(response));
  } catch (error) {
    // Dispatch the failure action with the error message
    yield put(updateClientFail(error));
  }
}

export function* watchUpdateClient() {
  yield takeLatest(UPDATE_CLIENT_REQUEST, updateClientSaga);
}
