import * as constants from '../constants/refreshAll.constants';

export const refreshAll = (payload) => ({
    type: constants.REFRESH_ALL,
    payload
  });
  
  export const refreshAllSuccessful = (response) => ({
    type: constants.REFRESH_ALL_SUCCESSFULL,
    response
  });
  
  export const refreshAllFailed = (payload) => ({
    type: constants.REFRESH_ALL_FAILED,
    payload
  });