import * as constants from '../constants/clientsCreate.constants';

export const initialState = {
  isLoading: false,
  clientCreateData: null,
  isError: null,
};

export const clientsCreateReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.CREATE_CLIENTS:
      return {
        ...state,
        isLoading: true,
      };
    case constants.CREATE_CLIENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        clientCreateData: action.payload,
        isError: null,
      };
    case constants.CREATE_CLIENTS_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };
    default:
      return state;
  }
};
