import {useEffect, useCallback} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getItems } from '../../../utils/storage';
import useActions from '../../../redux/use-actions';
import getSingleProductDetails from '../../../redux/selectors/addsingleproduct.selector';
import {handleSingleProduct, resetProduct } from '../../../redux/actions/addsingleproduct.actions';
import * as dashboardactions from '../../../redux/actions/dashboard.actions';

const UseAddProduct = () => {
    const [productsCreated] = getItems(['productsCreated']);
    const navigate = useNavigate()
    const actions = useActions({ handleSingleProduct, resetProduct });
    const { singleProductDetails } = useSelector(getSingleProductDetails)
    const { isSuccessful, isFailure,message } = singleProductDetails;
    const dispatch = useDispatch();

// console.log('single product ',  isSuccessful, isFailure,message)

    useEffect(() =>{
        if(isSuccessful){
            setTimeout(()=>{
                dispatch(actions.resetProduct());
                if(!productsCreated){
                    navigate('/settings')
                } else {
                    dispatch(dashboardactions.dashboardReset())
                    navigate('/dashboard')
                }
            }, 2*1000)
        }
    }, [isSuccessful]) // eslint-disable-line

    useEffect(() =>{
        if(isFailure){
            setTimeout(()=>{
                dispatch(actions.resetProduct());
            }, 2000)
        }
    }, [isFailure]) // eslint-disable-line


    const handleSingleProductSubmit = useCallback((pValues) =>{
        pValues.prd_small_image = pValues.prd_medium_image;
        // console.log('handlesubmit', pValues);
        actions.handleSingleProduct(pValues)
        
    }, [actions])


    return {
        handleSingleProductSubmit, addProductSuccess:isSuccessful, addProductFailure:isFailure,message: message,
    }
}

export default UseAddProduct;