import * as constants from '../constants/getLocationQa.constants';

export const initialState = {
  
  locationQa: {
    isLoading: false,
    isSuccessful: false,
    isFailure: false,
    data: null,
    message: null,
  },
};

export const getLocationQaReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    
    case constants.GET_LOCATION_QA:
      return {
        ...state,
        locationQa: {
          isLoading: true,
          isSuccessful: false,
          isFailure: false,
          data: null,
          errorMessage: null
        },
        productsCreated: false
      };
    case constants.GET_LOCATION_QA_SUCCESSFUL:
    //  console.log('reducer', action)
      return {
        ...state,
        locationQa: {
          isLoading: false,
          isSuccessful: true,
          isFailure: false,
          message: null,
          data: action.response
        }
      };
    case constants.GET_LOCATION_QA_FAILED:
      return {
        ...state,
        locationQa: {
          isLoading: false,
          isSuccessful: false,
          isFailure: true,
          message: payload,
          data: null
        }
      };
    default:
      return state;
  }
};