import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  FormControl,
  FormGroup,
  Button,
  Typography,
} from "@mui/material";
// import { isEmpty } from "lodash";

import UseReport from "./useReport";
// tableau changes
const RightPanel = (props) => {
  const {
    getFilterdData,
    marketFilterData,
    filteringData,
    categoringData,
    brandFilterData,
    locationFilterData,
    currentLocation,
  } = props;
  const { filterdProducts, data, marketData } = UseReport();
  // const [tableauUrl, setTableauUrl] = useState(
  //   "https://public.tableau.com/views/pricecomparison_16859631940200/Dashboard2?:language=en-US&:display_count=n&:origin=viz_share_link"
  // );
  const [tableauUrl, setTableauUrl] = useState(
    "https://public.tableau.com/views/Assortmentandoffers/Dashboard1?:language=en-US&:display_count=n&:origin=viz_share_link"
  );
  // console.log("currentLocationl ==>", currentLocation);
  // console.log("filtered datas", filteringData, brandFilterData, categoringData, currentLocation);
  useEffect(() => {
    const url = tableauUrl;
    const options = {
      hideTabs: true,
      onFirstInteractive: function () {
        const workbook = viz.getWorkbook();
        const activeSheet = workbook.getActiveSheet();
        // Access workbook and activeSheet properties here
      },
    };
  
    let viz;
  
    const initTableau = () => {
      if (window.tableau.Viz) {
        const vizContainer = document.getElementById("tableauViz");
        viz = new window.tableau.Viz(vizContainer, url, options);
      } else {
        setTimeout(initTableau, 100);
      }
    };
  
    initTableau();
  
    return () => {
      if (viz) {
        viz.dispose();
      }
    };
  }, [tableauUrl]);

  return (
    <Grid item xs={10} sx={{ padding: 2 }}>
      <Grid
        item
        sx={{ display: "flex", alignItems: "flex-start", flexDirection: "column", justifyContent: "flex-start",}}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <FormGroup sx={{ flexDirection: "row", alignItems: "center" }}>
            {/* <FormControl sx={{ m: 1, mr: 0, mt: 0, mb: 2 }}>
              <Button
                variant="text"
                sx={{ fontSize: 11, textTransform: "none", borderColor: "#1C3B6E", color: "text.secondary", justifyContent: "flex-start",}}>
                Price Comparison
              </Button>
            </FormControl>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body2" sx={{ ml: "5px", marginTop: "-14px" }}>
                |
              </Typography>
              <FormControl sx={{ m: 1, mr: 0, mt: 0, mb: 2 }}>
                <Button
                  variant="text"
                  sx={{ fontSize: 11, textTransform: "none", borderColor: "#1C3B6E", color: "text.secondary", justifyContent: "flex-start",}}>
                  Market Rate
                </Button>
              </FormControl> 
              <Typography variant="body2" sx={{ ml: "5px", marginTop: "-14px" }}>
                |
              </Typography>
            </Box>
            <FormControl sx={{ m: 1, mr: 0, mt: 0, mb: 2 }}>
              <Button
                variant="text" sx={{fontSize: 11,textTransform: "none",borderColor: "#1C3B6E",color: "text.secondary",justifyContent: "flex-start",
                }}>
                Price Per Unit
              </Button>
            </FormControl>
            <Typography variant="body2" sx={{ ml: "5px", marginTop: "-14px" }}>
              |
            </Typography>
            <FormControl
              sx={{ m: 1, mr: 0, mt: 0, mb: 2, minWidth: "35px", width: "50px",}}>
              <Button
                variant="text"
                sx={{ minWidth: 40, fontSize: 11, textTransform: "none", borderColor: "#1C3B6E", color: "text.secondary", justifyContent: "flex-start",}}>
                CPI
              </Button>
            </FormControl>
            <Typography variant="body2" sx={{ ml: "5px", marginTop: "-14px" }}>
              |
            </Typography> */}
            <FormControl sx={{ m: 1, mr: 0, mt: 0, mb: 2 }}>
              <Button
              onClick={()=>{
                setTableauUrl("https://public.tableau.com/views/Assortmentandoffers/Dashboard1?:language=en-US&:display_count=n&:origin=viz_share_link")
              }}
                variant="text" sx={{fontSize: 11,textTransform: "none",borderColor: "#1C3B6E",color: "text.secondary",justifyContent: "flex-start",
                }}>
                Assortment
              </Button>
            </FormControl>
            <Typography variant="body2" sx={{ ml: "5px", marginTop: "-14px" }}>
              |
            </Typography>
            <FormControl sx={{ m: 1, mr: 0, mt: 0, mb: 2 }}>
              <Button
              onClick={()=>{
                setTableauUrl("https://public.tableau.com/views/Dashboard_final_packaged/LIGHT?:language=en-US&:display_count=n&:origin=viz_share_link")
              }}
                variant="text" sx={{fontSize: 11,textTransform: "none",borderColor: "#1C3B6E",color: "text.secondary",justifyContent: "flex-start",
                }}>
                Private Label
              </Button>
            </FormControl>
          </FormGroup>
        </Box>

        <Box
          id="tableauViz"
          sx={{
            width: "100%",
            height: "100%",
            overflowY: "auto",
            overflowX: "auto",
          }}
        ></Box>
      </Grid>
    </Grid>
  );
};

export default RightPanel;
