import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Typography, Button, FormControlLabel, RadioGroup, Radio, DialogActions } from '@mui/material';
import { getItem } from '../../utils/storage';
import { isEmpty } from 'lodash';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { getRefreshProduct } from '../../redux/actions/refreshProduct.actions';
import selectRefreshProductDetails from '../../redux/selectors/refreshProduct.selector';


export default function SingleRefreshDialog(props) {
    const { refreshDialogOpen, setRefreshDialosOpen, marketLabelData,selectedItem,userRole,setSelectedRefreshValue,selectedRefreshValue} = props
    
    const {refreshProductDetails} = useSelector(selectRefreshProductDetails)
    const {isLoading,isSuccessful,isFailure } = refreshProductDetails
    
    const [marketIds, setMarketIds] = useState([])
    const [locIds, setLocIds] = useState([])
    const [maketLabels, setMaketLabel] = useState([])
    
    const newLoc_id = marketLabelData && marketLabelData.length > 0 ? marketLabelData[0].locations[0].loc?.loc_id : undefined;
    const newMarket_place_id = marketLabelData && marketLabelData[0]?.market_place_id;

    const dispatch = useDispatch()
    
    const [themeName, setThemeName] = useState("");
    const [disableConfirm,setDisableConfirm] = useState(false)

    const [allData,setAllData] = useState([])
    const staticData = ['Amazon.ae'];  //giving static value if marketlists are empty
    const activeMarketPlace = getItem('activeMarketPlace');
    const marketLocation = getItem('marketLocation');

    useEffect(() => {
      if(isSuccessful){
        handleClose()
        window.location.reload()
      }
    }, [isSuccessful])
    useEffect(() => {
      if(isFailure){
        handleClose()
        setTimeout(() => {
        window.location.reload()
        }, 2000);
      }
    }, [isFailure])


    useEffect(() => {
        const storedTheme = localStorage.getItem("appTheme");
        setThemeName(storedTheme);
      }, []);
      const competitorInformation = selectedItem?.competitors
      
      useEffect(() => {
        if(!isEmpty(competitorInformation)){
            const marketIdsa = [...new Set(competitorInformation.map(competitor => competitor.market_place_id).flat())]
            const marketLabel = [...new Set(competitorInformation.map(competitor => competitor.market_place_name).flat())]
            // console.log('marketLabel ', marketLabel);
            // console.log('marketIdsa ', marketIdsa);
            const locationIds = [...new Set(competitorInformation.map(competitor => competitor.loc.loc_id).flat())]
            // console.log(competitorInformation,' locationIds ', locationIds)
            setMarketIds(marketIdsa);
            setMaketLabel(marketLabel);
            // console.log('locationIds ', locationIds)
            setLocIds(locationIds);
        }
    }, [competitorInformation])

    
useEffect(() => {
  if(userRole !==2 ){
    setAllData(marketLabelData) 
  } else{
    setAllData(maketLabels)
  }
}, [userRole,maketLabels])

  const handleClose = () => {
    setRefreshDialosOpen(false);
    setSelectedRefreshValue("");
  };

const handleRefresh = () => {
    setDisableConfirm(true)
    if (selectedRefreshValue.length > 0) {
        // const mIndex = maketLabels.findIndex(item => item === selectedRefreshValue);

      dispatch(getRefreshProduct({
        prd_id:selectedItem?.prd_id,
        cuttoff_score:100, 
        loc_id: marketLocation,
        market_place_ids: activeMarketPlace
        // loc_id: locIds[mIndex] !== undefined ? locIds[mIndex] : (locIds[0] !== undefined ? locIds[0] : newLoc_id),
        // market_place_ids: marketIds[mIndex] ? marketIds[mIndex] : newMarket_place_id 
      }));
    }else{
        setRefreshDialosOpen(false);
        setSelectedRefreshValue("");
    }
    
  };
  

  const handleRadioChange = (e)=>{
    setSelectedRefreshValue(e.target.value);
}

  return (
    <div>
      <Dialog
        open={refreshDialogOpen}
        onClose={handleClose}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          sx: {
            width: '25%', 
            border: themeName === 'dark' ? '1px solid white' : '1px solid black', 
          },
        }}
      >
        <DialogTitle className='typo headingText' sx={{ color: 'text.secondary' }} >
            Select Market
        </DialogTitle>
        <DialogContent sx={{display:'flex', flexDirection:'column'}}>
              <RadioGroup 
              value={selectedRefreshValue} 
              onChange={handleRadioChange}
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group">
              {
                allData.length >0 ?
                allData && allData.map((item, index) => {
                  const market_place_name = userRole !== 2 ? item.market_place_name : item;
                  // console.log("item",item);
                  return (item.market_place_id === activeMarketPlace ? (<FormControlLabel key={`check${index}`} value={market_place_name} sx={{fontSize:11, color:'text.secondary'}} 
                    control={<Radio id={market_place_name} 
                    sx={{padding:'5px'}} size="small" />} 
                    label= {<Typography variant='body2' sx={{}}>{market_place_name}</Typography>} />) : null );
                }) 
                :
                staticData && staticData.map((item, index) => {
                  const market_place_name = userRole !== 2 ? item.market_place_name : item;
                  // console.log("item",item);
                  return (<FormControlLabel key={`check${index}`} value={market_place_name} sx={{fontSize:11, color:'text.secondary'}} 
                    control={<Radio id={market_place_name} 
                    sx={{padding:'5px'}} size="small" />} 
                    label= {<Typography variant='body2' sx={{}}>{market_place_name}</Typography>} />)
                  
                })
              }
              </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button disabled={disableConfirm} onClick={handleRefresh} autoFocus>
            Confirm
          </Button>
        </DialogActions>
        { isLoading && <Typography variant="body2" textAlign="center" >Refreshing market place...</Typography> }
        { isFailure && <Typography variant="body2" textAlign="center" >Some error occured...</Typography> }
        { isSuccessful && <Typography variant="body2" textAlign="center" >Refreshed marketplace</Typography> }
      </Dialog>
    </div>
  );
}