import { combineReducers } from 'redux';
import { authenticationReducer } from './authentication.reducer';
import { dashboardReducer } from './dashboard.reducer';
import { myproductReducer } from './myproducts.reducer';
import { editProductReducer } from './editProduct.reducer'
import { productDetailsReducer } from './productdetails.reducer';
import { productCompetitorsReducer } from './productcompetitors.reducer'
import { productLocationsReducer } from './productLocations.reducer'
import { addSingleProductReducer } from './addsingleproduct.reducer'
import { addBulkProductReducer } from './addbulkproduct.reducer'
import { locationsReducer } from './locations.reducer';
import { getLocationQaReducer } from './getLocationQa.reducer';
import { brandsReducer } from './brands.reducer';
import { categoriesReducer } from './categories.reducer';
import { departmentReducer } from './department.reducer';
import { productCompetitorReducer } from './competitors.reducer';
import { matchrateReducer } from './matchrate.reducer';
import { addCompetitorReducer } from './addcompetitors.reducer';
import { deleteCompetitorReducer } from './deletecompetitor.reducer'
import { deleteProductReducer } from './deleteProduct.reducer'
import { refreshProductReducer } from './refreshProduct.reducer'
import { marketPlaceReducer } from './marketplace.reducer'
import { marketLocationReducer } from './marketlocation.reducer'
import { addMarketplaceReducer } from './addmarketplace.reducer'
import { addMarketruleReducer } from './addmarketrules.reducer';
import { changePasswordReducer } from './changepassword.reducer';
import { countryStateReducer } from './countrystate.reducer';
import { addLocationReducer } from './addlocations.reducer';
import { addMarketLocReducer } from './addmarketloc.reducer';
import { updateCurrencyReducer } from './currency.reducer'
import { updateLocationReducer } from './updatelocation.reducer';
import { deletemarketPlaceReducer } from './deletemarketplace.reducer'
import { deleteAllProductReducer } from './deleteAllProduct.reducer';
import { productNotificationReducer } from './productnotify.reducer';
import { settingsNotificationReducer } from './settingsnotify.reducer';
import { sellersReducer } from './sellers.reducer';
import { addCategoryReducer } from './addcategory.reducer';
import { addDepartmentReducer } from './adddepartment.reducer';
import { addSellerReducer } from './addseller.reducer';
import { qaUserReducer } from './qaUser.reducer';
import { productPrevNextReducer } from './productprevnext.reducer';
import { qaApproveReducer } from './qaapprove.reducer';
import { downloadXlsReducer } from './downloadxls.reducer'
import { productPriceVariationReducer } from './productPriceVariation.reducer';
import { dashboardPriceVariationReducer } from './dashboardPriceVariation.reducer';
import { clientsReducer } from './clients.reducer';
import { clientUpdateReducer } from './clientUpdate.reducers';
import { clientsCreateReducer } from './clientsCreate.reducer';
import { clientsMarketCountReducer } from './clientMakrketCount.reducer';
import { clientMigrateReducer } from './clientMigrage.reducer';
import { commentReducer } from './comments.reducer';
import { productKeyValueReducer } from './productKeyvalue.reducer';
import { deletemarketPlaceFullReducer } from './deleteMarketplacefull.reducer';
import { getAppNotificationReducer } from './getappnotification.reducer';
import { priceChangeNotificationReducer } from './pricechangeNotification.reducer';
import { PrdUpdateMatchReducer } from './updateEnablematch.reducer';
import { getRefreshBtnStatusReducer } from './getRefreshBtnStatus.reducer';
import { refreshAllReducer } from './refreshAll.reducer';

const reducers = combineReducers({
    authentication: authenticationReducer,
    dashboard: dashboardReducer,
    products: myproductReducer,
    editedProduct: editProductReducer,
    productDetails: productDetailsReducer,
    productsCompetitors: productCompetitorsReducer,
    productlocationDetails: productLocationsReducer,
    singleProductDetails: addSingleProductReducer,
    addBulkProduct: addBulkProductReducer,
    locationQa: getLocationQaReducer,
    locationDetails: locationsReducer,
    categoryDetails: categoriesReducer,
    departmentDetails: departmentReducer,
    productCompetitorDetails: productCompetitorReducer,
    updatedMatchrateDetails: matchrateReducer,
    competitorDetails: addCompetitorReducer,
    deleteCompetitorDetails: deleteCompetitorReducer,
    deleteProductDetails: deleteProductReducer,
    refreshProductDetails: refreshProductReducer,
    marketPlaceDetails: marketPlaceReducer,
    marketLocationDetails: marketLocationReducer,
    addMarketplaceDetails: addMarketplaceReducer,
    addMarketruleDetails:addMarketruleReducer,
    passwordDetails: changePasswordReducer,
    countryStateDetails: countryStateReducer,
    addLocationDetails: addLocationReducer,
    addMarketLocDetails: addMarketLocReducer,
    updateCurrencyDetails: updateCurrencyReducer,
    updateLocationDetails: updateLocationReducer,
    deletemarketPlaceDetails: deletemarketPlaceReducer,
    deleteAllProductDetails: deleteAllProductReducer,
    brandDetails: brandsReducer,
    productNotifyDetails: productNotificationReducer,
    settingsNotifyDetails: settingsNotificationReducer,
    sellerDetails: sellersReducer,
    addCategoryDetails: addCategoryReducer,
    addDepartmentDetails: addDepartmentReducer,
    addSellerDetails: addSellerReducer,
    qaUserDetails: qaUserReducer,
    productPrevNextDetails:productPrevNextReducer,
    qaapproveDetails: qaApproveReducer,
    downloadxlsDetails: downloadXlsReducer,
    productPriceVariationDetails: productPriceVariationReducer,
    dashboardPriceVariationDetails: dashboardPriceVariationReducer,
    clientsReducerDetails:clientsReducer,
    clientUpdateReducerDetails:clientUpdateReducer,
    clientCreateReducerDetails:clientsCreateReducer,
    clientsMarketCountReducerDetails:clientsMarketCountReducer,
    clientMigrateReducerDetails:clientMigrateReducer,
    commentCreateReducerDetails:commentReducer,
    productKeyValueReducerDetails:productKeyValueReducer,
    deletemarketPlaceFullReducerDetails:deletemarketPlaceFullReducer,
    getAppNotificationReducerDetails:getAppNotificationReducer,
    priceChangeNotificationReducerDetails:priceChangeNotificationReducer,
    PrdUpdateMatchReducerDetails:PrdUpdateMatchReducer,
    getRefreshBtnStatus: getRefreshBtnStatusReducer,
    refreshAll: refreshAllReducer
})

export default reducers;
  