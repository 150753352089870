import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Paper, TextField, InputAdornment, Divider, Box, FormControlLabel, RadioGroup, Radio, FormControl, Avatar, Typography, Tooltip, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import EmailIcon from '@mui/icons-material/Email';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import moment from "moment";
import EditIcon from '@mui/icons-material/Edit';

import styles from "./client.module.css"
import Loader from "../../components/loader";
import MoneyIcon from '@mui/icons-material/Money';
import AdminEditBox from "../../components/adminEdit";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

import selectClients from "../../redux/selectors/clients.selector";
import { getClients } from "../../redux/actions/clientsActions";

import selectClientsMarketCount from "../../redux/selectors/clientMarketCount.selector";
import { getClientsMarketCount } from "../../redux/actions/clientMarketCount.action";

import { withStyles } from "@mui/styles";
import AddClients from "../../components/addClients";
import ClientFeatureAccordion from "../../components/clientFeatureAccordion";

// styling address textfield
const CustomTextField = withStyles({
  root: {
    '& .MuiInputBase-input': {
      overflowY: 'auto',
      scrollbarWidth: 'thin',
      scrollbarColor: '#888888 transparent', // Customize the color
      '&::-webkit-scrollbar': {
        width: '6px', // Adjust the width
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '3px',
        backgroundColor: '#888888', // Customize the color
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#555555', // Customize the color
      },
    },
  },
})(TextField);


function ClientsList(props) {
  // const { themeName } = props;
  const dispatch = useDispatch();
  const [clientsData, setClientsData] = useState([]);
  const [clientsMarketCountData, setClientsMarketCountData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [themeName, setThemeName] = useState("");
  const [radioValue, setRadioValue] = useState("active"); // Initialize with "active" as the default value
  const [editClientValue,setEditClientValue] = useState({})
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [ isClientAddDialogOpen, setIsClientAddDialog] = useState(false);

  const { clients, loading } = useSelector(selectClients);
  const { marketCount, loading: marketCountLoading } = useSelector(selectClientsMarketCount);
// console.log("clientsData",clientsData)
// console.log("clientsMarketCountData",clientsMarketCountData);
  const inputBoxStyle = {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: themeName === "dark" && "#ced4da" , 
      },
    },
  };

// saga function for getting clients list
  useEffect(() => {
    dispatch(getClients());
  }, [dispatch]);

// saga function for getting getClientsMarketCount
  useEffect(() => {
    dispatch(getClientsMarketCount());
  }, [dispatch]);
  
  // accessing theme
  useEffect(() => {
    const storedTheme = localStorage.getItem("appTheme");
    setThemeName(storedTheme); 
  }, [props]);
  
  // setting the clients list to clientsData state
  useEffect(() => {
    setClientsData(clients);
  }, [clients]);

  // setting the clients marketcount list to clientsMarketCount state
  useEffect(() => {
    setClientsMarketCountData(marketCount);
  }, [marketCount]);

  // Filtering logic with radio button
  const filteredClientsData = clientsData?.filter((obj) => {
    if (radioValue === "active") {
      return obj.status === true;
    } else if (radioValue === "inactive") {
      return obj.status !== true;
    }
    return true;
  }).filter((obj) =>
    obj.first_name?.includes(searchInput) || obj.email?.includes(searchInput) || obj?.company_name?.includes(searchInput)
  );

  // Dialog close
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setEditClientValue({});
  };

  // Client add dialog
  const handleOpenAddClientDialog = () => {
    setIsClientAddDialog(true);
  };

  const handleCloseAddClientDialog = () => {
    setIsClientAddDialog(false);
  };

  // Loading loader
  if (loading || marketCountLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Loader />
      </div>
    );
  }

  return (
    <Grid className={styles.container} container item xs={12}>
      {/* Condition to open dialog */}
      {isDialogOpen && <AdminEditBox clientsData={clientsData} onClose={handleCloseDialog} clientValue={editClientValue} />}
      {isClientAddDialogOpen && <AddClients clientsData={clientsData} handleCloseAddClientDialog={handleCloseAddClientDialog} />}
      <Grid item xs={12}><Divider light /></Grid>
      {/* 2nd row */}
      <Grid mt={1} item container xs={12}>
        {/* Search box */}
        <Grid xs={6} item>
          <Box sx={{ height: "100%" }}>
            <Paper sx={{ p: 1, boxShadow: "none", borderRadius: "0", height: "100%" }}>
              <div style={{ padding: "10px" }}>
                <TextField
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  sx={{
                    width: "50%",
                    background: themeName === "dark" ? "#1a222d" : "none",
                    borderRadius: 3,
                    // border: "1px solid #fff !important",
                    outline: themeName === "dark" ? "1px solid #fff" : '#000' ,
                    height: "38px",
                    color: themeName === "dark" ? "#fff" : "#000"}}
                  id="outlined-size-normal"
                  placeholder="Search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    sx: { borderRadius: 3, height: "38px" },
                  }}
                />
                <Tooltip title="Add New Client" placement="top">
                  <PersonAddAlt1Icon onClick={handleOpenAddClientDialog} fontSize="large" sx={{ marginLeft: "1em", color: "#559eff", cursor: 'pointer' }} />
                </Tooltip>
              </div>
            </Paper>
          </Box>
        </Grid>
        {/* Radio button */}
        <Grid xs={6} item>
          <Box sx={{ height: "100%" }}>
            <Paper sx={{ p: 1, boxShadow: "none", borderRadius: "0", height: "100%" }}>
              <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", height: "100%", padding: "10px" }}>
                <FormControl>
                  <RadioGroup
                    value={radioValue}
                    onChange={(e) => setRadioValue(e.target.value)}
                    sx={{ justifyContent: "center", alignItems: "center" }} row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group"
                  >
                    <FormControlLabel value="active" control={<Radio color="success" />} label="Active Clients" />
                    <FormControlLabel value="inactive" control={<Radio color="error" />} label="Inactive Clients" />
                  </RadioGroup>
                </FormControl>
              </div>
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider light />
        </Grid>
      </Grid>
      {/* row 3 */}
      {/* Clients listing */}
      {clientsData?.length === 0 ? (
        <div style={{ display: 'flex', alignItems: 'center', marginTop: "10px" }}>
          <Typography variant="h6">No Clients Found</Typography>
          <Button sx={{ marginLeft: '1em' }} variant="contained" color="success" onClick={() => window.location.reload()}>
            Refresh
          </Button>
        </div>
      ) : (
        filteredClientsData?.sort((a, b) => a.email.localeCompare(b.email))?.map((obj) => {
          const productCountData = clientsMarketCountData?.find((item) => item.id === obj.id);
          const marketplaceCountData = clientsMarketCountData?.find((item) => item.id === obj.id);
          const productCount = productCountData?.product_count ?? "N/A";
          const marketplaceCount = marketplaceCountData?.marketplace_count ?? "N/A";

          return (
            <Grid key={obj.id} container mt={2}>
              <Grid item xs={12}>
                <Paper sx={{ minHeight: "220px" }}>
                  <Grid item xs={12} container style={{ height: "100%" }}>
                    {/* First part */}
                    <Grid item xs={4} container justifyContent="center" alignItems="center">
                      <Grid item xs={3} display="flex" justifyContent="center" alignItems="center">
                        <div style={{ marginTop: "2em" }}>
                          <Avatar sx={{ bgcolor: "red", width: 60, height: 60 }}>{obj.first_name?.charAt(0).toUpperCase()}</Avatar>
                        </div>
                      </Grid>
                      <Grid item xs={9}>
                        <Grid item xs={12}>
                          <div>
                            <Typography variant="h6" mt={2} sx={{ marginLeft: "1em" }}>{obj?.company_name || obj?.first_name}</Typography>
                          </div>
                        </Grid>
                        <div style={{ display: "flex" }}>
                          <Grid item xs={12}>
                            <CustomTextField
                              sx={inputBoxStyle}
                              fullWidth
                              multiline
                              rows={4}
                              variant="outlined"
                              InputProps={{
                                readOnly: true,
                                style: {
                                  background: "none",
                                  border: "none",
                                  resize: "none",
                                },
                              }}
                              value={obj?.address ? obj?.address : "No address found"}
                            />
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>

                    <Grid item xs={1} container justifyContent="center" alignItems="center">
                      <Divider
                        orientation="vertical"
                        flexItem
                        style={{ backgroundColor: "grey", height: "80%", marginTop: "35px" }}
                      />
                    </Grid>
                    {/* Second part */}
                    <Grid item xs={3} container alignItems="center" justifyContent="center">
                      <Grid item xs={12}>
                        <div style={{ marginTop: "3em" }}>
                          <ul style={{ display: "flex", flexDirection: "column", listStyle: "none", padding: 0 }}>
                            <li style={{ display: "flex", alignItems: "center", margin: "2px" }}>
                              <EmailIcon fontSize="medium" />
                              <Typography ml={1}>{obj.email ? obj.email : "N/A"}</Typography>
                            </li>
                            <li style={{ display: "flex", alignItems: "center", margin: "2px" }}>
                              <PhoneIphoneIcon fontSize="medium" />
                              <Typography ml={1}>{obj.phone_number ? obj.phone_number : "N/A"}</Typography>
                            </li>
                            <li style={{ display: "flex", alignItems: "center", margin: "2px" }}>
                              <CalendarMonthOutlinedIcon fontSize="medium" />
                              <Typography ml={1}>{moment(obj?.created_date).format('YYYY-MM-DD HH:mm:ss a')}</Typography>
                            </li>
                            <li style={{ display: "flex", alignItems: "center", margin: "2px" }}>
                              <MoneyIcon fontSize="medium" />
                              <Typography ml={1}>{obj?.currency?.length ? obj.currency[0].currency : "N/A"}</Typography>
                            </li>
                            <li style={{ margin: "2px" }}>
                              <ClientFeatureAccordion themeName={themeName} clientData={obj} competitor_analysis={obj?.competitor_analysis} sales_analysis={obj?.sales_analysis} />
                            </li>
                          </ul>
                        </div>
                      </Grid>
                    </Grid>

                    <Grid item xs={1} container justifyContent="center" alignItems="center">
                      <Divider
                        orientation="vertical"
                        flexItem
                        style={{ backgroundColor: "grey", height: "80%", marginTop: "35px" }}
                      />
                    </Grid>
                    {/* Third part */}
                    <Grid item xs={3} container alignItems="center" justifyContent="center">
                      <Grid item xs={9}>
                        <div style={{ marginTop: "3em" }}>
                          <ul style={{ display: "flex", flexDirection: "column", listStyle: "none", padding: 0 }}>
                            <li style={{ display: "flex", alignItems: "center", margin: "2px" }}>
                              <Typography style={{ whiteSpace: "nowrap" }} ml={-3}>No of Products : </Typography>
                              <Typography style={{ wordBreak: "break-word" }} ml={0}>
                                { `${productCount} / ${Number(obj.no_of_produts_allowed)}` }
                              </Typography>
                              {/* <Typography style={{ wordBreak: "break-word" }} ml={0}>
                                {productCount ? `${productCount} / ${Number(obj.no_of_produts_allowed)}` : "N/A"}
                              </Typography> */}
                            </li>
                            <li style={{ display: "flex", alignItems: "center", margin: "2px" }}>
                              <Typography style={{ whiteSpace: "nowrap" }} ml={-3}>No of Market Place : </Typography>
                              <Typography style={{ wordBreak: "break-word" }} ml={0}>
                                {`${marketplaceCount} / ${Number(obj.no_of_mp_allowed)}` }
                                </Typography>
                            </li>
                            <li style={{ display: "flex", alignItems: "center", margin: "2px" }}>
                              <Typography style={{ whiteSpace: "nowrap" }} ml={-3}>Industry : </Typography>
                              <Typography style={{ wordBreak: "break-word" }} ml={0}>{obj.Industry ? obj.Industry : "N/A"}</Typography>
                            </li>
                            <li style={{ display: "flex", alignItems: "center", margin: "2px" }}>
                              <Typography style={{ whiteSpace: "nowrap" }} ml={-3}>TimeZone : </Typography>
                              <Typography style={{ wordBreak: "break-word" }} ml={0}>
                                {obj?.currency?.length ? obj.currency[0].time_zone : "N/A"}
                              </Typography>
                            </li>
                          </ul>
                        </div>
                      </Grid>

                      <Grid container item xs={3} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                          <EditIcon color="primary" fontSize="medium" onClick={() => {
                            setIsDialogOpen(true);
                            setEditClientValue(obj); // Passing 'obj' as prop to AdminEditBox
                          }} />
                          <Radio color={obj.status === true ? "success" : "error"} defaultChecked={obj.status === true || obj.status === false} />
                        </div>
                      </Grid>

                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          );
        })
      )}

    </Grid>
  );
}

export default ClientsList;
