import { call, put, takeLatest  } from 'redux-saga/effects';  //takeLatest

import NetworkApi from '../../api/NetworkApi';
import { getItem, getItems } from '../../utils/storage';
import paths from '../../api/path';
import { PRD_UPDATE_MATCH,PRD_UPDATE_MATCH_FAILED,PRD_UPDATE_MATCH_RESET,PRD_UPDATE_MATCH_SUCCESSFULL } from '../constants/updateEnablematch.constants';
import { editPrdmatchRate,editPrdmatchRateFailed,editPrdmatchRateReset,editPrdmatchRateSuccessful } from '../actions/updateEnablematch.action';

const getApiConfig = () => {
    const [
      token
    ] = getItems([
      'token',
    ]);

    const headers = {
      Authorization: `token ${token}`,
    };
  
    return {
      Authorization: headers.Authorization
    }
  }
  function* updatePrdmatchRate(action){

    const [userId] = getItems(['userId'])
    const userIdAsNumber = Number(userId);
    const apiConfig = getApiConfig();
    const userRole = getItem('role');
    const payload = action.payload
    const {prd_id} = action.payload;
    const loc_id = action.payload?.locations[0]?.loc?.loc_id
    
    // console.log("first",action.payload)
    payload.loc_id = loc_id;
    payload.prd_mrp = action.payload?.locations[0]?.prd_mrp;
    payload.prd_sales_price = action.payload?.locations[0]?.prd_sales_price;
    payload.prd_offer= action.payload?.locations[0]?.prd_offer;
    payload.seller_id= action.payload?.locations[0]?.seller?.seller_id;
    payload.dept_id= action.payload?.dept?.dept_id;
    payload.category_id= action.payload?.category?.category_id;
    
    
    try {
        // const response = yield call(NetworkApi.put, `${Number(userRole[0] === 2) ? paths.qaUpdateProductURL : paths.updateProductURL}?prd_id=${prd_id}&loc_id=${loc_id}&user_id=${userIdAsNumber}`,
        const response = yield call(NetworkApi.put, `${paths.updateProductURL}?prd_id=${prd_id}&loc_id=${loc_id}&user_id=${userIdAsNumber}`,
        payload,apiConfig);
        // console.log(response)
        yield put(editPrdmatchRateSuccessful(response));
    } catch (error) {
       yield put(editPrdmatchRateFailed(error))
    }
}


  export function* watchPrdEnablematch() {
    yield takeLatest(PRD_UPDATE_MATCH, updatePrdmatchRate);
  }