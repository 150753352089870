import { put, takeLatest, call } from 'redux-saga/effects';
import { CREATE_CLIENTS_MIGRATE,CREATE_CLIENTS_MIGRATE_FAIL,CREATE_CLIENTS_MiGRATE_SUCCESS } from '../constants/clientMigrate.constants';

import { createClientsMigrate,createClientsMigrateFail,createClientsMigrateSuccess } from '../actions/clientMigrate.action';

import NetworkApi from '../../api/NetworkApi';
import { getItem, getItems } from '../../utils/storage';
import paths from '../../api/path';

const getApiConfig = () => {
  const [ token ] = getItems(['token']);

  const headers = {
    Authorization: `token ${token}`,
  };

  return {
   // headers,
    Authorization: headers.Authorization
  }
}
function* createClientsMigrateSaga(action) {
    // console.log("action",action)
  const apiConfig = getApiConfig();
  const userRole = getItem('role');
  try {
    const response = yield call(NetworkApi.post,Number(userRole) === 1 && paths.clientMigrateUrl, { db_name: action.payload }, apiConfig);
    // console.log("response",response)
    yield put(createClientsMigrateSuccess(response));
  } catch (error) {
    // console.log(error.message)
    yield put(createClientsMigrateFail(error));
  }
}

export function* watchCreateClientMigrate() {
  yield takeLatest(CREATE_CLIENTS_MIGRATE, createClientsMigrateSaga);
}
