import { Grid, Paper, Typography } from '@mui/material';
import React from 'react';
// import Logo from '../../components/Logo';
import PrivacyDialog from '../../components/privacyDialog/privacyDialog';
 
function MainFooter({themeName}) {
  const [open, setOpen] = React.useState(false);   

    const currentYear = new Date().getFullYear();
  return (
    <>
    { open && <PrivacyDialog  open={open} setOpen={setOpen} /> }
        <Paper sx={{padding:"8px"}} >
        <Grid xs={12} container >
          <Grid item xs={3} >
            <div>
            <img src={'./assets/logo.png'} alt='OttixHow' style={{height:"30px",marginTop:"12px"}} />
            </div>
          </Grid>
          <Grid item xs={6} >
            <div style={{display:"flex",justifyContent:"center",alignItems:'center',marginTop:"12px"}} >
            <Typography variant="subtitle1" textAlign="center" > &copy; {currentYear} - All rights reserved. </Typography>
            </div>
          </Grid>
          <Grid item xs={3} >
          <div onClick={() => setOpen(true)} style={{ display: 'flex', justifyContent: "flex-end", alignItems: "center", marginTop: "12px", cursor: "pointer", textDecoration: 'underline', color: '#559EFF' }}>
            <Typography variant='subtitle1'>Privacy Policy</Typography>
          </div>

          </Grid>
        </Grid>
        </Paper>
    </>
      );
    }

export default MainFooter;




/* <Grid  container >
      <Grid container padding={1.2} item xs={12}>
        <Paper
          elevation={0}
          sx={{
            // minHeight: "55px",
            padding: '26px',
            width: '100%', 
          }}
        >
          <Grid item xs={10} >
          <Typography variant="h6" color="textSecondary">
            &copy; 2023 - Company Name. All rights reserved.
          </Typography>
          </Grid>
          <Grid item xs={2} >
            asd
          </Grid>
        </Paper>
      </Grid>
    </Grid> */