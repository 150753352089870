import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {editProduct, editProductReset} from '../../../redux/actions/editProducts.actions';
import selectEditedProducts from '../../../redux/selectors/editProduct.selector';
import useActions from '../../../redux/use-actions';


const EnableCrawlMatch = ({productData}) =>{

    const [isCrawl, setIsCrawl] = useState(false);
    const [isMatch, setIsMatch] = useState(false);
    const dispatch = useDispatch();

    const { editedProduct } = useSelector(selectEditedProducts);
    const { isSuccessful, isFailure} = editedProduct;
    //  console.log('addedcompete', isSuccessful)
    const actions = useActions({ editProduct, editProductReset });

    useEffect(() =>{
        // console.log(isSuccessful);
        if(isSuccessful){
            setTimeout(() =>{
                setIsCrawl(false)
                setIsMatch(false)
                dispatch(actions.editProductReset())
            }
            , 1000)
        }
    }, [isSuccessful]) // eslint-disable-line

    useEffect(() =>{
        // console.log(isSuccessful);
        if(isFailure){
            if(isCrawl){
                productData.prd_is_crawl_enabled = !productData.prd_is_crawl_enabled
            }
            if(isMatch){
                productData.prd_is_match_enabled = !productData.prd_is_match_enabled
            }

            setTimeout(() =>{
                setIsCrawl(false)
                setIsMatch(false)
                dispatch(actions.editProductReset())
            }
            , 1000)
        }
    }, [isFailure]) // eslint-disable-line

    const dispatchEditData = () =>{
        productData.loc_id = productData.locations[0].loc.loc_id;
        productData.prd_mrp = productData.locations[0].prd_mrp;
        productData.prd_sales_price = productData.locations[0].prd_sales_price;
        productData.prd_norm_price = productData.locations[0].prd_norm_price;
        productData.prd_offer = productData.locations[0].prd_offer;
        productData.seller_id = productData.locations[0].seller.seller_id;
        productData.category_id =  productData.category.category_id;
        productData.dept_id= productData.dept.dept_id;
        productData.prd_offer= productData.prd_offer || '';
        dispatch(actions.editProduct(productData));
    }


    const handleCrawlEnable = (e) =>{
        productData.prd_is_crawl_enabled = !productData.prd_is_crawl_enabled;
        setIsCrawl(true);
        dispatchEditData();
        
    }
    const handleMatchEnable = (e) =>{
        productData.prd_is_match_enabled = !productData.prd_is_match_enabled;
        setIsMatch(true);
        dispatchEditData();
    }

    return {
        handleMatchEnable,
        handleCrawlEnable,
        editSuccess: isSuccessful , 
        editFailure: isFailure,
        isCrawl,
        isMatch
    }


}

export default EnableCrawlMatch;