import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  // DialogTitle,
  TextField,
  Typography,
  InputAdornment
} from "@mui/material";
import "./productComment.css";
import { useDispatch, useSelector } from "react-redux";

import { deepPurple } from "@mui/material/colors";
import Avatar from "@mui/material/Avatar";
import { getItem } from "../../utils/storage";
import { sendComment } from "../../redux/actions/comments.actions";
import ReplyIcon from '@mui/icons-material/Reply';
import selectCommentCreate from "../../redux/selectors/comment.action";

function ProductCommentBox({ setCommentBoxOpen, commentBoxOpen, prd_id, productData }) {
  const dispatch = useDispatch();
  const [userName, setUserName] = useState("");
  const [currentTheme, setCurrentTheme] = useState("");
  const [message, setMessage] = useState("");
  const [allComments, setAllComments] = useState([]);
  const [allResponses, setAllResponses] = useState([]);
  const [selectedCommentIdReplyIcon, setSelectedCommentIdReplyIcon] = useState(null);
  const [replyText, setReplyText] = useState("");
  const qaUser = getItem('qaUser');

  const { commentStatus } = useSelector(selectCommentCreate);

  const params = useParams();
  const { id } = params;
  const userRole = getItem(["role"]);

  // const token = getItem(["token"]);

  useEffect(() => {
    const storedUserName = localStorage.getItem("username");
    const sanitizedUserName = storedUserName?.replace(/"/g, '');
    sanitizedUserName && setUserName(sanitizedUserName);

    const storedTheme = localStorage.getItem("appTheme");
    storedTheme && setCurrentTheme(storedTheme);

  }, []);

  const handleChatClose = () => {
    setCommentBoxOpen(false);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  useEffect(() => {
    const existingComments = JSON.parse(productData?.prd_comments || null)?.comments || [];
    const existingResponses = JSON.parse(productData?.prd_comments || null)?.responses || [];

    setAllComments(existingComments);
    setAllResponses(existingResponses);
  }, [productData, prd_id]);

  // useRef to store the previous commentStatus
  const prevCommentStatus = useRef(null);

  useEffect(() => {
    if (commentStatus !== prevCommentStatus.current) {
      prevCommentStatus.current = commentStatus;

      if (commentStatus !== null) {
        try {
          const parsedCommentStatus = JSON.parse(commentStatus.prd_comments);
          const updatedComments = parsedCommentStatus.comments || [];
          setAllComments(updatedComments);

          const updatedResponses = parsedCommentStatus.responses || [];
          setAllResponses(updatedResponses);
        } catch (e) {
          // Handle the error
        }
      }
    }
  }, [commentStatus]);

  const handleSend = () => {
    if (message.trim()) {
      const newComment = {
        text: message,
        timestamp: new Date().toISOString(),
        userName: userName,
        id: Date.now().toString(),
        productId: id,
        msgStatus: true
      };

      let updatedComments;

      if (allComments) {
        updatedComments = [...allComments, newComment];
      } else {
        updatedComments = [newComment];
      }

      const updatedComment = {
        prd_id: prd_id,
        prd_comments: {
          comments: updatedComments,
          responses: allResponses,
        },
      };
      // console.log("updatedComment",updatedComment)
      dispatch(sendComment(updatedComment));
      setMessage("");
    }
  };

  const handleReplySend = (commentId, productId, userName) => {
    if (replyText.trim()) {
      const newResponse = {
        text: replyText,
        userName: userName,
        commentId: commentId,
        productId: productId,
        user: "qa@ottixhow",
        id: Date.now().toString(),
        timestamp: new Date().toISOString(),
        resStatus: true
      };

      const updatedComment = {
        prd_id: prd_id,
        user_id: Number(qaUser),
        prd_comments: {
          comments: allComments,
          responses: [...allResponses, newResponse],
        },
      };

      dispatch(sendComment(updatedComment));
      setReplyText("");
      setSelectedCommentIdReplyIcon(null);
    }
  };

  const handleReplyCancel = () => {
    setSelectedCommentIdReplyIcon(null);
    setReplyText("");
  };

  const handleReplyClick = (commentId) => {
    setSelectedCommentIdReplyIcon(commentId);
  };

  const isAdminOrQAUser = userRole === 2 || userRole === 3;
  // const hasNoComments = allComments?.length === 0;
  return (
    <Box>
      <Dialog sx={{ '& .MuiDialogContent-root': { padding: "6px 6px" } }} open={commentBoxOpen} onClose={handleChatClose} maxWidth="md" fullWidth>
        { }
        <DialogContent
          style={{ overflowY: "hidden", overflowX: "hidden", display: "flex", flexDirection: "column", minHeight: "120px" }}>
          {/* comments - section */}
          <div className="chat-messages">
            {allComments.length > 0 ? (
              allComments
                .filter(
                  (comment) =>
                    (userRole === 2 && comment.productId === id) ||
                    (userRole === 3 && comment.userName === userName && comment.productId === id)
                )
                .map((comment) => (
                  <Box
                    key={comment.id}
                    style={{ padding: "8px", background: "lightgrey", marginBottom: "10px", borderRadius: "8px", }}>
                    <div
                      style={{ display: "flex", alignItems: "center", marginBottom: "6px", }}>
                      <Avatar
                        sx={{ bgcolor: deepPurple[500], width: 28, height: 28 }}
                      >
                        {comment?.userName?.replace(/[^a-zA-Z]/g, '').charAt(0).toUpperCase()}
                      </Avatar>
                      <Typography
                        style={{ color: "black", marginLeft: "8px", textTransform: "capitalize" }} variant="h6">
                        {comment?.userName.split("@")[0].replace(/[^a-zA-Z]/g, '')}
                      </Typography>
                      <Typography color="black" fontStyle="italic" variant="body2" marginLeft="auto">
                        {new Date(comment.timestamp).toLocaleString("en-US", {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        })}
                      </Typography>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", marginLeft: "-4em" }}>
                      <div className="talk-bubble tri-right left-top" ></div>
                      <Box
                        sx={{ color: "black", marginTop: "8px", padding: "6px", background: "#ffffff", borderRadius: "8px", maxWidth: "60%", }}>
                        <Typography
                          style={{ color: "black", marginTop: "4px", marginBottom: "4px", wordBreak: "break-word", }}
                          variant="subtitle1">
                          {comment?.text}
                        </Typography>
                      </Box>
                    </div>


                    {/* response mapping */}
                    {allResponses
                      .filter((response) =>
                        userRole === 3
                          ? response.userName === userName &&
                          response.productId === id &&
                          response.commentId === comment.id
                          : response.productId === id &&
                          response.commentId === comment.id
                      )
                      .map((response) => (
                        <div key={response.id}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row-reverse",
                              alignItems: "flex-start",
                              maxWidth: "100%",
                              overflow: "hidden",
                              // marginRight:"-56px"
                            }}
                          >
                            <div className="talk-bubble tri-right right-top"></div>
                            <Box
                              sx={{
                                color: "black",
                                marginTop: "8px",
                                padding: "5px",
                                background: "#d9fdd3",
                                borderRadius: "8px",
                                width: "fit-content",
                                maxWidth: "50%", // Adjust this value as needed
                                marginLeft: "auto",
                                position: "relative",
                                wordWrap: "break-word",
                                marginRight: "-5em",
                              }}
                            >
                              <Typography
                                variant="subtitle1"
                                color="black"
                                style={{ marginBottom: "4px" }}
                              >
                                {response?.text}
                                <span
                                  style={{
                                    fontStyle: "italic",
                                    marginLeft: "auto",
                                    fontSize: "0.8em",
                                    display: "block", // to ensure the date is on a separate line
                                  }}
                                >
                                  {new Date(response?.timestamp).toLocaleString(undefined, {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                  })}
                                </span>
                              </Typography>
                              <div className="response-right-arrow"></div>
                            </Box>
                          </div>
                        </div>
                      ))}

                    {/* reply icon for qa account */}
                    {
                      userRole === 2 && (
                        <Box onClick={() => handleReplyClick(comment.id)} sx={{ fontSize: "large", mt: 1, cursor: "pointer", color: "#2d59ee" }} mt={1}>
                          <ReplyIcon fontSize="large" />
                        </Box>
                      )
                    }
                    {/* input for qa users */}
                    {selectedCommentIdReplyIcon === comment.id && (
                      // <div style={{background:"rgb(211 211 211)",padding:".5em",borderRadius:".5em"}} >
                      <div style={{ background: "rgb(153 148 148)", padding: ".5em", borderRadius: ".5em" }} >
                        <TextField
                          fullWidth id={`reply-${comment.id}`} label="Reply here ..." value={replyText} onChange={(e) => setReplyText(e.target.value)} multiline maxRows={5}
                          variant="standard"
                          InputProps={{
                            sx: {
                              color: currentTheme === "light" ? "black" : "black",
                              padding: "5px"
                            },
                            endAdornment: (
                              <InputAdornment position="end" style={{ marginRight: "-8px" }}>
                                <Button variant="contained" style={{ marginBottom: "1em", boxShadow: "none" }} onClick={handleReplyCancel}>
                                  Cancel
                                </Button>
                                <Button disabled={!replyText.trim()} variant="contained" color="success" style={{ marginLeft: "8px", marginBottom: "1em", marginRight: "1em", boxShadow: "none" }} onClick={() => handleReplySend(comment.id, id, comment.userName)}
                                >
                                  Send
                                </Button>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    )}
                  </Box>
                ))
            ) : (isAdminOrQAUser && allComments.length === 0 && (
              <div style={{ marginTop: "35px" }} >
                <Typography textAlign="center">No comments yet.</Typography>
              </div>
            )
            )}
          </div>
          {/* input field */}
          {userRole === 3 && (
            <>
              <TextField
                value={message}
                onChange={handleMessageChange}
                multiline
                maxRows={4}
                inputProps={{
                  style: {
                    color: currentTheme === "light" ? "#000000" : "#ffffff",
                  },
                }}
                placeholder="Type here..."
                fullWidth
                autoFocus
                size="small"
                style={{ marginTop: "16px", marginBottom: "5px" }}
              />
            </>
          )}
        </DialogContent>
        {
          userRole === 3 && (
            <>
              <DialogActions sx={{ marginTop: "-15px" }}>
                <Button onClick={handleChatClose} style={{ boxShadow: "none" }} variant="contained">
                  Cancel
                </Button>
                <Button disabled={!message.trim()} style={{ boxShadow: "none" }} variant="contained" color="success" onClick={handleSend} >
                  Send
                </Button>
              </DialogActions>
            </>
          )
        }
      </Dialog>
    </Box>
  );
}

export default ProductCommentBox;