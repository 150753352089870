import { CREATE_CLIENTS,CREATE_CLIENTS_FAIL,CREATE_CLIENTS_SUCCESS } from "../constants/clientsCreate.constants";

export const createClients = (payload) => ({
  type: CREATE_CLIENTS,
  payload,
});

export const createClientsSuccess = (data) => ({
  type: CREATE_CLIENTS_SUCCESS,
  payload: data,
});

export const createClientsFail = (error) => ({
  type: CREATE_CLIENTS_FAIL,
  payload: error,
});
