import React, { useState, useEffect } from 'react';
// import { useParams} from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Stepper, Step, StepLabel, FormGroup, Typography, Grid, Divider, Button, FormControl, InputBase,InputLabel, Select, MenuItem } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { styled } from '@mui/material/styles';

import {editProduct, editProductReset} from '../../redux/actions/editProducts.actions'
import selectEditedProducts from '../../redux/selectors/editProduct.selector'
import useActions from '../../redux/use-actions';

import Categories from '../Categories';
import Department from '../Department';
// import Locations from '../locations';
import { getItem } from '../../utils/storage';

import CompModal from '../Modal'
import { isNaN } from 'lodash';

const editProductSchema = Yup.object().shape({
    name: Yup.string().min(6, 'Too short - should be 6 chars minimum!').required('Required'),
    url: Yup.string().min(8, 'Too short - should be 8 chars minimum!').required('Required'),

  })

  
const steps = [
    'Step 1',
    'Step 2'
  ];


const EditProduct = props => {
     const { productInformation, handleClose } = props
    //  console.log('data',productInformation);
    // const params = useParams()
    // const { id } = params;
    const dispatch = useDispatch();

    const [activeStep, setActiveStep] = useState(0);
    const [completed, ] = useState({});

    const qaUser = getItem("qaUser")
    const { editedProduct } = useSelector(selectEditedProducts);
    const { isSuccessful, isFailure } = editedProduct;
    //  console.log('addedcompete', isSuccessful)
    const actions = useActions({ editProduct, editProductReset });
    
    const {prd_qa_approved, prd_id, prd_name, prd_desc, prd_brand, prd_rating, prd_is_match_enabled, prd_is_crawl_enabled, prd_is_active, prd_matched, prd_tot_reviews,  prd_spec, prd_notification_enabled, category, prd_variants, keyword, prd_medium_image, prd_small_image, dept, prd_upc, prd_asin, prd_uom, prd_url, prd_no_of_pieces, prd_size, locations  } = productInformation;

    // console.log(locations)
    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        'label + &': {
          marginTop: theme.spacing(2),
          width: '100%',
        },
        '& .MuiInputBase-input': {
          borderRadius: 4,
          position: 'relative',
          backgroundColor: 'transparent', //theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
          border: '1px solid #ced4da',
          fontSize: 11,
          width: '100%',
          padding: '10px 12px',
          color: theme.palette.text.primary,
          transition: theme.transitions.create([
            'border-color',
            'background-color',
          ]),
          // Use the system font instead of the default Roboto font.
          fontFamily:'Inter',
          '&:focus': {
            borderColor: theme.palette.primary.main,
          },
        },
      }));


    const totalSteps = () => {
        return steps.length;
      };
    const completedSteps = () => {
        return Object.keys(completed).length;
      };
    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
      };
    const allStepsCompleted = () => {
       return completedSteps() === totalSteps();
    };
    const handleNext = () => {
        const newActiveStep =
          isLastStep() && !allStepsCompleted()
            ? // It's the last step, but not all steps have been completed,
              // find the first step that has been completed
              steps.findIndex((step, i) => !(i in completed))
            : activeStep + 1;
        setActiveStep(newActiveStep);
      };
    
      const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      };



    useEffect(() =>{
        if(isSuccessful){
            setTimeout(() =>{
                dispatch(actions.editProductReset())
                handleClose();
                window.location.reload()
            }
            , 1000)
        }
    }, [handleClose, dispatch, isSuccessful, actions])
    
    const handleSubmit= (values) =>{
         
        if(values.prd_sales_price === '' ){
            values.prd_sales_price = values.prd_mrp;
        } else if(values.prd_mrp === ''){
            values.prd_mrp = values.prd_sales_price;
            // item.prd_norm_price = item.prd_sales_price !== 0 ? 0
        }
        if(values.prd_mrp === '' || values.prd_sales_price === '') {

        }
        // console.log('values ', values);
        // e.preventDefault();
        // e.stopPropogation();
        actions.editProduct(values)
    }
    // console.log('locations[0] ', isFailure);

    return (
        <CompModal {...props}>
            <Typography variant="h5" component={'h5'}>Edit Product</Typography>
            <Divider />
            <Grid item sx={{mt:1}}>
                {isFailure && <Typography variant='body2'>There is some issues with the data what you have submitted.</Typography>}
                {isSuccessful && <Typography variant='body2'>Product edited successfully.</Typography>}
            <Stepper activeStep={activeStep+1} alternativeLabel>
                {steps.map((label) => (
                <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                </Step>
                ))}
            </Stepper>
            <Formik enableReinitialize
            initialValues={{
                prd_id: prd_id || '',
                prd_name: prd_name || '',
                prd_desc: (prd_desc && prd_desc.toLowerCase() !== 'nan' && prd_desc) || '',
                prd_medium_image: prd_medium_image || '',
                prd_small_image: prd_small_image || '',
                prd_brand: prd_brand || '',
                category_id: category?.category_id || '',
                dept_id : dept?.dept_id || '',
                loc_id: locations[0].loc.loc_id || 'loc',
                prd_variants:prd_variants || '',
                // productZip: '040404',
                keyword: (keyword.toLowerCase() !== 'nan' && keyword) || '',
                prd_url: prd_url || '',
                prd_upc: (prd_upc !== null && prd_upc !== undefined && (prd_upc.toLowerCase() !== 'nan' && prd_upc)) || '',
                prd_asin: (prd_asin !== null  && prd_asin !== undefined && (prd_asin.toLowerCase() !== 'nan' && prd_asin)) || '',
                prd_sku: (prd_asin !== null && prd_asin !== undefined  && (prd_asin.toLowerCase() !== 'nan' && prd_asin)) || '',
                prd_uom: prd_uom || '',
                prd_size: prd_size || '',
                prd_rating: (prd_rating !== null && prd_rating !== undefined && prd_rating) || 0, 
                prd_is_match_enabled: prd_is_match_enabled || true, 
                prd_is_crawl_enabled: prd_is_crawl_enabled || true, 
                prd_is_active: prd_is_active || true, 
                prd_matched: prd_matched || 0,
                prd_tot_reviews: prd_tot_reviews || 0,  
                prd_spec: prd_spec || '',
                prd_notification_enabled: prd_notification_enabled || true,
                prd_no_of_pieces: prd_no_of_pieces || '',
                // keys: keys || '',
                prd_mrp: parseFloat(locations[0].prd_mrp) || 0 ,
                prd_sales_price: parseFloat(locations[0].prd_sales_price) || parseFloat(locations[0].prd_mrp) || 0,
                prd_norm_price: locations[0].prd_norm_price || '',
                prd_offer: locations[0].prd_offer || '',
                seller_id: locations[0].seller?.seller_id || null,
                prd_qa_approved: prd_qa_approved || false,
                user_id:qaUser
            }}
            validationSchema={editProductSchema}
            >
                {({ errors, 
                    setFieldValue,
                    //handleSubmit,
                    values 
                }) => (
                <FormGroup sx={{flexFlow:'column'}}>
                    <Grid item xs={12} sx={{display:activeStep === 0 ? 'flex':'none'}}>
                        <Grid item xs={12} sm={6} sx={{mr:2, pr:2}}>
                        <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                            <InputLabel shrink htmlFor='prd_name' sx={{color:'text.primary'}}>
                                Product Name
                                </InputLabel>
                            <BootstrapInput 
                                value={values.prd_name} 
                                placeholder='Enter Product Name'
                                id='prd_name'
                                onChange={e => setFieldValue('prd_name', e.target.value)}
                            />
                            <Typography color="red" variant="subtitle">{errors.productName}</Typography>
                        </FormControl>
                        <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                            <InputLabel shrink htmlFor='productDescription' sx={{color:'text.primary'}}>
                                Product Description
                                </InputLabel>
                            <BootstrapInput 
                                value={values.prd_desc} 
                                placeholder='Enter Product Description'
                                id='productDescription'
                                onChange={e => setFieldValue('prd_desc', e.target.value)}
                            />
                        </FormControl>
                        <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                            <InputLabel shrink htmlFor='productImage' sx={{color:'text.primary'}}>
                                Product Image
                                </InputLabel>
                            <BootstrapInput 
                                value={values.prd_medium_image} 
                                placeholder='Enter Product Image Url'
                                id='productImage'
                                onChange={e => setFieldValue('prd_medium_image', e.target.value)}
                            />
                        </FormControl>
                        <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                            <InputLabel shrink htmlFor='productBrand' sx={{color:'text.primary'}}>
                                Brand
                                </InputLabel>
                            <BootstrapInput 
                                value={values.prd_brand} 
                                placeholder='Enter Brand Name'
                                id='productBrand'
                                onChange={e => setFieldValue('prd_brand', e.target.value)}
                            />
                        </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}sx={{mr:2, pr:2}}>
                            <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                                <InputLabel shrink htmlFor='productCategory' sx={{position:'relative', color:'text.primary'}}>
                                    Category
                                    </InputLabel>
                                <Categories value={values.category_id}  handleChange={e => setFieldValue('category_id', e.target.value)}/>
                            </FormControl>
                            <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                                <InputLabel shrink htmlFor='productDepartment' sx={{position:'relative', color:'text.primary'}}>
                                    Department
                                    </InputLabel>
                                    <Department value={values.dept_id} 
                                        handleChange={e =>  setFieldValue('dept_id', e.target.value)} />
                                
                            </FormControl>
                            <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                                <InputLabel shrink htmlFor='productKey' sx={{color:'text.primary'}}>
                                    SPEC
                                </InputLabel>
                                <BootstrapInput 
                                    value={values.prd_spec} 
                                    placeholder='Enter Specification'
                                    id='productKey'
                                    onChange={e => setFieldValue('prd_spec', e.target.value)}
                                />
                            </FormControl>
                            <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                                <InputLabel shrink htmlFor='productURL' sx={{color:'text.primary'}}>
                                    URL
                                    </InputLabel>
                                <BootstrapInput 
                                    value={values.prd_url} 
                                    placeholder='Enter URL'
                                    id='productURL'
                                    onChange={e => setFieldValue('prd_url', e.target.value)}
                                />
                            </FormControl>
                        </Grid>
                        {/* <Grid item xs={12}>
                            <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                                <InputLabel shrink htmlFor='productURL' sx={{color:'text.primary'}}>
                                    SPEC
                                    </InputLabel>
                                <BootstrapInput 
                                    value={values.prd_spec} 
                                    placeholder='Enter URL'
                                    id='productURL'
                                    onChange={e => setFieldValue('prd_spec', e.target.value)}
                                />
                            </FormControl>
                        </Grid> */}
                    </Grid>
                    <Grid item xs={12} sx={{display:activeStep === 1 ? 'flex':'none'}} >
                        <Grid item xs={12} sm={6} sx={{mr:2, pr:2}}>
                            <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                                <InputLabel shrink htmlFor='productUPC' sx={{color:'text.primary'}}>
                                    UPC
                                    </InputLabel>
                                <BootstrapInput 
                                    value={values.prd_upc} 
                                    placeholder='Enter UPC'
                                    id='productUPC'
                                    onChange={e => setFieldValue('prd_upc', e.target.value)}
                                />
                            </FormControl>
                            <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                                <InputLabel shrink htmlFor='productASIN' sx={{color:'text.primary'}}>
                                    SKU
                                    </InputLabel>
                                <BootstrapInput 
                                    value={values.prd_asin} 
                                    placeholder='Enter SKU'
                                    id='productASIN'
                                    onChange={e => setFieldValue('prd_asin', e.target.value)}
                                />
                            </FormControl>
                            <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                                <InputLabel shrink htmlFor='productMeasure' sx={{color:'text.primary'}}>
                                    Unit of measurement
                                    </InputLabel>
                                <BootstrapInput 
                                    value={values.prd_uom} 
                                    placeholder='Enter Unit of Measurement'
                                    id='productMeasure'
                                    onChange={e => setFieldValue('prd_uom', e.target.value)}
                                />
                            </FormControl>
                            <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                                <InputLabel shrink htmlFor='productSize' sx={{color:'text.primary'}}>
                                    Size
                                    </InputLabel>
                                <BootstrapInput 
                                    value={values.prd_size} 
                                    placeholder='Enter Size'
                                    id='productSize'
                                    onChange={e => setFieldValue('prd_size', e.target.value)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}sx={{mr:2, pr:2}}>
                            <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                                <InputLabel shrink htmlFor='productQuantity' sx={{color:'text.primary'}}>
                                    Number of pieces
                                </InputLabel>
                                <BootstrapInput 
                                    value={values.prd_no_of_pieces} 
                                    placeholder='Enter Number of pieces'
                                    id='productQuantity'
                                    onChange={e => {
                                        
                                        setFieldValue('prd_no_of_pieces', e.target.value)
                                        if(!isNaN(values.prd_sales_price) ){
                                            setFieldValue('prd_norm_price', Number(values.prd_sales_price/e.target.value).toFixed(2))
                                        }
                                        
                                    }}
                                />
                            </FormControl>
                            <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                                <InputLabel shrink htmlFor='productKey' sx={{position:'relative', color:'text.primary'}}>
                                    Location
                                </InputLabel>
                                <Select size='small' variant='outlined'
                                    value= {values.loc_id}
                                    input={<BootstrapInput sx={{marginTop:'0px !important', '& .MuiInputBase-input': {paddingTop:'8px', paddingBottom:'8px'}}}/>} 
                                    onChange={e => {
                                        setFieldValue('loc_id', e.target.value)
                                        const locIs =  locations.filter(item => item.loc?.loc_id === e.target.value)
                                        //console.log(e.target.value, locIs)
                                        setFieldValue('prd_mrp', Number(locIs[0].prd_mrp).toFixed(2));
                                        setFieldValue('prd_offer', locIs[0].prd_offer);
                                        setFieldValue('seller_id', Number(locIs[0].seller.seller_id));
                                        setFieldValue('prd_sales_price', Number(locIs[0].prd_sales_price).toFixed(2));
                                        setFieldValue('prd_norm_price', Number(locIs[0].prd_norm_price).toFixed(2));
                                    }}>
                                    <MenuItem value='loc'>Select Location</MenuItem>
                                    {locations && locations.map(locItem =>{
                                        return <MenuItem value={locItem.loc?.loc_id}>{locItem.loc?.loc_name}</MenuItem>
                                    })}

                                </Select>
                                {/* <Locations value={values.loc} handleChange={e => console.log(e.target.value)} /> */}
                            </FormControl>
                            <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                                <InputLabel shrink htmlFor='productMRP' sx={{color:'text.primary'}}>
                                    MRP
                                </InputLabel>
                                <BootstrapInput 
                                    value={values.prd_mrp}
                                    placeholder='Enter MRP'
                                    id='productMRP'
                                    onChange={e => setFieldValue('prd_mrp', e.target.value)}
                                />
                            </FormControl>
                            <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                                <InputLabel shrink htmlFor='productSalePrice' sx={{color:'text.primary'}}>
                                    Sale price
                                </InputLabel>
                                <BootstrapInput 
                                    value={values.prd_sales_price} 
                                    placeholder='Enter Sale Price'
                                    id='productSalePrice'
                                    onChange={e => {
                                        setFieldValue('prd_sales_price', e.target.value)
                                        if(!isNaN(values.prd_no_of_pieces)>0){
                                            setFieldValue('prd_norm_price', Number(e.target.value/values.prd_no_of_pieces).toFixed(2))    
                                        }
                                        
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{mt:2, display:'flex', justifyContent:'space-between'}}>
                <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }} > Back </Button>
                <Button sx={{ display:activeStep === 1 ? 'flex': 'none', alignSelf:'center', width:'50%', backgroundColor:'#559EFF', borderColor:'#1C3B6E', color:'#000000'}}
                        onClick={e => handleSubmit(values)} >Update</Button>
                <Button disabled={activeStep === totalSteps()-1} onClick={handleNext} sx={{ mr: 1 }}> Next </Button>
              </Grid>
                </FormGroup>
                )}
            </Formik>
            
        </Grid>
        </CompModal>
    )

}


export default EditProduct;