import { Typography } from '@mui/material';
import React from 'react';
import PieChartDashboard from '../../components/charts/PieChartDashboard';

function Positiondashboard({currentTheme,assortment_analysis}) {
    // let totalPositionValue = ""
  return (
    <>
    <Typography className='typo' variant='h5' component={'h5'} sx={{color:'text.secondary'}}  >Assortment Analysis</Typography>
    {assortment_analysis ?
    <div style={{margin:0, marginTop:'2rem', textAlign:'', width:'50%'}}>
        <PieChartDashboard currentTheme={currentTheme} assortment_analysis={assortment_analysis} />
    </div>
    : <div style={{margin:'12px 0px 8px 0', width:'61%'}}>
        <img src={currentTheme === 'light'? '/assets/positionlight.jpg' : '/assets/positiondark.jpg'} alt='position' style={{width:'100%'}} />
    </div>
    }
</>
  )
}

export default Positiondashboard;