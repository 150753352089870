import * as constants from '../constants/getLocationQa.constants';

export const getLocationQa = (payload) => ({
    type: constants.GET_LOCATION_QA,
    payload
  });

export const getLocationQaSuccessful = (response) => ({
    type: constants.GET_LOCATION_QA_SUCCESSFUL,
    response
  });
  
export const getLocationQaFailed = (payload) => ({
    type: constants.GET_LOCATION_QA_FAILED,
    payload
  });

