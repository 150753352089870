import React, { useEffect, useState, useContext } from 'react';
// import {useNavigate} from 'react-router-dom';
// import { useSelector } from 'react-redux';
import {Container, Grid, Typography, Paper, Stack } from '@mui/material'
import { styled } from '@mui/material/styles';

import { isNull, isEmpty } from 'lodash';
import {CustomThemeContext} from '../../theme/CustomThemeProvider'
 import { setItem } from '../../utils/storage';
//  import selectUserDetails from '../../redux/selectors/authenticate.selector';


// import CircularPrgs from '../../components/CircularProgress';
import LinearPrgs from '../../components/LinearProgress';

import DashboardVariationChart from '../../components/charts/DashboardVariationChart'
import BarChart from '../../components/charts/BarChart';

import {} from './main.class.css';

import UseDashboard from './useDashboard';
import SummaryDetails from './Summary';
import LocationDetails from './Locations';
import Positions from './Positions';
import MarketPlace from './marketPlace';
import Positiondashboard from './positiondashboard';

const Dashboard = props => {

    // const {themeName} = props;
    const { currentTheme } = useContext(CustomThemeContext)
    const { dashboardDetails, isSuccessful, isFailure } =  UseDashboard();
    const barChartColor = ['#A8CBFF', '#4F8EEC', '#355FBE']
    const {summary,  linkedProducts, matchRate, productStats,assortment_analysis, marketPlace_wise_productStats} = !isNull(dashboardDetails) &&  dashboardDetails;   //locationFilter,
    setItem('pageList', null);
    // console.log(dashboardDetails);
    
    const Item = styled(Paper)(({ theme }) => ({
        // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#232D3B',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        // color: '#FFFFFF',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        boxShadow: 'none'
      }));
    // const basketGraphSize = 150;
    const [circularSize, setCircularSize] = useState(120);
    // const [basketData, setBasketData] = useState(basketGraphSize)
    const [matchLabelData, setMatchLabelData] = useState([])
    useEffect(() => {
        const resizeWindow = () =>{
            if(window.innerWidth > 1350 && window.innerWidth < 1024){
                setCircularSize(120);
                // setBasketData(basketGraphSize)
            } else {

                setCircularSize(120* window.innerWidth/1400 );
                // setBasketData(basketGraphSize* window.innerWidth/1400 );
            }
        }
        window.addEventListener('resize', resizeWindow)
        resizeWindow()
        return ()=> window.removeEventListener("resize",resizeWindow)
    }, [])

    useEffect(() => {
        if(matchRate && !matchRate.status){
            // const obj = Object.keys(matchRate);
            // obj.push('amazon')
            // setMatchLabelData(obj);
             setMatchLabelData(Object.keys(matchRate));
        }
        
    }, [matchRate])

    return (
        <Container maxWidth={'xl'} className='maincontainer' sx={{padding:0, marginTop:2}}>
            {
                isNull(dashboardDetails) ?
                !isSuccessful && !isFailure ? 
                <Grid item sx={{display:'flex',flexFlow:'column', mt:'20%', justifyContent:'center', alignItems:'center'}}>
                    <div style={{display:'flex'}}>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    </div>
                    <Typography variant='' component={'p'} >Loading, Please wait.</Typography>
              </Grid>
                 :
                    // <Typography className='typo' variant='h5' component={'h5'} sx={{mt:'20%', color:'text.secondary'}} >Processing...</Typography>
                    <div
    >
      <img src="/assets/loadinggif.gif" alt="gif" style={{ height:'auto',width:'50px',marginTop:'20%', color:'red',marginLeft:'50%' }} />
    </div>
            :<>
            <Grid container sx={{mb:2}}>
                <Grid item xs={12} sm={7} md={7} lg={7} xl={6} rowSpacing={2} className='gridSubContainer' sx={{flexBasis:'56.25% !important', maxWidth:'56.25% !important',}}>
                    { !isNull(summary) ?
                        <SummaryDetails summary={summary} circularSize={circularSize} {...props} />
                        :
                        <Typography className='typo' variant='h5' component={'h5'} sx={{color:'text.secondary'}} >No Data</Typography>
                    }
                </Grid>
                <Grid item xs={12} sm={5} md={5} lg={5} xl={6} rowSpacing={2} className='gridSubContainer' sx={{flexBasis:'43.75% !important', maxWidth:'43.75% !important', display:'flex', flexFlow:'column'}}>
                    <LocationDetails 
                        // locationFilter={locationFilter}
                        locationFilter = {summary}
                     />
                </Grid>
            </Grid>
            <Grid container spacing={0}>
                <Grid item xs={12} sm={6} md={6} lg={6} rowSpacing={2} className='gridSubContainer'>
                    <Item sx={{padding:'16px 0px', minHeight:240, height:'-webkit-fill-available'}}>
                        <Typography className='typo' variant='h5' component={'h5'} sx={{color:'text.secondary'}} >Linked Products</Typography>
                        <Grid item style={{width:'100%', paddingLeft:16, paddingRight:16}}>
                            <LinearPrgs variant="determinate" label='Total Products' 
                                value={!isEmpty(summary) ? 100 : 0} 
                                labelvalue={!isEmpty(summary) ? summary.productCount : 0} 
                                barcolor='#559EFF'
                            />
                            { !isEmpty( linkedProducts) && !linkedProducts?.status  &&
                                linkedProducts.map((linkedItem, index) => {
                                    const linkedKey = Object.keys(linkedItem)[0];
                                    const linkedValue = Number(linkedItem[linkedKey]);
                                    // console.log('linkedItem ', linkedItem[linkedKey])
                                    return <LinearPrgs key={index} variant="determinate" label= {linkedKey} 
                                        value={!isEmpty(linkedProducts) ? linkedValue > 0 && Math.round((linkedValue/summary.productCount)*100) : 0} 
                                        labelvalue= {linkedValue} 
                                        barcolor= {barChartColor[index]}
                                        />
                                })
                            }
                        </Grid>
                    </Item>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} rowSpacing={2} className='gridSubContainer' sx={{display:'flex', flexFlow:'column'}}>
                    <Grid item xs= {12} style={{ marginLeft:16, padding:'0px', display:'flex', flexFlow:'row', maxHeight:'271px'}}>
                        <Grid item xs={12} md={6}>
                            <Item sx={{justifyContent:'center', padding:'16px 0px', height:'-webkit-fill-available'}}>
                                <Positions productStats={productStats} currentTheme={currentTheme} />
                            </Item>
                        </Grid>
                        {/* <Grid item xs={12} md={6} style={{marginLeft: 16}}>
                            <Item sx={{ justifyContent:'space-between',padding:2, height:'-webkit-fill-available' }}>
                            <Typography className='typo' variant='h5' component={'h5'} sx={{color:'text.secondary'}}  >Basket Analysis</Typography>
                                <CircularPrgs sx={{color: '#285248', backcolor: themeName === 'light'? '#CECECE' :'#515151', padding:'0px 10px', margin:'24px 0', marginBottom:1}} thickness={6} size={basketData} variant="determinate" value={40} labelvalue='No Data' label='' />
                            </Item>
                        </Grid> */}
                        <Grid item xs={12} md={6} style={{marginLeft: 16}}>
                        <Item sx={{justifyContent:'center', padding:'16px 0px', height:'-webkit-fill-available'}}>
                                <Positiondashboard assortment_analysis={assortment_analysis} currentTheme={currentTheme} />
                            </Item>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={0}>
                <Grid item xs={12} sm={6} md={6} lg={6} rowSpacing={2} className='gridSubContainer'>
                    <Item sx={{marginTop:2, padding:'16px 0px'}}>  
                        <Typography className='typo' variant='h5' component={'h5'} sx={{color:'text.secondary'}}  >Match Rate</Typography>
                        {!isEmpty(matchLabelData) ? <>
                            <Grid item style={{width:'85%', marginLeft:0, marginTop:'2em',  display:'flex', justifyContent:'flex-end', flexFlow:'row', alignItems:'flex-end'}}>

                                { matchLabelData.map((matchItem, index) => {
                                        // console.log(matchItem);
                                        return <div key={index} style={{display:'flex', alignItems:'center', marginLeft:index >0 ? 12:0, marginTop: index >0 ? 8:0 }}>
                                            <div style={{backgroundColor:barChartColor[index], width:'16px', height:'16px', borderRadius:'50%'}} />
                                            <Typography className='chartLegendLabel' component={'p'} variant='body2' sx={{color:'text.secondary'}}>{matchItem}</Typography>
                                        </div>
                                    })
                                    
                                }
                        </Grid>
                        <Grid item style={{margin:'10px 0', width:'100%', maxWidth:'95%', height:250}}>
                            <BarChart barRecordData={matchRate} matchLabelData={matchLabelData} currentTheme={currentTheme}  />
                        </Grid>
                        </>
                            : 
                            <Grid item sx={{width:'85%', height:280, alignItems:'center',  marginLeft:0, marginTop:'2em',}}>
                            <Grid style={{margin:'5px 0', width:'100%', maxWidth:'95%', height:'270px'}}>
                                <img src={currentTheme === 'light'? '/assets/matchratelight.jpg': '/assets/matchratedark.jpg'} alt='match rate' style={{width:'100%', height:'100%'}} />
                            </Grid>
                            </Grid>
                        }
                    </Item>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} rowSpacing={2} className='gridSubContainer'>
                    {/* <Item sx={{marginTop:2, marginLeft:2, padding:0, flexFlow:'column'}}> */}
                        <DashboardVariationChart />
                    {/* </Item> */}
                </Grid>
            </Grid>
            {
                !marketPlace_wise_productStats.status && 
                <Stack spacing={2}>
                    <Item sx={{marginTop:2, padding:0, background:'transparent'}}>
                        <MarketPlace data={[...marketPlace_wise_productStats]} />
                    </Item>
                </Stack>
            }
            </>
        }
        </Container>
    )

}

export default Dashboard;