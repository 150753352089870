import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableSortLabel from '@mui/material/TableSortLabel';
// import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { IconButton, MenuItem, Select, Tooltip, Typography, } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from '@mui/icons-material/Link';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { visuallyHidden } from '@mui/utils';
import AlertDialogSlide from '../Dialog';
import MessageDialogSlide from '../Dialog/message';
import EditProduct from '../editProduct';
import BrandProductPop from '../../layout/MyProduct/BrandProductPop';
import ModifyPop from '../../layout/MyProduct/ProductDetails/ModifyPop';
import QaDialog from '../qaDialog';

import { handleDeleteProduct, deleteProductFailed } from '../../redux/actions/deleteproduct.actions';
import deleteProductSelector from '../../redux/selectors/deleteproduct.selector'
import useActions from '../../redux/use-actions';

import { getItem } from '../../utils/storage';
import { isUndefined, isNull, isEmpty } from 'lodash';
import Notification from '../notification';
// import moment from 'moment';
import KeyValue from '../keyvalue';

import SingleRefreshDialog from '../singelRefreshDialog';

import { RefreshRounded } from '@mui/icons-material';


import { getMarketPlace } from '../../redux/actions/marketplace.actions';
import selectMarketPlaceDetails from '../../redux/selectors/marketplace.selector';
import { getRefreshBtnStatus } from '../../redux/actions/getRefreshBtnStatus.actions';
import selectRefreshBtnStatus from '../../redux/selectors/getRefreshBtnStatus.selector';


const Index = props => {
  const { setRowsPerPage, rowsPerPage, page, setPage, data, userRole, getProductList, setpageList, productData, marketPlaceLabel, location } = props;

  // refreshSingledialog code 
  const [ refreshDialogOpen,setRefreshDialosOpen ] = useState(false);
  const [selectedItem,setSelectedItem] = useState();

  const [selectedRefreshValue,setSelectedRefreshValue] = useState("")
  
  const { marketPlaceDetails } = useSelector(selectMarketPlaceDetails);
  const {data:marketplaceLists} = marketPlaceDetails;
  const [marketLabelData, setMarketLabelData] = useState([]);
  


const [fetchBtnClicked, setFetchBtnClicked] = useState(false);

  useEffect(() =>{
    if(!marketplaceLists && userRole !== 2){
        dispatch(getMarketPlace());
    }
  }, [marketplaceLists, userRole]) 

  useEffect(() => {
    setMarketLabelData(marketplaceLists);
  }, [marketplaceLists, userRole])

  const [productList, setProductList] = useState([])
  const [deletePop, setDeletePop] = useState({
    isVisible: false,
    productId: null
  })
  const [modifyPopOption, setModifyPopOption] = useState(false)
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [deleteMessagePop, setDeleteMessagePop] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState('')
  const queryParams = new URLSearchParams(location).toString();
  const [brandProduct, setBrandProduct] = useState({
    isVisible: false,
    productInfo: null,
    brand: null
  })
  const [editedProduct, setEditedProduct] = useState({
    isVisible: false,
    productInformation: null
  })
  let [currency,] = useState(!isNull(getItem('currency')) && !isUndefined(getItem('currency')) ? getItem('currency').length !== undefined ? typeof (getItem('currency')) === 'string' ? JSON.parse(getItem('currency'))[0] : getItem('currency')[0] : JSON.parse(getItem('currency')) : { 'currency_symbol': '$' });
  // let [customDesign, ] = useState(getItem('customDesign') !== undefined );
  const DELETE_MESSAGE = 'Deleting Product, Please wait...';
  const { deleteProductDetails } = useSelector(deleteProductSelector);
  const { isSuccessful, isFailure, errorMessage } = deleteProductDetails;
  const actions = useActions({ handleDeleteProduct, deleteProductFailed });
  const dispatch = useDispatch();
  const [qaDialogOpen, setQaDialogOpen] = useState(false);
  // const [prdColTemp, setPrdColTemp] = useState([]);
  // const [ mrktAdded, setMrktAdded] = useState(false);
  const [ numOfMarkt, setNumOfMarkt] = useState(0);

  const [productColumn, setProductColumn] = useState(
    [
      {
        id: 'prd_mod_name',
        label: 'Name',
        minWidth: 250,
        isEnable: true,
        align: 'left',
      },
      {
        id: 'actionsData',
        label: 'Action',
        minWidth: 100,
        isEnable: false,
        align: 'center',
      },
      {
        id: 'filtered_sales_price',
        label: 'Price',
        minWidth: 100,
        maxWidth: 120,
        isEnable: true,
        align: 'center',
        format: (value) => {
          const formattedValue = value?.toLocaleString(undefined, { minimumFractionDigits: 2 });
          return `${!isEmpty(currency) ? currency.currency_symbol ? currency.currency_symbol : currency.currency : '$'} ${formattedValue}`;
        }
      },
      {
        id: 'prd_brand',
        label: 'Brand',
        minWidth: 90,
        isEnable: true,
        align: 'center',
      },
      {
        id: 'categoryname',
        label: 'Category',
        minWidth: 80,
        isEnable: true,
        textTransform: 'capitalize',
        align: 'center',
        format: (value) => !isNull(value) && !isUndefined(value) ? value : 'N/A'
      },
      {
        id: 'deptname',
        label: 'Department',
        minWidth: 80,
        isEnable: true,
        style: { textTransform: 'uppercase' },
        textTransform: 'uppercase',
        align: 'center',
        format: (value) => !isNull(value) && !isUndefined(value) ? value : 'N/A'
      },
      {
        id: "keyvalue",
        label: "Key Value",
        minWidth: 100,
        align: 'center',
        isEnable: true,
      },
      {
        id: 'notifications',
        label: 'Notification',
        isEnable: false,
        minWidth: 100,
        align: 'center',
      }
    ])

  //  logic for conditional displaying of modified_date column
  const hasBuyBoxColumn = productColumn.some(column => column.id === 'buybox');
  const hasLastUpdatedColumn = productColumn.some(column => column.id === 'comp_modified_date');

  const customDesign = getItem("customDesign")
  const qaUser = getItem("qaUser")

  if ((customDesign === "UI_2" || qaUser === 6) && !hasBuyBoxColumn) {
    const columnIndex = 3;  // 4th position (index starts from 0)
    productColumn.splice(columnIndex, 0, {
      id: 'buybox',
      label: 'Buy Box',
      minWidth: 90,
      maxWidth: 90,
      isEnable: true,
      align: 'center',
    });
  }
  if ((customDesign === "UI_2" || qaUser === 6) && !hasLastUpdatedColumn) {
    const columnIndex = 4; // 5th position (index starts from 0)

    productColumn.splice(columnIndex, 0, {
      id: 'comp_modified_date',
      label: 'Last Updated',
      minWidth: 100,
      maxWidth: 120,
      isEnable: true,
      align: 'center',
    });
  }
  
  const { refreshStatus } = useSelector(selectRefreshBtnStatus);
  const [showRefreshBtn,setShowRefreshBtn] = useState(true)
  
  useEffect(()=>{
    if (customDesign === "UI_2" || qaUser === 6) {
        dispatch(getRefreshBtnStatus());
    }
},[customDesign])

useEffect(() => {
  if (customDesign === "UI_2" || qaUser === 6) {
    if (refreshStatus.data) {
      const tempStatus = refreshStatus.data.show_refresh;
      
      if(tempStatus){
          setShowRefreshBtn(true);
      }else{
          setShowRefreshBtn(false);
      }
    }
  }
}, [ refreshStatus]);

  const handleProduct = (item) => {
    if (userRole === 2) {
      setQaDialogOpen(true)
      setTimeout(() => {
        setQaDialogOpen(false)
      }, 2000)
    } else {
      setEditedProduct({
        isVisible: true,
        productInformation: item
      })
    }
  }
  const handleBrandClose = () => {
    setBrandProduct({
      isVisible: false,
      productInfo: null,
      brand: null
    })
  }
  const handleBrand = (item, pBrand) => {
    setBrandProduct({
      isVisible: true,
      productInfo: item,
      brand: pBrand
    })
  }

  const copyPrdName = (pName) => {
    const parts = pName.split("-");
    const resultString = parts.slice(0, -1).join('-');
    navigator.clipboard.writeText(resultString);
  }

  useEffect(() => {
    const createData = (args) => {
      const returnItems = args.map((item) => {
        item.notifications = <Notification type='select' item={item} />
        item.keyvalue = <KeyValue item={item} />
        item.actionsData = generateActionData(item);
        item.prd_mod_name = typeof (item.prd_name) === 'string' ? item.prd_name : item.prd_name;
        return item;
      })
      setProductList(returnItems)
    }

    createData(productData)
    let pageStr = ';'
    let pageLength = 0;
    if (productData.length < rowsPerPage && productData.length < 10) {
      pageLength = 0;
    }
    if (!isNull(data) && !isNull(data.next)) {
      pageStr = data?.next.split('?')[1].split('&');
      const ss = pageStr.findIndex(item => {
        return item.search('page=') !== -1;
      })
      const pageSize = pageStr.findIndex(item => {
        return item.search('page_size=') !== -1;
      })
      setRowsPerPage(pageStr[pageSize].split('=')[1])
      pageLength = pageStr[ss].split('=')[1] - 2
      setPage(pageLength)

    } else if (!isNull(data) && !isNull(data.previous)) {
      pageStr = data?.previous.split('?')[1].split('&');
      const ss = pageStr.findIndex(item => {
        return item.search('page=') !== -1;
      })
      // console.log('ss ', ss, pageStr[ss])

      pageLength = ss !== -1 ? pageStr[ss].split('=')[1] : 1
      setPage(Number(pageLength))
      const pageSize = pageStr.findIndex(item => {
        return item.search('page_size=') !== -1;
      })
      setRowsPerPage(pageStr[pageSize].split('=')[1])
    } else if (!isNull(data) && isNull(data.previous) && isNull(data.next) && getItem('pageList') !== null) {
      // console.log('pageList ', getItem('pageList'))
      const tempPageList = JSON.parse(getItem('pageList')).split('&');
      tempPageList.map(item => {
        if (item.indexOf('page=') > -1) {
          setPage(0);
        }
        if (item.indexOf('page_size=') > -1) {
          setRowsPerPage(item.split("=")[1] ? item.split("=")[1] : rowsPerPage);
        }
        return item;
      })
    }
    else {
      setPage(0)
    }
  }, [productData]); // eslint-disable-line
  useEffect(() => {
    if (isFailure && errorMessage !== '' && deleteMessagePop) {
      setDeleteMessage('There are some error. Try again to delete...');
    } else if (isSuccessful) {
      setDeleteMessage('Product deleted successfully...');
      setTimeout(() => {
        setDeleteMessagePop(false)
        dispatch(actions.deleteProductFailed())
        getProductList();
      }, 500)
    }
  }, [isSuccessful, isFailure, errorMessage, deleteMessagePop]) // eslint-disable-line

  const addDifference = (pValue, pRow, pColumnId, prop) => {
    // const pSalesValue = pRow['filtered_sales_price'];
    const brandName = pColumnId.split('competitorMaxPrice')[1]
    // const compSalesValue = Number(pValue.split(" ")[1]);
    // const prdSalesValue = Number(pSalesValue);
    let compPpu = currency ? `${currency?.currency_symbol}${prop}` : `${currency?.currency}${prop}`;;
    // if(compSalesValue < prdSalesValue){
    //   const diff = Number(prdSalesValue - compSalesValue).toFixed(2);
    //   // console.log(diff, (prdSalesValue - compSalesValue))
    //   pValue = `${pValue} <br /><span style="font-Size:9px"> (< ${diff})</span>`
    // } else if(prdSalesValue < compSalesValue){
    //   const diff = Number(compSalesValue - prdSalesValue).toFixed(2);
    //   pValue = `${pValue} <br /><span style="font-Size:9px"> (> ${diff})</span>`
    // }
    // pValue = <Button onClick={e => handleBrand(pRow)}><span dangerouslySetInnerHTML={{__html: pValue}} /></Button>
    pValue = <div style={{ color: 'inherit', padding: 0, cursor: "pointer" }} onClick={e => handleBrand(pRow, brandName)}>
      <div style={{ fontSize: ".7065rem", }} dangerouslySetInnerHTML={{ __html: pValue }} />
      {(customDesign === "UI_2" || qaUser === 6) ? null : <div style={{ textTransform: 'none', fontSize: "0.5785rem" }} dangerouslySetInnerHTML={{ __html: compPpu }} />}
    </div>
    return pValue
  }


  useEffect(() => {
      const tempProductColumn = [...productColumn];
      tempProductColumn.splice(3, numOfMarkt);
      setNumOfMarkt(marketPlaceLabel.length);
      marketPlaceLabel.forEach((item, index) => {
        // const compPPU = {
        //   id: `competitorMaxNormPrice${item}`,
        //   label: `${item}`,
        //   minWidth: 100,
        //   isEnable: true,
        //   align: 'center',
        //   format: (value) => value ? value : 'N/A'
        // };
        // tempProductColumn.splice(3 + index, 0, compPPU);
  
        const obj = {
          id: `competitorMaxPrice${item}`,
          label: `${item}`,
          minWidth: 100,
          isEnable: true,
          align: 'center',
          format: (value, row) => {
  
            return value ? `${!isEmpty(currency) ? currency.currency_symbol ? currency.currency_symbol : currency.currency : '$'} ${value?.toLocaleString(undefined, { minimumFractionDigits: 2 })}` : 'N/A';
          },
        };
  
        tempProductColumn.splice(3 + index, 0, obj);
      });
      setProductColumn(tempProductColumn);
      // setMrktAdded(true)
  }, [marketPlaceLabel]); // eslint-disable-line

  const handleModifySuccess = () => {
    setEditedProduct({
      isVisible: false,
      productInformation: null
    })
    // setModifyPopOption(false);
  }
  const handleModifyFailed = () => {
    setModifyPopOption(false);
  }
  const showModifiPop = (item) => {
    window.open(item, '_blank');
  }
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleSuccess = () => {
    const pop = { ...deletePop };
    pop.isVisible = false;
    setDeletePop(pop);
    setDeleteMessage(DELETE_MESSAGE);
    actions.handleDeleteProduct(pop.productId)
    setDeleteMessagePop(true);
  }
  const handleFailed = () => {
    setDeletePop({ isVisible: false, productId: null });
  }

  // console.log('productlist', productList)
  const showDeletePop = (pid) => {
    if (userRole === 2) {
      setQaDialogOpen(true)
      setTimeout(() => {
        setQaDialogOpen(false)
      }, 2000)
    } else {
      setDeletePop({ isVisible: true, productId: pid });
    }
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const generateActionData = (items) => {
    // console.log(showRefreshBtn)
    const actionInformation =
      (<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
        {
          customDesign === "UI_2" && <Button disabled={fetchBtnClicked} aria-label="refresIcon" size="small" onClick={() => 
          // (customDesign === "UI_2" || qaUser === 6) && <Button disabled={fetchBtnClicked} aria-label="refresIcon" size="small" onClick={() => 
          {
            if(showRefreshBtn){
              setRefreshDialosOpen(true)
              setSelectedItem(items)
              setFetchBtnClicked(true)
            }else{
              setRefreshDialosOpen(false)
            }
          }
          }
          >
          <RefreshRounded id='refresIcon' sx={{ fontSize: 14, color: 'text.secondary' }} />
        </Button>
        }
        <IconButton aria-label="deleteicon" size="small" onClick={() => showDeletePop(items.prd_id)}>
          <CloseIcon id='deleteicon' sx={{ fontSize: 14, color: 'text.secondary' }} />
        </IconButton>
        <IconButton aria-label="editicon" size="small" onClick={() => handleProduct(items)}>
          <EditIcon id='editicon' sx={{ fontSize: 14, color: 'text.secondary' }} />
        </IconButton>
        {items.prd_url && items.prd_url !== '' &&
          <IconButton aria-label="linkicon" size="small" onClick={() => showModifiPop(items.prd_url)}>
            <LinkIcon id='linkicon' sx={{ fontSize: 14, color: 'text.secondary' }} />
          </IconButton>
        }

      </Grid>
      )
    return actionInformation
  }
  
  function getComparator(order, orderBy) {
    return (a, b) => {
      if (orderBy === 'keyvalue') {
        const aValue = a.prd_is_key_value ? 1 : 0;
        const bValue = b.prd_is_key_value ? 1 : 0;

        if (bValue < aValue) {
          return order === 'asc' ? -1 : 1;
        }
        if (bValue > aValue) {
          return order === 'asc' ? 1 : -1;
        }
        return 0;
      } else if (orderBy.startsWith('competitorMaxPrice')) {
        // const item = orderBy.replace('competitorMaxPrice', ''); // Extract the item name from the orderBy string
        const aValue = parseFloat(a[orderBy]);
        const bValue = parseFloat(b[orderBy]);

        if (isNaN(aValue)) {
          return 1; // Handle cases where aValue is not a number
        }

        if (isNaN(bValue)) {
          return -1; // Handle cases where bValue is not a number
        }

        // Compare numeric values
        if (aValue < bValue) {
          return order === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return order === 'asc' ? 1 : -1;
        }
        return 0;
      } else {
        // Default sorting logic for other columns using localeCompare for strings
        const aValue = a[orderBy]?.toString();
        const bValue = b[orderBy]?.toString();

        return order === 'asc' ? aValue?.localeCompare(bValue) : bValue?.localeCompare(aValue);
      }
    };
  }




  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    return (
      <TableHead>
        <TableRow>
          {productColumn.map((column) => (
            <TableCell
              key={column.id}
              align={column.align}
              sx={{ minWidth: column.minWidth, border: 'none', textTransform: 'capitalize', color: '#e3e3e3', fontSize: 13, padding: '5px', background: '#314055' }}
              sortDirection={orderBy === column.id ? order : false}
            >
              {column.isEnable ?
                <TableSortLabel
                  style={{
                    color: orderBy === column.id ? '#559eff' : 'white', // #559eff when active, white when not
                  }}
                  active={orderBy === column.id}
                  direction={orderBy === column.id ? order : 'asc'}
                  onClick={createSortHandler(column.id)}
                >
                  {column.label}
                  {orderBy === column.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
                : <>
                  {column.label}
                </>
              }

              {/* {column.label} */}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
  };


  const getURLLink = (pName) => {
    const filtered = productData.filter(item => String(item.prd_mod_name).toLowerCase() === String(pName).toLowerCase());

    if (!isEmpty(filtered)) {
      const maxLength = 55;
      const truncatedValue = pName.length > maxLength ? `${pName.substring(0, maxLength)}...` : pName;

      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {customDesign === "UI_2" || qaUser === 6 ? (
          // not navigating to myproducts
            <Tooltip title={pName} placement="top">
              <Link style={{ textDecoration: 'none' }} >
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {truncatedValue}
              </Typography>
                </Link>
            </Tooltip>
          ) : (
            // navigating to myproducts
            <Tooltip title={pName} placement="top">
              <Link style={{ textDecoration: 'none' }} to={`/myproducts/${filtered[0].prd_id}?${queryParams}`}>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {truncatedValue}
                </Typography>
              </Link>
            </Tooltip>
          )}

          {Number(userRole) === 2 && filtered[0].prd_qa_approved && (
            <CheckCircleIcon sx={{ ml: 1, fontSize: 20, color: '#1d9e42' }} />
          )}
          {(customDesign === "UI_2" || qaUser === 6) && (
            <IconButton style={{paddingLeft: "10px"}} onClick={()=> copyPrdName(pName)}>
              <ContentCopyIcon id='copyicon' sx={{ fontSize: 14, color: 'text.secondary' }}/>
            </IconButton>
          )}
        </div>
      );
    }

    return null;
  };



  const handlePrevPage = () => {
    const splitStr = data?.previous.split('?')
    if (splitStr[1].indexOf('page_size') === -1) {
      setpageList(`${splitStr[1]}&page_size=${rowsPerPage}`)
    } else {
      setpageList(`${splitStr[1]}`)
    }
  }
  const handleNextPage = () => {
    let splitStr = data?.next.split('?')
    if (splitStr[1].indexOf('page_size') === -1) {
      setpageList(`${splitStr[1]}&page_size=${rowsPerPage}`)
    } else {
      setpageList(`${splitStr[1]}`)
    }
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0)
    setpageList(`page=1&page_size=${event.target.value}`)
  };
  const checkLabelValue = (label, value, prdSalesPrice) => {
    if (label === 'filtered_sales_price') {
      if (value === 0) return 'transparent';
      if (prdSalesPrice === null) return 'transparent';
      return '#71AEFF';
    } else if (label.indexOf('competitorMaxPrice') !== -1) {
      const percent = 20;
      // console.log('prdSalesPrice ', prdSalesPrice, value)
      if ((prdSalesPrice === null && value > 0) || (prdSalesPrice === 0 && value > 0)) {
        return 'red';
      }
      // applying red color if prdSalesPrice =0 and value >0
      if (value < prdSalesPrice && prdSalesPrice > 0 && value > 0) {
        const higherDifference = ((value) / prdSalesPrice) * 10
        // console.log('higherDifference ', higherDifference)
        const colorVal = higherDifference < percent ? '#fe8383' : '#ff6464';
        return colorVal
      } else if (value === prdSalesPrice && prdSalesPrice > 0) {
        return '#FFA132'   //'#DAC934';
      } else if (value > prdSalesPrice && prdSalesPrice > 0 && value > 0) {
        const lowerDifference = ((prdSalesPrice) / value) * 10
        // console.log('lowerDifference ', lowerDifference)
        const colorVal = lowerDifference < percent ? '#78d587' : '#389c60';
        return colorVal;
      }
    }
    return 'transparent'
  }

  return (
    <Grid item xs={12}>
      {userRole !== 2 && deleteMessagePop && (
        <MessageDialogSlide>
          {deleteMessage}
        </MessageDialogSlide>
      )}
      {
        userRole === 2 && qaDialogOpen && <QaDialog />
      }
      { showRefreshBtn && refreshDialogOpen && <SingleRefreshDialog selectedItem={selectedItem} selectedRefreshValue={selectedRefreshValue} setSelectedRefreshValue={setSelectedRefreshValue} marketLabelData={marketLabelData} userRole={userRole} setRefreshDialosOpen={setRefreshDialosOpen} refreshDialogOpen={refreshDialogOpen} /> }
      {/* {deletePop.isVisible && <AlertDialogSlide isAlert={false} handleSuccess={handleSuccess} handleFailed={handleFailed}/>} */}
      {userRole !== 2 && deletePop.isVisible && <AlertDialogSlide isAlert={false} handleSuccess={handleSuccess} handleFailed={handleFailed} />}
      {brandProduct.isVisible && <BrandProductPop handleClose={handleBrandClose} productInformation={brandProduct} />}
      {editedProduct.isVisible &&
        <EditProduct
          productInformation={editedProduct.productInformation}
          handleClose={handleModifySuccess} modalOpen={editedProduct.isVisible}
        />}
      {modifyPopOption && <ModifyPop handleSuccess={handleModifySuccess} handleFailed={handleModifyFailed} />}
      <TableContainer className='custom-scrollbar' sx={{ width: '-webkit-fill-available', maxHeight: 443, height: 'auto', overflowX: 'scroll' }}>
        <Table stickyHeader aria-label="sticky table" sx={{ backgroundColor: 'background.paper' }}>

          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {productList.length === productData.length &&
              stableSort(productList, getComparator(order, orderBy))
                .slice(0, rowsPerPage)
                .map((row, index) => {
                  // Include index parameter in the map function
                  return (
                    <TableRow hover role="productTable" tabIndex={-1} key={`td_${row.prd_id}`}>
                      {productColumn.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            sx={{
                              backgroundColor: checkLabelValue(column.id, value, row.filtered_sales_price),
                              fontSize: column.id === 'filtered_sales_price' ? '.7065rem' : 11,
                              fontFamily: 'Inter',
                              textTransform: 'capitalize',
                              border: 'none',
                              padding: '6px',
                              color:
                                (column.id === 'filtered_sales_price' || column.id.indexOf('competitorMaxPrice') !== -1) && value > 0
                                  ? '#272727'
                                  : 'text.secondary',
                              fontWeight: 400,
                            }}
                            key={column.id}
                            align={column.align}
                          >
                            {column.id === 'buybox'
                              ? value || 'N/A'
                              : column.format && typeof value === 'number'
                                ? value > 0
                                  ? column.id.includes('competitorMaxPrice') ? (
                                    <>
                                      {addDifference(
                                        `${column.format(value)}`,
                                        row,
                                        column.id,
                                        `${row[`competitorMaxNormPrice${column.label}`]}`
                                      )}
                                      {/* <br /> */}
                                      {/* <span style={{fontSize:"0.625rem",textTransform: 'uppercase' }}>ppu:</span>  <span style={{fontSize:"0.625rem"}} >{`${row[`competitorMaxNormPrice${column.label}`]}`}</span> */}
                                    </>
                                  )
                                    : column.id === 'filtered_sales_price'
                                      ?
                                      (
                                        <>
                                          {`${column.format(value)}`}
                                          <br />
                                          {(customDesign === "UI_2" || qaUser === 6) ? null :
                                          <span style={{ fontSize: "0.5785rem" }} >{currency ? currency?.currency_symbol : currency?.currency}{String(row?.filtered_norm_price)?.match(/^-?\d+(?:\.\d{0,2})?/)[0]} / {row?.prd_uom}</span>
                                          }
                                        </>
                                      )
                                      : column.format(value)
                                  : 'N/A'
                                : value
                                  ? column.id === 'prd_mod_name'
                                    ? <div>{getURLLink(value)}</div>
                                    : column.id === `competitorMaxNormPrice${row.prd_id}` // Access the "competitorMaxNormPrice" value
                                      ? row[column.id] // Display the value from the row object
                                      : value
                                  : 'N/A'}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
          </TableBody>





        </Table>
      </TableContainer>

      {
        // productList.length>5 && 

        <Grid item xs={12} sx={{ mt: 1, width: '-webkit-fill-available' }}  >
          <Grid item sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Grid item xs={12} md={4} lg={4}>
              <Select size='small' value={rowsPerPage} onChange={handleChangeRowsPerPage} variant='outlined'
                sx={{ borderColor: '#E4E3E3', fontSize: 11, textTransform: 'none' }}>
                <MenuItem style={{ fontSize: 11 }} value={5}>5</MenuItem>
                <MenuItem style={{ fontSize: 11 }} value={10}>10</MenuItem>
                <MenuItem style={{ fontSize: 11 }} value={25}>25</MenuItem>
                <MenuItem style={{ fontSize: 11 }} value={100}>100</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} md={8} lg={8} sx={{ display: 'flex', alignItems: 'center' }}>
              <Button disabled={data?.previous === null} onClick={handlePrevPage} variant='outlined'
                sx={{ borderColor: 'text.primary', fontSize: 12, textColor: 'text.primary', textTransform: 'none' }}>Previous</Button>
              <Typography variant='body2' sx={{ fontSize: 12, mx: 1 }}>Page {page + 1} of {Math.ceil(data?.count / rowsPerPage)}</Typography>
              <Button variant='outlined' disabled={data?.next === null} onClick={handleNextPage}
                sx={{ borderColor: 'text.primary', fontSize: 12, color: 'text.primary', textTransform: 'none' }}>Next</Button>
            </Grid>
          </Grid>
        </Grid>
      }
    </Grid>
  )
}
export default Index;