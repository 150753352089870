import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Select, MenuItem, Typography, InputBase } from '@mui/material';
import { styled } from '@mui/material/styles';
import selectProductLocationsDetails from '../../redux/selectors/productLocations.selector';

import selectUpdateLocationDetails from '../../redux/selectors/updatelocation.selector'

import * as actions from '../../redux/actions/productLocations.actions';
import * as locActions from '../../redux/actions/updatelocation.actions'
import { isEmpty } from 'lodash';


const DefaultLocation = props => {

    const [ locationValue ,setLocationValue ] = useState('location')
    const [ isUpdated, setIsUpdated ] = useState(false)

    const dispatch = useDispatch()
    const { productlocationDetails } = useSelector( selectProductLocationsDetails )
    const { prdlocdata } = productlocationDetails;
    const { updateLocationDetails } = useSelector(selectUpdateLocationDetails)
    const { isSuccessful } = updateLocationDetails;

    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        'label + &': {
          marginTop: theme.spacing(1),
        },
        '& .MuiInputBase-input': {
          borderRadius: 4,
          position: 'relative',
          backgroundColor: 'transparent', //theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
          border: '1px solid #ced4da',
          fontSize: 11,
          width: '100%',
          padding: '8px 12px',
          color: theme.palette.text.primary,
          transition: theme.transitions.create([
            'border-color',
            'background-color',
          ]),
          // Use the system font instead of the default Roboto font.
          fontFamily:'Inter',
          '&:focus': {
            borderColor: theme.palette.primary.main,
          },
        },
    }));

    // console.log('loc data', data);
    useEffect(() =>{
        if(!prdlocdata){
            // console.log('loccc', data)
            dispatch(actions.getProductLocations());
        }
    }, [prdlocdata, dispatch])
    
    useEffect(() => {
    if(isUpdated === true){
        setTimeout(() => {
            window.location.reload()
        }, 1000);
    }  
    }, [isUpdated])
    
    useEffect(() =>{
        if(isSuccessful){
            setIsUpdated(true)
            setTimeout(()=>{
                locActions.updateLocationFailed()
                setIsUpdated(false)
            }, 1000)
            
        }

    }, [isSuccessful])

const handleLocationChange = (event) =>{
    const {target: {value}} = event;
    if(value === 'locationvalue') return;

    setLocationValue(value);
    dispatch(locActions.handleUpdateLocation({loc_id:Number(value)}))
}


return (
    <Grid item sx={{display:'flex',mt:1, mb:2, alignItems:'center'}}>
        <Grid item xs={12} sm={4}>
            <Typography variant='body2' sx={{}}>Default Location</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
            <Select size='small' variant='outlined' value={locationValue} onChange={handleLocationChange}
                input={<BootstrapInput sx={{marginTop:'0px !important'}}/>}
                sx={{ width:200 ,fontSize:11, fontFamily:'Inter', color:'#E4E3E3', fontWeight:400}}>
                <MenuItem sx={{fontSize:11}} value={'location'}>Select Location</MenuItem>
                {
                    !isEmpty(prdlocdata) && 
                    prdlocdata.map(
                        (item, index) => {
                            const {loc_is_default, loc_id, loc_zip, loc_name} = item;
                            return <MenuItem key={index} value={loc_id}>{`${loc_name}-${loc_zip} ${loc_is_default === true ? '(default)' :'' }`} </MenuItem>
                        }
                    )
                }
            </Select>
            { isUpdated && <Typography variant='body2'>Updated successfully.</Typography>}
        </Grid>
    </Grid>
)
}
export default DefaultLocation;

