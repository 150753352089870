import { call, put, takeLatest  } from 'redux-saga/effects';  //takeLatest
// import { isEmpty, } from 'lodash';
import * as actions from '../actions/marketplace.actions';
import * as constants from '../constants/marketplace.constants';
import NetworkApi from '../../api/NetworkApi';
import { getItems } from '../../utils/storage';
import paths from '../../api/path';
//import { toast } from 'react-toastify';

const getApiConfig = () => {
  const [
    token
  ] = getItems([
    'token',
  ]);

  const headers = {
    Authorization: `token ${token}`,
  };

  return {
    Authorization: headers.Authorization
  }
}


export function* getMarketPlace(request = {}) {
  const { payload = {} } = request;
  // console.log(payload);

  const apiConfig = getApiConfig()
  const productPath = payload !== ''? `${paths.marketplaceURL}` : `${paths.marketplaceURL}`
  try {
    const response = yield call(NetworkApi.get, productPath, 
      {},
      apiConfig, );
  //  console.log(response);

    if (response) {

      if (!response.details) {
        // console.log(response.length, 'response.length ', response)
         if(response.length>0){
          yield put(actions.getMarketPlaceSuccessful(response));
         } else {
          yield put(actions.getMarketPlaceFailed('Market Place not found.'));
         }
        
      } else {
        yield put(actions.getMarketPlaceFailed(response.details));
      }
    } else {
      // console.log('error ', response)
      const message = response?.details ? (
        response?.details
      ) : (
        ''
        // <FormattedMessage id="notProcess" />
      );
      yield put(actions.getMarketPlaceFailed(message));
    }

  } catch (error) {
    const message = error?.response?.data?.detail ? (
        error?.message
    ) : (
      ''
    );
    
    yield put(actions.getMarketPlaceFailed(message));
  }
}

export function* fetchMarketPlace() {
  yield takeLatest(constants.GET_MARKET_PLACE, getMarketPlace);
}
