import React, { forwardRef, useEffect, useState, useImperativeHandle } from 'react';
import { Box, Grid, FormControl, OutlinedInput, InputAdornment, InputBase, Icon, Button, FormGroup, Typography, Select, MenuItem } from '@mui/material';
import { SearchOutlined } from '@mui/icons-material';
import { isEmpty } from 'lodash';
import { getItem, removeItem, setItem } from '../../utils/storage';
import Table from '../../components/Table/index';
import { styled } from '@mui/material/styles'
import Modal from '../../components/Modal'
import UseMyProduct from '../MyProduct/myproduct';
import DownloadXls from '../../components/Downloadxls';
import Loader from '../../components/loader';
import { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../redux/actions/getRefreshBtnStatus.actions';
import * as refreshActions from '../../redux/actions/refreshAll.actions';
import selectRefreshBtnStatus from '../../redux/selectors/getRefreshBtnStatus.selector';
import selectRefreshAll from '../../redux/selectors/refreshAll.selector';
import selectMarketPlaceDetails from '../../redux/selectors/marketplace.selector';

const RightPanel = forwardRef((props, ref) => {

  const { checkedKeyvalue, setCheckedKeyvalue, rowsPerPage, page, handleQaUser, getFilterdData, priceFilterData, filteringData, categoringData, brandFilterData, locationFilterData, handleRefreshAllData, isFiltering, setIsFiltering, setPage } = props;
  const { userRole, userList, filterdProducts, data, isLoading, isFailure, qaSuccess, handleApproveAll, handleGetProductList, setpageList, setProductList, getProductList, marketPlaceLabel } =
    UseMyProduct({ page, rowsPerPage, priceFilterData, filteringData, categoringData, brandFilterData, locationFilterData, checkedKeyvalue, setCheckedKeyvalue, setIsFiltering })
  const [marketPlace, setMarketPlace] = useState("");
  const [marketLocation, setMarketLocation] = useState("");
  const [approvePop, showApprovePop] = useState(false);
  const [fetchBtnClicked, setFetchBtnClicked] = useState(false);
  const customDesign = getItem('customDesign');
  const qaUser = getItem('qaUser');
  const dispatch = useDispatch();
  const { refreshStatus } = useSelector(selectRefreshBtnStatus);
  const { refreshAll } = useSelector(selectRefreshAll);
  const { marketPlaceDetails } = useSelector(selectMarketPlaceDetails);
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(0),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: 'transparent', //theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
      border: '1px solid #ced4da',
      fontSize: 11,
      width: '100%',
      padding: '8px 12px',
      color: theme.palette.text.primary,
      transition: theme.transitions.create([
        'border-color',
        'background-color',
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: 'Inter',
      '&:focus': {
        borderColor: theme.palette.primary.main,
      },
    },
  }));


  useImperativeHandle(ref, (e) => ({
    handleFilters(priceFilterData, filtData, cateData, brndData) {
      //  console.log(filteringData, categoringData, brandFilterData, locationFilterData)
      setTimeout(() => {
        setProductList(priceFilterData, filtData, cateData, brndData);
      }, 100)

    }
  }))
  useEffect(() => {
    if (!isEmpty(filterdProducts)) {
      // console.log(filterdProducts, 'data', data)
      getFilterdData(filterdProducts, data);
    }
  }, [filterdProducts, getFilterdData, data]) // eslint-disable-line

  // useEffect(() =>{
  // setSelectedLoc(selectLoc);
  // }, [selectLoc]) // eslint-disable-line

  const OutlinedBox = styled(OutlinedInput)(({ theme }) => {
    return {
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: '#7B7B7B'
      }
    }
  })

  const handleProductList = () => {
    handleRefreshAllData();
    // getProductList();
  }
  const handleSearchProduct = () => {
    const searchStr = `search=${document.getElementById('searchTextBox').value}&prd_mp=${marketPlace ? marketPlace : ""}`
    getProductList(searchStr)
  }
  const handleSearchEvent = (event) => {
    event.preventDefault();
    if (event.keyCode === 13) handleSearchProduct()
  }
  const cancelApproveAll = () => {
    showApprovePop(false);
  }
  const confirmApproveAll = () => {
    showApprovePop(false);
    handleApproveAll()
  }
  const openApproveAll = () => {
    showApprovePop(true);
  }

  const handleFetchLatest = () => {
    setFetchBtnClicked(true);
    // console.log('Called 2nd API');
    dispatch(refreshActions.refreshAll({
        cuttoff_score:100,
        loc_id: marketLocation,
        market_place_id: marketPlace
    }));
  }

  const handleMarketPlaceChange = (e) =>{
    const tempActiveMarket = e.target.value;
    setMarketPlace(tempActiveMarket);
    const activeMarketData = marketPlaceDetails.data.filter((market)=> tempActiveMarket === market.market_place_id);
    const activeLocation = activeMarketData[0].locations.filter((loc)=> !loc.is_my_market_place);
    const temp_loc_Id = activeLocation[0].loc.loc_id;
    setMarketLocation(temp_loc_Id);
    setPage(0);
    setpageList(`page=1&prd_mp=${e.target.value}`)
}

  useEffect(() => {
    if (customDesign === "UI_2" || qaUser === 6) {
      dispatch(actions.getRefreshBtnStatus());
      let tempMarketPlace = getItem("activeMarketPlace");
      let tempMarketLocation = getItem('marketLocation');
      if(tempMarketPlace){
        setMarketPlace(tempMarketPlace);
      }
      if(tempMarketLocation){
        setMarketLocation(tempMarketLocation);
      }
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    if (refreshStatus.data) {
      const tempStatus = refreshStatus.data.show_refresh
      if (tempStatus)
        setFetchBtnClicked(false);
      else
        setFetchBtnClicked(true);
    }
  }, [refreshStatus])

  useEffect(() =>{
    if(marketPlace && (customDesign === "UI_2" || qaUser === 6)){
      setItem('activeMarketPlace', marketPlace);
      setItem('marketLocation', marketLocation);
    }
  }, [marketPlace]) //eslint-disable-line

  useEffect(() =>{
    const tempMarketPlace = getItem("activeMarketPlace");
    if((customDesign === "UI_2" || qaUser === 6) && marketPlaceDetails.data && !marketPlace){
      let firstMarketData = null;
      if(tempMarketPlace){
        firstMarketData = marketPlaceDetails.data.find( mkt=> mkt.market_place_id === tempMarketPlace )
      }else{
        firstMarketData = marketPlaceDetails.data[0];
      }
      const firstLocData = firstMarketData.locations.filter((loc)=> !loc.is_my_market_place);
      const temp_loc_Id = firstLocData[0].loc.loc_id;
      setMarketLocation(temp_loc_Id);
      setMarketPlace(firstMarketData.market_place_id);
      setpageList(`page=1&prd_mp=${firstMarketData.market_place_id}`)
    }
  }, [marketPlaceDetails]) //eslint-disable-line

  return (
    <Fragment>
      {approvePop && <Modal width={300} modalOpen={approvePop} handleClose={cancelApproveAll}>
        <Typography>
          Are you sure to approve all products?
        </Typography>
        <Grid item xs={12} sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
          <Button onClick={cancelApproveAll}
            sx={{ backgroundColor: '#559EFF', fontSize: 11, textTransform: 'none', borderColor: '#1C3B6E', color: '#000000' }}>
            Cancel</Button>
          <Button onClick={confirmApproveAll} sx={{ ml: 1, backgroundColor: '#559EFF', fontSize: 11, textTransform: 'none', borderColor: '#1C3B6E', color: '#000000' }}
          >Okay</Button>
        </Grid>
      </Modal>}
      <Grid item xs={10} sm={10} md={10} sx={{ padding: 2, maxWidth: '85% !important', flexBasis: '85% !important', width: '85% !important' }}>
        <Grid item sx={{ display: 'block' }}>
          {!isEmpty(filterdProducts) ?
            <>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <FormGroup sx={{ flexDirection: 'row', alignItems: 'center' }}>
                  <FormControl sx={{ m: 0, mr: 1, mb: 2, width: '30ch' }} variant="outlined">
                    <OutlinedBox size='small'
                      id="searchTextBox"
                      type='text' sx={{ fontSize: 11, backgroundColor: 'background.paper', color: 'text.secondary' }}
                      onKeyUp={handleSearchEvent}
                      endAdornment={
                        <InputAdornment position="end">
                          <Icon
                            aria-label="search icon"
                            edge="end"
                          >
                            <SearchOutlined sx={{ color: 'text.secondary' }} />
                          </Icon>
                        </InputAdornment>
                      }
                      label=""
                    />
                  </FormControl>
                  <FormControl sx={{ m: 1, mt: 0, mb: 2, width: '7em' }}>
                    <Button sx={{ backgroundColor: '#559EFF', fontSize: 11, textTransform: 'none', borderColor: '#1C3B6E', color: '#000000' }}
                      onClick={handleSearchProduct}
                    >Search</Button>
                  </FormControl>
                  <FormControl sx={{ m: 1, mt: 0, mb: 2, width: '7em' }}>
                    <Button sx={{ backgroundColor: '#559EFF', fontSize: 11, textTransform: 'none', borderColor: '#1C3B6E', color: '#000000' }}
                      onClick={handleProductList}
                    >Reset</Button>
                  </FormControl>
                  {(customDesign === "UI_2" || qaUser === 6) && <FormControl sx={{ m: 1, mt: 0, mb: 2, width: '10em' }}>
                    <Select size='small' variant='outlined' onChange={handleMarketPlaceChange} value={marketPlace}
                      input={<BootstrapInput />}
                      sx={{ ...props.style, width: '100%', fontSize: 11, fontFamily: 'Inter', color: 'text.secondary', fontWeight: 400 }}>
                      {marketPlaceDetails.data && marketPlaceDetails.data.map((item, index) => {
                        const { market_place_id, market_place_name } = item;
                        return <MenuItem key={`market${index}`} value={market_place_id}>{market_place_name}</MenuItem>
                      })
                      }
                    </Select>
                  </FormControl>}
                </FormGroup>
                {/* {console.log('qaSuccess ', qaSuccess)} */}
                {userRole === 2 && <>
                  <Grid item sx={{ display: 'flex', flexFlow: 'column' }}>
                    {qaSuccess && <Typography variant='' component={'p'} >All Product approved successfully.</Typography>}
                    <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                      <Select size='small'
                        input={<BootstrapInput sx={{ marginTop: '0px !important', height: 36, width: 180 }} />}
                        value={getItem('qaUser')}
                        // defaultValue={}
                        className='custom-scrollbar'
                        MenuProps={{ className: 'custom-scrollbar', style: { maxHeight: 200 } }}
                        style={{ marginLeft: 1 }}
                        onChange={(e) => { handleQaUser(e, userList); handleGetProductList(e); }}>
                        {!isEmpty(userList) && userList.map(item => {
                          return <MenuItem key={`qauser_${item.id}`} value={item.id}>{item.email}</MenuItem>
                        })}
                      </Select>
                      <Button sx={{ m: 1, mt: 0, mb: 0, backgroundColor: '#559EFF', fontSize: 11, textTransform: 'none', borderColor: '#1C3B6E', color: '#000000' }} onClick={openApproveAll}>Approve All</Button>
                    </Grid>
                  </Grid>
                </>
                }
                <FormGroup sx={{ flexDirection: 'row', alignItems: 'center' }}>
                  {(customDesign === "UI_2" || qaUser === 6) && <FormControl sx={{ m: 1, mt: 0, mb: 2, width: '7em' }}>
                    <Button disabled={fetchBtnClicked} sx={{ backgroundColor: '#559EFF', fontSize: 11, textTransform: 'none', borderColor: '#1C3B6E', color: '#000000' }}
                      onClick={handleFetchLatest}
                    >Refresh All</Button>
                  </FormControl>}
                  <DownloadXls {...props} />
                </FormGroup>
              </Box>
              <Grid container sx={{}}>
                <Table
                  getProductList={getProductList}
                  setpageList={setpageList}
                  data={data}
                  userRole={userRole}
                  productData={filterdProducts}
                  marketPlaceLabel={marketPlaceLabel}
                  location={locationFilterData[0]}
                  {...props}
                />
              </Grid>
            </>
            : <>
              {<Grid item sx={{ height: 400, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                {
                  (isLoading && (!isFailure)) || isFiltering ?
                    <Loader />
                    : <Typography variant='' component={'p'} >Products are not available</Typography>

                }

                {((isEmpty(filterdProducts) && !isFiltering && !isEmpty(data)) || isFailure) &&
                  <Button
                    sx={{ mt: 1, backgroundColor: '#559EFF', borderColor: '#1C3B6E', color: '#000000' }}
                    onClick={handleProductList}>Refresh</Button>
                }
              </Grid>
              }
            </>
          }
        </Grid>
      </Grid>
    </Fragment>
  )
}, () => true)

export default RightPanel;