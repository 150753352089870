import { put, takeLatest, call } from 'redux-saga/effects';
import axios from 'axios';
import { GET_CLIENTS_MARKETCOUNT,GET_CLIENTS_MARKETCOUNT_FAIL,GET_CLIENTS_MARKETCOUNT_SUCCESS } from '../constants/clientMarketCount.constants';
import { getClientsMarketCount,getClientsMarketCountFail,getClientsMarketCountSuccess } from '../actions/clientMarketCount.action';
import NetworkApi from '../../api/NetworkApi';
import { getItems, getItem } from '../../utils/storage';
import paths from '../../api/path';

const getApiConfig = () => {
  const [token] = getItems(['token']);

  const headers = {
    Authorization: `token ${token}`,
    
  };

  return {
    Authorization: headers.Authorization,
  };
};
function* getClientsSaga() {
  const apiConfig = getApiConfig();
  const userRole = getItem('role');
  
  try {
    const response = yield call(NetworkApi.get, Number(userRole) === 1 && `${paths.clientsMarketCountUrl}`,null, apiConfig);
    // console.log('Response data:', response);
    yield put(getClientsMarketCountSuccess(response));
    // console.log("success")
  } catch (error) {
    yield put(getClientsMarketCountFail(error.message));
  }
}

export function* watchGetClientsMarketCount() {
  yield takeLatest(GET_CLIENTS_MARKETCOUNT, getClientsSaga);
}
