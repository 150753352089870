import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, Typography, TextField, DialogContent, DialogActions, Button, Grid, MenuItem, Box } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import styles from "./addclients.module.css";
import { makeStyles } from '@mui/styles';
import { useDispatch,useSelector } from "react-redux";

import { createClients } from "../../redux/actions/clientCreate.action";
import selectCreateClients from "../../redux/selectors/clientsCreate.selector";

// import { createClientsMigrate } from "../../redux/actions/clientMigrate.action";
// import selectClientsMigrate from "../../redux/selectors/clientMigrate.selector";


const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Email is required"),
  company_name: Yup.string().required("Company Name is required"),
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  Industry: Yup.string().required("Industry is required"),
  phone_number: Yup.string().required('Phone Number is required').matches(/^\+?\d+$/, 'Phone Number must contain only digits'),
  created_date: Yup.date().required("Created Date is required"),
  db_name: Yup.string().required("Db Name is required"),
  time_zone: Yup.string().required("Timezone is required"),
  currency: Yup.string().required("Currency is required"),
  currency_symbol: Yup.string().required('Currency Symbol field cannot be empty'),
  country: Yup.string().required("Country is required"),
  status: Yup.boolean().required("Status is required"),
  flag_status: Yup.boolean().required("Flag Status is required"),
  sales_analysis: Yup.boolean().required("Sales Analysis is required"),
  competitor_analysis: Yup.boolean().required("Competitor Analysis is required"),
  no_of_products_allowed: Yup.number().typeError('Only digits are allowed').required('Number of Products Allowed field cannot be empty'),
  no_of_mp_allowed: Yup.number().typeError('Only digits are allowed').required('Number of MP Allowed field cannot be empty'),
  address: Yup.string().required('Address field cannot be empty'),
  password: Yup.string().required('Password is required').min(8, 'Password must have at least 8 characters'),
  db_connection_string:Yup.string().required('db connection string is required'),
  // flag_status: Yup.boolean().required("Flag Status is required")

});

function AddClients({ handleCloseAddClientDialog,clientsData }) {
  const dispatch = useDispatch();
  // const [emailExists, setEmailExists] = useState(false);
  // const [dbNameExists, setDbNameExists] = useState(false);
  // const [phoneNumberExists, setPhoneNumberExists] = useState(false);
  // const [companyNameExists, setCompanyNameExists] = useState(false);

  // console.log("clientsData in add ",clientsData)

  const [themeName, setThemeName] = useState('');
const useStyles = makeStyles({
  inputField: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: themeName==="dark" && "#ced4da" ,
      },
      '&:hover fieldset': {
        borderColor: themeName==="dark" && "#ced4da",
      },
      '&.Mui-focused fieldset': {
        borderColor: themeName==="dark" && "#ced4da",
      },
    },
    '& .MuiSelect-root': {
      '& .MuiSelect-icon': {
        color: themeName==="dark" && "#ced4da",
      },
      '&:hover .MuiSelect-icon': {
        color: themeName==="dark" && "#ced4da",
      },
      '&.Mui-focused .MuiSelect-icon': {
        color: themeName==="dark" && "#ced4da",
      },
    },
  },scrollbar: {
    '&::-webkit-scrollbar': {
      width: '8px',
      background: '#f5f5f5',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },
  errorMessage: {
    color: 'red', // Customize the error message color here
  },
});
const inputBoxStyle = {
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: themeName === "dark" && "#ced4da" , 
    },
  },
};
const classes = useStyles(); 

useEffect(() => {
  const storedTheme = localStorage.getItem('appTheme');
  setThemeName(storedTheme);
}, []);

// useEffect(() => {
//   setEmailExists(false);
//   setDbNameExists(false);
//   setPhoneNumberExists(false);
//   setCompanyNameExists(false);
// }, [clientsData]);

const { isError, isLoading, clientCreateData } = useSelector(selectCreateClients);
// const { isError: isErrorMigrate, isLoading: isLoadingMigrate, clientCreateMigrateData } = useSelector(selectClientsMigrate);

const handleSubmit =(values) => {
   dispatch(createClients(values));
  // console.log("values",values)
};
// const handleSubmit = async (values) => {
//   const { db_name } = values;
//   try {
//     await dispatch(createClients(values));
//     await dispatch(createClientsMigrate(db_name));
//   } catch (error) {
//     // console.log("Error:", error);
//   }
// };

useEffect(() => {
  if(clientCreateData){
   window.location.reload();
  }
 }, [clientCreateData]);

// useEffect(() => {
//   if(clientCreateData && clientCreateMigrateData){
//    window.location.reload();
//   }
//  }, [clientCreateData]);
 
 useEffect(() => {
   if (isError) {
     setTimeout(() => {
       window.location.reload();
     }, 2000);
   }
 }, [isError]);

const validateEmail = (value) => {
  let error;
  if (!value) {
    error = "Email is required";
  } else if (!/^\S+@\S+\.\S+$/.test(value)) {
    error = "Invalid email address";
  } else if (
    clientsData.some((client) => client.email === value)
  ) {
    error = "Email already exists";
  }
  return error;
};
const validateDbName = (value) => {
  let error;
  if (!value) {
    error = "DB Name is required";
  } else if (
    clientsData.some((client) => client.db_name === value)
  ) {
    error = "DB Name already exists";
  }
  return error;
};

const validatePhoneNumber = (value) => {
  let error;
  if (!value) {
    error = "Phone Number is required";
  } else if (
    clientsData.some((client) => client.phone_number === value)
  ) {
    error = "Phone Number already exists";
  }
  return error;
};

const validateCompanyName = (value) => {
  let error;
  if (!value) {
    error = "Company Name is required";
  } else if (
    clientsData.some((client) => client.company_name === value)
  ) {
    error = "Company Name already exists";
  }
  return error;
};

  const initialValues = {
    email: "",
    company_name: "",
    first_name: "",
    last_name: "",
    Industry: "",
    address:"",
    remarks:"",
    phone_number: "",
    created_date: new Date().toISOString(),
    db_name: "",
    time_zone: "",
    currency: "",
    currency_symbol: "",
    country:"",
    no_of_products_allowed:0,
    no_of_mp_allowed: 0,
    status: true,
    password:"",
    role:3,
    competitor_analysis:true,
    sales_analysis:true,
    db_connection_string:"",
    flag_status:true,
    // profile_image: null,
  };
  

  return (
    <Dialog open={true} onClose={handleCloseAddClientDialog} maxWidth="xl" fullWidth>
      <DialogTitle>
        <Typography textAlign="center">Add New Client</Typography>
        {isLoading && <Typography textAlign="center">Please wait ....</Typography>}
        {isError && <Typography textAlign="center">Error occurred while creating new client</Typography>}
        {clientCreateData && <Typography textAlign="center">New Client created successfully</Typography>}
      </DialogTitle>
      <DialogContent className={classes.scrollbar} >
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ values, setFieldValue }) => (
            <Form>
              <Grid mt={2} container spacing={2}>
              <Grid item xs={6}>
                <Field validate={validateEmail} sx={inputBoxStyle} size="small" type="text" name="email" label="Email" as={TextField} fullWidth />
                <ErrorMessage name="email" component="div" className={styles.errorMessage}/>
              </Grid>
              <Grid item xs={6}>
                <Field validate={validateCompanyName} sx={inputBoxStyle} size="small" type="text" name="company_name" label="Company Name" as={TextField} fullWidth />
                <ErrorMessage name="company_name" component="div" className={styles.errorMessage} />
              </Grid>
              <Grid item xs={6}>
                <Field sx={inputBoxStyle} size="small" type="text" name="first_name" label="First Name" as={TextField} fullWidth />
                <ErrorMessage name="first_name" component="div" className={styles.errorMessage} />
              </Grid>
              <Grid item xs={6}>
                <Field sx={inputBoxStyle} size="small" type="text" name="last_name" label="Last Name" as={TextField} fullWidth />
                <ErrorMessage name="last_name" component="div" className={styles.errorMessage} />
              </Grid>
              <Grid item xs={6}>
                <Field sx={inputBoxStyle} size="small" type="text" name="Industry" label="Industry" as={TextField} fullWidth />
                <ErrorMessage name="Industry" component="div" className={styles.errorMessage} />
              </Grid>
              <Grid item xs={6}>
                <Field validate={validatePhoneNumber} sx={inputBoxStyle} size="small" type="text" name="phone_number" label="Phone Number" as={TextField} fullWidth />
                <ErrorMessage name="phone_number" component="div" className={styles.errorMessage} />
              </Grid>
              <Grid item xs={6}>
                <Field sx={inputBoxStyle} size="small" type="text" name="address" label="Address" as={TextField} fullWidth />
                <ErrorMessage name="address" component="div" className={styles.errorMessage} />
              </Grid>
              <Grid item xs={6}>
                <Field sx={inputBoxStyle} size="small" type="text" name="created_date" label="Created Date" as={TextField} fullWidth inputProps={{ readOnly: true }} />
                <ErrorMessage name="created_date" component="div" className={styles.errorMessage} />
              </Grid>
              <Grid item xs={6}>
                <Field validate={validateDbName} sx={inputBoxStyle} size="small" type="text" name="db_name" label="DB Name" as={TextField} fullWidth/>
                <ErrorMessage name="db_name" component="div" className={styles.errorMessage} />
              </Grid>
              <Grid item xs={6}>
                <Field sx={inputBoxStyle} size="small" type="text" name="time_zone" label="Time Zone" as={TextField} fullWidth  />
                <ErrorMessage name="time_zone" component="div" className={styles.errorMessage} />
              </Grid>
              <Grid item xs={6}>
                <Field sx={inputBoxStyle} size="small" type="text" name="country" label="Country" as={TextField} fullWidth  />
                <ErrorMessage name="country" component="div" className={styles.errorMessage} />
              </Grid>
              <Grid item xs={6}>
                <Field sx={inputBoxStyle} size="small" type="text" name="currency_symbol" label="Currency Symbol" as={TextField} fullWidth  />
                <ErrorMessage name="currency_symbol" component="div" className={styles.errorMessage} />
              </Grid>
              <Grid item xs={6}>
                <Field sx={inputBoxStyle} size="small" type="text" name="currency" label="Currency" as={TextField} fullWidth  />
                <ErrorMessage name="currency" component="div" className={styles.errorMessage} />
              </Grid>
              <Grid item xs={6}>
                <Field sx={inputBoxStyle} size="small" type="text" name="no_of_products_allowed" label="No of Produts Allowed" as={TextField} fullWidth  />
                <ErrorMessage name="no_of_products_allowed" component="div" className={styles.errorMessage} />
              </Grid>
              <Grid item xs={6}>
                <Field sx={inputBoxStyle} size="small" type="text" name="no_of_mp_allowed" label="No of MP Allowed" as={TextField} fullWidth  />
                <ErrorMessage name="no_of_mp_allowed" component="div" className={styles.errorMessage} />
              </Grid>
              <Grid item xs={6}>
                <Field sx={inputBoxStyle} size="small" type="text" name="role" label="Role" as={TextField} fullWidth inputProps={{ readOnly: true }} />
                {/* <ErrorMessage name="role" component="div" className={styles.errorMessage} /> */}
              </Grid>
              <Grid item xs={6}>
                <Field sx={inputBoxStyle} size="small" type="text" name="password" label="Password" as={TextField} fullWidth />
                <ErrorMessage name="password" component="div" className={styles.errorMessage} />
              </Grid>
              {/* db_connection_string */}
              <Grid item xs={6}>
                <Field sx={inputBoxStyle} size="small" type="text" name="db_connection_string" label="db_connection_string" as={TextField} fullWidth />
                <ErrorMessage name="db_connection_string" component="div" className={styles.errorMessage} />
              </Grid>
              <Grid item xs={6}>
                <Field sx={inputBoxStyle} size="small" name="status" label="Status" as={TextField} select fullWidth>
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
                </Field>
                <ErrorMessage name="status" component="div" className={styles.errorMessage} />
                </Grid>
              <Grid item xs={6}>
                <Field sx={inputBoxStyle} size="small" name="competitor_analysis" label="Competitor Analysis" as={TextField} select fullWidth>
                <MenuItem value={true}>Allowed</MenuItem>
                <MenuItem value={false}>Not-Allowed</MenuItem>
                </Field>
                <ErrorMessage name="competitor_analysis" component="div" className={styles.errorMessage} />
                </Grid>
              <Grid item xs={6}>
                <Field sx={inputBoxStyle} size="small" name="sales_analysis" label="Sales Analysis" as={TextField} select fullWidth>
                <MenuItem value={true}>Allowed</MenuItem>
                <MenuItem value={false}>Not-Allowed</MenuItem>
                </Field>
                <ErrorMessage name="sales_analysis" component="div" className={styles.errorMessage} />
                </Grid>
                {/* flag */}
                {/* <Grid container alignItems="center" item xs={6}>
                  <Grid item>
                    <InputLabel>Flag Status</InputLabel>
                  </Grid>
                  <Grid item>
                    <FormControl fullWidth size="small" variant="outlined">
                      <Field name="flag_status">
                        {({ field }) => (
                          <Switch
                            {...field}
                            checked={field.value === true}
                            color="primary"
                            inputProps={{ 'aria-label': 'flag status' }}
                          />
                        )}
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item>
                  <Typography variant="body1">{values.flag_status ? 'True' : 'False'}</Typography>
                  </Grid>
                  <Grid item>
                    <ErrorMessage name="flag_status" component="div" className={styles.errorMessage} />
                  </Grid>
                </Grid> */}

                {/* logo */}
                {/* <Grid item xs={12}>
                  <input
                    type="file"
                    name="profile_image"
                    onChange={(event) => setFieldValue("profile_image", event.currentTarget.files[0].name)}
                  />
                  <ErrorMessage name="profile_image" component="div" className={styles.errorMessage} />
                </Grid> */}
              </Grid>
              <DialogActions>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '16px' }}>
                <Button variant="contained" color="error" onClick={handleCloseAddClientDialog}>
                  Cancel
                </Button>
                <Button variant="contained" color="success" type="submit">
                  Create
                </Button>
              </Box>
            </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

export default AddClients;
