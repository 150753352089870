import React, {Suspense, useEffect, useState} from 'react';

import { Route, Routes, useLocation } from 'react-router-dom';
import { StyledEngineProvider } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import "react-toastify/ReactToastify.min.css";

import './App.css';

import LoginScreen from './pages/login';
import Header from './layout/header/Header';
// import Footer from './layout/footer/Footer';
import Dashboard from './layout/dashboard/index';
import MyProduct from './layout/MyProduct';
import Contact from './layout/contact';
import Settings from './layout/settings';
import ProductDetails from './layout/MyProduct/ProductDetails'
import AddProduct from './layout/MyProduct/AddProduct';
import Notification from './layout/notification';
import Reports from './layout/report';
// import MySales from './layout/mySales';

// import Layout from './layout';
import ClientsList from './layout/clientSection';
import { getItem } from './utils/storage';
import MainFooter from './layout/mainFooter';
// import selectDashboardDetails from '../../redux/selectors/dashboard.selector'
// import { useSelector } from 'react-redux'
import { useSelector,useDispatch } from 'react-redux';
import selectDashboardDetails from './redux/selectors/dashboard.selector';

// import { getAppNotification } from './redux/actions/getappnotification.action';
// import selectGetappNotification from './redux/selectors/getappnotification.selector';


const App = (props) => {
  const dispatch = useDispatch()
  const {dashboardDetails} = useSelector(selectDashboardDetails)
  
  
// useEffect(() => {
//   dispatch(getAppNotification())
// }, [])
//   const appNotificationData = useSelector(selectGetappNotification); 

  // console.log("appNotificationData",appNotificationData)
  const [theme, setTheme] = useState("dark");
  const locations = useLocation();
  const {pathname} = locations;
  const userRole = getItem('role');

  useEffect(()=> {
    if(pathname === '/login'){
      document.body.classList.add('loginBackground');
    } else {
      document.body.classList.remove('loginBackground');
    }
  }, [pathname])
  

  const setThemeModeHandler=(stheme) =>{
    setTheme(stheme);
  }
  
  let routes = (
      <Routes>
       <Route path="/myproducts" element= { <MyProduct {...props} themeName={theme} />} />
       <Route path="/myproducts/:id" element= { <ProductDetails {...props} themeName={theme} />} />
       <Route path="/addproduct/" element= { <AddProduct {...props} themeName={theme} />} />
       <Route path="/contact/" element= { <Contact {...props} themeName={theme} />} />
       <Route path="/settings" element= { <Settings {...props} setThemeModeHandler={setThemeModeHandler} themeName={theme} />} />
       <Route path="/notifications" element= { <Notification  {...props} themeName={theme} />} />
       <Route path="/reports" element= { <Reports {...props} themeName={theme} />} />
       {/* <Route path="/mysales" element= { <MySales {...props} themeName={theme} />} /> */}
       {userRole === 1 && (
     <Route path="/clients" element={<ClientsList themeName={theme} />} />
        )}

      <Route path= {process.env.NODE_ENV === 'development' ? 'dashboard' : 'dashboard'} element={<Dashboard {...props} themeName={theme} />} />
      <Route exact path={process.env.NODE_ENV === 'development' ? '/login' : 'login'} element={<LoginScreen {...props} themeName={theme}  />} />
      {/* <Route path="*" element={<NoMatch />} /> */}
      {/* <Redirect to="/" /> */}
      </Routes>
  );


  return (
    <div>
      <StyledEngineProvider injectFirst>
      { pathname !== '/login' && <Header dashboardDetails={dashboardDetails} setThemeModeHandler= {setThemeModeHandler} />}
      <div style={{minHeight:"90vh"}} >  { /* added minheight to 90vh eo maintain footer at the end if there is no content */ }
          <Suspense fallback={<p>Loading... </p>}>{routes}</Suspense>
      </div>
      {pathname !== '/login' && <MainFooter themeName={theme} /> }
      <ToastContainer position="top-right" newestOnTop />
      </StyledEngineProvider>
    </div>
  )
}

export default App;
