import { GET_CLIENTS_SUCCESS, GET_CLIENTS, GET_CLIENTS_FAIL } from "../constants/clients.constants";

export const getClients = () => ({
  type: GET_CLIENTS,
});

export const getClientsSuccess = (clients) => ({
  type: GET_CLIENTS_SUCCESS,
  payload: clients,
});

export const getClientsFail = (error) => ({
  type: GET_CLIENTS_FAIL,
  payload: error,
});
