import { call, put, takeLatest } from 'redux-saga/effects';
import NetworkApi from '../../api/NetworkApi';
import paths from '../../api/path';
import {
  GETAPP_NOTIFICATION,
  GETAPP_NOTIFICATION_FAILED,
  GETAPP_NOTIFICATION_SUCCESSFUL,
} from '../constants/getappnotification.constants';
import {
  getAppNotificationFailure,
  getAppNotificationSuccess,
} from '../actions/getappnotification.action';
import { getItem, getItems } from '../../utils/storage';

// Define a function to get the API configuration
const getApiConfig = () => {
  const [token] = getItems(['token']);

  const headers = {
    Authorization: `token ${token}`,
  };

  return {
    headers,
  };
};

// Define the saga function to make the API request
function* getappnotifications(action) {
  try {
    const apiConfig = getApiConfig();
    const userRole = getItem('role');
    // const payload = action.payload;
    // console.log("apiConfig",apiConfig)
    const response = yield call(NetworkApi.get, paths.getappnotificationURL, {}, apiConfig.headers);
    // console.log("response",response)
    yield put(getAppNotificationSuccess(response));
  } catch (error) {
    // console.log("error",error)
    yield put(getAppNotificationFailure(error.message));
  }
}

// Define a watcher saga to listen for GETAPP_NOTIFICATION action
export function* watchgetappnotification() {
  yield takeLatest(GETAPP_NOTIFICATION, getappnotifications);
}
