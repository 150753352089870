import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Button, FormControlLabel, RadioGroup, Radio, DialogActions } from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import selectMarketPlaceDetails from '../../../redux/selectors/marketplace.selector';
import * as actions from '../../../redux/actions/marketplace.actions';



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SelectMarket = props => {
   const {marketData, userRole, selectedMarket, handleClose, handleSelectMarket, headingStyle } = props
   const [selectedValue, setSelectedValue] = useState(selectedMarket)
   const dispatch = useDispatch()
   const { marketPlaceDetails } = useSelector(selectMarketPlaceDetails);
   const {data} = marketPlaceDetails;
   const [marketLabelData, setMarketLabelData] = useState([]);
   
   const [themeName, setThemeName] = useState("");
   useEffect(() => {
       const storedTheme = localStorage.getItem("appTheme");
       setThemeName(storedTheme); 
     }, []);

  useEffect(() =>{
    if(!data && userRole !== 2){
        dispatch(actions.getMarketPlace());
    }
  }, [data, userRole]) // eslint-disable-line

  useEffect(() => {
    userRole === 2 ? setMarketLabelData(marketData) : setMarketLabelData(data);

  }, [data, marketData, userRole])

// console.log('marketData ', marketData)
    const handleSelectValue = (event) => {
      const {target : {value}} = event;
      setSelectedValue(value);
    }
    const handleSelectedClose = () =>{
      // console.log('selectedValue ', selectedValue);
      if(selectedValue === 'selectmarket' ) return;
      handleSelectMarket(selectedValue);
    }
// console.log(data)
  return (
    <div>
      <Dialog
        open={true}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          sx: {
            width: '25%', // Adjust the width as needed
            // height: '32%', // Adjust the height as needed
            // boxShadow: themeName === 'dark' ? '0px 0px 5px 2px rgba(255, 255, 255, 1.5)' : '0px 0px 5px 2px rgba(0, 0, 0, 0.5)', 
            border: themeName === 'dark' ? '1px solid white' : '1px solid black', 
          },
        }}
      >
        <DialogTitle className='typo headingText' sx={headingStyle}>
            Select Market
        </DialogTitle>
        <DialogContent sx={{display:'flex', flexDirection:'column'}}>
            {/* <List sx={{display:'flex', flexFlow:'column'}}> */}
              <RadioGroup 
                aria-labelledby="demo-radio-buttons-group-label"
                value= {selectedValue}
                onChange={handleSelectValue}
                name="radio-buttons-group">
              {marketLabelData && marketLabelData.map((item, index) => {
                const market_place_name = userRole !== 2 ? item.market_place_name : item;
                // console.log(item);
                return (<FormControlLabel key={`check${index}`} value={market_place_name} sx={{fontSize:11, color:'text.secondary'}} 
                  control={<Radio id={market_place_name} 
                  // checked={Number(item.value) === Number(selectedLoc) ? true:false} 
                  sx={{padding:'5px'}} size="small" />} 
                  label= {<Typography variant='body2' sx={{}}>{market_place_name}</Typography>} />)
                
              })}
              </RadioGroup>
            {/* </List> */}
            {/* {selectedValue === 'selectmarket' && <Typography variant='body2'>Please Select Market Place.</Typography>} */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSelectedClose}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default SelectMarket