import { call, put, takeLatest  } from 'redux-saga/effects';  //takeLatest
// import { isEmpty, } from 'lodash';
import * as actions from '../actions/matchrate.actions';
import * as constants from '../constants/matchrate.constants';
import NetworkApi from '../../api/NetworkApi';
import { getItems } from '../../utils/storage';
import paths from '../../api/path';

const getApiConfig = () => {
  const [
    token
  ] = getItems([
    'token',
  ]);

  const headers = {
    Authorization: `token ${token}`,
  };

  return {
   // headers,
    Authorization: headers.Authorization
  }
}

// export function* resetProduct(){}


export function* handleMatchRate(request = {}) {
  const apiConfig = getApiConfig()
  const { payload = {} } = request;
  const userRole = getItems(['role'])
  const {user_id,comp_prd_id} = payload
  // console.log(payload, paths.matchRateURL);

  try {
    const url = Number(userRole[0] === 2)
    ? `${paths.qaUpdateCompetitorURL}?comp_prd_id=${comp_prd_id}&user_id=${user_id}`
    : paths.matchRateURL;
  
  let response;
  
  if (Number(userRole[0] === 2)) {
    response = yield call(NetworkApi.put, url, payload, apiConfig);
  } else {
    response = yield call(NetworkApi.post, url, payload, apiConfig);
  }
  
    // console.log(response);
    if (response && response.status === 'success') {
      const responseData = {...response, comp_prd_id:payload.comp_prd_id}
      // console.log(responseData);
        yield put(actions.matchrateSuccessful(responseData));
    } else {
      // console.log('error ', response)
      const message = response?.status ? (
        response?.status
      ) : (
        ''
      );
      yield put(actions.matchrateFailed(message));
    }

  } catch (error) {
    const message = error?.response?.status ? (
      error.response.status
    ) : (
      ''
      // <FormattedMessage id="notProcess" />
    );
    yield put(actions.matchrateFailed(message));
  }
}


export function* postMatchRate() {
  yield takeLatest(constants.UPDATE_MATCHRATE, handleMatchRate);
}
