import { PRD_UPDATE_MATCH,PRD_UPDATE_MATCH_FAILED,PRD_UPDATE_MATCH_RESET,PRD_UPDATE_MATCH_SUCCESSFULL } from "../constants/updateEnablematch.constants";


  
  export const editPrdmatchRate = (payload) => ({
    type: PRD_UPDATE_MATCH,
    payload: payload,
  });
  
  export const editPrdmatchRateSuccessful = (response) => ({
    type: PRD_UPDATE_MATCH_SUCCESSFULL,
  payload: {
    status: response
  },
  });
  
  export const editPrdmatchRateFailed = (error) => ({
    type: PRD_UPDATE_MATCH_FAILED,
    payload: error,
  });

