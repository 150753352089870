import { put, takeLatest, call } from 'redux-saga/effects';
import axios from 'axios';
import { GET_CLIENTS_SUCCESS, GET_CLIENTS, GET_CLIENTS_FAIL } from '../constants/clients.constants';
import { getClientsSuccess, getClientsFail } from '../actions/clientsActions';
import NetworkApi from '../../api/NetworkApi';
import { getItems, getItem } from '../../utils/storage';
import paths from '../../api/path';

const getApiConfig = () => {
  const [token] = getItems(['token']);

  const headers = {
    Authorization: `token ${token}`,
    
  };

  return {
    Authorization: headers.Authorization,
  };
};
function* getClientsSaga() {
  const apiConfig = getApiConfig();
  const userRole = getItem('role');
  
  try {
    const response = yield call(NetworkApi.get, Number(userRole) === 1 && `${paths.clientsUrl}`,null, apiConfig);
    // console.log('Response data:', response);
    yield put(getClientsSuccess(response));
    // console.log("success")
  } catch (error) {
    yield put(getClientsFail(error.message));
  }
}

export function* watchGetClients() {
  yield takeLatest(GET_CLIENTS, getClientsSaga);
}
