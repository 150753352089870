import { DELETE_MARKET_PLACE_FULL,DELETE_MARKET_PLACE_FULL_FAILED,DELETE_MARKET_PLACE_FULL_SUCCESSFUL } from "../constants/deleteMarketplacefull.constants";


export const deleteMarketPlaceFull = (payload)=>({
    type:DELETE_MARKET_PLACE_FULL,
    payload:payload
})
export const deleteMarketPlaceSuccessfulFull = (response)=>({
    type:DELETE_MARKET_PLACE_FULL_SUCCESSFUL,
    payload:response
})
export const deleteMarketPlaceFailedFull = (error)=>({
    type:DELETE_MARKET_PLACE_FULL_FAILED,
    payload:error
})
